import { fetch } from '../utils/request';
import { withRetries } from '../utils/helpers';

const StoryAPI = process.env.STORY_API;

/**
 * Get list of featured posts for the given tagboard.
 * @param {Number} tagboardId - ID of the tagboard holding the featured posts.
 * @param {Number} [opts.count] - Number of posts to fetch
 * @param {Number} [opts.before] - Pagination flag
 * @returns {Object}
 */
const getFeaturedPosts = async (tagboardId, opts = {}) => {
  const {
    count = 30,
    before = null,
  } = opts;

  const resp = await fetch(`${StoryAPI}/posts/${tagboardId}`, {
    query: {
      count,
      before,
    },
  });

  return resp;
};

/**
 * Get list of social posts by owner (id).
 * @param {string|number} owner
 * @param {Object[]} posts - List of posts to fetch via post_id
 */
const getManyPosts = async (owner, posts) => {
  const resp = await fetch(`${StoryAPI}/owner/${owner}/many`, {
    method: 'POST',
    body: JSON.stringify({ posts }),
  });

  return resp;
};

export default {
  getFeaturedPosts: withRetries(getFeaturedPosts, { retryDelay: 500 }),
  getManyPosts: withRetries(getManyPosts, { retryDelay: 500 }),
};
