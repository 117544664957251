<template>
  <div class="post-container">
    <div class="post">
        <div class="media-module">
          <img :src="imageUrl" :data-id="post.post_id" alt="" class="media photo">
        </div>
    </div>
  </div>
</template>

<script>
import postUtils from '../../../../utils/post-utils';
import PostRenderMixins from '../../../../mixins/post-render-mixins';

export default {
  mixins: [PostRenderMixins],
  props: ['post', 'theme'],
  computed: {
    imageUrl() {
      if (this.post.photos && this.post.photos.length) {
        if (this.post.network === 'instagram') {
          return postUtils.instagramMediaUrl(this.post, 't');
        }

        return (this.post.photos[0].s || this.post.photos[0].m);
      }

      if (this.post.videos && this.post.videos.length) {
        if (this.post.network === 'youtube') {
          return this.post.videos[0].t;
        }

        if (this.post.network === 'instagram') {
          return postUtils.instagramMediaUrl(this.post, 't');
        }

        return this.post.videos[0].p;
      }

      return null;
    },
  },
};
</script>
