/**
 * Calculate total leave duration of element animations (includes child elements)
 * @param {Object} element - Graphic or graphic layer
 */
const getLeaveDuration = (element) => {
  if (!element || element.hidden) {
    return 0;
  }

  // Duration of base element
  let duration = element.animation
    ? Number(element.animationOutDuration) + Number(element.animationOutDelay)
    : 0;

  // Check if element has children and get their duration recursively
  if (element.elements && element.elements.length) {
    duration = Math.max(
      duration,
      ...element.elements.map((el) => getLeaveDuration(el)),
    );
  }

  return duration;
};

const LeaveDurationBuffer = 55;

/**
 * Return largest leave duration found on layers of graphic.
 * Buffer helps alleviate the flashing between full screen graphics.
 * @param {Object} graphic
 * @returns {Number}
 */
const calcLeaveDuration = (graphic) => Math.max(LeaveDurationBuffer, getLeaveDuration(graphic));

export {
  LeaveDurationBuffer,
  getLeaveDuration,
  calcLeaveDuration,
};
