<template>
  <div v-bind:class="getThemeClass" class="theme-wrapper">
    <div class="fullscreen-wrapper">
      <div class="display-wrapper">
        <div v-bind:class="{ 'darkened-background': theme.settings.darkenBackground }" class="backdrop" v-bind:style="{ backgroundImage: 'url(' + theme.settings.backgroundImage + ')' }">
          <video v-if="theme.settings.backgroundVideo !== ''" v-bind:src="theme.settings.backgroundVideo" class="background-video" width="auto" autoplay loop muted></video>
          <div class="background-overlay"></div>
        </div>

        <div class="posts" v-bind:class="theme.settings.animation">
          <post v-if="currentPost && shouldShowStoryPost(currentPost)" :key="getKey(currentPost)" :theme="theme" :post="currentPost"></post>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '../../../../utils/event-bus';
import StoriesPostComponent from './StoriesPost.vue';
import HeaderComponent from '../../../header/v1.0/Header.vue';
import AnimationMixins from '../../../../mixins/animation-mixins';
import LayoutMixins from '../../../../mixins/layout-mixins';

export default {
  props: ['posts', 'post', 'theme'],

  components: {
    'header-component': HeaderComponent,
    post: StoriesPostComponent,
  },

  mixins: [
    LayoutMixins,
    AnimationMixins,
  ],

  methods: {
    setPost() {
      const self = this;

      self.currentPost = self.post;
      if ((self.isGif || !self.isVideo) && !self.theme.settings.interactive) {
        EventBus.$emit('resetTimer');
        EventBus.$emit('startTimer', self.theme.settings.displayTime);
      }
    },

    shouldShowStoryPost(postData) {
      const postNetwork = postData.network;
      const themeNetwork = this.theme.settings.network;

      return (postNetwork === 'instagram-stories' && themeNetwork === 'instagram') || (postNetwork === 'snapchat' && themeNetwork === 'snapchat');
    },
  },
};
</script>
