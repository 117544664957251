<template>
    <group
      :animkey="element.id"
      :element="elementWithMappedData"
      :graphic="graphic"
    />
</template>

<script>
import { cloneDeep } from 'lodash';
import { mapState, mapActions } from 'vuex';
import GraphicsMixins from '../../mixins/graphics-mixins';
import Group from '../group/Group.vue';

export default {
  name: 'Shoppable',

  components: {
    Group,
  },

  mixins: [GraphicsMixins],

  computed: {
    ...mapState({
      // namespaced - arrow functions don't work here because of the scope of this
      productData(state, getters) {
        return getters[`${this.element.id}_${this.graphic.timeline_id}/getProduct`];
      },
      termsText(state, getters) {
        return getters[`${this.element.id}_${this.graphic.timeline_id}/getTermsText`];
      },
      formattedPrice(state, getters) {
        return getters[`${this.element.id}_${this.graphic.timeline_id}/getFormattedProductPrice`];
      },
    }),

    shoppableData() {
      return this.element.data;
    },

    hasProduct() {
      return this.productData?.url;
    },

    elementWithMappedData() {
      return {
        ...this.element,
        elements: this.mappedChildElements,
      };
    },

    mappedChildElements() {
      return this.mapData(this.element);
    },
  },

  watch: {
    shoppableData: {
      deep: true,
      handler(newValue) {
        this.setData(newValue);
      },
    },
  },

  methods: {
    ...mapActions({
      async setData(dispatch, payload) {
        await dispatch(`${this.element.id}_${this.graphic.timeline_id}/setData`, payload);
        this.mapData();
      },
    }),
    mapData() {
      const clone = cloneDeep(this.element.elements.filter((e) => e.visible));

      const { url, name, image, shortUrl } = this.productData;
      const { formattedPrice, termsText } = this;

      function setDataValues(path) {
        switch (path) {
          case 'productName':
            return name || '';

          case 'productPrice':
            return formattedPrice || '';

          case 'productImage':
            return image || '';

          case 'termsText':
            return termsText || '';

          default:
            return shortUrl || url || ''; // affiliate link
        }
      }

      return clone.map((e) => {
        if (e.data?.dataKeyMap) {
          if (!this.hasProduct) {
            // not having data breaks the universe of the elements, so if the user hasn't picked a product, we need to hide the element
            e.visible = false;
            return e;
          }
          e.data.dataValues = {};
          Object.entries(e.data?.dataKeyMap).forEach(([key, value]) => {
            e.data.dataValues[key] = setDataValues(value);
          });
        }

        return e;
      });
    },
  },
};
</script>
