import { get } from 'lodash-es';

export default {
  methods: {
    formatPath(path, data) {
      const map = {
        seasonId: get(data, 'Season.seasonId', ''),
        teamId: get(data, 'Team.teamId', ''),
        playerId: get(data, 'Player.playerId', ''),
      };

      return Object.keys(map).reduce((memo, key) => (
        memo.replace(RegExp(`{{${key}}}`, 'ig'), map[key])
      ), path);
    },

    formatValue(value, {
      key = false, custom = false, typeName = false, schemaInfo = false,
    } = {}) {
      const rawValue = value;
      const numberValue = Number(value);
      let format = custom;

      if (key && typeName && schemaInfo) {
        const lowerKey = key.toLowerCase();
        const schemaType = get(schemaInfo[typeName], key); // Get type info from schema
        if (lowerKey.endsWith('_pct')) format = 'percent';
        else if (schemaType === 'Float') format = 'float';
        else if (lowerKey === 'weight') format = 'weight';
        else if (lowerKey === 'height') format = 'height';
      }

      const isValidNumber = typeof numberValue === 'number' && !Number.isNaN(numberValue);

      switch (format) {
        case 'percent':
          return isValidNumber
            ? `${(numberValue < 1 ? (numberValue * 100).toFixed(1) : numberValue.toFixed(1))}%`
            : '0.0%';
        case 'weight':
          return isValidNumber ? `${numberValue} lbs.` : '0 lbs.';
        case 'height':
          return isValidNumber
            ? `${Math.floor(numberValue / 12)}'${Math.round(numberValue % 12)}"`
            : '0\'0"';
        case 'float':
          return isValidNumber ? numberValue.toFixed(1) : '0.0';
        default:
          if (isValidNumber) return numberValue.toString();
          if (typeof rawValue === 'string') return rawValue; // if "stat" is a string value (e.g. playerPosition), just return it
          return '0';
      }
    },
  },
};
