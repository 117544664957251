/* eslint-disable no-underscore-dangle */
import {
  get,
  isEmpty,
  pick,
  omit,
} from 'lodash-es';
import { SRClient, resolverNames } from '../../utils/sportradar';
import { preloadImage } from '../../utils/helpers';

/**
 * Maps GraphQL schema fields from an introspection query response.
 * This function processes the given introspection query response to filter and map the schema types
 * and their respective fields. It excludes certain types and system types (starting with '__').
 * @param {Object} introspectionResponse - The response object from a GraphQL introspection query.
 * @returns {Object} An object mapping each type name to its fields, with field types.
 */
// TODO: share with graphics (tgb-gql-helpers?), along with some other schema-related functions
function mapGqlSchemaFields(introspectionResponse) {
  const EXCLUDED_TYPE_NAMES = ['Query', 'Endpoints', 'RecordValue'];
  const { types } = introspectionResponse.__schema;
  const filteredTypes = types.filter((type) => {
    const { kind, name } = type;
    return kind === 'OBJECT' && !name.startsWith('__') && !EXCLUDED_TYPE_NAMES.includes(name);
  });
  const schemaTypesList = filteredTypes.map((t) => t.name);

  return filteredTypes.reduce((typeHash, currentType) => {
    const { name: typeName, fields } = currentType;

    const typeFields = {};

    fields.forEach((field) => {
      const { name: fieldName } = field;
      const { name: ofTypeName } = field?.type || {};
      typeFields[fieldName] = (schemaTypesList.includes(ofTypeName)) ? 'Object' : ofTypeName;
    });

    // eslint-disable-next-line no-param-reassign
    typeHash[typeName] = typeFields;

    return typeHash;
  }, {});
}

const state = () => ({
  loaded: false,
  sportradarData: {},
  schemaInfo: null,
});

/* eslint-disable no-shadow, no-param-reassign */
const mutations = {
  SET_DATA(state, payload) {
    state.sportradarData = payload;
  },
  SET_LOADED(state, payload) {
    state.loaded = payload;
  },
  SET_SCHEMA_INFO(state, payload) {
    state.schemaInfo = payload;
  },
};
const actions = {
  // eslint-disable-next-line no-shadow,consistent-return
  async getData({ state, commit }, payload) {
    const {
      graphicOwner,
      graphicElements,
      sport,
      seasonId,
      teamId,
      playerId,
      gameId,
      controlOptions,
      includeImages = true,
    } = payload;

    SRClient.setOwner(graphicOwner);

    const statsData = await SRClient.getData({
      sport,
      seasonId,
      teamId,
      playerId,
      gameId,
      controlOptions,
      includeImages,
    });
    let data = { images: state.sportradarData.images || { teamLogo: null, playerHeadshot: null } };

    const resolverName = Object.keys(statsData).find((key) => resolverNames.includes(key));
    const resolverData = statsData[resolverName];
    const entityType = resolverName.replace('get', '');

    if (includeImages && (teamId || playerId)) {
      if (playerId) data.images.playerHeadshot = statsData[resolverName]?.Player?.PlayerHeadshot?.imageUrl;
      if (teamId) data.images.teamLogo = statsData[resolverName]?.Team?.TeamLogo?.imageUrl;

      // Fall back to the API if any expected images are missing.
      if ((playerId && !data.images.playerHeadshot) || (teamId && !data.images.teamLogo)) {
        const checkForImageElements = (elements) => {
          const teamLogos = elements.filter((el) => el?.type === 'image' && el?.name === 'Team Logo' && el?.data?.dataKeyMap?.imageUrl === 'images.teamLogo');
          const playerImages = elements.filter((el) => el?.type === 'image' && el?.name === 'Player Headshot' && el?.data?.dataKeyMap?.imageUrl === 'images.playerHeadshot');
          return {
            hasTeamLogoElement: !!teamLogos?.length,
            hasPlayerImageElement: !!playerImages?.length,
          };
        };

        const {
          hasTeamLogoElement,
          hasPlayerImageElement,
        } = checkForImageElements(graphicElements);

        const imagesData = await SRClient.getImagesData({
          sport,
          teamId: (hasTeamLogoElement) ? teamId : null,
          playerId: (hasPlayerImageElement) ? playerId : null,
        });

        if (teamId && imagesData.teamLogo) data.images.teamLogo = imagesData.teamLogo || null;
        if (playerId && imagesData.playerHeadshot) data.images.playerHeadshot = imagesData.playerHeadshot || null;
      }
    }

    if (!resolverData) {
      // if no stats data set data needed for displaying name and other things.
      // TODO: stopgap for now. We shouldn't need an extra call to get the names, etc, when no stats data exists.
      // probable solution is to update the resolver to return the names, etc, when no stats data exists.
      const statSelectData = await SRClient.getStatSelectionData({
        leagueName: sport,
        seasonId,
        teamId,
        playerId,
        gameId,
        ownerId: graphicOwner,
        controlOptions,
      });
      const statType = controlOptions.player ? 'player' : 'team';
      const statPeriod = controlOptions.game ? 'game' : 'season';
      const Player = statType === 'player' ? statSelectData?.currentPlayers?.find((p) => p.playerId === playerId) : { playerId };
      const Game = statPeriod === 'game' ? statSelectData?.currentGames?.find((p) => p.gameId === gameId) : { gameId };
      const Season = statSelectData?.currentSeason || { seasonId };
      const Team = statSelectData?.currentTeam || { teamId };
      commit('SET_DATA', {
        seasonId,
        teamId,
        playerId,
        gameId,
        controlOptions,
        Player,
        Game,
        Season,
        Team,
        ...data,
      });
      return commit('SET_LOADED', true);
    }

    if (resolverData) {
      // properties to be nested under stats entity
      const statProperties = [
        'statistics',
        'teamTotal',
        'teamAverage',
        'opponentsTotal',
        'opponentsAverage',
        'total',
        'average',
      ];

      // create a new object with only the properties to be nested
      const nestedProperties = pick(resolverData, statProperties);

      if (!isEmpty(nestedProperties)) {
        // set data under entity name
        data[entityType] = nestedProperties;
      }

      // create a new object excluding the nested properties
      const remainingResolverData = omit(resolverData, statProperties);

      data = { ...data, ...remainingResolverData };
    }

    // preload images
    if (data.images && includeImages) {
      const imagePaths = new Set();

      // recursively get unique mapped image paths
      const getImagePaths = (elements) => {
        elements.forEach((el) => {
          if (el.data?.dataKeyMap) {
            // get unique mapped image paths for preloading
            Object.entries(el.data.dataKeyMap).forEach(([key, value]) => {
              if (['imageUrl', 'backgroundImageUrl'].includes(key)) {
                imagePaths.add(value);
              }
            });
          }

          if (el.elements?.length) {
            getImagePaths(el.elements);
          }
        });
      };

      getImagePaths(graphicElements);



      await Promise.all([...imagePaths].map(async (path) => {
        await preloadImage(get(data, path)).catch(console.error);
      }));
    }

    commit('SET_DATA', {
      seasonId,
      teamId,
      playerId,
      gameId,
      controlOptions,
      ...data,
    });
    commit('SET_LOADED', true);
  },
  async getSchemaInfo({ commit }, leagueName) {
    try {
      const schemaInfo = await SRClient.getSchemaInfo(leagueName);
      const schema = mapGqlSchemaFields(schemaInfo, leagueName);
      commit('SET_SCHEMA_INFO', schema);
    } catch (error) {
      console.error('Error fetching schema info:', error);
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
