<template>
  <div class="post-panel">
    <iframe
      class="smart-panel"
      :class="panel.type"
      allow="autoplay"
      border="0"
      height="100%"
      width="100%"
      :src="panelsBase + '/render/' + panel._id"
    />
  </div>
</template>

<script>
import PanelMixins from '../../../mixins/panel-mixins';
import EventBus from '../../../utils/event-bus';

const { PANELS_BASE } = require('../../../services/services');

export default {
  props: ['panel', 'theme', 'post'],
  mixins: [PanelMixins],
  data() {
    return {
      panelsBase: PANELS_BASE,
    };
  },
  mounted() {
    this.$frame = $(this.$el).find('iframe');

    if (!this.isVideo) {
      EventBus.$emit('startTimer', this.theme.settings.displayTime);
    }
  },
};
</script>
