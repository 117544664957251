<template>
  <div :class="getThemeClass" class="theme-wrapper">
    <div
      class="fullscreen-wrapper"
      :style="{ 'background-color': computedBackgroundColor }"
      :class="[
        theme.settings.direction,
        theme.settings.template,
        getSidebarPosition,
        'post-color-theme-' + theme.settings.postColorTheme,
        'font-' + theme.settings.font,
        { 'safe-area': safeArea },
        'cta-template-' + theme.settings.ctaTemplate,
        'cta-social-icon-style-' + theme.settings.ctaSocialIconStyle,
        { 'no-title': !theme.settings.showCtaText },
        { 'no-social-icons': !theme.settings.showCtaSocialIcons },
      ]">
      <div
        v-if="safeArea && isOnlyFullscreen"
        :class="{ 'darkened-background': theme.settings.darkenBackground }"
        class="backdrop"
        :style="[backgroundStyle]">
        <video
          v-if="theme.settings.backgroundVideo"
          :src="theme.settings.backgroundVideo"
          class="background-video"
          width="auto"
          autoplay
          loop
          muted></video>
        <div class="background-overlay"></div>
      </div>
      <div
        class="display-wrapper"
        :style="[
          { height: getHeight },
          { width: getWidth },
          theme.settings.backgroundImage !== ''
            ? {} : { 'background-color': theme.settings.backgroundColor },
          safeArea ? wrapperStyles : {},
        ]">
        <div
          v-if="showBackground"
          :class="{ 'darkened-background': theme.settings.darkenBackground }"
          class="backdrop"
          :style="[backgroundStyle]">
          <video
            v-if="theme.settings.backgroundVideo"
            :src="theme.settings.backgroundVideo"
            class="background-video"
            width="auto"
            autoplay
            loop
            muted></video>
          <div class="background-overlay"></div>
        </div>

        <header-component
          :theme="theme"
          v-if="theme.settings.template !== 'lowerthird'"
        />

        <template v-if="theme.settings.direction === 'vertical'">
          <transition-group
            class="posts"
            :class="{ 'hide-links': theme.settings.hideLinks }"
            tag="div"
            @before-enter="beforeEnter"
            @after-enter="afterEnter"
            name="carousel-anim">
            <post-vertical
              :class="[theme.settings.animation, { active: index === 1 }]"
              v-for="(postData, index) in columnPosts"
              :key="postData.post_id"
              :theme="theme"
              :post="postData"></post-vertical>
          </transition-group>
        </template>

        <template v-if="theme.settings.direction === 'horizontal'">
          <transition-group
            class="posts"
            :class="{ 'hide-links': theme.settings.hideLinks }"
            tag="div"
            @before-enter="beforeEnter"
            @after-enter="afterEnter"
            name="carousel-anim">
            <post-horizontal
              :class="[theme.settings.animation, { active: index === 1 }]"
              v-for="(postData, index) in columnPosts"
              :key="postData.post_id"
              :theme="theme"
              :post="postData"></post-horizontal>
          </transition-group>
        </template>

        <template v-if="theme.settings.showOverlay">
          <div
            v-if="theme.settings.direction === 'horizontal'"
            class="carousel-overlay">
            <div class="left"></div>
            <div class="right"></div>
          </div>

          <div v-else class="carousel-overlay">
            <div class="top"></div>
            <div class="bottom"></div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash-es';

import EventBus from '../../../../utils/event-bus';
import CarouselVerticalPostComponent from './CarouselPostVertical.vue';
import CarouselHorizontalPostComponent from './CarouselPostHorizontal.vue';
import HeaderComponent from '../../../header/v1.5/Header.vue';
import MultipostMixins from '../../../../mixins/multipost-mixins';
import LayoutMixins from '../../../../mixins/layout-mixins';

export default {
  props: ['theme', 'post', 'posts'],

  components: {
    'header-component': HeaderComponent,
    'post-vertical': CarouselVerticalPostComponent,
    'post-horizontal': CarouselHorizontalPostComponent,
  },

  mixins: [
    LayoutMixins,
    MultipostMixins,
  ],

  data() {
    return {
      columnPosts: [],
      animating: false,
    };
  },

  computed: {
    enterAnim() {
      return this.$store.getters.getEnter;
    },
    exitAnim() {
      return this.$store.getters.getExit;
    },
    mode() {
      return this.$store.getters.getMode;
    },
    isOnlyFullscreen() {
      return !this.isLowerThird && !this.isSidebar;
    },
    isLowerThird() {
      return this.theme.settings.template === 'lowerthird';
    },
    isSidebar() {
      return this.theme.settings.template === 'sidebar';
    },
    getBackgroundColor() {
      if (this.theme.settings.template === 'lowerthird' || this.theme.settings.template === 'sidebar') {
        return this.theme.settings.keyColor;
      }
      return this.theme.settings.backgroundColor;
    },
    getSidebarPosition() {
      if (this.theme.settings.template === 'sidebar') {
        return this.theme.settings.sidebarPosition;
      }
      return '';
    },
    getWidth() {
      return this.theme.settings.template === 'sidebar' ? `${this.theme.settings.sidebarWidth}%` : '';
    },
    getHeight() {
      return this.theme.settings.template === 'lowerthird' ? `${this.theme.settings.lowerThirdHeight}%` : '';
    },
    safeArea() {
      return this.theme.settings.safeArea;
    },
    wrapperStyles() {
      const {
        template, safeWidth, safeHeight, sidebarPosition,
      } = this.theme.settings;

      const styles = {
        'max-width': `${safeWidth}px`,
        'max-height': `${safeHeight}px`,
      };

      if (template === 'sidebar') {
        if (sidebarPosition === 'left') {
          styles.left = `calc((100% - ${safeWidth}px) / 2)`;
        } else if (sidebarPosition === 'right') {
          styles.right = `calc((100% - ${safeWidth}px) / 2)`;
        }
      } if (template === 'lowerthird') {
        styles.bottom = `calc((100% - ${safeHeight}px) / 2)`;
      }

      return styles;
    },
    safeAreaMaxWidth() {
      const { safeArea, safeWidth } = this.theme.settings;

      return safeArea && safeWidth && safeWidth > 0
        ? `${safeWidth}px`
        : '100%';
    },
    safeAreaMaxHeight() {
      const { safeArea, safeHeight } = this.theme.settings;

      return safeArea && safeHeight && safeHeight > 0
        ? `${safeHeight}px`
        : '100%';
    },
    computedBackgroundColor() {
      if (this.theme.settings.safeArea && this.isOnlyFullscreen) {
        return this.theme.settings.backgroundColor;
      }
      return this.getBackgroundColor;
    },
    backgroundStyle() {
      if (this.theme.settings.backgroundImage !== '') {
        return {
          backgroundImage: `url('${this.theme.settings.backgroundImage}')`,
        };
      }
      return {};
    },
    showBackground() {
      const { backgroundVideo, backgroundImage } = this.theme.settings;

      if (
        (this.safeArea && !this.isOnlyFullscreen && (backgroundImage || backgroundVideo))
      || (!this.safeArea && this.isOnlyFullscreen && (backgroundImage || backgroundVideo))
      ) {
        return true;
      }

      return false;
    },
  },

  created() {
    this.getColumnPosts(this.posts);
  },

  mounted() {
    EventBus.$on('resize', this.setFontSize);
    this.setFontSize();
  },

  beforeDestroy() {
    EventBus.$off('resize', this.setFontSize);
  },

  methods: {
    setPost() {
      const self = this;

      if (!this.columnPosts.length
      || (this.animating && this.theme.settings.interactive)) { return; }

      this.checkPostMedia(this.post)
        .then(() => {
          self.$store.dispatch('setDisplayNum', self.post.post_id);

          this.$nextTick(() => {
            const temp = cloneDeep(self.columnPosts);
            if (self.columnPosts.length === 3) {
              temp.pop();
            }
            temp.splice(0, 0, self.post);
            self.columnPosts = temp;
          });
        }).finally(() => {
          if (!self.theme.settings.interactive) {
            self.$store.dispatch('startTimer', self.theme.settings.displayTime);
          }
        }).catch(() => {
          EventBus.$emit('advance', 'next');
        });
    },
    beforeEnter() {
      this.animating = true;
    },
    afterEnter() {
      this.animating = false;
    },
    setFontSize() {
      const $displayWrapper = $('.display-wrapper');

      if ($displayWrapper.width() / $displayWrapper.height() <= 1) {
        $displayWrapper.css('font-size', $displayWrapper.width() * 0.05);
      } else if ($displayWrapper.width() / $displayWrapper.height() > 1
      && $displayWrapper.width() / $displayWrapper.height() <= 4 / 3) {
        $displayWrapper.css('font-size', $displayWrapper.height() * 0.04);
      } else if ($displayWrapper.width() / $displayWrapper.height() > 4 / 3
      && $displayWrapper.width() / $displayWrapper.height() <= 2) {
        $displayWrapper.css('font-size', $displayWrapper.height() * 0.05);
      } else {
        $displayWrapper.css('font-size', $displayWrapper.height() * 0.08);
      }
    },
    sort(a, b) {
      const aDisplayNum = a.display_num;
      const bDisplayNum = b.display_num;

      if (aDisplayNum === bDisplayNum) {
        // If these posts haven't been shown, sort by curated time
        return b.curated_time - a.curated_time;
      }
      // Otherwise, sort by order the posts were displayed
      return aDisplayNum - bDisplayNum;
    },
    getColumnPosts(posts) {
      const promiseAll = [];
      const holder = [];
      const self = this;

      posts.forEach((post) => {
        promiseAll.push(
          self.checkPostMedia(post)
            .then(() => {
              holder.push(post);
            }).catch(() => {}),
        );
      });

      Promise.all(promiseAll).then(() => {
        self.columnPosts = holder.slice(0, 2).reverse();
        self.columnPosts.forEach((post) => {
          self.$store.dispatch('setDisplayNum', post.post_id);
        });

        this.$nextTick(() => {
          $(self.$el).find('.posts').imagesLoaded()
            .always(() => {
              window.parent.postMessage('tgbLive:loaded', '*');
              self.$emit('frameloaded');
              if (!self.theme.settings.interactive) {
                self.$store.dispatch('startTimer', self.theme.settings.displayTime);
              }
            });
        });
      }).catch((reason) => {
        console.error(reason);
      });
    },
  },
};
</script>
