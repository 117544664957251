import MediaProxy from '../utils/media-proxy';
import postUtils from '../utils/post-utils';

import { proxyNetworks } from '../utils/appSettings';

export default {
  data() {
    return {
      proxyAttempt: false,
    };
  },

  computed: {
    isGif() {
      return !!(this.post && this.post.videos && this.post.videos[0].type === 'gif');
    },

    isVideo() {
      return !!(this.post && this.post.videos && this.post.videos.length);
    },

    videoPlayer() {
      switch (this.post.network) {
        case 'youtube':
          return 'youtube';
        case 'facebook':
          return 'facebook';
        default:
          return 'generic';
      }
    },

    isVerified() {
      return {
        verified: this.post.service_meta && this.post.service_meta.user_verified,
        gov: this.post.service_meta && this.post.service_meta.user_verified && this.post.service_meta.verified_type === 'government',
        org: this.post.service_meta && this.post.service_meta.user_verified && this.post.service_meta.verified_type === 'business',
      };
    },

    dynamicClasses() {
      return {
        'has-media': this.hasMedia,
      };
    },

    noText() {
      return {
        'no-text': !this.postHtml,
      };
    },

    videoType() {
      try {
        if (this.post?.videos?.length > 0) {
          return this.post.videos[0].type || '';
        }
        return '';
      } catch (error) {
        console.log('Error determining video type:', error);
        return '';
      }
    },
    hasMedia() {
      return this.post.photos?.length > 0 || this.post.videos?.length > 0;
    },

    hasOverlay() {
      return this.post.videos && this.post.videos[0].o;
    },

    avatarUrl() {
      const {
        user_profile_image_url: url = '',
        network,
      } = this.post;

      if (!url) return ''; // since for whatever reason, url can be null
      switch (network) {
        case 'twitter': {
          return url.replace('_normal', '_200x200');
        }

        case 'facebook': {
          if (url?.includes('graph.facebook.com')) {
            return `${url.split('?')[0]}?type=large`;
          }

          return url;
        }

        default: {
          return url;
        }
      }
    },

    imageUrl() {
      const { photos, videos } = this.post;

      if (photos?.length) {
        const url = (this.post.photos[0].l || this.post.photos[0].m);

        if (this.post.type === 'instagram') {
          return postUtils.instagramMediaUrl(this.post, 'l');
        }

        return this.proxyAttempt ? MediaProxy.genUrl(url) : url;
      }

      if (videos?.length) {
        if (this.post.network === 'youtube') {
          return this.post.videos[0].t;
        }

        return this.post.videos[0].p;
      }

      return '';
    },
    noAvatar() {
      let hasAvatar = true;

      // Should this post attempt to display an avatar or not
      switch (this.post.network) {
        case 'share-it':
        case 'reddit':
        case 'instagram':
          // Instagram posts don't display avatars
          hasAvatar = false;
          break;
        case 'facebook':
          if (this.post.tgb_meta && this.post.tgb_meta.isComment) {
            // Facebook Comments don't display avatars
            hasAvatar = false;
          }
          break;
        default:
          break;
      }

      return hasAvatar ? '' : 'no-avatar';
    },

    noAuthor() {
      return (['instagram', 'share-it'].includes(this.post.network) && !this.post.user_name) ? 'no-author' : '';
    },

    posterUrl() {
      const { videos } = this.post;

      if (!videos?.length) {
        return '';
      }

      const url = this.post.videos[0].p;
      return url;
    },

    overlayUrl() {
      const { videos } = this.post;

      if (!videos?.length) {
        return '';
      }

      const url = this.post.videos[0].o;
      return url;
    },

    sourceSnapchat() {
      return {
        'source-snapchat': this.post.tgb_meta.source === 'story.snapchat.com',
      };
    },

    postHtml() {
      return this.post.html || this.post.text;
    },

    hasCommentOrAttribution() {
      return this.post.text || this.post.user_name;
    },

    noCommentOrAttribution() {
      return this.hasCommentOrAttribution ? '' : 'no-text';
    },
  },

  methods: {
    updateVideoUrl(payload) {
      if (this.post) {
        this.$store.dispatch('updatePost', {
          post_id: this.post.post_id,
          property: 'videos[0].m',
          value: payload,
        });
      }
    },

    imageError(e) {
      // Most layouts check post images before display inside layout-mixins.js, for those that don't,
      // we can attempt to proxy the images here after they fail
      if (MediaProxy.usingNetwork(this.post.network, proxyNetworks) && this.post.post_type === 'photo' && !MediaProxy.urlIsProxied(e.currentTarget.currentSrc)) {
        this.proxyAttempt = true;
      } else if (MediaProxy.urlIsProxied(e.currentTarget.currentSrc)) {
        // this URL was already proxied and failed again, lets remove it.
        this.$store.dispatch('removePost', this.post.post_id);
      }
    },

    layoutPost() {
      try {
        // TODO: Rewrite without jquery
        const $post = $(this.$el);
        if (!$post?.length) { return; }

        const $textModule = $post.find('.text-module');
        const $message = $post.find('.message');
        const $metaAuthor = $post.find('.meta-author');
        const $postTime = $post.find('.post-time');
        let $postTimeHeight;

        $post.removeClass('portrait-post');

        if ($post.hasClass('twitter')) {
          $post.find("a:contains('pic.twitter.com')").addClass('twitter-media-url');
          $post.find("a:contains('https://twitter.com')").addClass('quote-tweet-url');
        }
        $post.find("a:not('.hashtag'):not('.mention'):not('.twitter-media-url'):not('.quote-tweet-url')").addClass('link');

        $message.css('font-size', '');

        // aspect ratio < 3/4
        if ($post.outerWidth() / $post.outerHeight() < 1) {
          $post.addClass('portrait-post');
        }

        if ($postTime.length && $postTime.is(':visible')) {
          $postTimeHeight = $postTime.outerHeight(true);
        } else {
          $postTimeHeight = 0;
        }

        // shrinkify post text
        if ($message.length) {
          $message.shrinkify({
            minFontPct: 5,
            maxHeightPx: $textModule.height() - $metaAuthor.outerHeight(true) - $postTimeHeight,
            width: false,
          });
        }
      } catch (err) {
        console.error('Error updating layoutPost', err);
      }
    },
  },
};
