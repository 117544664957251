import EventBus from '../utils/event-bus';

export default {
  computed: {
    key_screen() {
      return this.$store.getters.getKeyScreen;
    },

    isKeyScreen() {
      return this.$store.getters.isKeyScreen;
    },

    isVideo() {
      return this.panel.type === 'vimeo' || this.panel.type === 'twitch' || this.panel.type === 'youtube';
    },

    playing() {
      return this.$store.getters.isPlaying;
    },

    videoPlaying() {
      return this.$store.getters.videoPlaying;
    },

    muted() {
      return this.$store.getters.isMuted;
    },

    displayActions() {
      return this.$store.state.production.displayActions;
    },

    frame() {
      return this.$frame && this.$frame[0];
    },
  },
  watch: {
    muted(muted) {
      if (!this.frame) {
        return;
      }

      if (muted) {
        this.frame.contentWindow.postMessage('tgbLive:mute', '*');
      } else {
        this.frame.contentWindow.postMessage('tgbLive:unmute', '*');
      }
    },

    videoPlaying(videoPlaying) {
      if (!this.frame) {
        return;
      }

      if (videoPlaying) {
        this.frame.contentWindow.postMessage('tgbLive:playVideo', '*');
      } else {
        this.frame.contentWindow.postMessage('tgbLive:pauseVideo', '*');
      }
    },

    displayActions(actions) {
      if (!this.frame) {
        return;
      }

      if (actions?.length) {
        this.frame.contentWindow.postMessage(`tgbLive:handleAction:${JSON.stringify(actions[0])}`, '*');
      }
    },
  },

  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage);
  },

  mounted() {
    const self = this;

    this.failTimeout = setTimeout(() => {
      EventBus.$emit('advance', 'next');
    }, 10000); // fail safe if for any reason a smart panel fails to load

    self.receiveMessage = function _receiveMessage(e) {
      if (typeof e.data !== 'string' || e.data.indexOf('tgbLive') === -1) { return; }

      const settings = {
        playing: self.playing,
        videoPlaying: self.videoPlaying,
        muted: self.muted,
        key_screen: self.key_screen,
        is_preview: self.$store.state.client.is_preview,
        isKeyScreen: self.isKeyScreen,
      };

      const action = e.data.split(':')[1];
      const data = e.data.split(':')[2];

      switch (action) {
        case 'loaded':
          clearTimeout(self.failTimeout);
          self.iframeLoaded();
          break;

        case 'getSettings':
          e.source.postMessage(`tgbLive:sendSettings:${JSON.stringify(settings)}`, '*');
          break;

        case 'pauseVideo':
          /* noop */
          break;

        case 'playVideo': {
          // Re-emit duration when the video plays to make sure it's up-to-date.
          // There could be a race condition where the duration is received BEFORE playing,
          // so the auto-advance timer doesn't get started.
          if (this.duration) {
            self.$store.dispatch('setPlayDuration', this.duration);
            self.$store.dispatch('setVideoDuration', this.duration);
          }

          break;
        }

        case 'advance':
          EventBus.$emit('advance', 'next');
          break;

        case 'duration': {
          this.duration = JSON.parse(data);
          self.$store.dispatch('setPlayDuration', this.duration);
          self.$store.dispatch('setVideoDuration', this.duration);
          break;
        }

        default: break;
      }
    };
    window.addEventListener('message', self.receiveMessage, false);
  },

  methods: {
    iframeLoaded() {
      this.$emit('frameloaded');
      this.$emit('postloaded');
    },
  },
};
