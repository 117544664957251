/* eslint-disable no-shadow */

import {
  findIndex,
  set,
  assign,
  omit,
} from 'lodash-es';

import Timer from '../../utils/timer';

import {
  diffpatcher,
  getNextItemOrPost,
  getLiveLayoutWithData,
  findTimelineItem,
  updateTimeline,
  setPrevProduction,
  updatePrevProduction,
} from '../../utils/production';

import SessionStore from '../../utils/sessionStorage';
import Animations from '../../utils/animations';
import Shortener from '../../utils/shortener';

import ProductionService from '../../services/production';

const getMeta = (name) => {
  const el = document.querySelector(`meta[name=${name}]`);
  if (!el) { return ''; }
  return el.getAttribute('content');
};

const state = {
  timeline: [],
  live: {},
  owner: null,
  direction: 'next',
  index: null,
  sub_index: null,
  videoPlaying: false,
  playing: false,
  muted: true,
  looped: false,
  backgroundType: 'color',
  backgroundColor: 'rgba(0,0,0,0)',
  backgroundImage: '',
  backgroundVideo: {
    url: '',
    poster: '',
    type: '',
  },
  enter: 'animated fadeInRight',
  exit: 'animated fadeOutLeft',
  transition: 'animated cutIn',
  transitionMode: 'out-in',
  mode: '',
  duration: 0,
  videoDuration: 0,
  display_token: null,
  displayActions: [],
  timer: null,
};

const actions = {
  /**
   * Received videoDuration from another client.
   * If another client beats this one to the punch,
   * then we can avoid sending duplicate socket messages
   * out by comparing the calculated duration to the one still in state.
   */
  async socket_videoDuration(context, payload) {
    const { duration, preview } = payload;

    if (preview === context.state.preview) {
      context.commit('SET_VIDEO_DURATION', duration);
    }
  },

  async socket_timeline(context, payload) {
    const production = context.state;
    const { client } = context.rootState;

    if (!payload || ('preview' in payload && !client.preview_room)) {
      return;
    }

    // Start by immediately merging in payload sans the `live` object.
    // This is so we know what's supposed to be live later and helps avoid race conditions.
    // Then we'll handle preloading missing data in the `live` map.
    context.commit('TIMELINE', omit(payload, ['live']));

    // Check delta to see if `playing` state has changed.
    // We are doubling down on this check because `timelineSettings` already handles it.
    // We can either remove timelineSettings or remove playing from the delta.
    const { playing } = payload.delta || {};
    if (playing) {
      // [old, new] pair
      const [, isPlaying] = playing;

      if (isPlaying) {
        context.dispatch('startAdvanceTimer');
      } else {
        context.dispatch('stopAdvanceTimer');
      }
    }

    const { owner } = payload;

    if (owner) {
      // NOTE: We don't need all these separate owner fields
      // state.owner (necessary to load posts if missing)
      context.commit('SET_PRODUCTION_OWNER', owner);

      // Owner field in snippets store
      context.dispatch('setOwner', owner);
    }

    // Get social posts from live element (if there are some),
    // and make sure they are loaded and put in the store.
    const live = await getLiveLayoutWithData(context, payload.live);
    if (!live) { return; }

    // For themes without a post, sub_index will match the layout index for some reason
    const {
      index,
      sub_index: subIndex,
    } = production;

    const hasChanged = (
      index !== (live.layout?.timeline_id || null)
      || (live.post?.timeline_id && subIndex !== (live.post?.timeline_id || null))
    );

    // Now only update `live` if it matches the current index/sub_index
    // This prevents race conditions when the current element changes while loading data
    // We'll check state.live instead of state.index, since that might've already updated from the delta.
    const isAlreadyLive = state.live?.layout?.timeline_id === payload?.live?.layout?.timeline_id;
    if ((!hasChanged && !isAlreadyLive) || payload.force) {
      // This could probably be a separate mutation entirely to avoid confusion if this works.
      context.commit('TIMELINE', { live });
    }
  },

  socket_timelineSettings(context, payload) {
    const { playing } = context.state;
    const { client } = context.rootState;

    // Ignore settings for preview if this isn't preview and vice versa
    if (!payload || ('preview' in payload && !client.preview_room)) {
      return;
    }

    context.commit('TIMELINE_SETTINGS', payload);

    // `playing` is also updated in `socket_timeline()` so this is duplicate code
    // to make sure we handle it when it changes
    if ('playing' in payload && playing !== payload.playing) {
      if (payload.playing) {
        context.dispatch('startAdvanceTimer');
      } else {
        context.dispatch('stopAdvanceTimer');
      }
    }
  },

  socket_startPlayback(context) {
    context.commit('START_VIDEO');
  },

  socket_stopPlayback(context) {
    context.commit('STOP_VIDEO');
  },

  socket_onReceiveAction(context, payload) {
    context.commit('UPDATE_ACTIONS', payload);
  },

  /**
   * Forced reload message received from Producer
   */
  socket_reload() {
    window.location.reload();
  },

  startAdvanceTimer(context) {
    const {
      index,
      sub_index: subIndex,
      timer,
      preview,
    } = context.state;

    // Prioritize videoDuration when it exists
    const duration = context.state.videoDuration || context.state.duration;

    // Preview iframe doesn't get the priviledge of auto-advancing
    if (preview) { return; }

    // If current live element hasn't changes,
    // just adjust current duration on existing timer and make sure it's started
    if (timer && timer.meta.index === index && timer.meta.subIndex === subIndex) {
      timer.setDuration(duration);

      if (!timer.isRunning) {
        timer.start();
      }

      return;
    }

    timer?.pause();
    context.commit('SET_TIMER', null);

    if (context.state.playing && duration) {
      context.commit('SET_TIMER', new Timer({
        // Using index/sub_index to track if we already changed
        meta: { index, subIndex },
        duration,
        onDone: () => {
          // Check that timeline is still playing before triggering advance,
          // jic the timer wasn't cleaned up properly
          if (context.state.playing) {
            context.dispatch('advance');
          }
        },
      }));
    }
  },

  stopAdvanceTimer(context) {
    const { timer } = context.state;
    timer?.pause();
  },

  resetAdvanceTimer(context) {
    const { timer, preview } = context.state;

    if (preview) {
      return;
    }

    timer?.pause();
    context.commit('SET_TIMER', null);
  },

  /**
   * Advance to the next timeline item and/or social post in a theme.
   * If `from` is provided, then it will get the next item after the provided index/sub_index
   * @param {Object} context
   * @param {Object} [from] - Object containing index and sub_index to advance from.
   */
  async advance(context, from) {
    const { timer } = context.state;

    // If timer doesn't exist yet, just pull current value from state
    // e.g. timer won't exist yet on initial load of production
    const index = timer?.meta?.index || context.state.index;
    const subIndex = timer?.meta?.subIndex || context.state.sub_index;

    // eslint-disable-next-line no-param-reassign
    from = {
      index: context.state.index,
      subIndex: context.state.sub_index,
      ...(from || {}),
    };

    // If live layout and/or post has changed already, then ignore the auto-advance
    if (index !== context.state.index || subIndex !== context.state.sub_index) {
      return;
    }

    const { timeline, looped } = context.state;

    const nextItem = getNextItemOrPost({
      timeline,
      looped,
      index: from.index,
      subIndex: from.subIndex,
    });

    const isSameItem = (
      nextItem.index === from.index
      && nextItem.subIndex === from.subIndex
    );

    // If next item matches current item,
    // and playlist isn't set to loop, then stop advancing.
    if (isSameItem && !looped) {
      // Nowhere to go, so stop playing
      context.dispatch('stopAdvanceTimer');
      context.dispatch('stopPlaying');
    }

    // Send new index/sub_index to clients using deltas
    const delta = diffpatcher.diff({
      index: from.index,
      sub_index: from.subIndex,
    }, {
      index: nextItem.index,
      sub_index: nextItem.subIndex,
    });

    const layout = timeline.find((item) => item.timeline_id === nextItem.index);
    const post = (layout?.posts || []).find((post) => post.timeline_id === nextItem.subIndex);

    let live = { layout, post };

    // We send the delta to update index/sub_index
    // and the raw `live` field for other outputs
    vm.$socket.emit('updateTimeline', {
      production_id: context.rootState.client.production_id,
      user_id: context.state.owner,
      delta,
      live,
      force: true,
    });

    context.dispatch('resetAdvanceTimer');

    // Go ahead and merge in the delta and `live` properties locally too
    // since the socket message won't emit back to this client
    try {
      live = await getLiveLayoutWithData(context, live);
    } catch (err) {
      // If something goes wrong with fetching/attached data, just force another advance
      // Retries will happen inside getLiveLayoutWithData,
      // so if an error reaches here it's probably bad.
      Sentry.captureException(err);

      // Make sure the user still WANTS to advance in the first place
      if (context.state.playing) {
        // Then skip this item and move to the next one
        context.dispatch('advance', nextItem);
      }

      return;
    }

    context.commit('TIMELINE', { delta, live });
  },

  stopVideo(context) {
    context.commit('STOP_VIDEO');
    vm.$socket.emit('stopVideo', { production_id: context.rootState.client.production_id });
  },

  stopPlaying(context) {
    context.commit('STOP_PLAYING');

    vm.$socket.emit('settings', {
      production_id: context.rootState.client.production_id,
      playing: state.playing,
      videoPlaying: state.videoPlaying,
      looped: state.looped,
      muted: state.muted,
      timelineLocked: state.timelineLocked,
    });
  },

  startTimer(context, payload) {
    context.commit('SET_ITEM_DURATION', payload);

    if (context.state.playing) {
      // Make sure we start the auto-advance timer
      context.dispatch('startAdvanceTimer');
    }

    vm.$socket.emit('playDuration', {
      production_id: context.rootState.client.production_id,
      duration: payload,
    });
  },

  /**
   * Legacy function name, it now just functions as a wrapper for starting the timer
   * and emitting the play duration to connected clients.
   * @param {Object} context
   * @param {Number} payload - Play duration for auto-advance timer.
   */
  setPlayDuration(context, payload) {
    context.dispatch('startTimer', payload);
  },

  /**
   * Set videoDuration in state and emit to other clients
   * so they know how long the video in the social post is.
   * @param {Object} context
   * @param {Number} duration - Video duration
   */
  setVideoDuration(context, duration) {
    const {
      production_id: productionId,
    } = context.rootState.client;

    const { videoDuration, preview } = context.state;

    context.commit('SET_VIDEO_DURATION', duration);

    // Don't bother emitting to other clients if video duration is zero,
    // or if the video duration has not changed.
    // Other clients should've already reset their state back to zero by this point.
    if (duration && duration !== videoDuration) {
      vm.$socket.emit('videoDuration', {
        production_id: productionId,
        duration,
        preview,
      });
    }
  },

  sendError(context, payload) {
    const {
      production_id: productionId,
      screen_id: screenId,
    } = context.rootState.client;

    const {
      message, title, error,
    } = payload;

    vm.$socket.emit('error', {
      production_id: productionId,
      screen_id: screenId,
      message,
      title,
      error,
    });
  },

  resetTimer() { /* noop */ },

  setDisplayToken(context, token) {
    context.commit('SET_DISPLAY_TOKEN', token);

    // Add meta tag to head so other libraries can find token,
    // until we clean up caching of it in localStorage.
    if (!getMeta('data-display-token') && token) {
      const el = document.createElement('meta');
      el.setAttribute('name', 'data-display-token');
      el.setAttribute('content', token);
      document.head.appendChild(el);
    }
  },

  async getProduction(context, id) {
    const data = await ProductionService.getProduction(id);

    // Set owner before all else, since we need it to make other preload requests
    const { owner } = data;
    if (owner) {
      // NOTE: We don't need all these separate owner fields
      // state.owner (necessary to load posts if missing)
      context.commit('SET_PRODUCTION_OWNER', owner);

      // Owner field in snippets store
      context.dispatch('setOwner', owner);
    }

    // Use preview_room, not is_preview
    // is_preview is also set for the LIVE previews
    const isPreview = !!context.rootState.client.preview_room;
    let autoAdvance = false;

    // Reconstruct `live` object in case something is already live
    // Skip if this is preview though
    if (data.index && data.index !== '0' && data.timeline?.length && !isPreview) {
      const layout = data.timeline.find((item) => item.timeline_id === data.index);
      const post = (layout?.posts || []).find((post) => post.timeline_id === data.sub_index);

      const live = { layout, post };

      try {
        data.live = await getLiveLayoutWithData(context, live);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('Error filling layout with data:', err);
        autoAdvance = true;
      }
    }

    context.commit('TIMELINE', data);

    // Make sure display token is cached
    if (data.display_token) {
      context.dispatch('setDisplayToken', data.display_token);
    }

    // Something went wrong with initial load,
    // so advance to next timeline item if possible
    if (autoAdvance && data.playing) {
      context.dispatch('advance');
    }

    return data;
  },

  updatePost(context, payload) {
    const { timeline } = context.state;

    const index = findIndex(timeline, (o) => o.timeline_id === state.index);
    const subIndex = findIndex(timeline[index].posts, (p) => p.timeline_id === state.sub_index);

    context.commit('UPDATE_CURRENT_POST', {
      index,
      sub_index: subIndex,
      property: payload.field,
      value: payload.newValue,
    });
  },

  updateCurrentPost(context, payload) {
    const { timeline } = context.state;

    const index = findIndex(timeline, (o) => o.timeline_id === state.index);
    const subIndex = findIndex(timeline[index].posts, (p) => p.timeline_id === state.sub_index);

    context.commit('UPDATE_CURRENT_POST', {
      index,
      sub_index: subIndex,
      property: payload.property,
      value: payload.value,
    });
  },

  removePost() {},

  setLock(context, payload) {
    const { key } = payload;
    const opts = {
      production_id: context.rootState.client.production_id,
      key,
    };

    return new Promise((resolve) => {
      vm.$socket.emit('setLock', opts, (success) => {
        resolve(success);
      });
    });
  },

  /**
   * Set properties on graphic element, then save by sending over socket.
   * @param {Object} context
   * @param {String} payload.timelineId - Playlist item ID.
   * @param {String} payload.elementId - ID of the graphic layer to add a field for.
   * @param {String} payload.property - Field key.
   * @param {any} payload.value - Value of the field.
   * @param {Boolean} [payload.concat] - If true, attempt to merge value into existing field.
   */
  setGraphicField(context, payload) {
    const {
      timelineId,
      elementId,
      property,
      value,
      concat = false,
    } = payload;

    const { timeline } = context.state;

    const item = findTimelineItem(timeline, timelineId);
    if (!item) {
      return;
    }

    if (!item.fields) {
      item.fields = {};
    }

    if (!item.fields[elementId]) {
      item.fields[elementId] = {
        visible: true,
      };
    }

    const fields = item.fields[elementId];

    if (concat && Array.isArray(fields[property])) {
      // Merge with existing list
      fields[property].push(...value);
    } else {
      // Just ignore the concat and set the property directly
      fields[property] = value;
    }

    // Make sure to update live too, or other clients won't show a QR code
    const { live } = context.state;
    live.layout.fields = item.fields;

    // We send the delta to update fields for other clients
    // and also make sure these URLs are saved for the FUTURE
    updateTimeline(state);
  },

  async generateShortUrl(context, payload) {
    const { url, element } = payload;
    const production = context.state;
    const user = production.owner;
    const item = context.state?.live?.layout;

    if (!item || !user) { return null; }

    const opts = {
      production,
      user,
      item,
      element,
    };

    const lockKey = `short-url:${url}:${element.id}:${production._id}`;
    const ts = await context.dispatch('setLock', { key: lockKey });
    if (!ts) {
      // Weren't able to create a lock,
      // so another client might already have it locked
      return null;
    }

    const { data } = await Shortener.getShortURL(url, opts);
    const { shortUrl } = data;

    context.dispatch('setGraphicField', {
      timelineId: item.timeline_id,
      elementId: element.id,
      property: 'shortLinks',
      value: [[url, shortUrl]], // tuple
      concat: true,
    });

    // Make sure to unlock. It would expire anyway, but still.
    vm.$socket.emit('unsetLock', { key: lockKey, ts });
    return shortUrl;
  },
};

const mutations = {
  /* eslint-disable no-param-reassign */

  UPDATE_ACTIONS(state, payload) {
    // try and keep this cleared out
    state.displayActions.splice(0, 1, payload);
  },

  SET_PRODUCTION_OWNER(state, owner) {
    state.owner = owner;
  },

  STOP_PLAYING() {
    state.playing = false;
  },

  START_VIDEO(state) {
    state.videoPlaying = true;
  },

  STOP_VIDEO() {
    state.videoPlaying = false;
  },

  SET_ITEM_DURATION(state, duration) {
    state.duration = duration;
  },

  SET_VIDEO_DURATION(state, duration) {
    state.videoDuration = duration;
  },

  TIMELINE_SETTINGS(state, payload) {
    updatePrevProduction(payload);
    assign(state, payload);
  },

  TIMELINE(state, p) {
    let payload = p;

    if (payload.delta) {
      const { delta } = payload;

      // If index or sub_index are updated, then clear videoDuration
      if ('index' in delta || 'sub_index' in delta) {
        state.videoDuration = 0;
      }

      diffpatcher.patch(state, delta);

      payload = omit(payload, [
        'delta',
        'timeline',
        'index',
        'sub_index',
      ]);
    }

    assign(state, payload);

    if (payload.live && payload.live.layout && payload.live.layout.settings) {
      const anim = payload.live.layout.settings.animation;
      const { layout } = payload.live.layout;

      if (layout && Animations[layout] && anim) {
        state.enter = Animations[layout][anim][state.direction].enter;
        state.exit = Animations[layout][anim][state.direction].exit;
        state.mode = Animations[layout][anim][state.direction].mode;
      }
    }

    // we need to remove display nums counter from localStorage if item not in timeline anymore
    const displayNums = {};
    const displayNumsCounter = {};
    const localDisplayNums = SessionStore('tgb_display_nums') || {};
    const localDisplayNumsCounter = SessionStore('tgb_display_nums_counter') || {};

    state.timeline.forEach((value) => {
      if (value.posts && value.posts.length) {
        if (value.posts[0].type === 'tagboard') {
          const timelineId = value.posts[0].timeline_id;

          if (localDisplayNums[timelineId]) {
            displayNums[timelineId] = localDisplayNums[timelineId];
          }

          if (localDisplayNumsCounter[timelineId]) {
            displayNumsCounter[timelineId] = localDisplayNumsCounter[timelineId];
          }
        }
      }
    });

    SessionStore('tgb_display_nums', displayNums);
    SessionStore('tgb_display_nums_counter', displayNumsCounter);

    setPrevProduction(state);
  },

  UPDATE_POST(state, payload) {
    const post = state.timeline
      .map((item) => item.posts)
      .flat()
      .find((post) => post.post_id === payload.post_id);

    if (post) {
      post[payload.field] = payload.newValue;
    }
  },

  UPDATE_CURRENT_POST(state, payload) {
    set(state.live.post, payload.property, payload.value);
  },

  SET_DISPLAY_TOKEN(state, token) {
    state.display_token = token;
  },

  SET_TIMER(state, timer) {
    state.timer = timer;
  },
};

const getters = {
  getMode(state) {
    return state.mode;
  },

  getEnter(state) {
    return state.enter;
  },

  getExit(state) {
    return state.exit;
  },

  isPlaying() {
    return state.playing;
  },

  videoPlaying() {
    return state.videoPlaying;
  },

  isMuted() {
    return state.muted;
  },

  needsVideoRemoval() {
    return false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
