import { cloneDeep, has } from 'lodash-es';

import { filterByRange } from '../utils/sheets';

export default {
  props: {
    element: {
      type: Object,
      required: true,
    },

    graphic: {
      type: Object,
      required: true,
    },

    data: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    filters() {
      return this.element.data?.filters || [];
    },

    range() {
      return this.element.data?.range;
    },

    settings() {
      return this.element.settings || {};
    },

    pagination() {
      return this.filters.find((f) => f.type === 'pagination');
    },

    page() {
      const { pagination } = this;
      return (pagination && pagination.page) || 0;
    },

    limit() {
      const { pagination } = this;
      return (pagination && pagination.limit) || 0;
    },

    /**
     * Filters data using range, pagination, etc.
     */
    filteredData() {
      let data = cloneDeep(this.data);

      if (this.range) {
        data = filterByRange(data, this.range);
      }

      // Then, pipe data through filters (e.g. pagination)
      for (let i = 0; i < this.filters.length; i += 1) {
        const filter = this.filters[i];

        switch (filter.type) {
          case 'pagination': {
            const start = (filter.page - 1) * filter.limit;
            const end = start + filter.limit;

            data = data
              // Start From
              .slice(filter.offset - 1)
              // Page + Limit Per Page
              .slice(start, end);

            // Pad with empty data if page isn't full
            // This is to stop jank with animations,
            // since the elements just... disappear
            if (data.length < filter.limit) {
              data.push(
                ...Array.from({ length: filter.limit - data.length }).map(() => []),
              );
            }

            break;
          }

          default: {
            // unsupported filter type
            break;
          }
        }
      }

      return data;
    },

    virtualElements() {
      const { layout, gap } = this.settings;

      // For each row in the filtered data...
      return this.filteredData.map((data, idx) => (
        // ... render every element again
        this.element.elements.slice().map((el) => {
          const isEmptyRow = data.length === 0;

          const element = cloneDeep({ ...el, id: `virtual_${el.id}_${idx}` });

          this.attachData(data, element, isEmptyRow);

          if (layout === 'row') {
            // Horizontal
            const repeatOffset = (this.element.styles.width + gap) * (idx);
            element.styles.left += repeatOffset;
          } else {
            // Vertical (default; fallback)
            const repeatOffset = (this.element.styles.height + gap) * (idx);
            element.styles.top += repeatOffset;
          }

          return element;
        })
      ));
    },
  },

  methods: {
    /**
     * Attach data values directly to elements that need them
     * Loops over children of groups to recursively inject provided data
     * NOTE: We might need to support elements that are mapped to multiple fields in the future
     * @param {Array<Object>} data - Row of data
     * @param {Object} el - Element to attempt to inject data into
     */
    attachData(data, el, isEmptyRow = false) {
      const element = el;
      if (!element) { return; }

      // Inject data into element
      if (data && element.data) {
        const { columnIdx, dataKeyMap } = element.data;
        if (dataKeyMap) {
          // new multimap way
          const dataValues = Object.keys(dataKeyMap).reduce((res, k) => {
            const currentDataPoint = data[dataKeyMap[k]];
            let value;
            switch (k) {
              case 'backgroundImageUrl':
              case 'imageUrl':
                if (has(currentDataPoint, 'img') && currentDataPoint.img.indexOf('http') === 0) {
                  value = currentDataPoint.img;
                } else if (has(currentDataPoint, 'text') && `${currentDataPoint.text}`.indexOf('http') === 0) {
                  // String conversion because text might be a number
                  value = currentDataPoint.text;
                } else {
                  // If neither img, or text is formatted as a URL, then ignore them
                  value = el[k];
                }
                break;

              default:
                value = currentDataPoint?.text ?? '';
                break;
            }

            res[k] = value;
            return res;
          }, {});

          element.data = {
            ...element.data,
            isEmptyRow,
            dataValues,
          };
        } else {
          // old single map way for backwards compatibility
          const dataValue = columnIdx >= 0 ? data[columnIdx] : null;

          element.data = {
            ...element.data,
            isEmptyRow,
            dataValue,
          };
        }
      } else if (!data && element.data && element.data.dataValue) {
        // Clear data if already attached to element
        // to prevent old data from rendering
        delete element.data.dataValue;
      }

      // Inject data into children of element
      if (element.elements && element.elements.length) {
        element.elements.forEach((child) => {
          this.attachData(data, child, isEmptyRow);
        });
      }
    },
  },
};
