export default {
  data() {
    return {
      ready: false
    };
  },
  mounted() {
    var self = this;

    this.$store.dispatch('getSnippets').then(function() {
      self.ready = true;
    });
  }
};
