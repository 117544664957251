<template>
  <div v-bind:class="[post.network, dynamicClasses, post.post_type, videoType, noAvatar]" class="post" v-bind:data-provider="post.network">
    <template v-if="hasMedia">
      <div class="blurred-media-module">
        <div v-if="post.videos" class="blurred-media video" v-bind:style="{ backgroundImage: 'url(' + posterUrl + ')' }"></div>
        <div v-else class="blurred-media photo" v-bind:style="{ backgroundImage: 'url(' + imageUrl + ')' }"></div>
      </div>

      <div v-if="post.videos" class="media-module">
        <component v-on:finished="advance" v-on:update-video-url="updateVideoUrl" v-bind:post="post" :theme="theme" v-bind:is="videoPlayer"></component>
      </div>

      <div v-else class="media-module">
        <img v-bind:src="imageUrl" alt="" class="media photo">
      </div>
    </template>

    <div class="text-module">
      <div v-bind:class="isVerified" class="meta-author clearfix">
        <span class="avatar" v-if="avatarUrl" v-bind:style="{ backgroundImage: 'url(' + avatarUrl + ')' }"><img v-bind:src="avatarUrl" alt="" class="user-img"></span>

        <template v-if="post.network === 'instagram'">
          <span
            v-if="post.user_name"
            class="handle"
            :class="handleStyle"
            :style="{'font-size':`${handleFontSize}px`}"
          >@<span v-html="post.user_name"></span>
          </span>
          <span
            v-else
            class="handle"
            :class="handleStyle"
            :style="{'font-size':`${handleFontSize}px`}"
          >
            Instagram user
          </span>
        </template>

        <template v-if="post.network === 'reddit'">
          <span v-if="post.service_meta.subreddit" class="subreddit">in r/<span v-html="post.service_meta.subreddit"></span></span>
          <span
            class="handle"
            :class="handleStyle"
            :style="{'font-size':`${handleFontSize}px`}"
          >by u/<span v-html="post.user_real_name"></span>
          </span>
        </template>

        <template v-if="post.network !== 'instagram' && post.network !== 'reddit'">
          <span
            class="author"
            :class="authorStyle"
            :style="{'font-size':`${authorFontSize}px`}"
            v-html="post.user_real_name"
          />
          <span
            v-if="post.network === 'twitter' || post.network === 'tiktok'"
            class="handle"
            :class="handleStyle"
            :style="{'font-size':`${handleFontSize}px`}"
          >@<span v-html="post.user_name"></span>
          </span>
        </template>
      </div>

      <div class="post-time clearfix">
        <span class="timestamp">{{post.post_time|timestampToString}}</span>
      </div>

      <div
        class="message"
        :class="postMessageStyle"
      >
        <div class="post-title" v-if="post.tgb_meta && !post.tgb_meta.isComment && post.title" v-html="post.title"></div>
        <div
          class="post-text"
          v-html="postHtml"
        ></div>
      </div>
    </div>

    <div class="text-module-horizontal">
      <div v-bind:class="isVerified" class="meta-author-horizontal clearfix">
        <span class="avatar" v-if="avatarUrl" v-bind:style="{ backgroundImage: 'url(' + avatarUrl + ')' }"><img v-bind:src="avatarUrl" alt="" class="user-img"></span>

        <template v-if="post.network === 'instagram'">
          <span
            v-if="post.user_name"
            class="handle"
            :class="handleStyle"
            :style="{'font-size':`${handleFontSize}px`}"
          >@<span v-html="post.user_name"></span></span>
          <span
            v-else
            class="handle"
            :class="handleStyle"
            :style="{'font-size':`${handleFontSize}px`}"
          >
            Instagram user
          </span>
        </template>

        <template v-if="post.network === 'reddit'">
          <span v-if="post.service_meta.subreddit" class="subreddit">in r/<span v-html="post.service_meta.subreddit"></span></span>
          <span
            class="handle"
            :class="handleStyle"
            :style="{'font-size':`${handleFontSize}px`}"
          >by u/<span v-html="post.user_real_name"></span>
          </span>
        </template>

        <template v-if="post.network !== 'instagram' && post.network !== 'reddit'">
          <span
            class="author"
            :class="authorStyle"
            :style="{'font-size':`${authorFontSize}px`}"
            v-html="post.user_real_name"
          />
          <span
            v-if="post.network === 'twitter' || post.network === 'tiktok'"
            class="handle"
            :class="handleStyle"
            :style="{'font-size':`${handleFontSize}px`}"
          >@<span v-html="post.user_name"></span>
          </span>
        </template>
      </div>

      <div class="message-horizontal">
        <div class="post-title" v-if="post.tgb_meta && !post.tgb_meta.isComment && post.title" v-html="post.title"></div>
        <div
          class="post-text"
          v-html="postHtml"
          :class="postMessageStyle"
        ></div>
      </div>
    </div>

    <div class="origin-flag"></div>
  </div>
</template>

<script>
import EventBus from '../../../../utils/event-bus';
import PostRenderMixins from '../../../../mixins/post-render-mixins';
import PostTimingMixins from '../../../../mixins/post-timing-mixins';
import YoutubeVideoComponent from '../../../video/youtube/YouTubeVideo.vue';
import FacebookVideoComponent from '../../../video/facebook/FacebookVideo.vue';
import GenericVideoComponent from '../../../video/generic/GenericVideo.vue';

export default {
  components: {
    youtube: YoutubeVideoComponent,
    facebook: FacebookVideoComponent,
    generic: GenericVideoComponent,
  },
  mixins: [PostRenderMixins, PostTimingMixins],
  props: ['post', 'theme'],

  mounted() {
    EventBus.$on('resize', this.layoutLowerThirdPost);
    this.layoutLowerThirdPost();
  },

  beforeDestroy() {
    EventBus.$off('resize', this.layoutLowerThirdPost);
  },
  computed: {
    handleStyle() {
      return [
        this.theme.settings?.handle?.textStyles ?? [''],
        `font-${this.theme.settings?.handle?.fontFamily}`,
      ];
    },

    postMessageStyle() {
      return [
        this.theme.settings?.postMessage?.textStyles ?? [''],
        `font-${this.theme.settings?.postMessage?.fontFamily}`,
      ];
    },

    authorStyle() {
      return [
        this.theme.settings?.author?.textStyles ?? [''],
        `font-${this.theme.settings?.author?.fontFamily}`,
      ];
    },

    handleFontSize() {
      return this.theme.settings?.handle?.size ?? '';
    },

    postMessageFontSize() {
      return this.theme.settings?.postMessage?.size ?? '';
    },

    authorFontSize() {
      return this.theme.settings?.author?.size ?? '';
    },
  },

  methods: {
    layoutLowerThirdPost() {
      const $post = $(this.$el);
      let $textModule = $post.find('.text-module');
      const $metaAuthor = $post.find('.meta-author');
      let $message = $post.find('.message');
      let $postText = $post.find('.message .post-text');
      const $postTime = $post.find('.post-time');
      let $postTimeHeight;

      $post.removeClass('portrait-post');

      if ($post.hasClass('twitter')) {
        $post.find("a:contains('pic.twitter.com')").addClass('twitter-media-url');
        $post.find("a:contains('https://twitter.com')").addClass('quote-tweet-url');
      }
      $post.find("a:not('.hashtag'):not('.mention'):not('.twitter-media-url'):not('.quote-tweet-url')").addClass('link');

      if (!$post.find('.text-module').is(':visible') && $post.find('.text-module-horizontal').is(':visible')) {
        // This is a horizontal layout - .meta-author on left, .message on right
        $textModule = $post.find('.text-module-horizontal');
        $message = $post.find('.message-horizontal');
        $postText = $post.find('.message-horizontal .post-text');
      }

      $message.css('font-size', '');
      $message.css('width', '');

      $postText.css('font-size', '');

      // aspect ratio < 3/4
      if ($post.outerWidth() / $post.outerHeight() < 1) {
        $post.addClass('portrait-post');
      }

      if ($postTime.length && $postTime.is(':visible')) {
        $postTimeHeight = $postTime.outerHeight(true);
      } else {
        $postTimeHeight = 0;
      }

      // shrinkify post text
      if ($post.find('.text-module').is(':visible') && !$post.find('.text-module-horizontal').is(':visible')) {
        // This is a regular layout - .meta-author on top, .message on bottom
        $message.shrinkify({
          minFontPct: 5,
          maxHeightPx: $textModule.height() - $metaAuthor.outerHeight(true) - $postTimeHeight,
          width: false,
        });
      } else {
        // This is a horizontal layout - .meta-author on left, .message on right
        $postText.shrinkify({
          minFontPct: 5,
          maxHeightPx: $textModule.height(),
          width: false,
        });
      }
    },
  },
};
</script>
