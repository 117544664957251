<template>
  <div class="display-header">
    <div
      v-if="theme.settings.showCtaText"
      class="title"
      :class="[ctaStyle, (this.showQRCode ? 'has-qr-code' : '')]"
      :style="{
        'color': theme.settings.ctaTextColor,
        'font-size': `${ctaFontSize}px`
      }">
      {{theme.settings.ctaText}}
    </div>

    <div
      class="qr-module" v-if="showQRCode">
      <img class="square-placeholder" src="/live/assets/img/square.png">
      <qr-code :settings="theme.settings" />
    </div>

    <div
      v-if="hasLogo && theme.settings.logo"
      class="logo"
      :style="logoStyle"
    />

    <div
      v-if="hasCtaSocialIcons && theme.settings.showCtaSocialIcons"
      class="networks"
      :style="socialIconsStyle">
      <span class="network-icon network-icon-twitter icon icon-twitterx"></span>
      <span class="network-icon network-icon-instagram icon icon-instagram"></span>
      <span class="network-icon network-icon-facebook icon icon-facebook"></span>
    </div>
  </div>
</template>

<script>
import QRCodeComponent from '../../qr-code/v2.0/QRCode.vue';

export default {
  props: {
    theme: {
      type: Object,
      required: true,
    },
  },

  components: {
    'qr-code': QRCodeComponent,
  },

  computed: {
    logoStyle() {
      const {
        logo,
        logoWidth,
        logoHeight,
      } = this.theme.settings;

      return {
        'background-image': `url(${logo})`,
        width: `${logoWidth}%`,
        height: `${logoHeight}%`,
      };
    },

    socialIconsStyle() {
      const {
        ctaSocialIconStyle,
        ctaSocialIconColor,
      } = this.theme.settings;

      if (ctaSocialIconStyle === 'simplified') {
        return {
          color: ctaSocialIconColor,
        };
      }

      return {};
    },

    ctaStyle() {
      return [
        this.theme.settings?.cta?.textStyles ?? [''],
        this.theme.settings?.cta?.fontFamily ? `font-${this.theme.settings?.cta?.fontFamily}` : '',
      ];
    },

    ctaFontSize() {
      return this.theme.settings?.cta?.size ?? '';
    },

    hasLogo() {
      return ![
        'lowerthird',
        'lowerthirdscroll',
        'ticker',
        'stories',
        'upnext',
      ].includes(this.theme.layout);
    },

    hasCtaSocialIcons() {
      return ![
        'lowerthird',
        'lowerthirdscroll',
        'ticker',
        'stories',
      ].includes(this.theme.layout);
    },

    showQRCode() {
      const {
        settings,
      } = this.theme;

      const {
        showQrCode,
        qrCodeCtaPlacement,
      } = settings;

      return showQrCode && qrCodeCtaPlacement;
    },
  },
};
</script>
