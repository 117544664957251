<template>
  <div v-bind:id="post.uid" v-bind:class="[post.network, dynamicClasses, post.post_type, videoType, sourceSnapchat, noAvatar]" class="post" v-bind:data-provider="post.network">
    <template v-if="hasMedia">
      <div class="blurred-media-module">
        <div v-if="post.videos" class="blurred-media video" v-bind:style="{ backgroundImage: 'url(' + posterUrl + ')' }"></div>
        <div v-else class="blurred-media photo" v-bind:style="{ backgroundImage: 'url(' + imageUrl + ')' }"></div>
      </div>

      <div v-if="post.videos" class="media-module">
        <component v-on:finished="advance" v-on:update-video-url="updateVideoUrl" v-bind:post="post" :theme="theme" v-bind:is="videoPlayer"></component>
        <img v-if="hasOverlay" v-bind:src="overlayUrl" alt="" class="overlay">
      </div>

      <div v-else class="media-module">
        <img v-bind:src="imageUrl" alt="" class="media photo">
      </div>
    </template>

    <div class="text-module">
      <div v-if="post.tgb_meta.source === 'story.snapchat.com'" class="meta-author">
        <span
          class="author"
          :class="authorStyle"
          :style="{'font-size': `${authorFontSize}px`}"
          v-html="post.user_real_name"
        />
      </div>

      <div class="origin-flag"></div>
    </div>
  </div>
</template>

<script>
import PostRenderMixins from '../../../../mixins/post-render-mixins';
import YoutubeVideoComponent from '../../../video/youtube/YouTubeVideo.vue';
import FacebookVideoComponent from '../../../video/facebook/FacebookVideo.vue';
import GenericVideoComponent from '../../../video/generic/GenericVideo.vue';
import PostTimingMixins from '../../../../mixins/post-timing-mixins';

const imagesLoaded = require('imagesloaded');

export default {
  components: {
    youtube: YoutubeVideoComponent,
    facebook: FacebookVideoComponent,
    generic: GenericVideoComponent,
  },

  mixins: [PostRenderMixins, PostTimingMixins],
  props: ['post', 'theme'],

  mounted() {
    const $post = $(this.$el);

    imagesLoaded($post, () => {
      $post.css('opacity', '');
      $post.css('opacity', '1');
    });
  },

  computed: {
    authorStyle() {
      return [
        this.theme.settings?.author?.textStyles ?? [''],
        `font-${this.theme.settings?.author?.fontFamily}`,
      ];
    },

    authorFontSize() {
      return this.theme.settings?.author?.size ?? '';
    },
  },
};
</script>
