// staying commonjs because it's used by the server files too
const services = {
  CACHE_BASE: process.env.CACHE_API,
  GRAPHICS_BASE: process.env.GRAPHICS_API,
  SCREEN_BASE: process.env.SCREEN_API || 'https://live.tagboard.com',
  STORY_BASE: process.env.STORY_API,
  INSTAGRAM_BASE: process.env.INSTAGRAM_API,
  TIKTOK_BASE: process.env.TIKTOK_API || 'https://ing-tiktok.tagboard.com',
  STATIC_BASE: process.env.TAGBOARD_STATIC_URL,
  ACCOUNT_BASE: process.env.TAGBOARD_ACCOUNT_URL,
  PLANS_BASE: process.env.PLANS_API,
  ASSETS_BASE: process.env.ASSETS_URL || 'https://assets.tagboard.com',
  ASSET_MANAGER_BASE: process.env.ASSET_MANAGER_API,
  BOARDS_BASE: process.env.BOARDS_API,
  PROXY_BASE: process.env.RANDALL_API,
  SOCKETS_BASE: process.env.SOCKETS_URL || 'https://sockets.tagboard.com',
  PANELS_BASE: process.env.PANELS_API,
  TAGBOARD_APP_BASE: process.env.TAGBOARD_APP_URL,
  LIVE_BASE: process.env.LIVE_API || 'https://live.tagboard.com'
};

module.exports = services;
