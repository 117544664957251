import { request } from '../utils/request';

const SNIPPETS_BASE = window.location.protocol + '//' + window.location.host;

export default {
  getUserSnippets (owner_id) {
    return request({
      type: 'GET',
      url: SNIPPETS_BASE + '/snippets/' + owner_id
    });
  },
  getSnippet (snippet_id) {
    return request({
      type: 'GET',
      url: SNIPPETS_BASE + '/snippet/' + snippet_id
    });
  }
};
