<template>
  <div>
    <multiple-graphics
      :item="graphic"
      :graphics="graphics"

      :playing="playing"
      :snippets="snippets"

      @before-enter="$emit('before-enter')"
      @after-enter="$emit('after-enter')"
      @before-leave="$emit('before-leave')"
      @after-leave="$emit('after-leave')"
    />
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle, no-param-reassign */

import { mapGetters } from 'vuex';
import { cloneDeep } from 'lodash-es';

import Reactive from '../../../utils/reactive';
import EventBus from '../../../utils/event-bus';

import { withRetries } from '../../../utils/helpers';

import GraphicsService from '../../../services/graphics';

import MultipleGraphics from './graphics/components/MultipleGraphics.vue';

export default {
  components: {
    MultipleGraphics,
  },

  emits: ['before-enter', 'after-enter', 'before-leave', 'after-leave', 'frameloaded'],

  props: {
    graphic: {
      type: Object,
      required: false,
    },

    snippets: {
      type: Array,
      default: [],
    },

    playing: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      liveGraphic: null,
      multipleGraphics: null,
      scale: 1.0,
    };
  },

  created() {
    if (this.graphic) {
      this.loadGraphics();
    }
  },

  watch: {
    graphic(newVal, oldVal) {
      const { layout } = this.graphic || {};

      if (!newVal || !['graphic', 'graphics'].includes(layout)) {
        // User wants nothing, so let's show them nothing
        this.liveGraphic = null;
        this.multipleGraphics = null;
        return;
      }

      if (layout === 'graphic') {
        this.handleGraphicChange(newVal, oldVal);
      } else if (layout === 'graphics') {
        this.handleGraphicsChange(newVal, oldVal);
      }
    },
  },

  computed: {
    ...mapGetters([
      'isMuted',
    ]),

    /**
     * Returns list of graphics to render.
     * This list is reversed to match expected layering of grouped graphics
     */
    graphicsToRender() {
      return this.graphics
        .slice()
        .reverse()
        .filter((g) => !g.hidden);
    },

    graphics() {
      if (this.multipleGraphics) {
        return this.multipleGraphics;
      }

      return this.liveGraphic ? [this.liveGraphic] : [];
    },
  },

  methods: {
    /**
    * Wrapper around getData and getMultipleGraphics.
    * The correct method will be called based on whether or not
    * the live graphic is a single graphic or a group of graphics.
    */
    async loadGraphics() {
      const { layout } = this.graphic;

      // Create function wrapper that will re-attempt when errors occur
      const loadFn = withRetries(
        layout === 'graphic'
          ? this.getData // Single Graphic
          : this.getMultipleGraphics, // Grouped Graphics
      );

      try {
        await loadFn();
      } catch (err) {
        // Uh oh, failed a lot
        // eslint-disable-next-line no-console
        console.log('Error loading graphic(s):', err);
        this.$emit('error', err);
      }
    },

    handleGraphicChange(newVal, oldVal) {
      if (oldVal) {
        // Don't re-fetch template, just update data
        if (newVal.timeline_id === oldVal.timeline_id) {
          if (this.liveGraphic) {
            this.updateElementFields(this.liveGraphic.elements, this.graphic);
            this.liveGraphic.displayTime = this.graphic.displayTime;
            EventBus.$emit('startTimer', this.liveGraphic.displayTime);
          }

          return;
        }
      }

      this.loadGraphics();
    },

    handleGraphicsChange(newVal, oldVal) {
      // Things changed, so just skip straight to fetching what we need
      if (newVal?.posts.length !== oldVal?.posts.length || !this.multipleGraphics) {
        this.loadGraphics();
        return;
      }

      // Could still be different, so check if same things are still in there
      const isSameGraphics = newVal.posts.every((post) => (
        this.multipleGraphics.some((g) => g.timeline_id === post.timeline_id)
      ));

      if (!isSameGraphics) {
        this.loadGraphics();
        return;
      }

      // Check if order matches, sort if not
      const isUnsorted = newVal.posts.some((post, idx) => (
        post.timeline_id !== this.multipleGraphics[idx].timeline_id
      ));

      if (isUnsorted) {
        const sortOrder = newVal.posts.map((p) => (p.timeline_id));
        this.multipleGraphics.sort((a, b) => (
          sortOrder.indexOf(a.timeline_id) - sortOrder.indexOf(b.timeline_id)
        ));
      }

      this.multipleGraphics.forEach((g, correctIdx) => {
        // Sync visibility
        g.hidden = newVal.posts[correctIdx].hidden;

        // Always try to update elements
        this.updateElementFields(g.elements, newVal.posts[correctIdx]);
      });
    },

    async getData() {
      const data = await GraphicsService.getTemplate(this.graphic.template_id);
      const graphic = data.result;

      this.updateElementFields(graphic.elements, this.graphic);

      graphic.template_id = graphic._id;
      graphic.timeline_id = this.graphic.timeline_id;
      graphic.displayTime = this.graphic.displayTime;

      this.liveGraphic = graphic;
      this.multipleGraphics = null;

      this.$nextTick(() => {
        this.$emit('frameloaded');
        EventBus.$emit('startTimer', this.liveGraphic.displayTime);
      });
    },

    async getMultipleGraphics() {
      const graphics = await Promise.all(
        this.graphic.posts.map(async (item) => {
          if (!item || !item.template_id) {
            return null;
          }

          const data = await GraphicsService.getTemplate(item.template_id);
          const graphic = data.result;

          this.updateElementFields(graphic.elements, item);

          graphic.template_id = graphic._id;
          graphic.timeline_id = item.timeline_id;
          graphic.displayTime = item.displayTime;
          graphic.hidden = item.hidden;

          return graphic;
        }),
      );

      this.multipleGraphics = graphics.filter(Boolean);
      this.liveGraphic = null;

      this.$nextTick(() => {
        this.$emit('frameloaded');
        EventBus.$emit('startTimer', this.graphic.displayTime);
      });
    },

    updateElementFields(elements, graphic) {
      for (let i = 0; i < elements.length; i += 1) {
        const element = elements[i];

        // This has to be cloned because changes to it will
        // bubble alllllll the way up to the store values
        const fields = cloneDeep(graphic.fields || {});

        if (fields[element.id] && fields[element.id].data) {
          // Merge data changes into graphic separately
          element.data = {
            ...element.data,
            ...fields[element.id].data,
          };

          delete fields[element.id].data;
        }

        element.visible = fields[element.id] ? fields[element.id].visible : true;

        if (element.elements) {
          this.updateElementFields(element.elements, graphic);
        }

        // No fields, so nothing to merge
        if (!fields[element.id]) {
          // eslint-disable-next-line no-continue
          continue;
        }

        if (fields[element.id].value) {
          // Legacy graphic settings; direct maps to text/imageUrl
          // NOTE: This has been here awhile... still necessary?
          if (element.type === 'text') {
            element.text = fields[element.id].value;
          } else {
            element.imageUrl = fields[element.id].value;
          }
        } else {
          // Merge `styles` separately from rest, so we can support partial overrides there.
          if (element.styles && fields[element.id]?.styles) {
            element.styles = {
              ...element.styles,
              ...fields[element.id]?.styles,
            };

            delete fields[element.id]?.styles;
          }

          // Merge ALL existing fields into element
          Reactive.createReactiveNestedObject(element, fields[element.id]);
        }
      }
    },
  },
};
</script>
