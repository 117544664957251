import {
  GET_SEASONS_TEAMS,
  GET_PLAYER_GAME_STATS,
  GET_PLAYER_SEASON_STATS,
  GET_TEAM_GAME_STATS,
  GET_TEAM_SEASON_STATS,
  GET_SEASONS_TEAMS_GAMES_PLAYERS,
  GET_SEASONS_TEAMS_GAMES,
  GET_SEASONS_TEAMS_PLAYERS,
  INTROSPECTION_QUERY,
} from '@tagboard/tgb-gql-helpers';

import { apolloClient } from '../services/apollo-client';

export const resolverNames = [
  'getPlayerGameStats',
  'getPlayerSeasonStats',
  'getTeamGameStats',
  'getTeamSeasonStats',
  'getSeasonsTeams',
];

export default class SportRadarClient {
  constructor(props) {
    this.baseUrl = props.baseUrl;
    this.owner = null;
    this.apolloClient = props.client;
  }

  setOwner(owner) {
    this.owner = owner;
  }

  async getSportradarData(opts = {}) {
    const {
      sport,
      route,
      query,
    } = opts;

    const fullQuery = {
      owner: this.owner,
      ...query,
    };

    const queryString = Object.keys(fullQuery).map((k) => `${k}=${fullQuery[k]}`).join('&');

    const displayToken = document.querySelector('meta[name=data-display-token]')?.getAttribute('content');

    const response = await fetch(`${this.baseUrl}/${sport}/${route}?${queryString}`, {
      mode: 'cors',
      credentials: 'include',
      headers: {
        'tgb-display-token': displayToken,
      },
    });

    if (response.ok) {
      return response.json();
    }

    throw new Error(`Error in sportradar response ${response.status} ${response.statusText}`);
  }

  static async getSportradarGraphQlData(opts = {}) {
    const {
      ownerId,
      seasonId,
      teamId,
      playerId,
      gameId,
      leagueName,
      statType,
      statPeriod,
    } = opts;

    const isPlayerGameStats = statType === 'player' && statPeriod === 'game';
    const isPlayerSeasonStats = statType === 'player' && statPeriod === 'season';
    const isTeamGameStats = statType === 'team' && statPeriod === 'game';
    const isTeamSeasonStats = statType === 'team' && statPeriod === 'season';

    let query;
    let variables;

    if (isPlayerGameStats) {
      query = GET_PLAYER_GAME_STATS;
      variables = { playerId, gameId, leagueName };
    } else if (isPlayerSeasonStats) {
      query = GET_PLAYER_SEASON_STATS;
      variables = {
        playerId, teamId, seasonId, leagueName,
      };
    } else if (isTeamGameStats) {
      query = GET_TEAM_GAME_STATS;
      variables = { teamId, gameId, leagueName };
    } else if (isTeamSeasonStats) {
      query = GET_TEAM_SEASON_STATS;
      variables = { teamId, seasonId, leagueName };
    } else {
      query = GET_SEASONS_TEAMS;
      variables = { seasonId, leagueName };
    }

    const displayToken = document.querySelector('meta[name=data-display-token]')?.getAttribute('content');
    try {
      const { data } = await apolloClient.query({
        fetchPolicy: 'network-only',
        query,
        variables,
        context: {
          headers: {
            'tgb-owner-id': ownerId,
            'tgb-display-token': displayToken,
          },
        },
      });
      return data;
    } catch (error) {
      console.error('Error fetching sportradar graphql data:', error);
      return {};
    }
  }

  async getStatSelectionData({
    leagueName,
    seasonId,
    teamId,
    playerId,
    gameId,
    ownerId,
    statPeriod,
    statType,
  }) {
    const isPlayerGameStats = statType === 'player' && statPeriod === 'game';
    const isPlayerSeasonStats = statType === 'player' && statPeriod === 'season';
    const isTeamGameStats = statType === 'team' && statPeriod === 'game';

    let query;

    if (isPlayerGameStats) {
      query = GET_SEASONS_TEAMS_GAMES_PLAYERS;
    } else if (isTeamGameStats) {
      query = GET_SEASONS_TEAMS_GAMES;
    } else if (isPlayerSeasonStats) {
      query = GET_SEASONS_TEAMS_PLAYERS;
    } else {
      // if controls are not provided, we will default to get all the things
      query = GET_SEASONS_TEAMS_GAMES_PLAYERS;
    }

    const displayToken = document.querySelector('meta[name=data-display-token]')?.getAttribute('content');
    const { data } = await this.apolloClient.query({
      query,
      variables: {
        leagueName,
        seasonId,
        teamId,
        playerId,
        gameId,
      },
      context: {
        headers: {
          'tgb-owner-id': ownerId,
          'tgb-display-token': displayToken,
        },
      },
    });

    return data.getGraphicsConnectModal;
  }

  /**
   * Fetches images data for a team and/or player.
   * Returns an object containing the URLs for team logo and player headshot.
   *
   * @param {Object} payload - The payload containing sport, teamId, and playerId.
   * @param {string} payload.sport - The sport for which to fetch images.
   * @param {string} [payload.teamId] - The team for which to fetch the logo.
   * @param {string} [payload.playerId] - The player for which to fetch the headshot.
   * @returns {Promise<{teamLogo: null|string, playerHeadshot: null|string}>} - An object containing the URLs for team logo and player headshot.
   */
  async getImagesData(payload) {
    const {
      sport,
      teamId,
      playerId,
    } = payload;

    const imagesData = { teamLogo: null, playerHeadshot: null };

    const teamLogoPromise = (teamId) ? this.getSportradarData({
      sport,
      route: `images/team/${teamId}`,
      query: {},
    }).catch(() => null) : {};

    const playerImagePromise = (playerId) ? this.getSportradarData({
      sport,
      route: `images/player/${playerId}`,
      query: {},
    }).catch(() => null) : {};

    const [teamLogo, playerImage] = await Promise.all([
      teamLogoPromise,
      playerImagePromise,
    ]);

    if (teamId && teamLogo?.image) {
      imagesData.teamLogo = teamLogo.image;
    }

    if (playerId && playerImage?.image) {
      imagesData.playerHeadshot = playerImage.image;
    }

    return imagesData;
  }

  /**
   * Fetches Sportradar stats data based on the provided parameters.
   * Returns statsData object with the data.
   *
   * @param {Object} payload
   * @returns {Promise<*|{}|undefined|{}>}
   */
  async getData(payload) {
    const {
      sport,
      seasonId,
      teamId,
      playerId,
      gameId,
      controlOptions,
    } = payload;

    try {
      return SportRadarClient.getSportradarGraphQlData({
        ownerId: this.owner,
        seasonId,
        teamId,
        playerId,
        gameId,
        leagueName: sport,
        statType: controlOptions.player ? 'player' : 'team',
        statPeriod: controlOptions.game ? 'game' : 'season',
      });
    } catch (error) {
      console.error('Error fetching Sportradar data:', error);
      return {};
    }
  }

  async getSchemaInfo() {
    try {
      const { data, error } = await this.apolloClient.query({
        query: INTROSPECTION_QUERY,
      });

      if (error) {
        throw new Error(error);
      }
      return data;
    } catch (error) {
      console.error('Error fetching sportradar schema:', error);
      return {};
    }
  }
}

export const SRClient = new SportRadarClient({
  baseUrl: process.env.SPORTRADAR_API,
  client: apolloClient,
});

export function gameChannelId(sport, gameId) {
  return `sportradar_game_${sport}_${gameId}`;
}
