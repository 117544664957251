var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"theme-wrapper",class:_vm.getThemeClass},[_c('div',{staticClass:"fullscreen-wrapper",class:[
      { 'crop-images': _vm.theme.settings.cropImages },
      {
        'show-blurred-images':
          !_vm.theme.settings.cropImages && _vm.theme.settings.showBlurredImages,
      },
      { 'hide-media-captions': !_vm.theme.settings.showMediaCaptions },
      'cta-template-' + _vm.theme.settings.ctaTemplate,
      'font-' + _vm.theme.settings.font,
      'cta-social-icon-style-' + _vm.theme.settings.ctaSocialIconStyle,
      { 'no-title': !_vm.theme.settings.showCtaText },
      { 'no-social-icons': !_vm.theme.settings.showCtaSocialIcons },
      { 'safe-area': _vm.safeArea },
    ],style:({ 'background-color': _vm.theme.settings.backgroundColor })},[(_vm.safeArea)?_c('div',{staticClass:"backdrop",class:{ 'darkened-background': _vm.theme.settings.darkenBackground },style:([_vm.backgroundStyle])},[(_vm.theme.settings.backgroundVideo)?_c('video',{staticClass:"background-video",attrs:{"src":_vm.theme.settings.backgroundVideo,"width":"auto","autoplay":"","loop":"","muted":""},domProps:{"muted":true}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"background-overlay"})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"display-wrapper",style:(_vm.safeArea ? _vm.wrapperStyles : {})},[(
          !_vm.safeArea &&
          (_vm.theme.settings.backgroundImage || _vm.theme.settings.backgroundVideo)
        )?_c('div',{staticClass:"backdrop",class:{ 'darkened-background': _vm.theme.settings.darkenBackground },style:([_vm.backgroundStyle])},[(_vm.theme.settings.backgroundVideo)?_c('video',{staticClass:"background-video",attrs:{"src":_vm.theme.settings.backgroundVideo,"width":"auto","autoplay":"","loop":"","muted":""},domProps:{"muted":true}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"background-overlay"})]):_vm._e(),_vm._v(" "),_c('header-component',{attrs:{"theme":_vm.theme}}),_vm._v(" "),_c('div',{staticClass:"posts",class:_vm.theme.settings.animation,attrs:{"data-animation":_vm.theme.settings.animation}},[(_vm.post)?[_c('transition',{attrs:{"appear":"","enter-active-class":_vm.enterAnim,"leave-active-class":_vm.exitAnim,"mode":_vm.mode}},[(_vm.currentPost)?_c(_vm.getPostType(_vm.currentPost),{key:_vm.getKey(_vm.currentPost),tag:"component",attrs:{"theme":_vm.theme,"panel":_vm.currentPost,"post":_vm.currentPost}}):_vm._e()],1)]:_vm._e()],2)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }