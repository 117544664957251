<template>
  <div
    class="marquee-text"
    :class="[
      element.cssClasses,
    ]"
    :style="styles"
    v-html="text"
  />
</template>

<script>
import GraphicsMixins from '../../mixins/graphics-mixins';

export default {
  name: 'MarqueeText',
  mixins: [GraphicsMixins],
  computed: {
    fontSize() {
      return this.element.styles.fontSize;
    },
    text() {
      if (this.element.data) {
        if ('dataValue' in this.element.data) {
          const { dataValue } = this.element.data;
          return (dataValue && dataValue.text) || '';
        }

        if ('dataValues' in this.element.data) {
          const { dataValues } = this.element.data;
          // we can map things other than text, so text could be hardcoded or come from data
          // yet have some other prop, like color based things, mapped
          return (dataValues?.text !== undefined && dataValues?.text !== null)
            ? dataValues.text : this.element.text;
        }
      }

      return this.element.text;
    },
    styles() {
      return {
        ...this.getBasicStyles,
        ...this.getTextShadowStyles,
        ...this.getTextBackgroundStyles,
      };
    },
    getTextShadowStyles() {
      if (this.element.textShadow) {
        const textShadowColor = this.element.data?.dataValues?.textShadowColor ?? this.element.textShadowColor;

        return {
          textShadow: `
          ${this.element.textShadowX}px
          ${this.element.textShadowY}px
          ${this.element.textShadowBlur}px
          ${textShadowColor}
        `,
        };
      }

      return {}; // Add a return statement at the end of the method
    },
    getTextBackgroundStyles() {
      if (this.element.background) {
        const { data } = this.element;
        const backgroundColor = data?.dataValues?.backgroundColor ?? this.element.backgroundColor;
        const gradientColorOne = data?.dataValues?.gradientColorOne ?? this.element.gradientColorOne;
        const gradientColorTwo = data?.dataValues?.gradientColorTwo ?? this.element.gradientColorTwo;

        if (this.element.fillStyle === 'gradient') {
          return {
            backgroundImage: `
            linear-gradient(${this.element.gradientDirection}deg,
            ${gradientColorOne}, ${gradientColorTwo})
            `,
          };
        }
        return {
          backgroundImage: 'none',
          backgroundColor
        };
      }
      return {}; // Add a return statement at the end of the method
    },
  },
};
</script>

<style lang="scss" scoped>
.marquee-text {
  display: block;
}
</style>
