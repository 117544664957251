<template>
  <div
    class="qr-wrapper"
    :style="{
      width: wrapperSize ? `${wrapperSize}px` : undefined,
      height: wrapperSize ? `${wrapperSize}px` : undefined,
      paddingTop: wrapperSize ? 0 : undefined,
    }"
  >
    <div
      ref="qrcode"
      class="qr-code"
      :style="{ '--dot-color': dotColor }"
      :data-test-qr-dot-color="dotColor"
      :data-test-qr-background-color="backgroundColor"
      :data-test-qr-permalink="permalink"
      :data-test-qr-logo="logo"
    />
    <!-- used to calculate height since we can't get pseudo elements -->
    <span
      ref="privacy"
      class="qr-privacy"
      aria-hidden="true"
    >privacy.tgb.io</span>
  </div>
</template>

<script>
import { debounce } from 'lodash-es';

const EasyQRCode = require('../../../../../lib/easy.qrcode.premium.min');

// Very simple. Optional https/mailto protocols, at least two character domain extension.
const urlRegex = /(https?:\/\/|mailto:)?[^\s\\]+\.[^\s\\]{2,}/;

const isURL = (url) => (
  url
  && urlRegex.test(url)
);

export default {
  props: ['settings'],

  data() {
    return {
      qrcode: null,
      wrapperSize: null,
    };
  },
  computed: {
    dotColor() {
      return this.settings.qrCodeDotColor;
    },

    backgroundColor() {
      return this.settings.qrCodeBackgroundColor;
    },

    permalink() {
      return this.settings.permalink;
    },

    logo() {
      return (this.settings.showQrCodeLogo && this.settings.qrCodeLogo) ? this.settings.qrCodeLogo : undefined;
    },

    shortLink() {
      return this.settings.shortLink;
    },
  },

  watch: {
    backgroundColor() {
      this.makeQRCode();
    },

    dotColor() {
      this.makeQRCode();
    },

    logo() {
      this.makeQRCode();
    },

    permalink(val) {
      if (isURL(val)) {
        this.makeQRCode();
      } else {
        this.$refs.qrcode.innerHTML = '';
      }

      this.updatePermalink();
    },

    shortLink() {
      this.makeQRCode();
    },
  },

  mounted() {
    this.makeQRCode();

    this.resizeObserver = new ResizeObserver(this.handleResize);
    this.resizeObserver.observe(document.body);
  },

  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },

  methods: {
    handleResize: debounce(function () {
      this.wrapperSize = null; // reset the size for checks (i.e. if the window is resized)

      this.$nextTick(() => {
        // confine the QR code to the smallest dimension
        const parentXPadding = parseInt(window.getComputedStyle(this.$el.parentElement).paddingLeft, 10)
          + parseInt(window.getComputedStyle(this.$el.parentElement).paddingRight, 10);
        const parentYPadding = parseInt(window.getComputedStyle(this.$el.parentElement).paddingTop, 10)
          + parseInt(window.getComputedStyle(this.$el.parentElement).paddingBottom, 10);
        const parentSize = Math.min(
          (this.$el.parentElement.clientWidth - parentXPadding),
          (this.$el.parentElement.clientHeight - parentYPadding),
        );
        const wrapperSize = Math.min(this.$el.clientWidth, this.$el.clientHeight);
        this.wrapperSize = Math.min(parentSize, wrapperSize);

        this.$nextTick(() => {
          this.makeQRCode();
        });
      });
    }, 200),

    makeQRCode() {
      if (!this.permalink) { return; }

      let size = this.wrapperSize;
      size -= this.$refs.privacy.clientHeight + 5; // subtract the height of the privacy text + padding

      const options = {
        text: this.shortLink || this.permalink,
        colorDark: this.dotColor,
        colorLight: this.backgroundColor,
        dotScale: 1,
        width: size,
        height: size,
        correctLevel: EasyQRCode.CorrectLevel.H, // L, M, Q, H
        eyeFrameDraw(ctx, x, y, w, h, position) {
          /* eslint-disable no-param-reassign */

          w = (w / 7) * 6;
          h = w;
          ctx.beginPath();
          const r = w / 7;
          ctx.arc(x + r, y + r, r, Math.PI, (Math.PI * 3) / 2);
          ctx.lineTo(w - r + x, y);

          if (position === EasyQRCode.EYE_POSITION.TR) {
            ctx.arc(w - r + x, r + y, r, (Math.PI * 3) / 2, Math.PI * 2);
            ctx.lineTo(w + x, h + y - r);
            ctx.arc(w - r + x, h - r + y, r, 0, (Math.PI * 1) / 2);
            ctx.lineTo(x, h + y);
            ctx.lineTo(x, y + r);
          } else if (position === EasyQRCode.EYE_POSITION.BL) {
            ctx.lineTo(w + x, y);
            ctx.arc(w - r + x, h - r + y, r, 0, (Math.PI * 1) / 2);
            ctx.lineTo(r + x, h + y);
            ctx.arc(r + x, h - r + y, r, (Math.PI * 1) / 2, Math.PI);
            ctx.lineTo(x, y + r);
          } else {
            ctx.arc(w - r + x, r + y, r, (Math.PI * 3) / 2, Math.PI * 2);
            ctx.lineTo(w + x, h + y);
            ctx.lineTo(r + x, h + y);
            ctx.arc(r + x, h - r + y, r, (Math.PI * 1) / 2, Math.PI);
            ctx.lineTo(x, y + r);
          }
          ctx.stroke();
        },
        eyeBallDraw(ctx, x, y, w) {
          const r = w / 6;
          const d = r * 2;
          for (let i = 0; i < 3; i += 1) {
            ctx.beginPath();
            for (let j = 0; j < 3; j += 1) {
              ctx.arc(x + j * d + r, y + i * d + r, r, 0, 2 * Math.PI);
            }
            ctx.fill();
          }
        },
        bodyBlockDraw(ctx, x, y, w, h, blockInfo) {
          w -= 2;
          const r = w / 2;
          x += 1;

          if (blockInfo.type === EasyQRCode.BLOCK.BLANK) {
            ctx.fillRect(x, y, w, h);
          } else if (blockInfo.type === EasyQRCode.BLOCK.SINGLE) {
            ctx.beginPath();
            ctx.arc(x + w / 2, y + h / 2, r, 0, 2 * Math.PI);
            ctx.fill();

            if (blockInfo.alignment === 'I') {
              ctx.fillStyle = this.dotColor;
              ctx.beginPath();
              ctx.arc(x + w / 2, y + h / 2, r, 0, 2 * Math.PI);
              ctx.fill();
            } else {
              ctx.beginPath();
              ctx.arc(x + w / 2, y + h / 2, r, 0, 2 * Math.PI);
              ctx.fill();
            }
          } else if (blockInfo.type === EasyQRCode.BLOCK.END) {
            const direction = blockInfo.endDirection;

            if (direction === EasyQRCode.DIRECTION.UP) {
              ctx.fillRect(x, y, w, h - r);
              ctx.beginPath();
              ctx.arc(x + w / 2, y + h / 2, r, 0, Math.PI);
              ctx.fill();
            } else if (direction === EasyQRCode.DIRECTION.DOWN) {
              ctx.fillRect(x, y + r, w, h - r);
              ctx.beginPath();
              ctx.arc(x + w / 2, y + h / 2, r, Math.PI, 2 * Math.PI);
              ctx.fill();
            } else if (direction === EasyQRCode.DIRECTION.LEFT) {
              ctx.beginPath();
              ctx.arc(x + w / 2, y + h / 2, r, 0, 2 * Math.PI);
              ctx.fill();
            } else if (direction === EasyQRCode.DIRECTION.RIGHT) {
              ctx.beginPath();
              ctx.arc(x + w / 2, y + h / 2, r, 0, 2 * Math.PI);
              ctx.fill();
            }
            ctx.fill();
          } else if (blockInfo.around.up && blockInfo.around.down) {
            ctx.fillRect(x, y, w, h);
          } else if (!blockInfo.around.up && blockInfo.around.down) {
            ctx.fillRect(x, y + r, w, h - r);
            ctx.beginPath();
            ctx.arc(x + w / 2, y + h / 2, r, Math.PI, 2 * Math.PI);
            ctx.fill();
          } else if (!blockInfo.around.down && blockInfo.around.up) {
            ctx.fillRect(x, y, w, h - r);
            ctx.beginPath();
            ctx.arc(x + w / 2, y + h / 2, r, 0, Math.PI);
            ctx.fill();
          } else {
            ctx.beginPath();
            ctx.arc(x + w / 2, y + h / 2, r, 0, 2 * Math.PI);
            ctx.fill();
          }
        },
      };

      if (this.settings.showQrCodeLogo) {
        options.logo = this.settings.qrCodeLogo;
        options.logoBackgroundTransparent = true;
        options.logoBackgroundColor = '#fff';
      }

      if (this.$refs.qrcode) {
        this.$refs.qrcode.innerHTML = '';
        this.qrcode = new EasyQRCode(this.$refs.qrcode, options);
      }
    },

    updatePermalink() {
      if (this.qrcode) {
        this.qrcode.makeCode(this.permalink);
      }
    },
  },

};
</script>
<style lang="scss" scoped>
.qr-wrapper {
  // confine the QR code to a square
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
}
.qr-code {
  // !important to override nested styles that still affect v1 QR code
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;

  &::after {
    content: 'privacy.tgb.io';
    font-size: clamp(13px, 1vw, .4em);
    margin: 0;
    display: block;
    text-align: center;
    color: var(--dot-color);
    line-height: 1 !important;
    padding: 5px 0 0 !important;
  }
}

// mirror ::after above for things that will affect the size
.qr-privacy {
  font-size: clamp(13px, 1vw, .4em);
  margin: 0;
  display: block;
  line-height: 1;
  padding: 5px 0 0;
  // hide it
  visibility: hidden;
  opacity: 0;
}
</style>
