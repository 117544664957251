<template>
  <div>
    <graphic-element
      v-for="el in childElements"
      :key="el.id"
      ref="child"
      :element="el"
      :graphic="graphic"
      v-on="$listeners"
    />
  </div>
</template>

<script>
export default {
  name: 'LinkedData',
  inject: [
    'providerData',
  ],
  props: {
    element: {
      type: Object,
      required: true,
    },

    graphic: {
      type: Object,
      required: true,
    },

    customLabels: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    GraphicElement: () => import('./render-elements'),
  },
  computed: {
    childElements() {
      return this.element.elements.slice().reverse().filter((x) => x.visible);
    },
  },
};
</script>

<style>

</style>
