<template>
  <div>
    <graphic-element
      v-for="el in childElements"
      :key="el.id"
      ref="child"
      :element="el"
      :graphic="graphic"
      :data="data"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import { get } from 'lodash-es';

export default {
  name: 'SheetData',
  components: {
    GraphicElement: () => import('../render-elements'),
  },

  props: {
    element: {
      type: Object,
      required: true,
    },

    graphic: {
      type: Object,
      required: true,
    },
  },

  computed: {
    data() {
      const { workbookId, sheet } = this.dataSettings;
      return get(this.$store.state.sheets.bySheetId, [workbookId, sheet]);
    },

    childElements() {
      const allowedTypes = ['data-list', 'marquee'];
      return this.element.elements
        .filter((el) => el.visible && allowedTypes.includes(el.type));
    },

    dataSettings() {
      return this.element.data || {};
    },
  },

  methods: {
    loadData() {
      this.$store.dispatch('getSheetData', {
        element: this.element,
        sync: true,
      });
    },
  },

  watch: {
    'element.pollingEnabled': {
      handler(pollingEnabled) {
        if (!pollingEnabled) {
          // Just disconnect from the pusher channel and don't refetch
          this.$store.dispatch('stopPolling', { element: this.element });
        } else {
          // User enabled polling, so fetch data again
          // This will also subscribe us to the pusher channel
          this.loadData();
        }
      },
    },

    'element.data': {
      deep: true,
      handler(data, oldData) {
        // If data has been force-synced, load the data again, and update the element
        if (data.lastSync !== oldData.lastSync) {
          this.loadData();
        }
      },
    },
  },
};
</script>
