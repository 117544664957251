/**
 * Checks if the given element is overflowing based on provided options.
 * @param {HTMLElement} element The HTML element to check for overflow.
 * @param {Object} options The options object.
 * @param {boolean} options.width Whether to check for width overflow.
 * @param {boolean} options.height Whether to check for height overflow.
 * @param {number} options.maxWidthPx The maximum width in pixels.
 * @param {number} options.maxHeightPx The maximum height in pixels.
 * @returns {boolean} True if overflowing, false otherwise.
 */
export function isOverflowing(element, options) {
  const {
    width, height, maxWidthPx, maxHeightPx,
  } = options;

  const rect = element.getBoundingClientRect(); // Get bounding client rect

  const elementWidth = rect.width;
  const elementHeight = rect.height;

  if ((width && (maxWidthPx || elementWidth) < element.scrollWidth)
      || (height && (maxHeightPx || elementHeight) < element.scrollHeight)) {
    return true;
  }

  return false;
}

/**
 * Resizes an element's font size to prevent overflow.
 * @param {HTMLElement} element The HTML element to resize.
 * @param {Object} options The options object.
 * @param {number} [options.minFontPct=50] The minimum font percentage.
 * @param {boolean} [options.width=true] Whether to resize based on width.
 * @param {boolean} [options.height=true] Whether to resize based on height.
 */
export function shrinkify(element, options) {
  const defaults = {
    minFontPct: 50,
    width: true,
    height: true,
    style: element.style, // Add style property to options
  };

  const mergedOptions = { ...defaults, ...options };

  if (isOverflowing(element, options)) {
    let min = options.minFontPct;
    let max = 100;
    let lastOverflow;

    while (max - min > 1) {
      const mid = Math.floor((min + max) / 2);
      mergedOptions.style.fontSize = `${mid}%`;

      if (isOverflowing(element, options)) {
        max = mid;
        lastOverflow = true;
      } else {
        min = mid;
        lastOverflow = false;
      }
    }

    if (lastOverflow) {
      mergedOptions.style.fontSize = `${min}%`;
    }
  } else {
    // If not overflowing, set font size to default
    mergedOptions.style.fontSize = ''; // Reset font size
  }
}
