<template>
  <div
    class="element image"
    :style="[getBasicStyles, getTransformStyles]"
    :class="[element.cssClasses, element.animationInStyle, element.animationOutStyle]"
  >
    <div class="image-wrap">
      <transition
        appear
        :enter-to-class="animationIn"
        :leave-to-class="animationOut"
        enter-class="enter"
        @before-enter="beforeEnter"
        @after-enter="afterEnter"
        @before-leave="beforeLeave"
        @after-leave="afterLeave"
        type="animation"
        :mode="transitionMode"
      >
        <div
          class="animated-element"
          :key="getKey"
          :class="[getFillTypeClass, getMaskClass, getLoopingClass]"
          :style="[getAnimationStyles, getBorderStyles, getImageBorderRadiusStyles, getBoxShadowStyles, getBlurStyles]"
        >
          <img
            v-if="imageUrl"
            :src="imageUrl"
            @error="handleImageError"
          >
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import GraphicsMixins from '../../mixins/graphics-mixins';

export default {
  name: 'ImageElement',
  mixins: [GraphicsMixins],

  data() {
    return {
      useFallbackImage: false,
    };
  },

  computed: {
    getFillTypeClass() {
      return this.element.fillType ? this.element.fillType : '';
    },

    maskPosition() {
      return this.element.maskPosition ?? 'center-center';
    },

    getMaskClass() {
      return this.element.mask ? `mask ${this.element.maskShape} ${this.maskPosition}` : '';
    },

    getImageBorderRadiusStyles() {
      const {
        mask,
        maskShape,
        borderTopLeftRadius: tl,
        borderTopRightRadius: tr,
        borderBottomRightRadius: br,
        borderBottomLeftRadius: bl,
      } = this.element;

      if (!mask || maskShape !== 'rectangle') {
        return {};
      }

      return {
        borderRadius: `${tl}px ${tr}px ${br}px ${bl}px`,
      };
    },
  },

  watch: {
    mappedImage() {
      this.useFallbackImage = false;
    },
  },

  methods: {
    handleImageError() {
      this.useFallbackImage = true;
    },
  },
};
</script>
