<template>
  <div
    class="marquee-image"
    :class="[
      element.cssClasses,
    ]"
    :style="[
      getBasicStyles,
      getTransformStyles,
      getBorderStyles,
      getImageBorderRadiusStyles,
      getBoxShadowStyles,
    ]"
  >
    <!-- Your component's HTML template goes here -->
    <img
      :src="imageUrl"
      alt="Marquee Image"
     @error="handleImageError"
    />
  </div>
</template>

<script>
import graphicsMixins from '../../mixins/graphics-mixins';

export default {
  name: 'MarqueeImage',
  mixins: [graphicsMixins],
  data() {
    return {
      useFallbackImage: false,
    };
  },

  computed: {
    getImageBorderRadiusStyles() {
      const {
        borderTopLeftRadius: tl,
        borderTopRightRadius: tr,
        borderBottomRightRadius: br,
        borderBottomLeftRadius: bl,
      } = this.element;

      return {
        borderRadius: `${tl}px ${tr}px ${br}px ${bl}px`,
      };
    },
  },

  watch: {
    mappedImage() {
      this.useFallbackImage = false;
    },
  },

  methods: {
    handleImageError() {
      this.useFallbackImage = true;
    },
  },
};
</script>
