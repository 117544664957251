<template>
  <div v-bind:class="[post.network, dynamicClasses, post.post_type, videoType, noAvatar]" class="post" v-bind:data-provider="post.network">
    <template v-if="hasMedia">
      <div class="blurred-media-module">
        <div v-if="post.videos" class="blurred-media video" v-bind:style="{ backgroundImage: 'url(' + posterUrl + ')' }"></div>
        <div v-else class="blurred-media photo" v-bind:style="{ backgroundImage: 'url(' + imageUrl + ')' }"></div>
      </div>

      <div v-if="post.videos" class="media-module">
        <component v-on:finished="advance" v-on:update-video-url="updateVideoUrl" v-bind:post="post" :theme="theme" v-bind:is="videoPlayer"></component>
      </div>

      <div v-else class="media-module">
        <img v-bind:src="imageUrl" alt="" class="media photo">
      </div>
    </template>

    <div class="text-module">
      <div v-bind:class="isVerified" class="meta-author clearfix">
        <span class="avatar" v-if="avatarUrl" v-bind:style="{ backgroundImage: 'url(' + avatarUrl + ')' }"><img v-bind:src="avatarUrl" alt="" class="user-img"></span>

        <template v-if="post.network === 'instagram'">
          <span
            v-if="post.user_name"
            class="handle"
            :class="handleStyle"
            :style="{'font-size': `${handleFontSize}px`}"
          >@<span v-html="post.user_name"></span>
          </span>
          <span
            v-else
            class="handle"
            :class="handleStyle"
            :style="{'font-size': `${handleFontSize}px`}"
          >
            Instagram user
          </span>
        </template>

        <template v-if="post.network === 'reddit'">
          <span v-if="post.service_meta.subreddit" class="subreddit">in r/<span v-html="post.service_meta.subreddit"></span></span>
          <span
            class="handle"
            :class="handleStyle"
            :style="{'font-size': `${handleFontSize}px`}"
          >by u/<span v-html="post.user_real_name"></span>
          </span>
        </template>

        <template v-if="post.network !== 'instagram' && post.network !== 'reddit'">
          <span
            class="author"
            :class="authorStyle"
            :style="{'font-size': `${authorFontSize}px`}"
            v-html="post.user_real_name"
          />
          <span
            v-if="post.network === 'twitter' || post.network === 'tiktok'"
            class="handle"
            :class="handleStyle"
            :style="{'font-size': `${handleFontSize}px`}"
          >@<span v-html="post.user_name"></span>
          </span>
        </template>
      </div>

      <div class="post-time clearfix">
        <span class="timestamp">{{post.post_time|timestampToString}}</span>
      </div>

      <div
        class="message"
        :class="postMessageStyle"
        :style="{'font-size': `${postMessageFontSize}px`}">
        <div class="post-scroll">
          <div class="post-title" v-if="post.tgb_meta && !post.tgb_meta.isComment && post.title" v-html="post.title"></div>
          <div
            class="post-text"
            v-html="postHtml"
          ></div>
        </div>
      </div>
    </div>

    <div class="text-module-horizontal">
      <div v-bind:class="isVerified" class="meta-author-horizontal clearfix">
        <span class="avatar" v-if="avatarUrl" v-bind:style="{ backgroundImage: 'url(' + avatarUrl + ')' }"><img v-bind:src="avatarUrl" alt="" class="user-img"></span>

        <template v-if="post.network === 'instagram'">
          <span
            v-if="post.user_name"
            class="handle"
            :class="handleStyle"
            :style="{'font-size': `${handleFontSize}px`}"
          >@<span v-html="post.user_name"></span>
          </span>
          <span
            v-else
            class="handle"
            :class="handleStyle"
            :style="{'font-size': `${handleFontSize}px`}"
          >
            Instagram user
          </span>
        </template>

        <template v-if="post.network === 'reddit'">
          <span v-if="post.service_meta.subreddit" class="subreddit">in r/<span v-html="post.service_meta.subreddit"></span></span>
          <span
            class="handle"
            :class="handleStyle"
            :style="{'font-size': `${handleFontSize}px`}"
          >by u/<span v-html="post.user_real_name"></span>
          </span>
        </template>

        <template v-if="post.network !== 'instagram' && post.network !== 'reddit'">
          <span
            class="author"
            :class="authorStyle"
            :style="{'font-size': `${authorFontSize}px`}"
            v-html="post.user_real_name"></span>
          <span
            v-if="post.network === 'twitter' || post.network === 'tiktok'"
            class="handle"
            :class="handleStyle"
            :style="{'font-size': `${handleFontSize}px`}"
          >@<span v-html="post.user_name"></span>
          </span>
        </template>
      </div>

      <div class="message-horizontal">
        <div class="post-scroll">
          <div class="post-title" v-if="post.tgb_meta && !post.tgb_meta.isComment && post.title" v-html="post.title"></div>
          <div
            class="post-text"
            v-html="postHtml"
            :class="postMessageStyle"
            :style="{'font-size': `${postMessageFontSize}px`}"
          ></div>
        </div>
      </div>
    </div>

    <div class="origin-flag"></div>
  </div>
</template>

<script>
import EventBus from '../../../../utils/event-bus';
import PostRenderMixins from '../../../../mixins/post-render-mixins';
import YoutubeVideoComponent from '../../../video/youtube/YouTubeVideo.vue';
import FacebookVideoComponent from '../../../video/facebook/FacebookVideo.vue';
import GenericVideoComponent from '../../../video/generic/GenericVideo.vue';

const imagesLoaded = require('imagesloaded');

export default {
  components: {
    youtube: YoutubeVideoComponent,
    facebook: FacebookVideoComponent,
    generic: GenericVideoComponent,
  },
  mixins: [PostRenderMixins],
  props: ['post', 'theme', 'main'],
  data() {
    return {
      fadeTimeout: null,
      resetAnimationTimeout: null,
    };
  },
  mounted() {
    EventBus.$on('resize', this.layoutLowerThirdScrollPost);
    this.layoutLowerThirdScrollPost();
  },
  beforeDestroy() {
    EventBus.$off('resize', this.layoutLowerThirdScrollPost);
    clearTimeout(this.fadeTimeout);
    clearTimeout(this.resetAnimationTimeout);
  },
  computed: {
    playing() {
      return this.$store.getters.isPlaying;
    },

    handleStyle() {
      return [
        this.theme.settings?.handle?.textStyles ?? [''],
        `font-${this.theme.settings?.handle?.fontFamily}`,
      ];
    },

    postMessageStyle() {
      return [
        this.theme.settings?.postMessage?.textStyles ?? [''],
        `font-${this.theme.settings?.postMessage?.fontFamily}`,
      ];
    },

    authorStyle() {
      return [
        this.theme.settings?.author?.textStyles ?? [''],
        `font-${this.theme.settings?.author?.fontFamily}`,
      ];
    },

    handleFontSize() {
      return this.theme.settings?.handle?.size ?? '';
    },

    postMessageFontSize() {
      return this.theme.settings?.postMessage?.size ?? '';
    },

    authorFontSize() {
      return this.theme.settings?.author?.size ?? '';
    },
  },
  methods: {
    advance() {
      if (!this.theme.settings.interactive) {
        EventBus.$emit('advance', 'next');
      }
    },
    layoutLowerThirdScrollPost() {
      const $post = $(this.$el);

      imagesLoaded($post, () => {
        $post.removeClass('portrait-post');

        // aspect ratio < 3/4
        if ($post.outerWidth() / $post.outerHeight() < 1) {
          $post.addClass('portrait-post');
        }
      });

      this.animateLowerThird();
    },
    animateLowerThird() {
      const $post = $(this.$el);
      let $textModule = $post.find('.text-module');
      const $metaAuthor = $post.find('.meta-author');
      let $message = $post.find('.message');
      let $postText = $post.find('.message .post-scroll');
      const $postTime = $post.find('.post-time');
      let $postTimeHeight;

      $post.find("a:contains('pic.twitter.com')").addClass('twitter-media-url');
      $post.find("a:not('.hashtag'):not('.mention'):not('.twitter-media-url')").addClass('link');

      if ($post.hasClass('twitter')) {
        $post.find("a:contains('pic.twitter.com')").addClass('twitter-media-url');
        $post.find("a:contains('https://twitter.com')").addClass('quote-tweet-url');
      }
      $post.find("a:not('.hashtag'):not('.mention'):not('.twitter-media-url'):not('.quote-tweet-url')").addClass('link');

      $message.css('font-size', '');
      $message.css('width', '');

      $postText.css({
        opacity: '0',
        transform: '',
        transition: '',
      });

      clearTimeout(this.fadeTimeout);
      clearTimeout(this.resetAnimationTimeout);

      if ($postTime.length && $postTime.is(':visible')) {
        $postTimeHeight = $postTime.outerHeight(true);
      } else {
        $postTimeHeight = 0;
      }

      let textAvailableSpace;

      if (!$post.find('.text-module').is(':visible') && $post.find('.text-module-horizontal').is(':visible')) {
        // This is a horizontal layout - .meta-author on left, .message on right
        $textModule = $post.find('.text-module-horizontal');
        $message = $post.find('.message-horizontal');
        $postText = $post.find('.message-horizontal .post-scroll');
        textAvailableSpace = $textModule.height();
      } else {
        // This is a regular layout - .meta-author on top, .message on bottom
        textAvailableSpace = $textModule.height() - $metaAuthor.outerHeight(true) - $postTimeHeight;
      }

      const textHeight = $postText.height();
      const lineHeight = parseInt($message.css('line-height')); // text line-height
      const lines = textHeight / lineHeight; // total number of lines
      const linesFit = textAvailableSpace / lineHeight; // total number of lines lower third can fit
      const animDistance = $postText.height() - textAvailableSpace;	// Distance to get end of text onscreen
      const scrollSpeed = 2000;
      const animDelay = 3000; // delay before text starts scrolling
      const cycleSpeed = this.theme.settings.displayTime;
      let playDuration = null;
      const animLength = (animDistance / lineHeight) * scrollSpeed; // scroll lineHeight pixels for every scrollSpeed seconds

      // scroll post text
      if (lines > linesFit && (lines - linesFit) > 0.1) {
        $postText.css({
          opacity: '1',
          transform: `translate3d(0, ${-animDistance}px, 0)`,
          transition: `transform ${animLength}ms linear ${animDelay}ms, opacity 500ms ease`,
        });

        this.resetAnimation($post, animLength + animDelay);

        if (cycleSpeed > (animLength + animDelay)) {
          playDuration = cycleSpeed;
        } else {
          playDuration = animLength + animDelay;
        }
      } else {
        $postText.css({
          opacity: '1',
          transition: 'opacity 500ms ease',
        });

        playDuration = cycleSpeed;
      }

      if (!this.timerStarted && (this.isGif || !this.isVideo) && this.main && !this.theme.settings.interactive) {
        this.timerStarted = true;
        EventBus.$emit('startTimer', playDuration);
      }
    },
    resetAnimation($post, delay) {
      const $postText = $post.find('.post-scroll');

      this.fadeTimeout = setTimeout(() => {
        $postText.css({
          opacity: '0',
          transition: 'opacity 500ms ease',
        });
      }, delay + 2000); // when ticker is done crawling, wait 2 seconds then fade to 0 opacity

      this.resetAnimationTimeout = setTimeout(() => {
        $postText.css({
          transform: '',
          transition: '',
        });

        this.animateLowerThird($post);
      }, delay + 3000); // wait 1 seconds after ticker faded to 0 opacity, then reset tranform and transition properties for $postText and call animateLowerThird
    },
  },
};
</script>
