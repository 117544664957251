import { ApolloClientManager } from '@tagboard/tgb-gql-helpers';
import pkg from '@tagboard/tgb-gql-helpers/package.json';

const apolloClientManager = new ApolloClientManager();

await apolloClientManager.initialize({
  apiUri: `${process.env.SPORTRADAR_API}/graphql`,
  enableApolloCache: process.env.APOLLO_CACHE_ENABLED, // enables local storage cache
  apiType: 'sportradar',
  connectToDevTools: process.env.NODE_ENV !== 'development',
  clientName: 'tgb-live',
  clientVersion: pkg?.version,
});

export const apolloClient = apolloClientManager.getClient();

export default {
  apolloClient,
};
