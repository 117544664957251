/**
 * Returns the base url of the media proxy.
 * @returns {string|string}
 */
function proxyRoot() {
  return process.env.MEDIA_PROXY_ROOT || 'https://randall.tagboard.com/';
}

/**
 * Determines if the supplied network should use the media proxy for media urls.
 * @param {String} network
 * @param {Array} suppliedNetworks
 * @returns {boolean}
 */
function usingNetwork(network, suppliedNetworks = []) {
  if (!network || !network.length) {
    return false;
  }
  const proxyNetworks = (process.env.ATTEMPT_IMAGE_PROXY_NETWORKS || '') // Example: 'instagram,facebook'
    .split(',')
    .filter((networkStr) => networkStr && networkStr.length);
  const allProxyNetworks = Array.isArray(suppliedNetworks) ? proxyNetworks.concat(suppliedNetworks) : proxyNetworks;
  return allProxyNetworks.includes(network);
}

/**
 * Determines if the supplied url is already a media proxy url.
 * @param {String} url
 * @returns {boolean}
 */
function urlIsProxied(url) {
  return !!(url && url.includes(proxyRoot()));
}

/**
 * Converts a media url (image/video) to a media proxy url with the original url encoded as the path.
 * @param {String} url
 * @param {String} [network]
 * @returns {string|*}
 */
function genUrl(url, network) {
  if (urlIsProxied(url)) {
    return url;
  }

  const unescapedUrl = unescape(encodeURIComponent(url));
  const encodedUrl = typeof btoa === 'function'
    ? btoa(unescapedUrl) // if Browser
    : Buffer.from(unescapedUrl).toString('base64'); // if Node.js

  let proxiedUrl = `${proxyRoot()}${encodedUrl}`;

  if (network === 'tiktok') {
    proxiedUrl = `${proxiedUrl}?tk=true`; // Used by Randall for the TikTok video scraper
  }

  return proxiedUrl;
}

export default {
  genUrl,
  urlIsProxied,
  usingNetwork,
};
