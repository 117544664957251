import Vue from 'vue';
import Vuex from 'vuex';

import cache from '../../utils/cache';

const catchify = require('catchify');

Vue.use(Vuex);

const getMeta = (name) => {
  const el = document.querySelector(`meta[name=${name}]`);
  return (el && el.getAttribute('content')) || '';
};

/**
 * Handle initial connection (and connection errors) for socket client.
 * Once connection is established, handles scaffolding to ensure everything is loaded and ready to show.
 */
export default {
  state: {
    loaded: false,
  },

  actions: {
    socket_connect_error(context) {
      // We still want to set things up if necessary,
      // so the production will show SOMETHING
      context.dispatch('onSocketConnect', { withSocket: false });
    },

    socket_connect(context) {
      context.dispatch('onSocketConnect');
    },

    async onSocketConnect(context, payload = {}) {
      const {
        withSocket = true,
      } = payload;

      if (!withSocket && context.state.loaded) {
        // Not worried about socket, and data already loaded
        // so let's skip this method
        return;
      }

      const socketEmit = (event, data) => {
        if (!withSocket) { return; }
        vm.$socket.emit(event, data);
      };

      context.commit('SET_SOCKET_LOADED', true);

      const productionId = getMeta('data-production-id');
      const verificationKey = getMeta('data-verification-key');
      const isGenerated = getMeta('data-is-generated');
      const previewRoom = getMeta('data-preview-room');

      let cachedScreen = cache('tgb_producer_screen');

      // Ignore cached screen if it doesn't match the current production
      if (productionId && productionId !== cachedScreen?.production_id) {
        // Will clear the cache too, so it's not used anywhere else.
        // But I would like to start using the production ID
        // in the key so we can keep multiple productions in local storage.
        // Clearing it here means any code screens opened in this browser will stop working.
        cachedScreen = cache('tgb_producer_screen', null);
      }

      let screenId = (Math.random() * 1e32).toString(36).slice(0, -13);

      if (productionId) {
        screenId = getMeta('data-screen-id');

        if (previewRoom) {
          socketEmit('joinSample', {
            preview_room: previewRoom,
            verification_key: verificationKey,
            production_id: productionId,
          });

          context.dispatch('setPreviewRoom', previewRoom);
        } else {
          socketEmit('production', {
            screen_id: screenId,
            production_id: productionId,
            verification_key: verificationKey,
            is_preview: !isGenerated,
          });
        }

        context.dispatch('setProductionId', productionId);
        context.dispatch('setIsPreview', !isGenerated);
      }

      if (productionId || !cachedScreen) {
        // No productionId and no cached screen
        // Just fire these off
        socketEmit('screen', { screen_id: screenId });
        context.dispatch('setScreenId', screenId);
        context.dispatch('setIsReady', true);
        return;
      }

      // Check the screen if it's cached to make sure it's valid
      context.dispatch('setDisplayToken', cachedScreen.display_token);

      let err = null;
      let screen = null;

      [err, screen] = await catchify(
        context.dispatch('getScreen', cachedScreen.screen_id),
      );

      // Merge with cached screen if exists.
      // Because sometimes the payload doesn't
      // include things like `screen_id` for temporary screens (connected via code)
      if (cachedScreen) {
        screen = {
          ...cachedScreen,
          ...screen,
        };
      }

      if (err) {
        // Screen no longer exists, remove the cache stuff and give it a new id
        context.dispatch('resetScreen');
      } else if (screen) {
        if (screen.production_id) {
          socketEmit('production', screen);
          context.dispatch('setProductionId', screen.production_id);
        }

        screenId = screen.screen_id;

        // Update cache bits
        context.dispatch('setCache', screen);
        context.dispatch('setConnected', true);
      }

      socketEmit('screen', { screen_id: screenId });
      context.dispatch('setScreenId', screenId);
      context.dispatch('setIsReady', true);
    },
  },

  mutations: {
    /* eslint-disable no-param-reassign */

    SET_SOCKET_LOADED(state, value) {
      state.loaded = value;
    },
  },
};
