<template>
  <div class="display-header">
    <div class="title">Join the conversation!</div>

    <div class="networks">
      <span class="network-icon network-icon-twitter icon icon-twitterx"></span>
      <span class="network-icon network-icon-instagram icon icon-instagram"></span>
      <span class="network-icon network-icon-facebook icon icon-facebook"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
};
</script>
