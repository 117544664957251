var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"theme-wrapper",class:_vm.getThemeClass},[_c('div',{staticClass:"fullscreen-wrapper",class:[
      _vm.theme.settings.direction,
      _vm.theme.settings.template,
      _vm.getSidebarPosition,
      'post-color-theme-' + _vm.theme.settings.postColorTheme,
      'font-' + _vm.theme.settings.font,
      { 'safe-area': _vm.safeArea },
      'cta-template-' + _vm.theme.settings.ctaTemplate,
      'cta-social-icon-style-' + _vm.theme.settings.ctaSocialIconStyle,
      { 'no-title': !_vm.theme.settings.showCtaText },
      { 'no-social-icons': !_vm.theme.settings.showCtaSocialIcons },
    ],style:({ 'background-color': _vm.computedBackgroundColor })},[(_vm.safeArea && _vm.isOnlyFullscreen)?_c('div',{staticClass:"backdrop",class:{ 'darkened-background': _vm.theme.settings.darkenBackground },style:([_vm.backgroundStyle])},[(_vm.theme.settings.backgroundVideo)?_c('video',{staticClass:"background-video",attrs:{"src":_vm.theme.settings.backgroundVideo,"width":"auto","autoplay":"","loop":"","muted":""},domProps:{"muted":true}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"background-overlay"})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"display-wrapper",style:([
        { height: _vm.getHeight },
        { width: _vm.getWidth },
        _vm.theme.settings.backgroundImage !== ''
          ? {} : { 'background-color': _vm.theme.settings.backgroundColor },
        _vm.safeArea ? _vm.wrapperStyles : {},
      ])},[(_vm.showBackground)?_c('div',{staticClass:"backdrop",class:{ 'darkened-background': _vm.theme.settings.darkenBackground },style:([_vm.backgroundStyle])},[(_vm.theme.settings.backgroundVideo)?_c('video',{staticClass:"background-video",attrs:{"src":_vm.theme.settings.backgroundVideo,"width":"auto","autoplay":"","loop":"","muted":""},domProps:{"muted":true}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"background-overlay"})]):_vm._e(),_vm._v(" "),(_vm.theme.settings.template !== 'lowerthird')?_c('header-component',{attrs:{"theme":_vm.theme}}):_vm._e(),_vm._v(" "),(_vm.theme.settings.direction === 'vertical')?[_c('transition-group',{staticClass:"posts",class:{ 'hide-links': _vm.theme.settings.hideLinks },attrs:{"tag":"div","name":"carousel-anim"},on:{"before-enter":_vm.beforeEnter,"after-enter":_vm.afterEnter}},_vm._l((_vm.columnPosts),function(postData,index){return _c('post-vertical',{key:postData.post_id,class:[_vm.theme.settings.animation, { active: index === 1 }],attrs:{"theme":_vm.theme,"post":postData}})}),1)]:_vm._e(),_vm._v(" "),(_vm.theme.settings.direction === 'horizontal')?[_c('transition-group',{staticClass:"posts",class:{ 'hide-links': _vm.theme.settings.hideLinks },attrs:{"tag":"div","name":"carousel-anim"},on:{"before-enter":_vm.beforeEnter,"after-enter":_vm.afterEnter}},_vm._l((_vm.columnPosts),function(postData,index){return _c('post-horizontal',{key:postData.post_id,class:[_vm.theme.settings.animation, { active: index === 1 }],attrs:{"theme":_vm.theme,"post":postData}})}),1)]:_vm._e(),_vm._v(" "),(_vm.theme.settings.showOverlay)?[(_vm.theme.settings.direction === 'horizontal')?_c('div',{staticClass:"carousel-overlay"},[_c('div',{staticClass:"left"}),_vm._v(" "),_c('div',{staticClass:"right"})]):_c('div',{staticClass:"carousel-overlay"},[_c('div',{staticClass:"top"}),_vm._v(" "),_c('div',{staticClass:"bottom"})])]:_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }