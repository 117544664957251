<template>
  <div>
    <template v-if="loaded">
      <graphic-element
        v-for="el in mappedElements"
        :key="el.id"
        ref="child"
        parent="sportradar"
        :element="el"
        :graphic="graphic"
        :custom-labels="element.data.customLabels"
        v-on="$listeners"
      />
    </template>
  </div>
</template>

<script>
import { get, cloneDeep } from 'lodash-es';
import { mapState, mapActions } from 'vuex';

import { snakeToCapitalized } from '../../utils/strings';
import SRMixin from '../../mixins/sportradar-mixin';
import PusherService from '../../../../../../services/pusher';
import { gameChannelId } from '../../../../../../utils/sportradar';

const PollingInterval = 60e3;

export default {
  name: 'Sportradar',

  mixins: [SRMixin],

  provide() {
    const providerData = {};

    Object.defineProperty(providerData, 'data', {
      enumerable: true,
      get: () => this.sportradarData,
    });

    return {
      providerData,
    };
  },

  props: {
    element: {
      type: Object,
      required: true,
    },

    graphic: {
      type: Object,
      required: true,
    },
  },

  components: {
    GraphicElement: () => import('../render-elements'),
  },

  data() {
    return {
      mounted: true,
      mappedElements: [],
      shouldRefetch: true,
      loadTimeout: undefined,
      pusherChannel: undefined,
      includeImagesOnLoad: true,
    };
  },

  computed: {
    ...mapState({
      sportradarData(state) {
        return state[`${this.element.id}_${this.graphic.timeline_id}`].sportradarData;
      },
      loaded(state) {
        return state[`${this.element.id}_${this.graphic.timeline_id}`].loaded;
      },
      schemaInfo(state) {
        return state[`${this.element.id}_${this.graphic.timeline_id}`].schemaInfo;
      },
    }),
  },

  watch: {
    sportradarData: {
      immediate: true,
      handler() {
        this.mapDataToElements();
      },
    },
    'element.data': {
      deep: true,
      handler(newValue, oldValue) {
        this.shouldRefetch = false;
        const keysToCheck = ['playerId', 'gameId', 'teamId', 'seasonId'];
        // If a relavent sports data has changed, refetch sportradar data
        if (keysToCheck.some((id) => (newValue && newValue[id]) !== (oldValue && oldValue[id]))) {
          this.shouldRefetch = true;
        } else {
          // If data has changed (visibility, etc.) but not the relevant sports data just map the new data to elements
          this.mapDataToElements();
        }
        this.loadData();
      },
    },
  },

  mounted() {
    setTimeout(this.loadData, Math.max(PollingInterval)); // Delay first polling run so that we don't immediately reload data.
    this.initGameSyncSubscription();
  },

  beforeDestroy() {
    clearTimeout(this.loadTimeout);
    this.terminateGameSyncSubscription();
    this.mounted = false;
  },
  methods: {
    ...mapActions({
      getData(dispatch, payload) {
        return dispatch(`${this.element.id}_${this.graphic.timeline_id}/getData`, payload);
      },
      getSchemaInfo(dispatch, payload) {
        return dispatch(`${this.element.id}_${this.graphic.timeline_id}/getSchemaInfo`, payload);
      },
    }),
    async mapDataToElements() {
      const clone = cloneDeep(this.element.elements.filter((e) => e.visible));
      // fetch schema info if not already loaded
      if (!this.schemaInfo) await this.getSchema();

      const attachData = (el) => {
        const element = el;

        // Inject data into element
        if (this.sportradarData && element?.data?.dataKeyMap) {
          const { dataKeyMap, isLabel, labelOverride } = element.data;
          const { customFormats } = this.element.data;
          const { schemaInfo } = this;
          const dataValues = Object.keys(dataKeyMap)
            .filter((k) => dataKeyMap[k]) // Filter out null/empty paths
            .reduce((res, k) => {
              const path = this.formatPath(dataKeyMap[k], this.sportradarData);

              if (isLabel) {
                res[k] = labelOverride ? element.name : snakeToCapitalized(path.split('.').pop());
              } else if (['imageUrl', 'backgroundImageUrl'].includes(k)) {
                res[k] = get(this.sportradarData, path);
              } else {
                const val = get(this.sportradarData, path);
                const parts = path.split('.'); // path to stat property
                const key = parts.pop(); // key for stat property
                const parentPath = parts.join('.'); // path to parent object
                const parentData = get(this.sportradarData, parentPath);
                const typeName = parentData?.__typename; // type of parent object. i.e

                const formatOptions = {
                  key,
                  typeName,
                  schemaInfo,
                  ...(customFormats?.[k] && { custom: customFormats[k] }),
                };
                res[k] = this.formatValue(val, formatOptions);
              }

              return res;
            }, {});

          element.data = {
            ...element.data,
            dataValues,
          };
        } else if (!this.sportradarData && element?.data?.dataValues) {
          // Clear data if already attached to element
          // to prevent old data from rendering
          delete element.data.dataValues;
        }

        // Inject data into children of element
        if (element?.elements?.length) {
          element.elements.forEach((child) => {
            attachData(child);
          });
        }
      };

      this.mappedElements = clone.reverse().map((el) => {
        attachData(el);
        return el;
      });
    },
    async loadData(includeImages = true) { // includeImagesOnLoad is true on first loadData event
      clearTimeout(this.loadTimeout); // prevent stacking calls

      if (!this.element.data || !this.mounted) {
        return;
      }

      const start = Date.now();
      const {
        seasonId,
        teamId,
        playerId,
        gameId,
        sport,
        controlOptions,
      } = this.element.data;

      if (this.shouldRefetch) {
        await this.getData({
          graphicOwner: this.graphic.owner,
          graphicElements: this.element.elements,
          sport,
          seasonId,
          teamId,
          playerId,
          gameId,
          controlOptions,
          includeImages,
        });
      }

      const delta = Date.now() - start;

      // Small bit of delay just to avoid constantly hitting the endpoints
      if (this.mounted) {
        // only include image requests on events that trigger a data reload, not on subsequent polling
        this.loadTimeout = setTimeout(() => this.loadData(false), Math.max(PollingInterval - delta, 0));
      }
    },

    async getSchema() {
      const { sport } = this.element.data || {};
      if (!this.schemaInfo) { // only if schemaInfo is not already loaded
        try {
          await this.getSchemaInfo(sport);
        } catch (error) {
          console.error('Error fetching schema info:', error);
        }
      }
    },

    initGameSyncSubscription() {
      this.pusherChannel = this.subscribeToGameChannel();
      this.subscribeToGameEventNotifications();
    },

    terminateGameSyncSubscription() {
      this.unsubscribeFromGameChannel();
      this.unsubscribeFromGameEventNotifications();
    },

    subscribeToGameChannel() {
      const { gameId, sport, controlOptions } = this.element.data || {};
      if (!sport || !gameId) {
        if (!sport || (!gameId && controlOptions.game === true)) {
          console.error(`subscribeToGameChannel - sport and gameId is required. Values provided: ${sport} and ${gameId}`); // eslint-disable-line no-console, max-len
        }
        return null;
      }

      const pusher = PusherService.getClientInstance();
      const channelId = gameChannelId(sport, gameId);
      return pusher.subscribe(channelId);
    },

    unsubscribeFromGameChannel() {
      const { gameId, sport } = this.element.data || {};
      if (!sport || !gameId) {
        return;
      }

      const pusher = PusherService.getClientInstance();
      const channelId = gameChannelId(sport, gameId);
      pusher.unsubscribe(channelId);
    },

    subscribeToGameEventNotifications() {
      this.pusherChannel?.bind('data-updated', async () => this.loadData());
    },

    unsubscribeFromGameEventNotifications() {
      this.pusherChannel?.unbind('data-updated');
    },
  },
};
</script>

<style>

</style>
