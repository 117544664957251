var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"element group",class:[_vm.element.cssClasses, _vm.element.animationInStyle, _vm.element.animationOutStyle],style:(_vm.getBasicStyles)},[_c('div',{staticClass:"group-wrap"},[_c('transition',{attrs:{"appear":"","enter-to-class":_vm.animationIn,"leave-to-class":_vm.animationOut,"enter-class":"enter","type":"animation","mode":_vm.transitionMode},on:{"before-enter":_vm.beforeEnter,"after-enter":_vm.afterEnter,"before-leave":_vm.beforeLeave,"after-leave":_vm.afterLeave}},[_c('div',{key:_vm.getKey,staticClass:"animated-element",class:[_vm.getLoopingClass, _vm.element.cssClasses],style:([
          _vm.getAnimationStyles,
          _vm.getBackgroundStyles,
          _vm.getBorderStyles,
          _vm.getBoxShadowStyles,
          _vm.getBlurStyles
        ])},[(_vm.hasBackground)?_c('div',{staticClass:"background-image",style:({
            backgroundSize: _vm.element.backgroundSize,
            backgroundImage: 'url(' + _vm.element.backgroundImageUrl + ')',
            backgroundPosition: _vm.element.backgroundPosition,
          })}):_vm._e(),_vm._v(" "),_vm._l((_vm.graphicElements),function(el){return _c('graphic-element',_vm._g({key:el.id,staticClass:"group-item",attrs:{"id":el.id,"graphic":_vm.graphic,"element":el}},_vm.$listeners))})],2)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }