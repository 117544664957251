<template>
  <div
    class="element timer-wrapper text"
    :class="[element.cssClasses]"
    :style="[getBasicStyles, getTextShadowStyles, getTransformStyles]"
  >
    <div
      class="timer-widget"
      :class="[element.animationInStyle, element.animationOutStyle, 'vertical-' + element.verticalAlign]"
      :style="{ display: 'flex', flexDirection: 'column' }"
    >
      <transition
        appear
        :mode="transitionMode"
        :enter-active-class="'animated ' + animationIn"
        :leave-active-class="'animated ' + animationOut"
        @before-enter="beforeEnter"
        @after-enter="afterEnter"
        @before-leave="beforeLeave"
        @after-leave="afterLeave"
      >
        <div
          :key="animKey"
          class="animated-element"
          :style="getAnimationStyles"
          :class="getLoopingClass"
        >
          <div
            v-if="element.showEndDateTime && element.counterFormat === 'Date & Time'"
            :style="[
              getTextStyles('endDateText'),
              { flexGrow: 1, display: 'flex', justifyContent: 'center', whiteSpace: 'nowrap' },
            ]"
            class="end-date"
          >
            {{ endDateTime }}
          </div>
          <div
            :style="getTimerStyles()"
            class="timer"
          >
            <!-- DAYS -->
            <div
              v-if="element.showDays"
              :style="getTimeStyles()"
              class="days"
            >
              <div
                v-if="element.style === 'Basic'"
                :style="[getTextStyles('timerText')]"
                class="digit"
              >
                {{ days }}
              </div>
              <div
                v-if="element.style === 'Flip clock'"
                :style="[getTextStyles('timerText'), getFlipStyles()]"
                class="splitflap digit"
              >
                <div
                  :style="getFlapStyles('newTop')"
                  class="half new-top"
                >
                  {{ days }}
                </div>
                <div
                  :style="getFlapStyles('newBottom')"
                  class="half new-bottom flip1"
                >
                  {{ days }}
                </div>
                <div
                  :style="getFlapStyles('oldTop')"
                  class="half old-top flip2"
                />
                <div
                  :style="getFlapStyles('oldBottom')"
                  class="half old-bottom"
                />
              </div>
              <div
                v-if="element.showUnits"
                :style="getTextStyles('unitText')"
                class="unit-label"
              >
                {{ element.daysLabel }}
              </div>
            </div>
            <div
              v-if="!element.showUnits && element.showDays"
              :style="[getTextStyles('timerText'), getDividerStyles()]"
            >
&nbsp;
            </div>
            <!-- HOURS -->
            <div
              v-if="element.showHours"
              :style="getTimeStyles()"
              class="hours"
            >
              <div
                v-if="element.style === 'Basic'"
                :style="[getTextStyles('timerText')]"
                class="digit"
              >
                {{ hours }}
              </div>
              <div
                v-if="element.style === 'Flip clock'"
                :style="[getTextStyles('timerText'), getFlipStyles()]"
                class="splitflap digit"
              >
                <div
                  :style="getFlapStyles('newTop')"
                  class="half new-top"
                >
                  {{ hours }}
                </div>
                <div
                  :style="getFlapStyles('newBottom')"
                  class="half new-bottom flip1"
                >
                  {{ hours }}
                </div>
                <div
                  :style="getFlapStyles('oldTop')"
                  class="half old-top flip2"
                />
                <div
                  :style="getFlapStyles('oldBottom')"
                  class="half old-bottom"
                />
              </div>
              <div
                v-if="element.showUnits"
                :style="getTextStyles('unitText')"
                class="unit-label"
              >
                {{ element.hoursLabel }}
              </div>
            </div>
            <div
              v-if="!element.showUnits && element.showHours && element.showMinutes"
              :style="[getTextStyles('timerText'), getDividerStyles()]"
            >
              :
            </div>
            <!-- MINUTES -->
            <div
              v-if="element.showMinutes"
              :style="getTimeStyles()"
              class="minutes"
            >
              <div
                v-if="element.style === 'Basic'"
                class="digit"
                :style="[getTextStyles('timerText')]"
              >
                {{ minutes }}
              </div>
              <div
                v-if="element.style === 'Flip clock'"
                :style="[getTextStyles('timerText'), getFlipStyles()]"
                class="splitflap digit"
              >
                <div
                  :style="getFlapStyles('newTop')"
                  class="half new-top"
                >
                  {{ minutes }}
                </div>
                <div
                  :style="getFlapStyles('newBottom')"
                  class="half new-bottom flip1"
                >
                  {{ minutes }}
                </div>
                <div
                  :style="getFlapStyles('oldTop')"
                  class="half old-top flip2"
                />
                <div
                  :style="getFlapStyles('oldBottom')"
                  class="half old-bottom"
                />
              </div>
              <div
                v-if="element.showUnits"
                :style="getTextStyles('unitText')"
                class="unit-label"
              >
                {{ element.minutesLabel }}
              </div>
            </div>
            <div
              v-if="!element.showUnits && element.showMinutes && element.showSeconds"
              :style="[getTextStyles('timerText'), getDividerStyles()]"
            >
              :
            </div>
            <!-- SECONDS -->
            <div
              v-if="element.showSeconds"
              :style="getTimeStyles()"
              class="seconds"
            >
              <div
                v-if="element.style === 'Basic'"
                :style="[getTextStyles('timerText')]"
                class="digit"
              >
                {{ seconds }}
              </div>
              <div
                v-if="element.style === 'Flip clock'"
                :style="[getTextStyles('timerText'), getFlipStyles()]"
                class="splitflap digit"
              >
                <div
                  :style="getFlapStyles('newTop')"
                  class="half new-top"
                >
                  {{ seconds }}
                </div>
                <div
                  :style="getFlapStyles('newBottom')"
                  class="half new-bottom flip1"
                >
                  {{ seconds }}
                </div>
                <div
                  :style="getFlapStyles('oldTop')"
                  class="half old-top flip2"
                />
                <div
                  :style="getFlapStyles('oldBottom')"
                  class="half old-bottom"
                />
              </div>
              <div
                v-if="element.showUnits"
                :style="getTextStyles('unitText')"
                class="unit-label"
              >
                {{ element.secondsLabel }}
              </div>
            </div>
            <div
              v-if="!element.showUnits && element.showMilliseconds"
              :style="[getTextStyles('timerText'), getDividerStyles()]"
            >
              .
            </div>
            <div
              v-if="element.showMilliseconds"
              :style="getTimeStyles()"
            >
              <div
                class="digit"
                :style="[getTextStyles('timerText')]"
              >
                {{ milliseconds }}
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import graphicsMixins from '../../../mixins/graphics-mixins';

export default {
  mixins: [graphicsMixins],
  data() {
    // durations are in seconds by default, unless this.element.showMilliseconds === true
    return {
      display: '', // timer shown on screen
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
      duration: 0, // total time we're counting down from, or up to (the limit that will stop the timer)
      countdown: 0, // keep track of how many seconds are actively being counted in countdown timer since start pressed
      countupDuration: 0, // keep track of total time counted in stopwatch or count up, used when timer is paused and started again
      countup: 0, // keep track of how many seconds or milliseconds are actively being counted in Count Up timer or stopwatch
      intervalId: null, // keep track of the setInterval which is controlling the timer
      clickTime: null, // track when user clicked dateTime
      startMoment: this.element.startMoment || dayjs(), // keep track of when the start button is pressed. used to track the time diff for timers not in real time
    };
  },

  computed: {
    animKey() {
      const {
        hourPreview: h,
        minutePreview: m,
        secondPreview: s,
        dateTime,
      } = this.element;

      // Using the "preview" for keys since those are start values
      return dateTime || `${h}:${m}:${s}`;
    },

    endDateTime() {
      const { dateTime, endDateTimeFormat } = this.element;

      const now = dayjs();
      const end = dayjs(dateTime);

      if (dateTime && end > now) return end.format(endDateTimeFormat);

      return null;
    },

    getTextShadowStyles() {
      if (this.element.textShadow) {
        const textShadowColor = this.element.data?.dataValues?.textShadowColor
        ?? this.element.textShadowColor;

        return { textShadow: `${this.element.textShadowX}px ${this.element.textShadowY}px ${this.element.textShadowBlur}px ${textShadowColor}` };
      }
    },
  },
  watch: {
    element() {
      if (this.element.duration || this.element.playTimer) {
        this.resetDuration(true);
        this.producer();
      } else if (this.element.counterFormat === 'Date & Time' && this.element.timerType === 'Countdown') {
        // prevent negative values for countdown date & time timer on reset
        this.resetDuration();

        if (this.duration <= 0) {
          this.duration = 0;
          this.element.playTimer = false;
        } else this.calculateTime();
      } else this.resetTimer();
    },
    actions(newVal) {
      // check if action is for timer
      if (newVal.length > 0
      && newVal[0].type === 'timer widget'
      && newVal[0].elementId === this.element.id) {
        // eslint-disable-next-line default-case
        switch (newVal[0].value) {
          case 'reset':
            this.resetTimer();
            break;
        }
      }
    },
  },

  mounted() {
    if (this.element.duration || this.element.playTimer) {
      this.resetDuration(true);
      this.producer();
      this.timer();
    } else if (this.element.counterFormat === 'Date & Time' && this.element.timerType === 'Countdown') {
      // prevent negative values for countdown date & time timer on reset
      this.resetDuration();

      if (this.duration <= 0) {
        this.duration = 0;
        this.element.playTimer = false;
      } else {
        this.calculateTime();
      }
    } else {
      this.resetTimer();
    }

    this.insertAnimationStyles();

    // set default datetime for date picker (prevents null value)
    if (this.element.dateTime == null) this.setDateTime();

    // if the timer type, preview settings, display settings, or datepicker are changed, update
    this.$watch(() => [
      this.element.hourPreview,
      this.element.minutePreview,
      this.element.secondPreview,
      this.element.timerFormat,
      this.element.counterFormat,
      this.element.dateTime,
    ], (newValues, oldValues) => {
      // The for loop is necessary for when we update this component from tgb-live/producer
      for (let i = 0; i < newValues.length; i += 1) {
        if (newValues[i] !== oldValues[i]) {
          if (this.element.counterFormat !== 'Date & Time' && newValues[5] !== oldValues[5]) {
            // disregard discrepancies in dateTime, if the counterFormat doesn't include dateTime
            return;
          }
          this.element.playTimer = false; // stop playing if any settings change
          this.resetTimer(); // update timer
        }
      }
    });

    // update timer when play/paused pressed
    this.$watch(() => this.element.playTimer, () => {
      this.timer();
    });

    // watch timer, and check if it needs to stop
    this.$watch(() => [this.countdown, this.countup, this.duration, this.countupDuration], () => this.clearTimer());

    // trigger animation for flip clock
    this.$watch(() => [this.days, this.hours, this.minutes, this.seconds], (newValues, oldValues) => {
      if (this.element.style === 'Flip clock') {
        const units = ['days', 'hours', 'minutes', 'seconds'];

        for (let i = 0; i < newValues.length; i += 1) {
          if (newValues[i] !== oldValues[i]) {
            const newBottom = document.querySelector(`.${units[i]} > .splitflap > .new-bottom`); // this flips
            const oldTop = document.querySelector(`.${units[i]} > .splitflap > .old-top`); // this flips
            const oldBottom = document.querySelector(`.${units[i]} > .splitflap > .old-bottom`);

            // manually set to old value
            oldTop.innerHTML = oldValues[i];
            oldBottom.innerHTML = oldValues[i];

            // manually trigger animation
            newBottom.classList.remove('flip1');
            newBottom.classList.add('flip1');

            oldTop.classList.remove('flip2');
            oldTop.classList.add('flip2');
          }
        }
      }
    });

    this.resizeText();
  },

  beforeDestroy() {
    clearInterval(this.intervalId);
  },

  methods: {
    producer() {
      if (this.element.duration) {
        switch (this.element.timerType) {
          case 'Countdown':
            if (this.element.counterFormat === 'Time') {
              this.duration -= this.element.duration;
              // prevent negative values from displaying,
              //  since we're manually overriding w/ values from producer
              if (this.duration <= 0) {
                this.duration = 0;
                this.element.playTimer = false;
              }
            } else if (this.element.counterFormat === 'Date & Time' && this.duration <= 0) {
              // prevent negative values for countdown date & time timer on pause
              this.duration = 0;
              this.element.playTimer = false;
            }

            break;
          case 'Count Up':
            if (this.element.counterFormat === 'Time') {
              this.countupDuration += this.element.duration;

              if (this.countupDuration >= this.duration) {
                this.countupDuration = this.duration;
                this.element.playTimer = false;
              }
            }
            break;
          default: // stopwatch
            this.countupDuration += this.element.duration;

            break;
        }
      }

      // This component is decrepit so I don't know the full ramifications of calling this before everything else.
      if (!this.element.playTimer) {
        return this.calculateTime();
      }

      // If the timer is set to running from producer, but it has exceeded the limit
      // timer needs to stop, and display needs to be set to limit
      const timeDiff = this.timeDiff(this.element.startMoment);

      if (this.element.timerType === 'Countdown') { // counterFormat == Timer && Date & TIme
        if (this.duration - timeDiff <= 0) {
          // if Date & Time, and the real time diff is negative,
          // or, regular timer, and the counted time plus the running time is negative
          this.duration = 0;
          this.element.playTimer = false;
        }

        return this.setTimer();
      }

      if (this.element.timerType === 'Count Up' && this.element.counterFormat !== 'Date & Time') {
        // countup shouldn't stop when counterFormat == 'Date & Time'
        if (this.countupDuration + timeDiff >= this.duration) {
          this.countupDuration = this.duration;
          this.element.playTimer = false;
          return this.calculateTime();
        }
      }

      return null;
    },

    timer() {
      // if paused, stop timer
      if (!this.element.playTimer) {
        // keep track of the time elapsed when we pause the stopwatch/timer
        if (this.element.timerType === 'Stopwatch') {
          this.countupDuration += this.countup;
        }

        if (this.element.timerType === 'Countdown') {
          this.duration -= this.countdown;
          this.countdown = 0;
        }

        if (this.element.timerType === 'Count Up') {
          this.countupDuration += this.countup;
          this.countup = 0;
        }

        clearInterval(this.intervalId);
        return;
      }

      // otherwise, set and play timer
      this.startMoment = this.element.startMoment || dayjs(); // use start time from producer if available
      this.intervalId = setInterval(this.setTimer, this.element.timerType === 'Stopwatch' ? 1 : 1000);
    },

    clearTimer() {
      // if timer already finished (has met criterion to stop): stop timer by setting play to false and clearing interval
      // stopwatch runs indefinitely, has no stop criteria
      const clear = () => {
        clearInterval(this.intervalId);
        this.element.playTimer = false;
        return true;
      };

      if (this.element.timerType === 'Countdown') {
        // if the duration for counting down is 0
        if (this.duration <= 0) return clear();
        // or, not real time (real time has no limit for countup, and stops when duration <= 0 for countdown)
        if (!this.element.counterFormat.includes('Date')) {
          // elapsed time has surpassed the limit, or the limit minus the elapsed time has reached 0
          if (this.countdown >= this.duration || (this.duration - this.countdown) <= 0) return clear();
        }
      }

      if (this.element.counterFormat === 'Date & Time' && this.element.dateTime === null) { // if null, user cleared the datepicker. value is only set by datepicker.
        return clear();
      }

      // cutoff
      if (this.element.counterFormat === 'Time' && this.element.cutoff === true) {
        const resetCutoff = () => {
          this.element.cutoff = false;
          return clear();
        };

        if (this.element.timerType === 'Countdown') {
          // Elapsed time has reached cutoff (elapsed time === total duration - cutoff time)
          if ((this.duration - this.countdown) <= this.element.cutoffTime) {
            return resetCutoff();
          }
        } else if ((this.countupDuration + this.countup) >= this.element.cutoffTime) {
          // Elapsed time has reached cutoff (elapsed time === cutoff)
          return resetCutoff();
        }
      }

      return false;
    },
    setTimer() {
      if (this.clearTimer()) return;
      // decrement/increment
      // real time will adjust automatically. otherwise, adjust manually
      if (this.element.timerType === 'Count Up') {
        // Count Up or stopwatch should increment.
        if (this.element.counterFormat.includes('Date')) this.countupDuration = this.realTimeDiff();
        else this.countup = this.timeDiff(this.startMoment);
      } else if (this.element.timerType === 'Stopwatch') {
        this.countup = this.timeDiff(this.startMoment);
      } else if (this.element.counterFormat.includes('Date')) {
        this.duration = this.realTimeDiff();
      } else {
        this.countdown = this.timeDiff(this.startMoment);
      }

      // total amount of time in seconds has been adjusted, now format it for displaying to user
      this.calculateTime();
    },
    timeDiff(startMoment) {
      // time elapsed since play was pressed
      return dayjs().diff(startMoment, this.element.timerType === 'Stopwatch' ? '' : 'seconds');
    },
    realTimeDiff() { // get total time duration for real time (the amount of time between two moments in time)
      // the amount of time between "time now" and a "date in the future".
      // Since "time now" is moving towards the "date in the future", this amount is always decreasing
      if (this.element.timerType === 'Countdown') {
        return dayjs(this.element.dateTime, 'MM/DD/YYYY hh:mm A')
          .diff(dayjs(), this.element.timerType === 'Stopwatch' ? '' : 'seconds');
      }

      // the amount of time between "time now" and a "date in the past"
      // Since "time now" is always moving away from the "date in the past", this amount is always increasing
      return dayjs()
        .diff(
          dayjs(this.element.dateTime, 'MM/DD/YYYY hh:mm A'),
          this.element.timerType === 'Stopwatch' ? '' : 'seconds',
        );
    },
    calculateTime() {
      // calculate time based on total duration amount in seconds & time values selected in format

      // if we're counting down, use duration. else (count up || stopwatch), use countup
      const duration = this.element.timerType === 'Countdown'
        ? this.element.counterFormat === 'Time'
          ? this.duration - this.countdown
          : this.duration // Date & Time (real time)
        : this.element.timerType === 'Count Up'
          ? this.element.counterFormat === 'Time'
            ? this.countupDuration + this.countup
            : this.countupDuration // Date & Time (real time)
          : this.countupDuration + this.countup; // timerType = Stopwatch

      // keep all time in 2-digit format
      const stringify = (int) => {
        if (int < 10) return `0${int.toString()}`;

        return int.toString();
      };

      if (this.element.showDays) {
        // days don't need 2 characters; toString to keep consistent values
        this.days = Math.floor(duration / (60 * 60 * 24)).toString(); // seconds
      } else this.days = 0;

      // if there are days, hours are calculated from the remainder of time after the days have been calculated
      // else, calculate hours from total duration
      if (this.element.showHours) {
        if (this.element.timerType === 'Stopwatch') {
          this.hours = this.element.showDays
            ? stringify(Math.floor((duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)))
            : stringify(Math.floor(duration / (1000 * 60 * 60)));
        } else {
          this.hours = this.element.showDays
            ? stringify(Math.floor((duration % (60 * 60 * 24)) / (60 * 60)))
            : stringify(Math.floor(duration / (60 * 60)));
        }
      } else this.hours = 0;

      // if there are hours, minutes are calculated from the remainder of time after the hours have been calculated
      // else, calculate minutes based on total duration
      if (this.element.showMinutes) {
        if (this.element.timerType === 'Stopwatch') {
          this.minutes = this.element.showHours
            ? stringify(Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60)))
            : stringify(Math.floor(duration / (1000 * 60)));
        } else {
          this.minutes = this.element.showHours
            ? stringify(Math.floor((duration % (60 * 60)) / 60))
            : stringify(Math.floor(duration / 60));
        }
      } else this.minutes = 0;

      // if there are minutes, seconds are calculated from the remainder after the minutes have been calculated,
      // else, seconds are the total duration
      if (this.element.showSeconds) {
        if (this.element.timerType === 'Stopwatch') {
          this.seconds = this.element.showMinutes
            ? stringify(Math.floor(duration % (1000 * 60) / 1000))
            : stringify(duration / 1000);
        } else {
          this.seconds = this.element.showMinutes
            ? stringify(Math.floor(duration % 60))
            : stringify(duration);
        }
      } else this.seconds = 0;

      if (this.element.timerType === 'Stopwatch') {
        this.milliseconds = Math.floor(duration % 1000);
      } else this.milliseconds = 0;
    },
    resetDuration(producer = false) {
      if (this.element.timerType === 'Stopwatch') {
        this.countupDuration = 0;
        this.countup = 0;
      } else { // count up or countdown
        switch (this.element.counterFormat) {
          case 'Time':
            // reset duration based on preview values in settings pane
            if (producer === false) {
              this.hours = this.element.hourPreview;
              this.minutes = this.element.minutePreview;
              this.seconds = this.element.secondPreview;
            }

            let hours; let minutes; let
              seconds;

            if (producer === true) {
              hours = this.element.hourPreview;
              minutes = this.element.minutePreview;
              seconds = this.element.secondPreview;
            } else {
              hours = this.hours;
              minutes = this.minutes;
              seconds = this.seconds;
            }

            this.countup = 0;
            this.countdown = 0;
            this.duration = this.element.showMilliseconds
              ? (hours * (1000 * 60 * 60)) + (minutes * (1000 * 60)) + (seconds * 1000)
              : (hours * (60 * 60)) + (minutes * 60) + seconds;
            this.countupDuration = this.duration;
            break;

          default: // Date & Time
            // reset duration/countup based on real time difference
            if (this.element.dateTime === null) {
              // set values to 0 if user cleared datepicker
              this.days = 0;
              this.hours = 0;
              this.minutes = 0;
              this.seconds = 0;
              break;
            }

            if (this.element.timerType === 'Count Up') {
              this.countupDuration = this.realTimeDiff();
            } else {
              this.duration = this.realTimeDiff();
            }

            break;
        }
      }
    },
    resetTimer() {
      this.resetDuration();
      this.calculateTime();
    },
    resizeText() {
      const widget = document.getElementsByClassName('timer')[0];
      if (!widget) { return; }

      let currentFontSize;

      // set font size to default/saved font size
      widget.style.fontSize = `${this.element.styles.fontSize}px`;

      while (widget.scrollHeight > widget.offsetHeight || widget.scrollWidth > widget.offsetWidth) {
        if (currentFontSize <= 10 || Number.isNaN(currentFontSize)) break;

        currentFontSize = parseInt(widget.style.fontSize.slice(0, -2));
        currentFontSize = Math.max(10, currentFontSize - 1);

        widget.style.fontSize = `${currentFontSize}px`;
      }
    },
    setDateTime() {
      this.element.dateTime = this.element.timerType === 'Countdown'
      // if countdown, set datepicker 10 days in future
        ? dayjs().add(10, 'days').format('MM/DD/YYYY hh:mm A')
      // if countup, set datepicker 10 days in past
        : dayjs().subtract(10, 'days').format('MM/DD/YYYY hh:mm A');
    },
    getTimerStyles() {
      return {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: this.element.timerType === 'Stopwatch' ? 'left' : 'center',
        gap: `${this.element.gap ?? 0}px`,
      };
    },
    getTimeStyles() {
      return {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '5px',
      };
    },
    getDividerStyles() {
      return {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '5px',
      };
    },
    getTextStyles(textType) {
      const textStyles = { ...this.element.styles[textType] };

      textStyles.fontSize = `${textStyles.fontSize}px`;
      textStyles.letterSpacing = textStyles.letterSpacing
        ? `${textStyles.letterSpacing}px`
        : null;

      textStyles.backgroundColor = 'transparent';
      textStyles.lineHeight = textStyles.lineHeight ?? '100%';

      return textStyles;
    },
    getFlipStyles() {
      if (this.element.style === 'Basic') return;

      const { fontSize } = this.element.styles.timerText;

      return {
        height: `${fontSize}px`,
        width: `${fontSize * 2}px`,
        backgroundColor: this.element.flipClockColor,
        top: 0,
        lineHeight: '100%',
        textAlign: 'center',
        borderRadius: '.15em',
        boxShadow: '0 0.025em 0.05em rgba(#000, .25)',
        perspective: '4.5em',
        margin: '5px',
      };
    },
    getFlapStyles(position) {
      if (this.element.style === 'Basic') return;

      if (position === 'newTop') {
        return {
          backgroundColor: this.element.flipClockColor,
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '50%',
          borderRadius: '.15em .15em 0 0',
          overflow: 'hidden',
          zIndex: 2,
          borderBottom: '1px solid grey',
        };
      }

      if (position === 'newBottom') {
        return {
          backgroundColor: this.element.flipClockColor,
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          borderRadius: '.15em',
          webkitTransform: 'rotate3D(1, 0, 0, 90deg)',
          transform: 'rotate3D(1, 0, 0, 90deg)',
          webkitTransformOrigin: 'center',
          transformOrigin: 'center',
          webkitBackfaceVisibility: 'hidden',
          backfaceVisibility: 'hidden',
          zIndex: -2,
        };
      }

      if (position === 'oldTop') {
        return {
          backgroundColor: this.element.flipClockColor,
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '50%',
          borderRadius: '.15em .15em 0 0',
          webkitTransform: 'rotate3D(1, 0, 0, 0deg)',
          transform: 'rotate3D(1, 0, 0, 0deg)',
          webkitTransformOrigin: 'bottom',
          transformOrigin: 'bottom',
          webkitBackfaceVisibility: 'hidden',
          backfaceVisibility: 'hidden',
          overflow: 'hidden',
          zIndex: 3,
          borderBottom: '1px solid grey',
        };
      }

      if (position === 'oldBottom') {
        return {
          backgroundColor: this.element.flipClockColor,
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          borderRadius: '.15em',
          zIndex: -3,
        };
      }
    },

    insertAnimationStyles() {
      const style = document.createElement('style');
      document.head.appendChild(style);
      const styleSheet = style.sheet;

      styleSheet.insertRule(`
          .flip1 {
            -webkit-animation: flip1 1s ease-in forwards;
            animation: flip1 1s ease-in forwards;
          }
        `);

      styleSheet.insertRule(`
          .flip2 {
            -webkit-animation: flip2 1s ease-in forwards;
            animation: flip2 1s ease-in forwards;
          }
        `);

      styleSheet.insertRule(`
          @-webkit-keyframes flip1 {
            0% {
              -webkit-transform: rotate3D(1, 0, 0, 90deg);
              transform: rotate3D(1, 0, 0, 90deg);
              z-index: -2;
            }

            50% {
              -webkit-transform: rotate3D(1, 0, 0, 90deg);
              transform: rotate3D(1, 0, 0, 90deg);
              z-index: -2;
            }

            100% {
              -webkit-transform: rotate3D(1, 0, 0, 0deg);
              transform: rotate3D(1, 0, 0, 0deg);
              z-index: -2;
            }
          }
        `);

      styleSheet.insertRule(`
          @keyframes flip1 {
            0% {
              -webkit-transform: rotate3D(1, 0, 0, 90deg);
              transform: rotate3D(1, 0, 0, 90deg);
              z-index: -2;
            }

            50% {
              -webkit-transform: rotate3D(1, 0, 0, 90deg);
              transform: rotate3D(1, 0, 0, 90deg);
              z-index: -2;
            }

            100% {
              -webkit-transform: rotate3D(1, 0, 0, 0deg);
              transform: rotate3D(1, 0, 0, 0deg);
              z-index: -2;
            }
          }
        `);

      styleSheet.insertRule(`
          @-webkit-keyframes flip2 {
            0% {
              -webkit-transform: rotate3D(1, 0, 0, 0deg);
              transform: rotate3D(1, 0, 0, 0deg);
              z-index: 3;
            }

            50% {
              -webkit-transform: rotate3D(1, 0, 0, -90deg);
              transform: rotate3D(1, 0, 0, -90deg);
              z-index: 3;
            }

            100% {
              -webkit-transform: rotate3D(1, 0, 0, -90deg);
              transform: rotate3D(1, 0, 0, -90deg);
              z-index: 3;
            }
          }
        `);

      styleSheet.insertRule(`
          @keyframes flip2 {
            0% {
              -webkit-transform: rotate3D(1, 0, 0, 0deg);
              transform: rotate3D(1, 0, 0, 0deg);
              z-index: 3;
            }

            50% {
              -webkit-transform: rotate3D(1, 0, 0, -90deg);
              transform: rotate3D(1, 0, 0, -90deg);
              z-index: 3;
            }

            100% {
              -webkit-transform: rotate3D(1, 0, 0, -90deg);
              transform: rotate3D(1, 0, 0, -90deg);
              z-index: 3;
            }
          }
        `);
    },
  },
};
</script>
