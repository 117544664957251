<template>
  <div v-if="post" class="post" v-bind:id="post.uid" v-bind:class="[post.network, dynamicClasses, post.post_type, videoType, noAvatar]" v-bind:data-provider="post.network">
    <div class="text-module">
      <div v-bind:class="isVerified" class="meta-author clearfix">
        <span class="avatar" v-if="avatarUrl" v-bind:style="{ backgroundImage: 'url(' + avatarUrl + ')' }"><img v-bind:src="avatarUrl" alt="" class="user-img"></span>

        <template v-if="post.network === 'instagram'">
          <span
            v-if="post.user_name"
            class="handle"
            :class="handleStyle"
            :style="{'font-size':`${handleFontSize}px`}"
          >
            @<span v-html="post.user_name"></span>
          </span>
          <span
            v-else
            class="handle"
            :class="handleStyle"
            :style="{'font-size':`${handleFontSize}px`}"
          >
            Instagram user
          </span>
        </template>

        <template v-if="post.network === 'reddit'">
          <span v-if="post.service_meta.subreddit" class="subreddit">in r/<span v-html="post.service_meta.subreddit"></span></span>
          <span
            class="handle"
            :class="handleStyle"
            :style="{'font-size':`${handleFontSize}px`}"
          >
            by u/<span v-html="post.user_real_name"></span>
          </span>
        </template>

        <template v-if="post.network !== 'instagram' && post.network !== 'reddit'">
          <span
            class="author"
            :class="authorStyle"
            :style="{'font-size':`${authorFontSize}px`}"
            v-html="post.user_real_name"
          />
          <span
            v-if="post.network === 'twitter' || post.network === 'tiktok'"
            class="handle"
            :class="handleStyle"
            :style="{'font-size':`${handleFontSize}px`}"
          >
            @<span v-html="post.user_name"></span>
          </span>
        </template>
      </div>

      <div
        class="message"
        :class="postMessageStyle"
        :style="{'font-size':`${postMessageFontSize}px`}">
        <div class="post-scroll">
          <div class="post-title" v-if="post.tgb_meta && !post.tgb_meta.isComment && post.title" v-html="post.title"></div>
          <div class="post-text">
            <span v-html="postHtml"></span><span v-if="theme.settings.showTimestamp" class="timestamp">{{post.post_time|timestampToString}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="origin-flag"></div>
  </div>
</template>

<script>
import EventBus from '../../../../utils/event-bus';
import PostRenderMixins from '../../../../mixins/post-render-mixins';
import YoutubeVideoComponent from '../../../video/youtube/YouTubeVideo.vue';
import FacebookVideoComponent from '../../../video/facebook/FacebookVideo.vue';
import GenericVideoComponent from '../../../video/generic/GenericVideo.vue';

export default {
  components: {
    youtube: YoutubeVideoComponent,
    facebook: FacebookVideoComponent,
    generic: GenericVideoComponent,
  },
  mixins: [PostRenderMixins],
  props: ['post', 'theme'],
  data() {
    return {
      fadeTimeout: null,
      resetAnimationTimeout: null,
      scrollingPost: true,
    };
  },
  computed: {
    playing() {
      return this.$store.getters.isPlaying;
    },

    handleStyle() {
      return [
        this.theme.settings?.handle?.textStyles ?? [''],
        `font-${this.theme.settings?.handle?.fontFamily}`,
      ];
    },

    postMessageStyle() {
      return [
        this.theme.settings?.postMessage?.textStyles ?? [''],
        `font-${this.theme.settings?.postMessage?.fontFamily}`,
      ];
    },

    authorStyle() {
      return [
        this.theme.settings?.author?.textStyles ?? [''],
        `font-${this.theme.settings?.author?.fontFamily}`,
      ];
    },

    handleFontSize() {
      return this.theme.settings?.handle?.size ?? '';
    },

    postMessageFontSize() {
      return this.theme.settings?.postMessage?.size ?? '';
    },

    authorFontSize() {
      return this.theme.settings?.author?.size ?? '';
    },
  },
  mounted() {
    this.layoutTickerPost();
    EventBus.$on('resize', this.layoutTickerPost);
  },
  beforeDestroy() {
    EventBus.$off('resize', this.layoutTickerPost);
    clearTimeout(this.fadeTimeout);
    clearTimeout(this.resetAnimationTimeout);
  },
  methods: {
    layoutTickerPost() {
      const $post = $(this.$el);
      const self = this;
      const $textModule = $post.find('.text-module');
      const $metaAuthor = $post.find('.meta-author');
      const $message = $post.find('.message');

      if ($post.hasClass('reddit')) {
        // Remove potentially crazy formatting from reddit posts for ticker
        const $scroll = $message.find('.post-scroll');
        $scroll.html($scroll.text());
      }

      if ($post.hasClass('twitter')) {
        $post.find("a:contains('pic.twitter.com')").addClass('twitter-media-url');
        $post.find("a:contains('https://twitter.com')").addClass('quote-tweet-url');
      }
      $post.find("a:not('.hashtag'):not('.mention'):not('.twitter-media-url'):not('.quote-tweet-url')").addClass('link');

      // $message.css('width', '');
      // $message.css('width' , $textModule.width() - $metaAuthor.outerWidth(true));

      self.animateTicker();
    },
    animateTicker() {
      const self = this;
      const $post = $(self.$el);
      const postWidth = $post.width();
      const $postScroll = $post.find('.post-scroll');
      const postScrollWidth = $postScroll.width();
      const messageWidth = $post.find('.message').width();
      const distance = postScrollWidth - messageWidth;
      const animDistance = distance + (messageWidth / 2); // distance to get end of text onscreen + half of messageWidth for buffer
      const crawlSpeed = self.theme.settings.crawlSpeed || 2000;
      const cycleSpeed = self.theme.settings.displayTime;
      const animDelay = 2000; // delay before text starts crawling
      const animLength = (animDistance * crawlSpeed) / (postWidth * 0.1); // crawl 10% of postWidth (px) every crawlSpeed (ms).

      if (distance > 0) {
        self.scrollingPost = true;
      } else {
        self.scrollingPost = false;
      }

      $postScroll.css({
        opacity: self.scrollingPost ? '0' : '1',
        transform: '',
        transition: '',
      });

      clearTimeout(self.fadeTimeout);
      clearTimeout(self.resetAnimationTimeout);

      if (self.scrollingPost) { // only animate text if it is overflowing.
        $postScroll.css({
          opacity: '1',
          transform: `translate3d(${-animDistance}px, 0, 0)`,
          transition: `transform ${animLength}ms linear ${animDelay}ms, opacity 500ms ease`,
        });

        if (cycleSpeed > (animLength + animDelay)) {
          self.playDuration = cycleSpeed;
        } else {
          self.playDuration = animLength + animDelay + 2000;
        }

        self.resetAnimation(animLength + animDelay, 1000, 1000);
      } else { // if text is NOT overflowing, just fade it in.
        $postScroll.css({
          opacity: '1',
          transition: 'opacity 500ms ease',
        });

        self.playDuration = cycleSpeed;
        self.resetAnimation(self.playDuration, 0, 0);
      }

      self.$store.dispatch('setPlayDuration', self.playDuration);
    },
    resetAnimation(delay, fadeDelay, resetDelay) {
      const self = this;
      const $post = $(this.$el);
      const $postScroll = $post.find('.post-scroll');
      const { tagboard } = this.$store.state;

      const isCurrentPost = () => (
        tagboard
        && this.post
        && tagboard.currentPost
        && tagboard.currentPost.post_id === this.post.post_id
        && tagboard.currentPost.network === this.post.network
      );

      // Is the post being handled by this callback the only post on the tagboard
      const isNotOnlyPost = () => (!tagboard || tagboard.posts.length !== 1);

      if (self.scrollingPost) { // don't fade a non-scrolling post
        self.fadeTimeout = setTimeout(() => {
          $postScroll.css({
            opacity: '0',
            transition: 'opacity 500ms ease',
          });
        }, delay + fadeDelay); // when ticker is done crawling, wait 1 second then fade to 0 opacity
      }

      self.resetAnimationTimeout = setTimeout(() => {
        $postScroll.css({
          transform: '',
          transition: '',
        });

        if (self.playing && !self.theme.settings.interactive) {
          if (isCurrentPost() && isNotOnlyPost()) {
            // Don't advance if this is finishing the animation loop from a previous post
            EventBus.$emit('advance', 'next');
          }

          // In case advance doesn't find a new post, make sure we still animate this one
          if (tagboard && isCurrentPost()) {
            self.animateTicker();
          }
        } else {
          // Animate this post scroll again (manually controlled producer case)
          self.animateTicker();
        }
      }, delay + resetDelay); // wait 1 second after ticker faded to 0 opacity, then reset tranform and transition properties for $postScroll and call animateTicker
    },
  },
};
</script>
