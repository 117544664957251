import {
  flatten,
  pick,
  cloneDeep,
  debounce,
} from 'lodash-es';

import ThemeService from '../themes/services/themeservice';

import {
  withRetries,
  isTheme,
  isSmartPanel,
  isGraphic,
} from './helpers';

const jsondiffpatch = require('jsondiffpatch');

// Create a timeline patcher that uses timeline_id to match array items
const diffpatcher = jsondiffpatch.create({
  objectHash: (obj, index) => (
    obj?.timeline_id || `$$index:${index}`
  ),
});

/**
 * Get settings for smart panel.
 * The settings are really just the current settings with fields merged in.
 * @param {Object} layout - Current live layout (smart panel)
 * @returns {Object} layout.settings merged with layout.fields
 */
const getPanelSettings = (layout) => ({
  ...(layout.settings || {}),
  ...(layout.fields || {}),
});

/**
 * Ensures that theme and social post(s) are loaded.
 * @param {Object} context - Store context
 * @param {Object} live - Live layout (and optional post)
 * @returns {Object} Copy of `live`, but with any missing data loaded.
 */
const ensureThemeAndPost = async (context, live) => {
  const { state, rootState } = context;
  const { owner } = rootState.production;

  // Clone so we can edit without mutating params
  const ret = cloneDeep({
    layout: live.layout,
    post: live.post,
  });

  // If live item has changed, then we need to load the theme to merge in settings.
  // If the live item has NOT changed, we can just assume settings are fine.
  // TODO: Maybe cache these in memory? Could become outdated though.
  if (ret.layout.timeline_id !== state.live?.layout?.timeline_id) {
    const { result: theme } = await ThemeService.getTheme(ret.layout._id);

    // Start by just merging theme into the layout we got
    ret.layout = {
      ...ret.layout,
      ...theme,
    };
  }

  const noTimelineItemSet = !(live?.layout?.timeline_id || state.live?.layout);

  // eslint-disable-next-line max-len
  if ((ret.layout && live?.layout?.timeline_id === state.live?.layout?.timeline_id) && !noTimelineItemSet) {
    // Copy over version to stop that from being lost
    ret.layout.version = (
      state.live.layout.settings.version
      || state.live.layout.version
    );

    // Timeline item is the same, so copy settings from the current one
    ret.layout.settings = state.live.layout.settings;
  }

  // Merge fields into settings
  ret.layout.settings = {
    ...(ret.layout.settings || {}),
    ...(ret.layout.fields || {}),
  };

  // If we need to show a social post, and it's not loaded yet...
  // we need to fetch the post and store it for future reference
  const { post } = live;

  let existingPost = post ? context.getters.getPostById(post.post_id) : null;

  if (post && post.post_id && owner && !existingPost) {
    // Post not already loaded into state, so make sure it's loaded first.
    await context.dispatch('getManyPosts', {
      posts: [{
        network: ret.post.network,
        post_id: ret.post.post_id,
      }],
    });

    existingPost = context.getters.getPostById(post.post_id);
  }

  if (existingPost) {
    ret.post = {
      ...ret.post,
      ...existingPost,
    };
  }

  return ret;
};

/**
 * Get list of social posts attached to the timeline item.
 * For themes and single graphics, this is just the array of posts found on them.
 * For grouped graphics, returned value will be a concatenated list of all social posts
 * found in all graphics in the group.
 * @param {Object} item - Timeline item
 * @returns {Array<Object>} List of social posts and/or tagboards
 */
const getSocialPosts = (item) => {
  switch (item.layout) {
    case 'graphic': {
      const [socialId] = item.socialData || [];
      const fields = item.fields[socialId] || {};
      return fields?.posts || [];
    }

    case 'graphics': {
      return flatten(
        item.posts.map((it) => getSocialPosts(it)),
      );
    }

    default: {
      return item.posts;
    }
  }
};

/**
 * Get next item and/or post given the current index and subIndex.
 * @param {Object[]} opts.timeline - Current production timeline
 * @param {string} opts.index - timeline_id of current live item
 * @param {string} opts.subIndex - timeline_id of current live post (nested inside item)
 * @param {boolean} opts.looped - Whether or not to loop around to the first item
 * @returns {Object} Object with next item/post index and subIndex
 */
const getNextItemOrPost = (opts = {}) => {
  const {
    timeline,
    index,
    subIndex,
    looped,
  } = opts;

  const itemCount = timeline.length;

  // Timeline Item + Index
  const currentItemIdx = timeline.findIndex((i) => i.timeline_id === index);
  const currentItem = timeline[currentItemIdx];

  const posts = getSocialPosts(currentItem);
  const postCount = posts.length;

  // Post Index
  const currentPostIdx = (index !== subIndex)
    ? posts.findIndex((p) => p.timeline_id === subIndex)
    : -1;

  const next = {
    index: (() => {
      if (currentPostIdx >= 0 && currentPostIdx < postCount - 1) {
        // Moving to next post, so stay on same item
        return index;
      }

      if (currentItemIdx >= itemCount - 1) {
        if (!looped) {
          // No looping, so stay right here
          return index;
        }

        // Wrap around to beginning
        return timeline[0].timeline_id;
      }

      // Move to next timeline item
      return timeline[currentItemIdx + 1].timeline_id;
    })(),

    subIndex: (() => {
      if (currentPostIdx >= 0 && currentPostIdx < postCount - 1) {
        // Move forward one post
        return posts[currentPostIdx + 1].timeline_id;
      }

      if (!looped && currentItemIdx >= itemCount - 1) {
        // Just stay on final item and final post
        return subIndex;
      }

      const nextItem = currentItemIdx < itemCount - 1
        ? timeline[currentItemIdx + 1] // Next item in playlist
        : timeline[0]; // Loop back to beginning

      // We know something has nested posts if they have different timeline_ids
      // Graphics groups uses `posts`, but they all render together instead of sequentially
      const nextPosts = getSocialPosts(nextItem);

      if (!nextPosts?.length) {
        // Just set sub_index to same value as index
        return nextItem.timeline_id;
      }

      // Set to first post on next timeline item (if applicable)
      return nextPosts[0].timeline_id;
    })(),
  };

  return next;
};

/**
 * Check what kinda layout we are sending live,
 * then ensure the necessary data is merged in and/or fetched.
 * @param {Object} context - Store context used to get everything from state
 * @param {Object} live - New layout + post to show
 * @returns {Object} Updated version of input
 */
const getLiveLayoutWithData = withRetries(async (context, live) => {
  const ret = cloneDeep(live);

  if (isGraphic(ret?.layout)) {
    // Try to preload social posts for social graphics.
    // If layout isn't a graphic then this action won't do anything.
    await context.dispatch('getLiveSocialPosts', ret.layout);
  } else if (isSmartPanel(ret?.layout)) {
    // If live layout is a smart panel, merge settings w/fields
    ret.layout.settings = getPanelSettings(ret.layout);
  } else if (isTheme(ret?.layout)) {
    // If live layout is a theme,
    // then we need to make sure we have the theme and post loaded
    ({
      layout: ret.layout,
      post: ret.post,
    } = await ensureThemeAndPost(context, live));
  }

  return ret;
});

/**
 * Find a timeline item by timeline_id.
 * Could be nested in a group, so check inner posts as well.
 * @param {Object[]} timeline - List of items in the playlist
 * @param {String} timelineId - ID of the timeline item to find.
 * @returns {Object|null} Timeline item, or null if not found.
 */
const findTimelineItem = (timeline, timelineId) => {
  for (let i = 0; i < timeline.length; i += 1) {
    const item = timeline[i];

    if (item) {
      if (item.timeline_id === timelineId) {
        return item;
      }

      if (item.posts?.length) {
        const nestedItem = findTimelineItem(item.posts, timelineId);
        if (nestedItem) {
          return nestedItem;
        }
      }
    }
  }

  return null;
};

let prevProduction;

// This is necessary until we fix the live state so it stores production separate.
// There's a lot of pollution that doesn't actually belong on a "production"
// List of fields we allow deltas for
const ProductionFields = [
  'timeline',
  'index',
  'sub_index',
];

/**
 * Merge provided object into prevProduction.
 * Mainly used to catch prevProduction up with any changes,
 * but not completely overwriting it.
 * @param {Object} partial - Object to merge into prevProduction
 */
const updatePrevProduction = (partial) => {
  prevProduction = Object.assign(prevProduction, pick(partial, ProductionFields));
};

/**
 * Overwrite prevProduction with a clone of the provided production
 * @param {Object} production - Production to clone and store
 */
const setPrevProduction = (production) => {
  prevProduction = cloneDeep(pick(production, ProductionFields));
};

/**
 * Calculate diff of production and send changes over the socket
 * @param {Object} state
 */
const updateTimeline = (state) => {
  try {
    const delta = diffpatcher.diff(
      pick(prevProduction, ProductionFields),
      pick(state, ProductionFields),
    );

    setPrevProduction(state);

    if (delta) {
      // When data is sent through here with preview = true
      // the data will be sent to the updatePreviewTimeline action
      // this way it'll update the connected screens on the preview room
      const data = {
        production_id: state._id,
        owner: state.owner,
        user_id: state.owner,
        delta,
        live: state.live, // Keep whatever is currently live, live and alive
      };

      vm.$socket.emit('updateTimeline', data);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('Error updating timeline:', err);
  }
};

const debouncedUpdateTimeline = debounce(updateTimeline, 500);

export {
  diffpatcher,
  getNextItemOrPost,
  getLiveLayoutWithData,
  findTimelineItem,

  // Delta stuff
  updatePrevProduction,
  setPrevProduction,
  debouncedUpdateTimeline as updateTimeline,
};
