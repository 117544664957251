<template>
  <div v-bind:class="getThemeClass" class="theme-wrapper">
    <div class="fullscreen-wrapper">
      <div class="display-wrapper">
        <div v-bind:class="{ 'darkened-background': theme.settings.darkenBackground }" class="backdrop" v-bind:style="{ backgroundImage: 'url(' + theme.settings.backgroundImage + ')' }">
          <video v-if="theme.settings.backgroundVideo !== ''" v-bind:src="theme.settings.backgroundVideo" class="background-video" width="auto" autoplay loop muted></video>
          <div class="background-overlay"></div>
        </div>

        <header-component></header-component>

        <div class="posts" v-bind:class="theme.settings.animation">
          <template v-if="post">
              <transition appear
                        v-bind:enter-active-class="enterAnim"
                        v-bind:leave-active-class="exitAnim"
                        v-bind:mode="mode">
              <component v-if="currentPost" v-bind:is="getPostType(currentPost)" :key="getKey(currentPost)" :theme="theme" :panel="currentPost" :main="true" :post="currentPost"></component>
            </transition>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LowerThirdScrollPostComponent from './LowerThirdScrollPost.vue';
import PostPanelComponent from '../../post-panel/PostPanel.vue';
import HeaderComponent from '../../../header/v1.0/Header.vue';
import LayoutMixins from '../../../../mixins/layout-mixins';

export default {
  props: ['posts', 'theme', 'post'],
  components: {
    'header-component': HeaderComponent,
    post: LowerThirdScrollPostComponent,
    panel: PostPanelComponent,
  },
  mixins: [
    LayoutMixins,
  ],
  data() {
    return {
      hasOwnTimer: true,
    };
  },
  computed: {
    enterAnim() {
      return this.$store.getters.getEnter;
    },
    exitAnim() {
      return this.$store.getters.getExit;
    },
    mode() {
      return this.$store.getters.getMode;
    },
  },
};
</script>
