<template>
  <div
    class="element marquee"
    :style="getBasicStyles"
    :class="[element.cssClasses, element.animationInStyle, element.animationOutStyle]"
  >
    <transition
      appear
      :enter-to-class="animationIn"
      :leave-to-class="animationOut"
      enter-class="enter"
      type="animation"
      :mode="transitionMode"
      @before-enter="beforeEnter"
      @after-enter="afterEnter"
      @before-leave="beforeLeave"
      @after-leave="afterLeave"
    >
      <div
        class="marquee-wrap animated-element"
        :class="[getLoopingClass, alignItemsClass]"
        :style="[
          getAnimationStyles,
          getBorderRadiusStyles,
          getBackgroundStyles,
          getBorderStyles,
          getBoxShadowStyles,
        ]"
      >
        <!-- key to data id so the component refreshes and restarts animation on data change -->
        <marquee-animate
          :speed="element.speed"
          :paused="paused"
          :reverse="element.reverse"
          :item-gap="element.styles.gap + 'px'"
          :animation-iteration-count="`infinite`"
          :space-between="spaceBetweenWidth"
          :key="page"
        >
          <!-- the repeated elements -->
          <template v-if="data.length">
            <template v-for="elements in virtualElements">
              <graphic-element
                v-for="e in elements.filter((x) => x.visible)"
                :id="e.id"
                :key="e.id"
                :element="e"
                ref="children"
                :type="e.type"
              />
            </template>
          </template>
        <template v-else>
          <!-- a case where we don't want to reverse the child elements because they are inline -->
          <graphic-element
            v-for="el in childElements"
            :key="el.id"
            :id="el.id"
            :type="el.type"
            :element="el"
            :parent="element.id"
            v-on="$listeners"
          />
        </template>
        </marquee-animate>
      </div>
    </transition>
  </div>
</template>

<script>
import { Marquee as MarqueeAnimate } from '@tagboard/tgb-component-lib';
import GraphicsMixins from '../../mixins/graphics-mixins';
import DataRepeaterMixins from '../../mixins/data-repeater-mixins';

export default {
  name: 'MarqueeWrapper',
  components: {
    GraphicElement: () => import('../render-elements'),
    MarqueeAnimate,
  },
  mixins: [GraphicsMixins, DataRepeaterMixins],
  data() {
    return {
      paused: false,
    };
  },
  computed: {
    spaceBetween() {
      if (this.element.tickerSpacing === 'spaced') {
        return true;
      }
      return false;
    },
    spaceBetweenWidth() {
      if (this.spaceBetween) {
        return this.element.styles.width - this.element.styles.gap;
      }

      return null;
    },
    getKey() {
      return this.animkey ?? null;
    },
    childElements() {
      // a case where we don't want to reverse the child elements because they are inline
      return this.element.elements
        ? this.element.elements.slice().filter((x) => x.visible)
        : [];
    },
    alignItemsClass() {
      switch (this.element.verticalAlign) {
        case 'top':
          return 'align-items-start';
        case 'middle':
          return 'align-items-center';
        case 'bottom':
          return 'align-items-end';
        default:
          return '';
      }
    },
  },
  watch: {
    actions(newVal) {
    // check if action is for marquee
      if (newVal.length > 0
        && newVal[0].type === 'marquee'
        && newVal[0].elementId === this.element.id) {
        // eslint-disable-next-line default-case
        switch (newVal[0].value) {
          case 'play/pause':
            this.togglePause();
            break;
        }
      }
    },
  },
  methods: {
    togglePause() {
      this.paused = !this.paused;
    },
  },
};
</script>
