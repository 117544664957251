import { isEqual, omit } from 'lodash-es';
import MediaProxy from '../utils/media-proxy';
import postUtils from '../utils/post-utils';
import EventBus from '../utils/event-bus';
import { proxyNetworks } from '../utils/appSettings';
import Fonts from '../services/custom-fonts';

const { STATIC_BASE } = require('../services/services');

const loadImage = (src) => new Promise((resolve, reject) => {
  const img = new Image();
  img.onload = () => { resolve(); };
  img.onerror = () => { reject(); };
  img.src = src;
});

export default {
  data() {
    return {
      currentPost: null,
      hasOwnTimer: false,
    };
  },

  created() {
    this.registerCustomCSS();
    this.registerCustomFonts();

    if (this.theme.layout !== 'grid') {
      this.setPost();
    }
  },

  mounted() {
    if (!['waterfall', 'grid', 'chat', 'carousel', 'mural'].includes(this.theme.layout)) {
      this.$nextTick(() => {
        this.$emit('frameloaded');
        window.parent.postMessage('tgbLive:loaded', '*');
      });
    }
  },

  watch: {
    post(newval, oldval) {
      if (newval && !isEqual(
        omit(oldval, ['display_num']),
        omit(newval, ['display_num']),
      )) {
        this.setPost();
      }
    },

    playing(isPlaying) {
      // If user starts playing timeline,
      // and there's no posts in the theme,
      // we need to start a timer anyway so auto-advance still works.
      if (isPlaying && this.noPosts) {
        this.initiateTimer();
      }
    },
  },

  computed: {
    displayToken() {
      // Display token can exist in multiple places since we can be in a live output, or theme,
      // `production` module doesn't exist in some contexts (like the theme editor)
      return (
        this.$store.state.theme?.displayToken
        || this.$store.state.production?.display_token
      );
    },

    playing() {
      return this.$store.getters.isPlaying;
    },

    isVideo() {
      return !!(this.currentPost && this.currentPost.videos && this.currentPost.videos.length);
    },

    isGif() {
      return !!(this.currentPost && this.currentPost.videos && this.currentPost.videos[0].type === 'gif');
    },

    noPosts() {
      return !this.posts.length;
    },

    getThemeClass() {
      const isPreview = this.$route && this.$route.query.preview;
      let classname = 'theme-';

      if (isPreview) {
        classname += 'editor';
      } else {
        classname += this.theme._id;
      }

      if (this.theme.settings.snippets) {
        this.theme.settings.snippets.forEach((s) => {
          classname += (` snp-${s}`);
        });
      }

      return classname;
    },

    snippets() {
      return this.$store.state.snippets.snippets;
    },

    filteredPosts() {
      return this.posts.filter((post) => post.network !== 'instagram-stories' && post.network !== 'snapchat');
    },
    customFonts() {
      return this.$store.state.theme.customFonts;
    },
  },

  methods: {
    initiateTimer() {
      const { production } = this.$store.state;

      // NOTE: Interactive is SUPPOSED to wait I guess, but it's unexpected behaviour in producer
      //       so I removed it from this check
      if ((this.isGif || !this.isVideo) && !this.theme.settings.interactive && !this.hasOwnTimer) {
        EventBus.$emit('resetTimer');
        EventBus.$emit('startTimer', this.theme.settings.displayTime);
      } else if (production) {
        // If there's a "production" module in the store, then we are in producer so emit the time anyway
        // TODO: This will need to be updated if we combine stores and rewrite theme rendering
        EventBus.$emit('startTimer', this.theme.settings.displayTime);
      }
    },

    setPost() {
      if (!this.post) {
        // If no post to set, but auto-advance is enabled,
        // we gotta start the timer anyway
        if (this.noPosts) {
          this.initiateTimer();
        }

        return;
      }

      const timelineId = this.post.timeline_id;

      if (this.currentPost && timelineId && timelineId === this.currentPost.timeline_id) {
        // Current post already matches target post, so just start timer and return
        this.initiateTimer();
        return;
      }

      this.currentPost = null;

      if (this.post.layout === 'panel') {
        this.currentPost = this.post;
      } else {
        this.checkPostMedia(this.post)
          .then(() => {
            this.currentPost = this.post;
          }).catch((err) => {
            // eslint-disable-next-line no-console
            console.log('Post images failed to load', err);

            this.$store.dispatch('removePost', this.post.post_id);
            if (!this.theme.settings.interactive && this.playing) {
              EventBus.$emit('advance', 'next');
            }
          }).finally(() => {
            this.initiateTimer();
          });
      }
    },

    async checkPostMedia(p) {
      const post = p;

      let tryingProxy = false;

      const pfp = post.user_profile_image_url;

      if (post.post_type === 'video' && post.videos?.length < 1) {
        // No video to check. Just skip.
        throw new Error('Missing post video');
      }

      const [photo] = post.photos || [];
      let photoSrc = photo && (photo.l || photo.m);

      await Promise.all([
        // Check pfp and replace if broken
        !pfp ? null : loadImage(pfp).catch(() => {
          this.$store.dispatch('updatePost', { post_id: post.post_id, field: 'user_profile_image_url', newValue: `${STATIC_BASE}/live/assets/img/profile_placeholder.jpg` });

          // NOTE: Should we setup the proxy for instagram pfps?
        }),

        // Check post image
        !photoSrc ? null : loadImage(photoSrc).catch(() => {
          if (!MediaProxy.usingNetwork(post.network, proxyNetworks) || post.post_type !== 'photo' || MediaProxy.urlIsProxied(photoSrc)) {
            // Not an image, or already proxied
            throw new Error('Invalid or broken post image');
          }

          // Try to proxy the image request
          if (post.network === 'instagram') {
            post.photos[0].s = postUtils.instagramMediaUrl(post, 't');
            post.photos[0].m = postUtils.instagramMediaUrl(post, 'm');
            post.photos[0].l = postUtils.instagramMediaUrl(post, 'l');
          } else {
            post.photos[0].s = MediaProxy.genUrl(post.photos[0].s);
            post.photos[0].m = MediaProxy.genUrl(post.photos[0].m);
            post.photos[0].l = MediaProxy.genUrl(post.photos[0].l);
          }

          if (photoSrc === (post.photos[0].l || post.photos[0].m)) {
            // Image didn't change, so just stick with the original failure
            throw new Error('Invalid or broken post image');
          }

          this.$store.dispatch('updatePost', { post_id: post.post_id, field: 'photos', newValue: post.photos });
          photoSrc = post.photos[0].l || post.photos[0].m;
          tryingProxy = true;
        }),
      ]);

      if (tryingProxy && photoSrc) {
        // Try fallback image proxy.
        // Just let it throw an error if it fails
        await loadImage(photoSrc);
      }

      return true;
    },

    getPostType(post) {
      if (post.layout === 'panel') {
        return 'panel';
      }

      return 'post';
    },

    getKey(post) {
      if (post.timeline_id) {
        return post.timeline_id;
      }
      return post.post_id;
    },

    registerCustomCSS() {
      const themeId = `theme-${this.theme._id}`;
      const $themeStyle = $(`style#${themeId}`);
      const $cssMarker = $("meta[name='custom-css-marker']");

      // Snippets get inserted before the CSS marker, and themes after.
      // This ensures that the more specific theme css is after any snippet css
      // so that theme rules take precedence over snippet rules.

      // Check if the style block for this theme has been added yet, if not, add it
      if ($themeStyle.length === 0) {
        $cssMarker.after(`<style id="${themeId}">${this.theme.css}</style>`);
      } else {
        $themeStyle.text(this.theme.css);
      }

      if (this.theme.settings.snippets) {
        // Iterate over each snippet on this theme, and make sure the css gets added
        this.theme.settings.snippets.forEach((snippetId) => {
          const $snippetBlock = $(`style#snp-${snippetId}`);

          // We haven't yet added a style block for this snippet
          if ($snippetBlock.length === 0) {
            // Find the snippet that matches this id and add the css block
            this.snippets.some((snippet) => {
              if (snippet._id !== snippetId) {
                return false; // This isn't the right snippet, try the next one
              }

              // eslint-disable-next-line no-console
              $cssMarker.before(`<style id="snp-${snippetId}">${snippet.css}</style>`);
              return true; // We found the snippet, we can stop iterating
            });
          }
        });
      }
    },

    async registerCustomFonts() {
      if (!this.theme?.owner) {
        return;
      }

      const data = await Fonts.getFonts(this.theme.owner, this.displayToken);

      const fonts = [];
      data.result.assets.forEach((item) => {
        fonts.push({
          name: item.title,
          font: item.title.split('.')[0],
          fontLink: item.alt ? item.alt.media_url : item.media_url,
          fontId: `f-${item._id}`,
        });
      });

      const cssMarker = $("meta[name='custom-fonts-marker']");
      let css = '';

      fonts.forEach((font) => {
        const fontFace = `
            @font-face {
              font-family: '${font.fontId}';
              src: url('${font.fontLink}');
              font-weight: bold;
              font-style: normal;
              font-display: block;
            }

          `;

        css += fontFace;
      });

      cssMarker.before(`<style id="customFonts">${css}</style>`);
      cssMarker.after(`<style id="Custom-Font-Css">${this.theme.customFontCss}</style>`);
      cssMarker.after(`<style id="CTA-Css">${this.theme.customFontCTACss}</style>`);
      cssMarker.after(`<style id="PostMessage-Css">${this.theme.customFontPostMessageCss}</style>`);
      cssMarker.after(`<style id="PostAuthor-Css">${this.theme.customFontPostAuthorCss}</style>`);
      cssMarker.after(`<style id="Handle-Css">${this.theme.customFontPostHandleCss}</style>`);
    },
  },
};
