export default function store(key, value) {
  let data;
  // If value is detected, set new or modify store
  if (typeof value !== "undefined" && value !== null) {
      // Convert object values to JSON
      if ( typeof value === 'object' ) {
          value = JSON.stringify(value);
      }
      // Set the store
      sessionStorage.setItem(key, value);
  }
  
  // No value supplied, return value
  if (typeof value === "undefined") {
      // Get value
      data = sessionStorage.getItem(key);
      
      // Try to parse JSON...
      try {
         data = JSON.parse(data);
      }
      catch(e) {
         data = data;
      }
      
      return data;
      
  }
  
  // Null specified, remove store
  if (value === null) {
    sessionStorage.removeItem(key);
  }
  
};