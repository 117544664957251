import { cloneDeep, omit } from 'lodash-es';

import { request } from '../../utils/request';

const THEMES_BASE = `${window.location.protocol}//${window.location.host}`;

/**
 * Remove fields not required by the server.
 * These fields will be set on the backend.
 */
const cleanTheme = (theme, omits = []) => omit(theme, [
  '_id',
  'created_by',
  'created_time',
  'css',
  'modified_by',
  'modified_time',
  ...omits,
]);

export default {
  getThemes(owner) {
    return request({
      type: 'GET',
      url: `${THEMES_BASE}/themes/all/${owner}`,
    });
  },

  getTheme(themeId) {
    return request({
      type: 'GET',
      url: `${THEMES_BASE}/theme/${themeId}`,
    });
  },

  createTheme(obj) {
    const theme = cloneDeep(obj);

    return request({
      type: 'POST',
      contentType: 'application/json',
      url: `${THEMES_BASE}/theme/create`,
      data: JSON.stringify(cleanTheme(theme)),
    });
  },

  editTheme(obj) {
    const id = obj._id;
    const theme = cloneDeep(obj);

    return request({
      type: 'PUT',
      url: `${THEMES_BASE}/theme/${id}`,
      contentType: 'application/json',
      data: JSON.stringify(cleanTheme(theme, ['owner'])),
    });
  },

  deleteTheme(obj) {
    return request({
      type: 'DELETE',
      url: `${THEMES_BASE}/theme/${obj._id}`,
      contentType: 'application/json',
    });
  },

  compileScss(scss) {
    return request({
      type: 'POST',
      url: `${THEMES_BASE}/theme/scss/compile`,
      data: { scss },
    });
  },
};
