<template>
  <div
    class="element qrcode-wrap"
    :class="[element.cssClasses, element.animationInStyle, element.animationOutStyle]"
    :style="{ '--dot-color': dotColor,'--background-color': backgroundColor, ...getBasicStyles }"
  >
    <transition
      appear
      :enter-to-class="animationIn"
      :leave-to-class="animationOut"
      enter-class="enter"
      type="animation"
      :mode="transitionMode"
      @before-enter="beforeEnter"
      @after-enter="afterEnter"
      @before-leave="beforeLeave"
      @after-leave="afterLeave"
    >
      <div
        v-if="ready"
        :key="permalink"
        ref="qrcode"
        :data-permalink="permalink"
        class="qr-code animated-element"
        :class="getLoopingClass"
        :style="getAnimationStyles"
      />
    </transition>
  </div>
</template>

<script>
import GraphicsMixins from '../../../mixins/graphics-mixins';

const EasyQRCode = require('../../../libs/easy.qrcode.premium.min');

/**
* Search tuples for provided key, and return the associated short url (value).
* @param {Array<Array<String>>} shortLinks - Key/value pairs of URLs w/shortened versions.
* @param {String} key - Unshortened URL to search for.
* @returns {String}
*/
const getShortLink = (shortLinks, key) => {
  const tuple = (shortLinks || []).find((s) => s[0] === key);
  return tuple?.length ? tuple[1] : '';
};

// Very simple. Optional https/mailto protocols, at least two character domain extension.
const urlRegex = /(https?:\/\/|mailto:)?[^\s\\]+\.[^\s\\]{2,}/;

const isURL = (url) => (
  url
  && urlRegex.test(url)
);

export default {
  mixins: [GraphicsMixins],
  computed: {
    width() {
      return this.element.styles.width;
    },

    height() {
      return this.element.styles.height;
    },

    enterActiveClass() {
      return `animated ${this.animationIn}`;
    },

    leaveActiveClass() {
      return `animated ${this.animationOut}`;
    },

    dotColor() {
      return this.element.dotColor;
    },

    backgroundColor() {
      return this.element.backgroundColor;
    },

    isMapped() {
      return !!this.element?.data?.provider;
    },

    /**
    * Return url from data mapped source, if there is one
    */
    dataLink() {
      if (!this.isMapped) {
        return null;
      }

      let url;

      // Old dusty way
      // We should probably update existing graphics instead of leaving legacy code here indefinitely
      if ('dataValue' in this.element.data) {
        const { dataValue } = this.element.data;
        url = dataValue?.text;
      }

      // New shiny way
      if ('dataValues' in this.element.data) {
        const { dataValues } = this.element.data;
        url = dataValues && dataValues.permalink;
      }

      if (!isURL(url)) {
        return null;
      }

      // Field looks like a URL, so let's return it
      return url;
    },

    isShoppable() {
      return this.element?.data?.providerType === 'shoppable';
    },

    permalink() {
      if (this.isMapped) {
        if (this.isShoppable) {
          return this.dataLink;
        }
        return this.shortUrl || '';
      }

      return process.env.QR_CODE_SWITCH === 'ON'
        ? (this.element.shortLink || this.element.permalink)
        : this.element.permalink;
    },

    logoUrl() {
      return this.element.logoUrl;
    },

    hasLogo() {
      return this.element.hasLogo;
    },

  },

  watch: {
    'element.shortLinks': {
      handler(shortLinks, prevLinks) {
        // shortLinks are tuples because Mongo doesn't like URLs as object keys
        const shortUrl = getShortLink(shortLinks, this.dataLink);
        const oldUrl = getShortLink(prevLinks, this.dataLink);

        if (shortUrl && shortUrl !== oldUrl) {
          this.shortUrl = shortUrl;
          this.makeQRCode();
        }
      },
    },

    dataLink: {
      handler() {
        this.makeQRCode();
      },
    },

    backgroundColor() {
      this.makeQRCode();
    },

    dotColor() {
      this.makeQRCode();
    },

    permalink() {
      this.ready = false;
      this.makeQRCode();
    },

    logoUrl() {
      this.makeQRCode();
    },

    hasLogo() {
      this.makeQRCode();
    },
  },

  data() {
    return {
      shortUrl: null,
      ready: false,
    };
  },

  mounted() {
    this.makeQRCode();
  },

  methods: {
    makeQRCode() {
      const options = {
        text: this.permalink,
        width: this.width,
        height: this.height,
        colorDark: this.dotColor,
        colorLight: this.backgroundColor,
        dotScale: 1,
        correctLevel: EasyQRCode.CorrectLevel.H,

        eyeFrameDraw(ctx, x, y, w, h, position) {
          /* eslint-disable no-param-reassign, no-mixed-operators */
          w = (w / 7) * 6;
          h = w;
          ctx.beginPath();
          const r = w / 7;
          ctx.arc(x + r, y + r, r, Math.PI, Math.PI * 3 / 2);
          ctx.lineTo(w - r + x, y);

          if (position === EasyQRCode.EYE_POSITION.TR) {
            ctx.arc(w - r + x, r + y, r, Math.PI * 3 / 2, Math.PI * 2);
            ctx.lineTo(w + x, h + y - r);
            ctx.arc(w - r + x, h - r + y, r, 0, Math.PI * 1 / 2);
            ctx.lineTo(x, h + y);
            ctx.lineTo(x, y + r);
          } else if (position === EasyQRCode.EYE_POSITION.BL) {
            ctx.lineTo(w + x, y);
            ctx.arc(w - r + x, h - r + y, r, 0, Math.PI * 1 / 2);
            ctx.lineTo(r + x, h + y);
            ctx.arc(r + x, h - r + y, r, Math.PI * 1 / 2, Math.PI);
            ctx.lineTo(x, y + r);
          } else {
            ctx.arc(w - r + x, r + y, r, Math.PI * 3 / 2, Math.PI * 2);
            ctx.lineTo(w + x, h + y);
            ctx.lineTo(r + x, h + y);
            ctx.arc(r + x, h - r + y, r, Math.PI * 1 / 2, Math.PI);
            ctx.lineTo(x, y + r);
          }
          ctx.stroke();
        },

        eyeBallDraw(ctx, x, y, w) {
          const r = w / 6;
          const d = r * 2;
          for (let i = 0; i < 3; i += 1) {
            ctx.beginPath();
            for (let j = 0; j < 3; j += 1) {
              ctx.arc(x + j * d + r, y + i * d + r, r, 0, 2 * Math.PI);
            }
            ctx.fill();
          }
        },

        bodyBlockDraw(ctx, x, y, w, h, blockInfo) {
          w -= 2;
          const r = w / 2;
          x += 1;

          if (blockInfo.type === EasyQRCode.BLOCK.BLANK) {
            ctx.fillRect(x, y, w, h);
          } else if (blockInfo.type === EasyQRCode.BLOCK.SINGLE) {
            ctx.beginPath();
            ctx.arc(x + w / 2, y + h / 2, r, 0, 2 * Math.PI);
            ctx.fill();

            if (blockInfo.alignment === 'I') {
              ctx.fillStyle = this.dotColor;
              ctx.beginPath();
              ctx.arc(x + w / 2, y + h / 2, r, 0, 2 * Math.PI);
              ctx.fill();
            } else {
              ctx.beginPath();
              ctx.arc(x + w / 2, y + h / 2, r, 0, 2 * Math.PI);
              ctx.fill();
            }
          } else if (blockInfo.type === EasyQRCode.BLOCK.END) {
            const direction = blockInfo.endDirection;

            if (direction === EasyQRCode.DIRECTION.UP) {
              ctx.fillRect(x, y, w, h - r);
              ctx.beginPath();
              ctx.arc(x + w / 2, y + h / 2, r, 0, Math.PI);
              ctx.fill();
            } else if (direction === EasyQRCode.DIRECTION.DOWN) {
              ctx.fillRect(x, y + r, w, h - r);
              ctx.beginPath();
              ctx.arc(x + w / 2, y + h / 2, r, Math.PI, 2 * Math.PI);
              ctx.fill();
            } else if (direction === EasyQRCode.DIRECTION.LEFT) {
              ctx.beginPath();
              ctx.arc(x + w / 2, y + h / 2, r, 0, 2 * Math.PI);
              ctx.fill();
            } else if (direction === EasyQRCode.DIRECTION.RIGHT) {
              ctx.beginPath();
              ctx.arc(x + w / 2, y + h / 2, r, 0, 2 * Math.PI);
              ctx.fill();
            }
            ctx.fill();
          } else if (blockInfo.around.up && blockInfo.around.down) {
            ctx.fillRect(x, y, w, h);
          } else if (!blockInfo.around.up && blockInfo.around.down) {
            ctx.fillRect(x, y + r, w, h - r);
            ctx.beginPath();
            ctx.arc(x + w / 2, y + h / 2, r, Math.PI, 2 * Math.PI);
            ctx.fill();
          } else if (!blockInfo.around.down && blockInfo.around.up) {
            ctx.fillRect(x, y, w, h - r);
            ctx.beginPath();
            ctx.arc(x + w / 2, y + h / 2, r, 0, Math.PI);
            ctx.fill();
          } else {
            ctx.beginPath();
            ctx.arc(x + w / 2, y + h / 2, r, 0, 2 * Math.PI);
            ctx.fill();
          }
        },
      };

      if (this.element.hasLogo) {
        options.logo = this.element.logoUrl;
        options.logoBackgroundTransparent = true;
        options.logoBackgroundColor = '#fff';
      }

      this.ready = true;
      this.$nextTick(() => {
        this.renderQRCode(options);
      });
    },

    renderQRCode(options) {
      if (!this.$refs.qrcode || this.$refs.qrcode.dataset?.permalink !== this.permalink) {
        clearTimeout(this.renderTimeout);
        this.renderTimeout = setTimeout(() => {
          this.$nextTick(() => {
            this.renderQRCode(options);
          });
        }, 10);
      }

      if (this.$refs.qrcode) {
        this.$refs.qrcode.innerHTML = '';
        this.$options.qrcode = new EasyQRCode(this.$refs.qrcode, options);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.qr-code{
  &:after{
    font-size: clamp(12px, .8em, 4rem);
    content: 'privacy.tgb.io';
    margin: auto;
    display: block;
    text-align: center;
    color: var(--dot-color);
    background: var(--background-color);
    line-height: 1;
    margin-top: -4px;
    padding: 5px 0;
  }
}
</style>
