import cache from '../../utils/cache';
import ScreensService from '../../services/screens';

const state = {
  is_preview: false,
  connected: false,
  screen_id: null,
  production_id: null,
  isReady: false,
  preview_room: null,
};

const actions = {
  async getScreen(context, screenId) {
    if (!screenId) {
      throw new Error('Missing screenId');
    }

    const data = await ScreensService.getScreen(screenId);
    return data.result;
  },

  setIsReady(context, payload) {
    context.commit('SET_IS_READY', payload);
  },

  setScreenId(context, payload) {
    context.commit('SET_SCREEN_ID', payload);
  },

  setIsPreview(context, payload) {
    context.commit('SET_IS_PREVIEW', payload);
  },

  setConnected(context, payload) {
    context.commit('SET_CONNECTED', payload);
  },

  setProductionId(context, id) {
    context.commit('SET_PRODUCTION_ID', id);
  },

  setCache(context, payload) {
    context.commit('SET_CACHE', payload);
  },

  socket_joinProduction(context, payload) {
    vm.$socket.emit('production', { screen_id: state.screen_id, production_id: payload.production_id, verification_key: payload.verification_key });
    context.commit('SCREEN_CONNECTED', payload);
  },

  socket_leaveProduction(context, productionId) {
    if (productionId) {
      vm.$socket.emit('leave', {
        production_id: productionId,
      });
    }

    context.commit('SET_PRODUCTION_ID', null);
  },

  resetScreen(context) {
    context.commit('RESET_SCREEN');
  },

  socket_resetScreen(context) {
    context.commit('RESET_SCREEN');
  },

  setPreviewRoom(context, payload) {
    context.commit('SET_PREVIEW_ROOM', payload);
  },
};

const mutations = {
  /* eslint-disable no-shadow, no-param-reassign */

  SCREEN_CONNECTED(state, payload) {
    state.connected = true;
    state.screen_id = payload.screen_id;
    state.production_id = payload.production_id;

    // Cache, retaining existing details if necessary
    const cachedScreen = cache('tgb_producer_screen') || {};
    cache('tgb_producer_screen', {
      ...cachedScreen,
      ...payload,
    });
  },

  SET_PRODUCTION_ID(state, payload) {
    state.production_id = payload;
    state.connected = true;
  },

  SET_SCREEN_ID(state, payload) {
    state.screen_id = payload;
  },

  SET_CONNECTED(state, payload) {
    state.connected = payload;
  },

  SET_IS_PREVIEW(state, payload) {
    state.is_preview = payload;
    state.connected = true;
  },

  SET_IS_READY(state, payload) {
    state.isReady = payload;
  },

  RESET_SCREEN(state) {
    state.connected = false;
    cache('tgb_producer_screen', null);
    window.location.reload();
  },

  SET_CACHE(state, payload) {
    cache('tgb_producer_screen', payload);
  },

  SET_PREVIEW_ROOM(state, payload) {
    state.preview_room = payload;
  },
};

const getters = {
  isKeyScreen(state) {
    return state.screen_id === state.key_screen;
  },

  getKeyScreen(state) {
    return state.key_screen;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
