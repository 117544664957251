import Pusher from 'pusher-js';

const PusherService = {
  pusher: undefined, // client instance

  getClientInstance() {
    if (!this.pusher) {
      const PUSHER_APP_KEY = process.env.PUSHER_KEY;
      const PUSHER_APP_CLUSTER = process.env.PUSHER_CLUSTER;
      this.pusher = new Pusher(PUSHER_APP_KEY, { cluster: PUSHER_APP_CLUSTER });
    }

    return this.pusher;
  },
};

export default PusherService;
