import { request } from '../utils/request';
import { GRAPHICS_BASE } from './services';

export default {
  getUserTemplates(userId) {
    return request({
      url: `${GRAPHICS_BASE}/templates/${userId}`,
      type: 'GET',
    });
  },

  getTemplate(templateId) {
    return request({
      url: `${GRAPHICS_BASE}/template/${templateId}`,
      type: 'GET',
    });
  },

  getGraphic(graphicId) {
    return request({
      url: `${GRAPHICS_BASE}/graphic/${graphicId}`,
      type: 'GET',
    });
  },
};
