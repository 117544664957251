/**
 * Calculate total leave duration of element animations (includes child elements)
 * @param {Object} element - Graphic layer
 */
const getLeaveDuration = (element) => {
  if (!element) {
    return 0;
  }

  // Duration of base element
  let duration = element.animation
    ? Number(element.animationOutDuration) + Number(element.animationOutDelay)
    : 0;

  // Check if element has children and get their duration recursively
  if (element.elements?.length) {
    duration = Math.max(
      duration,
      ...element.elements.map((el) => getLeaveDuration(el)),
    );
  }

  return duration;
};

/**
 * Sleep for provided amount of milliseconds, then resolve promise.
 * @param {Number} t - Amount of time to sleep
 */
const sleep = (t) => new Promise((resolve) => {
  setTimeout(resolve, t);
});

const DefaultRetryAttempts = 5; // 5 retry attempts before throwing
const DefaultRetryDelay = 500; // 500ms

/**
 * Returns a wrapper function around the provided function that will catch any errors and retry
 * the designated number of times before bubbling up the error.
 * @param {Function<Promise>} fn - The promise to retry
 * @param {Number} [opts.maxRetries] - Number of retries to attempt (default 5)
 * @param {Number} [opts.retryDelay] - Amount of ms to wait between retries (default 1s)
 * @returns {Function} - New wrapper function to call that will return the results of the inner function
 */
const withRetries = (fn, opts = {}) => {
  const {
    maxRetries = DefaultRetryAttempts,
    retryDelay = DefaultRetryDelay,
  } = opts;

  const run = async (...args) => {
    try {
      const result = await fn(...args);
      run.attempts = 0;
      return result;
    } catch (err) {
      if (run.attempts >= maxRetries) {
        run.attempts = 0;

        // Throw the most recent error and let it bubble up.
        throw err;
      }

      await sleep(retryDelay);
      run.attempts += 1;
      return run(...args);
    }
  };

  run.attempts = 0;
  return run;
};

/**
 * Determine if provided layout is a panel.
 * @param {Object} layout - Layout object
 */
const isSmartPanel = (layout) => {
  return layout?.layout === 'panel';
};

/**
   * Determine if provided layout is a Theme.
   * Since themes have various `layout` types,
   * we just check if its NOT a graphic or panel
   * @param {Object} layout - Layout object
   */
const isTheme = (layout) => {
  return layout && !(['graphic', 'graphics', 'panel'].includes(layout.layout));
};

const isGraphic = (layout) => {
  return layout && ['graphic', 'graphics'].includes(layout.layout);
};

/**
 * Fetch an image and resolve promise when image loads
 */
const preloadImage = (src) => new Promise((resolve) => {
  if (!src) { resolve(); }

  const img = new Image();

  // Ignore errors and just resolve for now
  img.onload = () => { resolve(); };
  img.onerror = () => { resolve(); };

  img.src = src;
});

const getTotalDuration = (posts) => {
  if (!posts || !posts.length) {
    return 0;
  }

  // Get longest animation in list of graphics
  let animDuration = 0;

  posts.forEach((post) => {
    if (!post) {
      return;
    }

    const leaveDuration = getLeaveDuration(post);
    animDuration = Math.max(animDuration, leaveDuration);
  });

  return animDuration;
};

/**
 * Attempt to detect if we are inside an iframe.
 * @returns {Boolean} True if inside an iframe (to our knowledge).
 */
const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

/**
 * Post message to parent window when inside an iframe.
 * @param {String} msg
 */
const postMessage = (msg) => {
  try {
    window.parent.postMessage(msg, '*');
  } catch (err) { /* noop */ }
};

export {
  withRetries,
  sleep,
  isSmartPanel,
  isTheme,
  isGraphic,
  preloadImage,
  getTotalDuration,
  inIframe,
  postMessage,
};
