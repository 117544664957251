<template>
  <div class="facebook-vid-wrap">
    <div
      :id="`fb-video-${pid}`"
      class="fb-video"
      data-autoplay="false"
      data-show-play-btn="true"
      :data-href="'https://www.facebook.com/video.php?v=' + pid"
    />
  </div>
</template>

<script>
/* global FB */

const { sleep } = require('../../../utils/helpers');

export default {
  props: ['post'],

  data() {
    return {
      fbPlayer: null,
    };
  },

  computed: {
    pid() {
      const pid = this.post.post_id.split('_');

      // :facepalm: Facebook, parse post_id and remove the author bit if it exists because apparently videos don't like it
      return pid[1] || pid[0];
    },

    videoPlaying() {
      return this.$store.getters.videoPlaying;
    },

    muted() {
      return this.$store.getters.isMuted;
    },

    playing() {
      return this.$store.getters.isPlaying;
    },
  },

  watch: {
    videoPlaying(videoPlaying) {
      if (videoPlaying) {
        this.playVideo();
      } else {
        this.stopVideo();
      }
    },

    muted(muted) {
      if (!this.fbPlayer) {
        return;
      }

      if (muted) {
        this.muteVideo();
      } else {
        this.unMuteVideo();
      }
    },
  },

  beforeDestroy() {
    FB.Event.unsubscribe('xfbml.ready', this.onReady);
  },

  mounted() {
    FB.XFBML.parse(this.$el);
    FB.Event.subscribe('xfbml.ready', this.onReady);
  },

  methods: {
    onStartPlaying() {
      // Gotta try to get the duration a few times because facebook
      const getDuration = async () => {
        for (let i = 0; i < 20; i += 1) {
          // Wait 100ms
          await sleep(100); // eslint-disable-line no-await-in-loop

          const duration = this.fbPlayer.getDuration() * 1e3;

          if (duration) {
            return duration;
          }
        }

        // Couldn't determine duration after a couple seconds
        return 0;
      };

      // Ignore duplicate events
      if (this.startedPlaying) { return; }
      this.startedPlaying = true;

      getDuration().then((duration) => {
        this.$store.dispatch('setPlayDuration', duration);
        this.$store.dispatch('setVideoDuration', duration);
      });
    },

    onFinished() {
      if (!this.playing) {
        this.playVideo(true);
      } else {
        this.$emit('finished');
      }
    },

    onPause() {
      this.$store.dispatch('stopVideo');
    },

    onError(err) {
      // eslint-disable-next-line no-console
      console.log(err);

      if (this.playing) {
        this.$emit('finished');
      }
    },

    onReady(msg) {
      this.startedPlaying = false;

      if (msg.type === 'video') {
        this.fbPlayer = msg.instance;

        if (this.videoPlaying) {
          // Auto-play if videoPlaying is already true
          this.playVideo();
        }

        // Bind all the things
        this.fbPlayer.subscribe('startedPlaying', this.onStartPlaying);
        this.fbPlayer.subscribe('finishedPlaying', this.onFinished);
        this.fbPlayer.subscribe('paused', this.onPause);
        this.fbPlayer.subscribe('error', this.onError);
      }
    },

    playVideo(fromBeginning) {
      if (!this.fbPlayer) {
        return;
      }

      if (fromBeginning) {
        this.fbPlayer.seek(0);
      }

      if (this.muted) {
        this.fbPlayer.mute();
      } else {
        this.fbPlayer.unmute();
      }

      this.fbPlayer.play();
    },

    stopVideo() {
      if (this.fbPlayer) {
        this.fbPlayer.pause();
      }
    },

    muteVideo() {
      this.fbPlayer.mute();
    },

    unMuteVideo() {
      this.fbPlayer.unmute();
    },
  },
};
</script>
