<template>
  <div
    class="element shape triangle"
    :style="[getBasicStyles, getTransformStyles]"
    :class="[element.cssClasses, element.animationInStyle, element.animationOutStyle]"
  >
    <div class="shape-wrap">
      <transition
        appear
        :enter-to-class="animationIn"
        :leave-to-class="animationOut"
        enter-class="enter"
        type="animation"
        :mode="transitionMode"
        @before-enter="beforeEnter"
        @after-enter="afterEnter"
        @before-leave="beforeLeave"
        @after-leave="afterLeave"
      >
        <div
          :key="getKey"
          class="animated-element"
          :class="getLoopingClass"
          :style="getAnimationStyles"
        >
          <div
            class="background"
            :style="[getBorderRadiusStyles, getBackgroundStyles, getBorderStyles, getBoxShadowStyles, getBlurStyles]"
          />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import GraphicsMixins from '../../mixins/graphics-mixins';

export default {
  mixins: [GraphicsMixins],
};
</script>
