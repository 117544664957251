import Vue from 'vue';
import Vuex from 'vuex';

import client from './modules/client';
import fonts from './modules/fonts';
import posts from './modules/posts';
import production from './modules/production';
import sheets from './modules/sheets';
import snippets from './modules/snippets';
import sockets from './modules/sockets';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    client,
    fonts,
    posts,
    production,
    sheets,
    snippets,
    sockets,
  },
});
