<template>
  <div class="element progress-indicator" :style="getBasicStyles" :class="element.progressIndicatorStyle">
    <div class="progress-stories-wrap" v-if="element.progressIndicatorStyle === 'stories'">
      <div class="stories-bar" :class="{'active': index == ind }" v-for="(slide,ind) in entries.length" :key="ind" :style="getFillStyle(ind)">
        <div class="progress" v-if="index == ind" :style="{'animation-duration': parent.cycleTime + 'ms', 'backgroundColor': element.barFillColor }"></div>
      </div>
    </div>

    <div class="progress-single-wrap" :style="{ backgroundColor: element.barBackgroundColor }" v-if="element.progressIndicatorStyle === 'single'">
      <div class="progress" v-for="(slide,ind) in entries.length" :key="ind" v-show="index == ind" :style="{'animation-duration': parent.cycleTime + 'ms', 'backgroundColor': element.barFillColor }"></div>
    </div>
  </div>
</template>

<script>
import { forEach } from 'lodash-es';

export default {
  props: ['element','parent','entries','index'],
  methods: {
    getFillStyle: function(index) {
      if(index < this.index) {
        return { backgroundColor: this.element.barFillColor };
      } else {
        return { backgroundColor: this.element.barBackgroundColor };
      }
    }
  },
  computed: {
    getBasicStyles: function() {
      var stylesObj = {},
          needsPixelsArray = ['top','left','width','height'];

      forEach(this.element.styles, function(value, key) {
        stylesObj[key] = value;
        if(needsPixelsArray.indexOf(key) !== -1) {
          stylesObj[key] = value + 'px';
        }
      });

      return stylesObj;
    }
  }
};

</script>
