<template>
  <div
    :id="element.id"
    ref="socialElement"
    class="element group social-ddg"
    :style="socialElementStyles"
    :class="[socialElementClass, element.animationInStyle, element.animationOutStyle]"
  >
    <div class="social-wrap">
      <transition
        appear
        :enter-to-class="animationIn"
        :leave-to-class="animationOut"
        enter-class="enter"
        type="animation"
        :mode="transitionMode"
        @before-enter="beforeEnter"
        @after-enter="afterEnter"
        @before-leave="beforeLeave"
        @after-leave="afterLeave"
      >
        <div
          v-if="post"
          :key="`${element.id}:${post.post_id}`"
          class="animated-element"
          :style="getAnimationStyles"
          :class="[getLoopingClass, element.cssClasses]"
        >
          <graphic-element
            v-for="el in mappedChildElements"
            :key="`${el.id}`"
            ref="child"
            parent="social"
            :element="el"
            :graphic="graphic"
            v-on="$listeners"
          />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash-es';
import { mapState, mapGetters } from 'vuex';

import GraphicsMixins from '../../mixins/graphics-mixins';

import { getSocialDataValue } from '../../../../../../utils/post-utils';
import Timer from '../../../../../../utils/timer';

export default {
  name: 'SocialDataElement',
  mixins: [GraphicsMixins],
  inject: ['item'],

  provide() {
    const data = {};

    Object.defineProperty(data, 'post', {
      enumerable: true,
      get: () => this.post,
    });

    Object.defineProperty(data, 'isTagboardPost', {
      enumerable: true,
      get: () => this.hasTagboard,
    });

    return data;
  },

  props: {
    element: {
      type: Object,
      required: true,
    },
  },

  components: {
    GraphicElement: () => import('../render-elements'),
  },

  data() {
    return {
      postIdx: 0,
      mappedChildElements: [],
    };
  },

  created() {
    this.startTimer();
  },

  beforeDestroy() {
    this.timer?.pause();
    this.timer = null;
  },

  watch: {
    postIdx() {
      this.startTimer();
    },

    element: {
      immediate: true,
      deep: true,
      handler() {
        this.mapChildElements();
      },
    },

    mappedChildElements: {
      immediate: true,
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.applyPostLinkStyles();
        });
      },
    },

    post: {
      immediate: true,
      deep: true,
      handler(v, o) {
        if (!this.hasTagboard) {
          this.$store.dispatch('setPlayDuration', this.duration);
          if (this.post && v.post_id !== o?.post_id) {
            // avoid empty mapping errors and double mapping on double post computed
            this.mapChildElements();
          }
        } else {
          this.mapChildElements();
        }
      },
    },

    // We can't use duration here, because it's also used by auto-advance
    // We need to prioritize video length > cycleSpeed
    duration() {
      if (this.timer) {
        this.timer.setDuration(this.duration);
      }

      // Only emit `duration` to clients if we aren't showing a full tagboard.
      // That way video duration will match auto-advance timer for individual posts.
      if (!this.hasTagboard) {
        this.$store.dispatch('setPlayDuration', this.duration);
      }
    },
  },

  methods: {
    /**
    * Set a timeout for cycling through social posts.
    * It will use the cycleSpeed on the element, unless the current post is a video.
    */
    startTimer() {
      if (!this.hasTagboard) { return; }

      // Stop existing timer from running.
      // Otherwise callback will still trigger.
      this.timer?.pause();

      this.timer = new Timer({
        duration: this.duration,
        meta: { tagboard: this.element.posts[0] },
        onDone: () => {
          const [tagboard] = this.element.posts;
          if (!tagboard) {
            this.postIdx = 0;
            return;
          }
          const posts = this.getPostsByTagboard(tagboard._id);

          // TODO: If we reach the end, we might need to load more posts instead of looping.
          //       But it depends if the user wants it to loop after a certain # of posts or not.
          if (this.postIdx >= posts.length - 1) {
            this.postIdx = 0; // Loop
          } else {
            this.postIdx += 1;
          }
        },
      });
    },

    beforeLeave() {
      if (this.element.animation) {
        this.animationDuration = `${this.element.animationOutDuration}ms`;
        this.animationDelay = `${this.element.animationOutDelay}ms`;
        this.animationTimingFunction = `cubic-bezier(${this.element.animationInEasing})`;
      }
    },

    mapChildElements() {
      const clone = cloneDeep(this.element.elements.filter((e) => e.visible));

      const attachData = (el) => {
        const element = el;

        // Inject data into element
        if (this.post && element?.data?.dataKeyMap) {
          const { dataKeyMap } = element.data;

          const dataValues = Object.keys(dataKeyMap).reduce((res, property) => {
            res[property] = getSocialDataValue({ post: this.post, element, property });
            return res;
          }, {});

          element.data = {
            ...element.data,
            dataValues,
          };
        } else if (!this.post && element?.data?.dataValues) {
          // Clear data if already attached to element
          // to prevent old data from rendering
          delete element.data.dataValues;
        }

        // Inject data into children of element
        if (element?.elements?.length) {
          element.elements.forEach((child) => {
            attachData(child);
          });
        }
      };

      this.mappedChildElements = clone.reverse().map((el) => {
        attachData(el);
        return el;
      });
    },
    applyPostLinkStyles() {
      if (!this.element.postSettings?.linkStyles) { return; }
      Object.entries(this.element.postSettings?.linkStyles).forEach(([className, styles]) => {
        // Find all elements with the current class name
        const domElements = this.$refs.socialElement.querySelectorAll(`.${className}`);

        domElements.forEach((domEl) => {
          // Apply each style to the element
          Object.entries(styles).forEach(([property, value]) => {
            // eslint-disable-next-line no-param-reassign
            domEl.style[property] = value;
          });
        });
      });
    },
  },

  computed: {
    ...mapState({
      postDurations: (state) => state.posts.postDurations,
    }),

    ...mapGetters([
      'getPostById',
      'getPostsByTagboard',
    ]),

    socialElementStyles() {
      return {
        width: `${this.graphic.canvas.width}px`,
        height: `${this.graphic.canvas.height}px`,
      };
    },

    duration() {
      return this.postDurations[this.post?.post_id] || this.element.cycleSpeed;
    },

    hasTagboard() {
      const { posts } = this.element;
      const [post] = posts || [];
      return post?.type === 'tagboard';
    },

    subIndex() {
      return this.$store.state.production.sub_index;
    },

    post() {
      const { posts } = this.element;

      if (this.hasTagboard) {
        const [tagboard] = posts;
        const byTagboard = this.getPostsByTagboard(tagboard._id);
        return byTagboard[this.postIdx];
      }

      // Individual posts
      const post = posts.find((p) => p.timeline_id === this.subIndex);
      return post ? this.getPostById(post.post_id) : null;
    },

    socialElementClass() {
      if (this.post) {
        return [
          `social-ddg--${this.post.network}`,
          (!this.element.postSettings?.showOtherLinks ? 'hide-link' : ''),
          (this.element.postSettings?.removeSpaces ? 'remove-spaces' : '')];
      }

      return '';
    },
  },
};
</script>
