<template>
  <div :class="getThemeClass" class="theme-wrapper">
    <div
      class="fullscreen-wrapper"
      :style="{ backgroundColor: theme.settings.keyColor }"
      :class="[
        'post-color-theme-' + theme.settings.postColorTheme,
        'font-' + theme.settings.font,
        { 'no-title': !theme.settings.showCtaText },
        { 'safe-area': safeArea },
      ]">
       <div
        v-if="safeArea"
        :class="{ 'darkened-background': theme.settings.darkenBackground, }"
        class="backdrop"
        :style="[backgroundStyle]">
        <video
          v-if="theme.settings.backgroundVideo"
          :src="theme.settings.backgroundVideo"
          class="background-video"
          width="auto"
          autoplay
          loop
          muted></video>
        <div class="background-overlay"></div>
      </div>
      <div
        class="display-wrapper"
        :style="[
          safeArea ? wrapperStyles : { 'background-color': theme.settings.backgroundColor },
        ]">
        <div
          v-if="!safeArea
              && (theme.settings.backgroundImage || theme.settings.backgroundVideo)"
          :class="{ 'darkened-background': theme.settings.darkenBackground, }"
          class="backdrop"
          :style="[backgroundStyle]">
          <video
            v-if="theme.settings.backgroundVideo"
            :src="theme.settings.backgroundVideo"
            class="background-video"
            width="auto"
            autoplay
            loop
            muted></video>
          <div class="background-overlay"></div>
        </div>

        <header-component :theme="theme"></header-component>

        <div class="posts" :class="[theme.settings.animation, { 'hide-links': theme.settings.hideLinks }]">
          <template v-if="post">
            <transition
              appear
              :enter-active-class="enterAnim"
              :leave-active-class="exitAnim"
              :mode="mode">
              <component
                v-if="currentPost"
                :is="getPostType(currentPost)"
                :key="getKey(currentPost)"
                :theme="theme"
                :panel="currentPost"
                :main="true"
                :post="currentPost">
              </component>
            </transition>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LowerThirdScrollPostComponent from './LowerThirdScrollPost.vue';
import PostPanelComponent from '../../post-panel/PostPanel.vue';
import HeaderComponent from '../../../header/v1.5/Header.vue';
import LayoutMixins from '../../../../mixins/layout-mixins';

export default {
  props: ['posts', 'theme', 'post'],
  components: {
    'header-component': HeaderComponent,
    post: LowerThirdScrollPostComponent,
    panel: PostPanelComponent,
  },

  mixins: [
    LayoutMixins,
  ],

  data() {
    return {
      hasOwnTimer: true,
    };
  },

  computed: {
    enterAnim() {
      return this.$store.getters.getEnter;
    },
    exitAnim() {
      return this.$store.getters.getExit;
    },
    mode() {
      return this.$store.getters.getMode;
    },
    safeArea() {
      return this.theme.settings.safeArea;
    },
    wrapperStyles() {
      const { safeWidth, safeHeight } = this.theme.settings;

      const styles = {
        'max-width': `${safeWidth}px`,
        'max-height': `${safeHeight}px`,
        bottom: `calc((100% - ${safeHeight}px) / 2)`,
      };

      return styles;
    },
    safeAreaMaxWidth() {
      const { safeArea, safeWidth } = this.theme.settings;

      return safeArea && safeWidth && safeWidth > 0
        ? `${safeWidth}px`
        : '100%';
    },
    safeAreaMaxHeight() {
      const { safeArea, safeHeight } = this.theme.settings;

      return safeArea && safeHeight && safeHeight > 0
        ? `${safeHeight}px`
        : '100%';
    },
    backgroundStyle() {
      const {
        safeArea, safeHeight, backgroundImage, backgroundColor,
      } = this.theme.settings;

      const heightValue = safeHeight ?? 1920;

      if (safeArea && backgroundImage !== '') {
        return {
          backgroundImage: `url(${backgroundImage})`,
          height: `calc(30% + ((100% - ${heightValue}px) / 2))`,
        };
      }
      if (safeArea && backgroundImage === '') {
        return {
          backgroundColor,
          height: `calc(30% + ((100% - ${heightValue}px) / 2))`,
        };
      }
      if (backgroundImage !== '') {
        return {
          backgroundImage: `url(${backgroundImage})`,
        };
      }

      return {};
    },
  },
};
</script>
