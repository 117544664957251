<template>
  <div v-bind:id="id" class="qr-code"></div>
</template>

<script>
const { STATIC_BASE } = require('../../../services/services');
const EasyQRCode = require('../../../../../lib/easy.qrcode.premium.min');

export default{
  props: ['id', 'permalink', 'shortLink', 'queryString', 'network', 'backgroundColor', 'dotColor', 'networkIcon', 'networkIconTheme', 'networkIconBorderStyle'],

  mounted() {
    const options = {
      text: this.shortLink || this.permalink,
      // width: 150,
      // height: 150,
      quietZone: 20,
      colorDark: this.dotColor,
      colorLight: this.backgroundColor,
      dotScale: 1,

      // logoWidth: 50, // width. default is automatic width
      // logoHeight: 50, // height. default is automatic height

      // === Position Pattern(Eye) Color
      // PO: '#55ACEE', // Global Position Outer color. if not set, the defaut is `colorDark`
      // PI: '#aa5b71', // Global Position Inner color. if not set, the defaut is `colorDark`
      // //					PO_TL:'', // Position Outer - Top Left
      // PI_TL: '#2bacca', // Position Inner - Top Left
      // PO_TL: '#55ACEE', // Position Outer - Top Left
      // PI_TR: '#2bacca', // Position Inner - Top Right
      // PO_TR: '#2bacca', // Position Outer - Top Right
      // PI_TR: '#c17e61', // Position Inner - Top Right
      // PO_BL:'', // Position Outer - Bottom Left
      // PI_BL:'' // Position Inner - Bottom Left

      correctLevel: EasyQRCode.CorrectLevel.H, // L, M, Q, H

      // Eye Frame: Round shape group
      eyeFrameDraw(ctx, x, y, w, h, position) {
        w = w / 7 * 6;
        h = w;
        ctx.beginPath();
        // ctx.strokeRect(x, y, w, h);
        const r = w / 7;
        ctx.arc(x + r, y + r, r, Math.PI, Math.PI * 3 / 2);
        ctx.lineTo(w - r + x, y);

        if (position == EasyQRCode.EYE_POSITION.TR) {
          ctx.arc(w - r + x, r + y, r, Math.PI * 3 / 2, Math.PI * 2);
          ctx.lineTo(w + x, h + y - r);
          ctx.arc(w - r + x, h - r + y, r, 0, Math.PI * 1 / 2);
          // ctx.lineTo(r + x, h + y);
          // ctx.arc(r + x, h - r + y, r, Math.PI * 1 / 2, Math.PI);

          ctx.lineTo(x, h + y);
          ctx.lineTo(x, y + r);
        } else if (position == EasyQRCode.EYE_POSITION.BL) {
          // ctx.arc(w - r + x, r + y, r, Math.PI * 3 / 2, Math.PI * 2);
          // ctx.lineTo(w + x, h + y - r);

          ctx.lineTo(w + x, y);
          ctx.arc(w - r + x, h - r + y, r, 0, Math.PI * 1 / 2);
          ctx.lineTo(r + x, h + y);
          ctx.arc(r + x, h - r + y, r, Math.PI * 1 / 2, Math.PI);
          ctx.lineTo(x, y + r);
        } else {
          ctx.arc(w - r + x, r + y, r, Math.PI * 3 / 2, Math.PI * 2);
          // ctx.lineTo(w + x, h + y - r);
          // ctx.arc(w - r + x, h - r + y, r, 0, Math.PI * 1 / 2);

          ctx.lineTo(w + x, h + y);
          ctx.lineTo(r + x, h + y);
          ctx.arc(r + x, h - r + y, r, Math.PI * 1 / 2, Math.PI);
          ctx.lineTo(x, y + r);
        }
        ctx.stroke();
      },

      // Eye Ball: Round shape group
      eyeBallDraw(ctx, x, y, w, h, position) {
        const r = w / 6;
        const d = r * 2;
        for (let i = 0; i < 3; i++) {
          ctx.beginPath();
          for (let j = 0; j < 3; j++) {
            ctx.arc(x + j * d + r, y + i * d + r, r, 0, 2 * Math.PI);
          }
          ctx.fill();
        }
      },

      // QR Body Block Sharp:
      // - EasyQRCode.BLOCK.SINGLE: Use filled diamond blocks
      // - EasyQRCode.BLOCK.END: Use filled triangle blocks
      // - EasyQRCode.BLOCK.END: Use filled rectangle blocks
      bodyBlockDraw(ctx, x, y, w, h, blockInfo) {
        w -= 2;
        const r = w / 2;
        x += 1;

        if (blockInfo.type == EasyQRCode.BLOCK.BLANK) {
          ctx.fillRect(x, y, w, h);
        } else if (blockInfo.type == EasyQRCode.BLOCK.SINGLE) {
          // ctx.fillStyle='#f00';
          ctx.beginPath();
          ctx.arc(x + w / 2, y + h / 2, r, 0, 2 * Math.PI);
          ctx.fill();

          if (blockInfo.alignment == 'I') {
            ctx.fillStyle = this.dotColor;
            ctx.beginPath();
            ctx.arc(x + w / 2, y + h / 2, r, 0, 2 * Math.PI);
            ctx.fill();
          } else {
            // ctx.fillStyle='#f00';
            ctx.beginPath();
            ctx.arc(x + w / 2, y + h / 2, r, 0, 2 * Math.PI);
            ctx.fill();
          }
        } else if (blockInfo.type == EasyQRCode.BLOCK.END) {
          // ctx.fillStyle='#00f';
          const direction = blockInfo.endDirection;

          // ctx.beginPath();
          if (direction == EasyQRCode.DIRECTION.UP) {
            ctx.fillRect(x, y, w, h - r);
            ctx.beginPath();
            ctx.arc(x + w / 2, y + h / 2, r, 0, Math.PI);
            ctx.fill();
          } else if (direction == EasyQRCode.DIRECTION.DOWN) {
            ctx.fillRect(x, y + r, w, h - r);
            ctx.beginPath();
            ctx.arc(x + w / 2, y + h / 2, r, Math.PI, 2 * Math.PI);
            ctx.fill();
          } else if (direction == EasyQRCode.DIRECTION.LEFT) {
            // ctx.fillRect(x, y, w-1, h)
            ctx.beginPath();
            ctx.arc(x + w / 2, y + h / 2, r, 0, 2 * Math.PI);
            ctx.fill();
          } else if (direction == EasyQRCode.DIRECTION.RIGHT) {
            // ctx.fillRect(x, y, w-1, h)
            ctx.beginPath();
            ctx.arc(x + w / 2, y + h / 2, r, 0, 2 * Math.PI);
            ctx.fill();
          }
          ctx.fill();
        } else {
          // EasyQRCode.BLOCK.NORMAL
          if (blockInfo.around.up && blockInfo.around.down) {
            ctx.fillRect(x, y, w, h);
          } else if (!blockInfo.around.up && blockInfo.around.down) {
            ctx.fillRect(x, y + r, w, h - r);
            ctx.beginPath();
            ctx.arc(x + w / 2, y + h / 2, r, Math.PI, 2 * Math.PI);
            ctx.fill();
          } else if (!blockInfo.around.down && blockInfo.around.up) {
            ctx.fillRect(x, y, w, h - r);
            ctx.beginPath();
            ctx.arc(x + w / 2, y + h / 2, r, 0, Math.PI);
            ctx.fill();
          } else {
            ctx.beginPath();
            ctx.arc(x + w / 2, y + h / 2, r, 0, 2 * Math.PI);
            ctx.fill();
          }
        }
      },
    };

    if (this.queryString) {
      options.text += this.queryString;
      if (options.text.indexOf('{{post_id}}') !== -1) {
        options.text = options.text.replace('{{post_id}}', this.id);
      }
    }

    if (this.networkIcon) {
      options.logo = `${STATIC_BASE}/live/assets/img/qr/${this.network}-${this.networkIconTheme}${this.networkIconBorderStyle === '' ? '.svg' : `-outline-${this.networkIconBorderStyle}.svg`}`;
      options.logoBackgroundTransparent = true;
      options.logoBackgroundColor = '#fff';
    }

    // Create EasyQRCode Object
    const qrCodeElement = document.getElementById(this.id);
    if (qrCodeElement) {
      new EasyQRCode(qrCodeElement, options);
    }
  },
};
</script>
