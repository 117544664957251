import SocketIO from 'socket.io-client';

export default {
  /**
   * Find all store actions with `socket_` prefix and register listeners.
   * Then bind `$socket` client to to Vue.
   */
  install(Vue, options) {
    const {
      store,
      actionPrefix = 'socket_',
    } = options;

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$socket = SocketIO(`${process.env.SOCKETS_URL}/producer`, {
      path: '/s',
      port: 443,
      secure: true,
      withCredentials: true,
    });

    const actions = store._actions; // eslint-disable-line no-underscore-dangle
    const socketActions = Object.keys(actions).filter((name) => name.indexOf(actionPrefix) === 0);

    socketActions.forEach((action) => {
      const eventName = action.substr(actionPrefix.length);

      Vue.prototype.$socket.on(eventName, (...args) => {
        store.dispatch(action, ...args);
      });
    });

    window.$socket = Vue.prototype.$socket;
  },
};
