<template>
  <div
    class="element video"
    :style="getBasicStyles"
    :class="[element.cssClasses, element.animationInStyle, element.animationOutStyle]"
  >
    <div class="video-wrap">
      <transition
        appear
        :enter-to-class="animationIn"
        :leave-to-class="animationOut"
        enter-class="enter"
        type="animation"
        @before-enter="beforeEnter"
        @after-enter="afterEnter"
        @before-leave="beforeLeave"
        @after-leave="afterLeave"
      >
        <div
          :key="element.src"
          class="animated-element"
          :class="[getLoopingClass]"
          :style="[ getAnimationStyles,
                    getBorderStyles,
                    getVideoBorderRadiusStyles,
                    getBoxShadowStyles,
                    getBlurStyles]"
        >
          <video
            ref="video"
            :muted="isMuted"
            @timeupdate="handleTimeupdate"
            @loadeddata="handleLoadeddata"
            @ended="handleEnded"
            :loop="element.videoOptions.loop"
            :autoplay="element.videoOptions.autoplay"
          >
            <source
              :src="element.src"
              :type="element.contentType"
            >
          </video>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import GraphicsMixins from '../../mixins/graphics-mixins';

export default {
  name: 'VideoElement',
  mixins: [GraphicsMixins],

  computed: {
    getVideoBorderRadiusStyles() {
      const {
        mask,
        maskShape,
        borderTopLeftRadius: tl,
        borderTopRightRadius: tr,
        borderBottomLeftRadius: bl,
        borderBottomRightRadius: br,
      } = this.element;

      if (!mask || maskShape !== 'rectangle') {
        return '';
      }

      return {
        borderRadius: `${tl}px ${tr}px ${br}px ${bl}px`,
      };
    },

    isTrimmed() {
      return this.element.videoOptions.start !== 0 || this.element.videoOptions.end;
    },

    isMuted() {
      const { isMuted } = this.$store.getters;
      return isMuted || this.element.videoOptions.muted;
    },
  },

  watch: {
    actions(newVal) {
      // check if action is for video
      if (newVal.length > 0
        && newVal[0].type === 'video'
        && newVal[0].elementId === this.element.id) {
        // eslint-disable-next-line default-case
        switch (newVal[0].value) {
          case 'play/pause':
            this.toggleVideo();
            break;
          case 'mute':
            this.muteVideo();
            break;
        }
      }
    },

    'element.videoOptions.muted': {
      handler() {
        const wasMuted = this.$refs.video.muted;
        this.$nextTick(() => {
          // setting changed in producer
          // preserve state before changing the setting
          this.$refs.video.muted = wasMuted;
        });
      },
    },

    'element.videoOptions.loop': {
      handler() {
        const { end, loop } = this.element.videoOptions;

        const defaultLengthEnded = this.$refs.video.ended && !end;
        const customLengthEnded = this.$refs.video.currentTime >= end;
        const videoEnded = defaultLengthEnded || customLengthEnded;

        if (videoEnded && loop) {
          this.restartVideo();
        }
      },
    },
  },

  methods: {
    handleTimeupdate(e) {
      if (this.isTrimmed) {
        const { start, end, loop } = this.element.videoOptions;

        if (e.target.currentTime < start) {
          this.setCurrentTime(start);
        }
        if (end && e.target.currentTime > end) {
          this.pauseVideo();
          if (loop) {
            this.restartVideo();
          }
        }
      }
    },

    handleLoadeddata() {
      if (!this.$refs.video) return;

      this.pauseVideo();
      this.setCurrentTime(this.element.videoOptions.start);
      if (this.element.videoOptions.autoplay) {
        this.playVideo();
      }
    },

    handleEnded() {
      this.pauseVideo();
      if (this.element.videoOptions.loop) {
        this.setCurrentTime(this.element.videoOptions.start);
        this.playVideo();
      }
    },

    toggleVideo() {
      const vid = this.$refs.video;
      if (vid.paused || vid.ended) {
        const { end, start } = this.element.videoOptions;
        if (end && vid.currentTime > end) {
          this.setCurrentTime(start);
        }
        this.playVideo();
      } else {
        this.pauseVideo();
      }
    },

    async playVideo() {
      try {
        await this.$refs.video.play();
      } catch (error) {
        // autoplay blocked, do something?
      }
    },

    restartVideo() {
      const { start } = this.element.videoOptions;
      this.setCurrentTime(start);
      this.playVideo();
    },

    pauseVideo() {
      this.$refs.video.pause();
    },

    muteVideo() {
      const vid = this.$refs.video;
      vid.muted = !vid.muted;
    },

    setCurrentTime(newTime) {
      this.$refs.video.currentTime = newTime;
    },
  },
};
</script>
