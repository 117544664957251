<template>
  <div class="theme-wrapper">
    <iframe
      class="smart-panel"
      allow="autoplay"
      border="0"
      height="100%"
      width="100%"
      :src="src"
      @load="sendSettings"
    />
  </div>
</template>

<script>
import { isEqual } from 'lodash-es';
import EventBus from '../../../../utils/event-bus';
import PanelMixins from '../../../../mixins/panel-mixins';

const { PANELS_BASE } = require('../../../../services/services');

export default {
  props: ['panel'],
  mixins: [PanelMixins],

  data() {
    return {
      query: {},
    };
  },

  computed: {
    displayTime() {
      return this.panel.displayTime ? this.panel.displayTime : this.panel.settings.displayTime;
    },

    src() {
      let query = '';
      if (this.query.disable_animations === 'true') {
        query += 'disable_animations=true';
      }

      return `${PANELS_BASE}/render/${this.panel._id}?${query}`;
    },
  },

  mounted() {
    this.query = window.location.search.substr(1)
      .split('&')
      .reduce((memo, param) => ({
        ...memo,
        [param.split('=')[0]]: decodeURIComponent(param.split('=')[1] || true),
      }), {});

    this.$frame = $(this.$el).find('iframe');

    if (!this.isVideo) {
      EventBus.$emit('resetTimer');
      EventBus.$emit('startTimer', this.displayTime || 10000);
    }
  },

  watch: {
    panel(newVal, oldVal) {
      if (this.$frame.length && !isEqual(newVal, oldVal)) {
        this.$frame[0].contentWindow.postMessage(`tgbLive:settings:${JSON.stringify(newVal)}`, '*');
      }
    },
  },

  methods: {
    sendSettings() {
      if (this.$frame.length) {
        this.$frame[0].contentWindow.postMessage(`tgbLive:settings:${JSON.stringify(this.panel)}`, '*');
      }
    },
  },
};
</script>
