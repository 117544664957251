var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"element marquee",class:[_vm.element.cssClasses, _vm.element.animationInStyle, _vm.element.animationOutStyle],style:(_vm.getBasicStyles)},[_c('transition',{attrs:{"appear":"","enter-to-class":_vm.animationIn,"leave-to-class":_vm.animationOut,"enter-class":"enter","type":"animation","mode":_vm.transitionMode},on:{"before-enter":_vm.beforeEnter,"after-enter":_vm.afterEnter,"before-leave":_vm.beforeLeave,"after-leave":_vm.afterLeave}},[_c('div',{staticClass:"marquee-wrap animated-element",class:[_vm.getLoopingClass, _vm.alignItemsClass],style:([
        _vm.getAnimationStyles,
        _vm.getBorderRadiusStyles,
        _vm.getBackgroundStyles,
        _vm.getBorderStyles,
        _vm.getBoxShadowStyles,
      ])},[_c('marquee-animate',{key:_vm.page,attrs:{"speed":_vm.element.speed,"paused":_vm.paused,"reverse":_vm.element.reverse,"item-gap":_vm.element.styles.gap + 'px',"animation-iteration-count":`infinite`,"space-between":_vm.spaceBetweenWidth}},[(_vm.data.length)?[_vm._l((_vm.virtualElements),function(elements){return _vm._l((elements.filter((x) => x.visible)),function(e){return _c('graphic-element',{key:e.id,ref:"children",refInFor:true,attrs:{"id":e.id,"element":e,"type":e.type}})})})]:_vm._l((_vm.childElements),function(el){return _c('graphic-element',_vm._g({key:el.id,attrs:{"id":el.id,"type":el.type,"element":el,"parent":_vm.element.id}},_vm.$listeners))})],2)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }