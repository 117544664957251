<template>
  <div id="tagboard-live" v-if="isReady">
    <template v-if="connected && production_id">
      <div class="display" v-if="snippets_ready" :style="[getBackgroundColor, getOutputSizeStyle]">
        <video
          v-if="hasBackgroundVideo"
          class="display__videoBackground"
          :src="backgroundVideoData?.url"
          :type="backgroundVideoData?.type"
          autoplay
          loop
          muted
        />

        <!-- Graphics handle their own transitions, so we keep it out of the transition wrapper -->
        <graphics-wrapper
          v-if="!isTransitioning"
          :playing="playing"
          :snippets="snippets"
          :graphic="graphic"
          @before-leave="graphicBeforeLeave"
          @after-leave="graphicAfterLeave"
          @error="onGraphicsError"
        />

        <transition
          v-if="!graphicTransitioning"
          appear
          @enter="enter"
          @leave="leave"
          @before-enter="beforeEnter"
          @enter-cancelled="enterCancelled"
          @before-leave="beforeLeave"
          @after-leave="afterLeave"
          mode="in-out"
          :css="false"
        >
          <tagboard
            v-if="layout && isTagboardDisplay"
            :key="layout.timeline_id"
            :data-id="layout.timeline_id"
            :theme="layout"
            :post="post"
            :posts="layout.posts"
            @frameloaded="frameloaded(layout.timeline_id)"
          />

          <panel
            v-else-if="layout && layout.layout == 'panel'"
            :key="layout.timeline_id"
            :data-id="layout.timeline_id"
            :panel="layout"
            @frameloaded="frameloaded(layout.timeline_id)"
          />

          <component
            v-else-if="layout && notGraphics"
            :key="layout.timeline_id"
            :data-id="layout.timeline_id"
            :class="[layout.layout, getThemeVersionNiceName]"
            :theme="layout"
            :post="formattedPost"
            :posts="layout.posts"
            :is="this.layout.layout + '-' + this.getThemeVersion"
            @frameloaded="frameloaded(layout.timeline_id)"
          />
        </transition>
      </div>
    </template>

    <!-- isReady, is connected, was connected screen, but no production -->
    <div
      v-if="(connected && !production_id && !isPreview) || !connected"
      class="connect-screen text-center"
    >
      <div class="tagboard-logo" />

      <div class="connect-display">
        <template v-if="connected && !production_id && !isPreview">
          Screen currently disconnected. Associate a production with this screen.
        </template>

        <template v-else-if="!connected">
          <div class="code">
            <h4>
              Use the following passcode in the Tagboard Producer app to display your Tagboard.
            </h4>

            <h2>{{ screenId }}</h2>
          </div>
        </template>
      </div>
    </div>

    <video
      v-if="checkingBrowserSettings"
      ref="videoTest"
      src="/live/assets/img/video/TinyTestVideo.webm"
      style="display: none;"
    />
  </div>
</template>

<script>
/* eslint-disable camelcase */
import { mapState } from 'vuex';
import { get, replace } from 'lodash-es';

import { postWithEmojis } from '../../utils/emojis';
import EventBus from '../../utils/event-bus';
import LiveMixins from '../../mixins/live-mixins';

import IsolatedComponentV1 from '../layouts/isolated/v1.0/Isolated.vue';
import LowerThirdComponentV1 from '../layouts/lowerthird/v1.0/LowerThird.vue';
import LowerThirdScrollComponentV1 from '../layouts/lowerthirdscroll/v1.0/LowerThirdScroll.vue';
import TickerComponentV1 from '../layouts/ticker/v1.0/Ticker.vue';
import StoriesComponentV1 from '../layouts/stories/v1.0/Stories.vue';
import CinematicComponentV1 from '../layouts/cinematic/v1.0/Cinematic.vue';
import WaterfallComponentV1 from '../layouts/waterfall/v1.0/Waterfall.vue';
import VerticalCarouselComponentV1 from '../layouts/carousel/v1.0/Carousel.vue';
import GridComponentV1 from '../layouts/grid/v1.0/Grid.vue';

import IsolatedComponentV1_5 from '../layouts/isolated/v1.5/Isolated.vue';
import LowerThirdComponentV1_5 from '../layouts/lowerthird/v1.5/LowerThird.vue';
import LowerThirdScrollComponentV1_5 from '../layouts/lowerthirdscroll/v1.5/LowerThirdScroll.vue';
import TickerComponentV1_5 from '../layouts/ticker/v1.5/Ticker.vue';
import StoriesComponentV1_5 from '../layouts/stories/v1.5/Stories.vue';
import CinematicComponentV1_5 from '../layouts/cinematic/v1.5/Cinematic.vue';
import ChatComponentV1_5 from '../layouts/chat/v1.5/Chat.vue';
import MuralComponentV1_5 from '../layouts/mural/v1.5/Mural.vue';
import PanelsComponent from '../layouts/panels/v1.0/Panels.vue';

import TagboardDisplay from '../layouts/tagboard-display/TagboardDisplay.vue';
import GraphicsWrapperComponent from '../layouts/graphics-wrapper/GraphicsWrapper.vue';

import Fonts from '../../utils/fonts';

const animationEndEvents = 'animationend webkitAnimationEnd MSAnimationEnd oAnimationEnd';

const params = new URLSearchParams(window.location.search);

export default {
  mixins: [LiveMixins],
  components: {
    'isolated-v1.0': IsolatedComponentV1,
    'isolated-v1.5': IsolatedComponentV1_5,
    'cinematic-v1.0': CinematicComponentV1,
    'cinematic-v1.5': CinematicComponentV1_5,
    'lowerthird-v1.0': LowerThirdComponentV1,
    'lowerthird-v1.5': LowerThirdComponentV1_5,
    'lowerthirdscroll-v1.0': LowerThirdScrollComponentV1,
    'lowerthirdscroll-v1.5': LowerThirdScrollComponentV1_5,
    'ticker-v1.0': TickerComponentV1,
    'ticker-v1.5': TickerComponentV1_5,
    'stories-v1.0': StoriesComponentV1,
    'stories-v1.5': StoriesComponentV1_5,
    'chat-v1.5': ChatComponentV1_5,
    'mural-v1.5': MuralComponentV1_5,
    panel: PanelsComponent,
    'waterfall-v1.0': WaterfallComponentV1,
    'carousel-v1.0': VerticalCarouselComponentV1,
    'grid-v1.0': GridComponentV1,
    tagboard: TagboardDisplay,
    'graphics-wrapper': GraphicsWrapperComponent,
  },

  created() {
    this.promises = {};

    EventBus.$on('resetTimer', () => {
      this.$store.dispatch('resetTimer');
    });

    EventBus.$on('startTimer', (payload) => {
      this.$store.dispatch('startTimer', payload);
    });
  },

  mounted() {
    this.watchIsReadyOnce = this.$watch('isReady', async (newVal) => {
      if (newVal) {
        try {
          await this.$refs.videoTest.play();
          this.checkingBrowserSettings = false;
        } catch (error) {
          this.$store.dispatch('sendError', {
            title: 'Output Missing Permissions',
            message: 'Check <a href="https://support.tagboard.com/knowledge-base/live-display-requirements" target="_blank">Live Display Requirements</a> for playback resolution',
            error: '',
          });
        }

        this.watchIsReadyOnce();
      }
    });
  },

  data() {
    return {
      graphicTransitioning: false,
      transitioningGraphics: 0,
      isTransitioning: false,
      customFonts: [],
      fonts: [],
      loading: false,
      checkingBrowserSettings: true,
    };
  },

  watch: {
    async production_id(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loading = true;

        // Load production first, because we need to grab the owner property
        const production = await this.$store.dispatch('getProduction', this.production_id);
        const owner = production.owner || production.result.owner;

        // Fetch any custom fonts uploaded by user
        try {
          await this.$store.dispatch('getFonts', owner);
          await this.createCustomFontArray();
        } catch (err) { /* noop */ }

        this.loading = false;
      }
    },
  },

  methods: {
    /**
    * Called whenever graphics fail to load/render.
    * If auto-advance is enabled, we'll automatically advance the timeline,
    * thus skipping the current borked graphic.
    * The GraphicsWrapper should've already handled retrying, so something must be down or borked.
    */
    onGraphicsError() {
      if (this.playing) {
        this.$store.dispatch('advance');
      }
    },

    async createCustomFontArray() {
      const fonts = this.$store.state.fonts.customFonts;

      fonts.forEach((item) => {
        this.fonts.push({
          name: item.title,
          font: item.title.split('.')[0],
          fontLink: item.alt ? item.alt.media_url : item.media_url,
          fontId: `f-${item._id}`,
        });
      });

      await Fonts.createFontLinks(this.fonts);
    },

    defer() {
      const deferred = {
        promise: null,
        resolve: null,
        reject: null,
      };

      deferred.promise = new Promise((resolve, reject) => {
        deferred.resolve = resolve;
        deferred.reject = reject;
      });

      return deferred;
    },

    frameloaded(timelineId) {
      const keys = Object.keys(this.promises).filter((key) => key !== timelineId);

      const enterPromise = get(this, ['promises', timelineId, 'enterPromise']);
      if (enterPromise) { enterPromise.resolve(); }

      keys.forEach((key) => {
        if (this.promises[key].leavePromise) {
          this.promises[key].leavePromise.resolve();
        }
      });
    },

    enterCancelled(el) {
      const { id } = el.dataset;
      try {
        this.promises[id].enterPromise.resolve();
        this.promises[id].leavePromise.resolve();
      } catch (e) { /* noop */ }
    },

    enter(el, done) {
      const { production } = this.$store.state;

      const { id } = el.dataset;
      const endTransitionCallback = () => {
        // prevent mem-leak
        $(el)
          .removeClass(production.transition)
          .off(animationEndEvents, endTransitionCallback);

        done();
      };

      if (this.promises[id]) {
        try {
          this.promises[id].enterPromise.resolve();
          this.promises[id].leavePromise.resolve();
        } catch (err) { /* noop */ }
      }

      this.promises[id] = {
        enterPromise: this.defer(),
        leavePromise: this.defer(),
      };

      this.promises[id].enterPromise.promise.then(() => {
        $(el).addClass(production.transition).css('opacity', 1).on(animationEndEvents, endTransitionCallback);
        this.promises[id].enterPromise = null;
      });
    },

    leave(el, done) {
      const { production } = this.$store.state;
      const { id } = el.dataset;
      const transitionOut = production.transition === 'animated fadeIn' ? 'animated fadeOut' : 'animated cutIn';
      const endTransitionCallback = () => {
        // prevent mem-leak
        $(el).removeClass(transitionOut).off(animationEndEvents, endTransitionCallback);
        delete this.promises[id];
        done();
      };

      if (!this.connected || !this.production_id || !this.layout || this.layout.layout === 'graphic' || this.layout.layout === 'graphics') {
        $(el).addClass(transitionOut).on(animationEndEvents, endTransitionCallback);
        return;
      }

      this.promises[id].leavePromise.promise.then(() => {
        done();
        delete this.promises[id];
      });

      // If element leaving matches the one going live, then probably just a type change so trigger the leave now
      if (this.layout?.timeline_id === id) {
        this.promises[id].leavePromise.resolve();
      }
    },

    beforeEnter(el) {
      const element = el;
      element.style.opacity = 0;
      element.style.zIndex = 1;
    },

    // Set flags when transitioning graphics, panels, etc.,
    // so we can transition smoothly between
    // components that don't normally have transitions between them
    beforeLeave() {
      if (this.graphicTransitioning) { return; }
      this.isTransitioning = true;
    },

    afterLeave() {
      if (this.graphicTransitioning) { return; }
      this.isTransitioning = false;
    },

    graphicBeforeLeave() {
      this.transitioningGraphics += 1;
      if (this.isTransitioning) { return; }
      this.graphicTransitioning = true;
    },

    graphicAfterLeave() {
      this.transitioningGraphics -= 1;
      if (this.isTransitioning) { return; }

      if (this.transitioningGraphics <= 0) {
        this.graphicTransitioning = false;
      }
    },
  },

  computed: {
    ...mapState(['production']),

    getCustomFonts() {
      return this.$store.state.fonts.customFonts;
    },

    playing() {
      return this.$store.getters.isPlaying;
    },

    layout() {
      return this.$store.state.production.live.layout;
    },

    graphic() {
      if (['graphic', 'graphics'].includes(this.layout?.layout)) {
        return this.layout;
      }

      return null;
    },

    post() {
      return this.$store.state.production.live.post;
    },

    formattedPost() {
      if (this.isTagboardDisplay) {
        return this.post;
      }

      return postWithEmojis(this.post);
    },

    index() {
      return this.$store.state.production.index;
    },

    connected() {
      return this.$store.state.client.connected;
    },

    screenId() {
      return this.$store.state.client.screen_id;
    },

    isReady() {
      return this.$store.state.client.isReady && !this.loading;
    },

    snippets_ready() {
      return this.$store.state.snippets.ready;
    },

    snippets() {
      return this.$store.state.snippets.snippets;
    },

    production_id() {
      return this.$store.state.client.production_id;
    },

    isPreview() {
      return this.$store.state.client.is_preview;
    },

    getThemeVersion() {
      if (this.layout.layout.indexOf('graphic') === 0) {
        return -1;
      }

      const version = this.layout.settings.version || this.layout.version;
      return version || 'v1.5';
    },

    getThemeVersionNiceName() {
      if (this.layout.layout.indexOf('graphic') === 0) {
        return 'Graphic';
      }

      return `${this.layout.layout}-${replace(this.getThemeVersion, '.', '_')}`;
    },

    /**
    * Treat the current layout like a tagboard display if it's waterfall, grid, upnext,
    * or if the first post is a tagboard instead of individual post(s)
    * @returns {Boolean}
    */
    isTagboardDisplay() {
      const { layout } = this.layout;

      return (
        ['waterfall', 'grid', 'upnext'].includes(layout)
        || this.post?.type === 'tagboard'
      );
    },

    notGraphics() {
      return this.layout && this.layout.layout !== 'graphic' && this.layout.layout !== 'graphics';
    },

    getBackgroundColor() {
      if (this.production.backgroundType === 'image') {
        return {
          backgroundImage: `url(${this.production.backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        };
      }

      if (this.production.backgroundType === 'none') {
        return { backgroundColor: 'transparent' };
      }

      return { backgroundColor: this.production.backgroundColor };
    },

    hasBackgroundVideo() {
      return this.production?.backgroundType === 'video';
    },

    backgroundVideoData() {
      return this.production?.backgroundVideo;
    },

    getOutputSizeStyle() {
      const width = Number(params.get('width'));
      const height = Number(params.get('height'));

      if (width && height) {
        return {
          width: `${width}px`,
          height: `${height}px`,
        };
      }

      return '';
    },
  },
};
</script>
