var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"theme-wrapper",class:_vm.getThemeClass},[_c('div',{staticClass:"fullscreen-wrapper",class:[
      'post-height-' + _vm.theme.settings.postHeight,
      { 'rounded-post-corners': _vm.theme.settings.roundedPostCorners },
      'post-color-theme-' + _vm.theme.settings.postColorTheme,
      'cta-template-' + _vm.theme.settings.ctaTemplate,
      'font-' + _vm.theme.settings.font,
      'cta-social-icon-style-' + _vm.theme.settings.ctaSocialIconStyle,
      { 'no-title': !_vm.theme.settings.showCtaText },
      { 'no-social-icons': !_vm.theme.settings.showCtaSocialIcons },
      { 'safe-area': _vm.safeArea },
      { 'has-qr-code': _vm.showQrCode },
      _vm.theme.settings.qrCodePosition === 'right' ? 'qr-right' : 'qr-left',
    ],style:({ 'background-color': _vm.theme.settings.backgroundColor })},[(_vm.safeArea)?_c('div',{staticClass:"backdrop",class:{ 'darkened-background': _vm.theme.settings.darkenBackground },style:([_vm.backgroundStyle])},[(_vm.theme.settings.backgroundVideo)?_c('video',{staticClass:"background-video",attrs:{"src":_vm.theme.settings.backgroundVideo,"width":"auto","autoplay":"","loop":"","muted":""},domProps:{"muted":true}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"background-overlay"})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"display-wrapper",class:{ 'empty-header': _vm.emptyHeader },style:(_vm.safeArea ? _vm.wrapperStyles : {})},[(
          !_vm.safeArea && (_vm.theme.settings.backgroundImage ||
          _vm.theme.settings.backgroundVideo))?_c('div',{staticClass:"backdrop",class:{ 'darkened-background': _vm.theme.settings.darkenBackground },style:([_vm.backgroundStyle])},[(_vm.theme.settings.backgroundVideo)?_c('video',{staticClass:"background-video",attrs:{"src":_vm.theme.settings.backgroundVideo,"width":"auto","autoplay":"","loop":"","muted":""},domProps:{"muted":true}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"background-overlay"})]):_vm._e(),_vm._v(" "),_c('header-component',{attrs:{"theme":_vm.theme}}),_vm._v(" "),(_vm.showQrCode)?_c('div',{staticClass:"qr-module"},[_c('img',{staticClass:"square-placeholder",attrs:{"src":"/live/assets/img/square.png"}}),_vm._v(" "),_c('qr-code',{attrs:{"settings":_vm.theme.settings}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"posts",class:[_vm.theme.settings.animation, { 'hide-links': _vm.theme.settings.hideLinks }]},[(_vm.post)?[_c('transition',{attrs:{"appear":"","enter-active-class":_vm.enterAnim,"leave-active-class":_vm.exitAnim,"mode":_vm.mode}},[(_vm.currentPost)?_c(_vm.getPostType(_vm.currentPost),{key:_vm.getKey(_vm.currentPost),tag:"component",attrs:{"theme":_vm.theme,"panel":_vm.currentPost,"post":_vm.currentPost}}):_vm._e()],1)]:_vm._e()],2)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }