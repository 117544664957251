<template>
  <div :class="[getThemeClass, theme.layout]" class="theme-wrapper">
    <div
      class="fullscreen-wrapper"
      :style="{ 'background-color': theme.settings.backgroundColor }"
      :class="[
        { interactive: theme.settings.interactive },
        { 'hide-media-captions': !theme.settings.showMediaCaptions },
        'gutter-' + theme.settings.gutter,
        'waterfall-columns-' + theme.settings.columns,
        'post-color-theme-' + theme.settings.postColorTheme,
        'cta-template-' + theme.settings.ctaTemplate,
        'font-' + theme.settings.font,
        'cta-social-icon-style-' + theme.settings.ctaSocialIconStyle,
        { 'no-title': !theme.settings.showCtaText },
        { 'no-social-icons': !theme.settings.showCtaSocialIcons },
        { 'safe-area': safeArea },
      ]">
      <div
        v-if="safeArea"
        :class="[{ 'darkened-background': theme.settings.darkenBackground }]"
        class="backdrop"
        :style="[backgroundStyle]">
        <video
          v-if="theme.settings.backgroundVideo"
          :src="theme.settings.backgroundVideo"
          class="background-video"
          width="auto"
          autoplay
          loop
          muted></video>
        <div class="background-overlay"></div>
      </div>
      <div
        class="display-wrapper"
        :style="safeArea ? wrapperStyles : {}"
        :class="{ 'empty-header': emptyHeader }">
        <div
          v-if="!safeArea && (theme.settings.backgroundImage || theme.settings.backgroundVideo)"
          :class="{ 'darkened-background': theme.settings.darkenBackground,}"
          class="backdrop"
          :style="[backgroundStyle]">
          <video
            v-if="theme.settings.backgroundVideo"
            :src="theme.settings.backgroundVideo"
            class="background-video"
            width="auto"
            autoplay
            loop
            muted></video>
          <div class="background-overlay"></div>
        </div>

        <header-component :theme="theme"></header-component>

        <div class="posts" :class="{ 'hide-links': theme.settings.hideLinks }">
          <transition-group
            class="waterfall-column"
            tag="div"
            :class="['gutter-' + theme.settings.gutter]"
            v-for="(column, index) in columnPosts"
            :key="index"
            name="waterfall-anim"
          >
            <div
              class="post-container post-container--qr-code"
              v-if="theme.settings.showQrCode && !theme.settings.qrCodeCtaPlacement && index === 0"
              key="waterfall-qr-code"
            >
              <div class="qr-module">
                <img class="square-placeholder" src="/live/assets/img/square.png">
                <qr-code :settings="theme.settings" />
              </div>
            </div>
            <post
              :class="theme.settings.animation"
              v-on:postclicked="showPostModal"
              v-for="postData in column"
              :key="postData.post_id"
              :theme="theme"
              :post="postData"
            />
          </transition-group>
        </div>
      </div>

      <template v-if="theme.settings.interactive">
        <modal
          class="post-modal"
          v-if="showModal"
          @close="showModal = false">
          <template slot="modal-body">
            <span
              v-if="modalPostIndex !== 0"
              @click.stop="navigateModal('previous')"
              class="previous icon icon-chevron-left"></span>
            <div class="isolated-v1_0 modal-body">
              <transition
                name="custom-classes-transition"
                :enter-active-class="modalEnter"
                :leave-active-class="modalLeave">
                <isolated
                  :key="modalPost.network + modalPost.post_id"
                  :theme="theme"
                  :post="modalPost" />
              </transition>
            </div>
            <span
              v-if="modalPostIndex < posts.length - 1"
              @click.stop="navigateModal('next')"
              class="next icon icon-chevron-right"></span>
          </template>
        </modal>
      </template>
    </div>
  </div>
</template>

<script>
import { forEach } from 'lodash-es';

import EventBus from '../../../../utils/event-bus';
import WaterfallPostComponent from './WaterfallPost.vue';
import HeaderComponent from '../../../header/v1.5/Header.vue';
import ModalComponent from '../../../modal/Modal.vue';
import IsolatedPostComponent from '../../../modal/ModalIsolatedPost.vue';
import QRCodeComponent from '../../../qr-code/v2.0/QRCode.vue';
import MultipostMixins from '../../../../mixins/multipost-mixins';
import LayoutMixins from '../../../../mixins/layout-mixins';

export default {
  props: ['theme', 'post', 'posts'],
  components: {
    'header-component': HeaderComponent,
    post: WaterfallPostComponent,
    modal: ModalComponent,
    isolated: IsolatedPostComponent,
    'qr-code': QRCodeComponent,
  },
  mixins: [
    LayoutMixins,
    MultipostMixins,
  ],
  data() {
    return {
      columnPosts: [],
      columnCounter: 0,
    };
  },
  computed: {
    enterAnim() {
      return this.$store.getters.getEnter;
    },
    exitAnim() {
      return this.$store.getters.getExit;
    },
    mode() {
      return this.$store.getters.getMode;
    },
    safeArea() {
      return this.theme.settings.safeArea;
    },
    wrapperStyles() {
      return {
        'max-width': this.safeAreaMaxWidth,
        'max-height': this.safeAreaMaxHeight,
      };
    },
    safeAreaMaxWidth() {
      const { safeArea, safeWidth } = this.theme.settings;

      return safeArea && safeWidth && safeWidth > 0
        ? `${safeWidth}px`
        : '100%';
    },
    safeAreaMaxHeight() {
      const { safeArea, safeHeight } = this.theme.settings;

      return safeArea && safeHeight && safeHeight > 0
        ? `${safeHeight}px`
        : '100%';
    },
    emptyHeader() {
      const { logo, showCtaText, showCtaSocialIcons } = this.theme.settings;
      return !logo && !showCtaText && !showCtaSocialIcons;
    },
    backgroundStyle() {
      if (this.theme.settings.backgroundImage !== '') {
        return {
          backgroundImage: `url('${this.theme.settings.backgroundImage}')`,
        };
      }
      return {};
    },
    getQRCodeFontFamily() {
      return this.theme.settings.font;
    },
  },
  created() {
    this.getColumnPosts(this.posts);
  },
  mounted() {
    EventBus.$on('resize', this.setFontSize);
    this.setFontSize();
  },
  beforeDestroy() {
    EventBus.$off('resize', this.setFontSize);
  },
  methods: {
    setPost() {
      const self = this;
      if (!this.columnPosts.length) { return; }

      const nextPost = this.getNextPost();
      let nextPostColumn = -1;
      let nextPostIndex = -1;

      this.checkPostMedia(nextPost)
        .then(() => {
          self.$store.dispatch('setDisplayNum', nextPost.post_id);

          self.columnPosts.forEach((column, index) => {
            self.columnPosts[index].forEach((post, ind) => {
              if (post.post_id === nextPost.post_id) {
                nextPostColumn = index;
                nextPostIndex = ind;
              }
            });
          });
          if (nextPostColumn > -1 && nextPostIndex > -1) {
            self.columnPosts[nextPostColumn].splice(nextPostIndex, 1);
          }
          self.$nextTick(() => {
            self.columnPosts[self.columnCounter].splice(0, 0, nextPost);
            self.columnCounter = self.columnCounter < (self.theme.settings.columns - 1)
              ? self.columnCounter + 1 : 0;
            self.cleanRemovedPosts();

            EventBus.$emit('startTimer', self.theme.settings.displayTime);
          });
        }).catch(() => {
          self.$store.dispatch('removePost', nextPost.post_id);
          if (!self.theme.settings.interactive) {
            EventBus.$emit('advance', 'next');
          }
        });
    },
    setFontSize() {
      const $posts = $('.posts');
      $posts.css('font-size', ($posts.width() * 0.07) / this.theme.settings.columns);
    },
    getNextPost() {
      const noDisplayNums = this.posts.filter((post) => post.display_num == -1);

      if (noDisplayNums.length) {
        noDisplayNums.sort(this.sort);
        return noDisplayNums[0];
      }
      return this.posts[0];
    },
    sort(a, b) {
      const aDisplayNum = a.display_num;
      const bDisplayNum = b.display_num;

      if (aDisplayNum === bDisplayNum) {
        // If these posts haven't been shown, sort by curated time
        return b.curated_time - a.curated_time;
      }
      // Otherwise, sort by order the posts were displayed
      return aDisplayNum - bDisplayNum;
    },
    cleanRemovedPosts() {
      const self = this;
      const validMap = {};

      forEach(self.posts, (post) => {
        validMap[post.post_id] = 1;
      });

      // Validate that posts in columns are still valid
      self.columnPosts.forEach((column, index) => {
        column.forEach((post, ind) => {
          if (!validMap[post.post_id]) {
            self.columnPosts[index].splice(ind, 1);
          }
        });
      });
    },
    async getColumnPosts(posts) {
      let iterator = 0;

      for (let i = 0; i < this.theme.settings.columns; i += 1) {
        this.columnPosts.push([]);
      }

      const filteredPosts = (await Promise.all(
        posts.map(async (post) => {
          try {
            await this.checkPostMedia(post);
          } catch (err) { return null; }

          // Images passed!
          return post;
        }),
      )).filter(Boolean);

      filteredPosts.sort(this.sort);

      filteredPosts.forEach((post) => {
        this.columnPosts[iterator].push(post);
        iterator = iterator < (this.theme.settings.columns - 1) ? iterator + 1 : 0;
      });

      // Desctructive reverse()
      filteredPosts.reverse().forEach((post) => { // very dumb
        this.$store.dispatch('setDisplayNum', post.post_id);
      });

      this.$nextTick(() => {
        this.$emit('frameloaded');

        window.parent.postMessage('tgbLive:loaded', '*');

        if (!this.theme.settings.interactive) {
          EventBus.$emit('startTimer', this.theme.settings.displayTime);
        }
      });
    },
  },
};
</script>
