<template>
  <!-- Video will autoplay if production.videoPlaying is true,
       or if we should loop the video. That was there before so I just left it -->
  <video
    class="media video"
    type="video/mp4"
    :muted="isMuted"
    :autoplay="videoPlaying || shouldLoop"
    :loop="shouldLoop"
    :poster="hasPoster"
    :src="post.videos[0].m"
  />
</template>

<script>
import postUtils from '../../../utils/post-utils';

const { INSTAGRAM_BASE, TIKTOK_BASE } = require('../../../services/services');

const videoErrorState = {
  NO_ERROR: 0,
  REQUESTING_MEDIA: 1,
  UPDATE_COMPLETE: 2,
};

export default {
  props: ['post', 'theme'],
  data() {
    return {
      vidErr: videoErrorState.NO_ERROR,
    };
  },
  computed: {
    needsRemoval() {
      return this.$store.getters.needsVideoRemoval;
    },

    isGif() {
      return this.post.videos && this.post.videos[0].type === 'gif';
    },

    videoPlaying() {
      return this.$store.getters.videoPlaying;
    },

    muted() {
      return this.$store.getters.isMuted;
    },

    playing() {
      return this.$store.getters.isPlaying;
    },

    isMuted() {
      return this.shouldLoop || this.muted;
    },

    shouldLoop() {
      return (
        this.isGif
        || ['waterfall', 'grid', 'chat'].includes(this.theme && this.theme.layout)
      );
    },

    hasPoster() {
      let url = this.post.videos[0].p;
      if (this.theme.layout === 'stories' || this.post.network === 'tiktok') {
        url = '';
      } else if (this.post.network === 'instagram') {
        url = postUtils.instagramMediaUrl(this.post, 'p');
      }
      return url;
    },
  },

  watch: {
    videoPlaying(newValue) {
      if (newValue) {
        this.play();
      } else {
        this.pause();
      }
    },

    muted(newValue) {
      if (newValue) {
        this.muteVideo();
      } else {
        this.unmuteVideo();
      }
    },
  },

  mounted() {
    this.$el.addEventListener('canplaythrough', this.play);
    this.$el.addEventListener('loadedmetadata', this.onLoaded);
    this.$el.addEventListener('ended', this.onEnded);
    this.$el.addEventListener('error', this.onError);
  },

  beforeDestroy() {
    this.$el.removeEventListener('loadedmetadata', this.onLoaded);
    this.$el.removeEventListener('canplaythrough', this.play);
    this.$el.removeEventListener('ended', this.onEnded);
    this.$el.removeEventListener('error', this.onError);

    this.$el.pause();

    if (this.needsRemoval) {
      this.$el.src = '';
      this.$el.load();
      this.$el.parentNode.removeChild(this.$el);
    }
  },

  methods: {
    onLoaded() {
      if (!this.shouldLoop) {
        const duration = this.$el.duration * 1e3;

        this.$store.dispatch('setPlayDuration', duration);
        this.$store.dispatch('setVideoDuration', duration);
      }
    },

    onEnded() {
      if (!this.playing || this.theme.settings.interactive) {
        this.$el.currentTime = 0;
        this.play();
      } else {
        this.$emit('finished');
      }
    },

    onError() {
      const { post } = this;

      // If there's an error, we'll remove the post from the store,
      // and we'll trigger the "finished" event which will advance the timeline.
      const unrecoverableError = () => {
        this.$store.dispatch('removePost', this.post.post_id);

        if (this.playing) {
          this.$emit('finished');
        }
      };

      // Attempt to get a new working media URL for this video
      if (this.vidErr === videoErrorState.NO_ERROR) {
        let endpoint;

        if (post.network === 'instagram') {
          let shortcode = post.permalink.replace(/\/$/, '').split('/');
          shortcode = shortcode[shortcode.length - 1];
          endpoint = `${INSTAGRAM_BASE}/video/${shortcode}?post_id=${post.post_id}`;
        } else if (post.network === 'tiktok') {
          endpoint = `${TIKTOK_BASE}/video/${post.post_id}`;
        } else {
          // We don't know how to handle this post.
          unrecoverableError();
          return;
        }

        // Set that we're requesting media,
        // so if we error again, we wait until the request comes back
        this.vidErr = videoErrorState.REQUESTING_MEDIA;

        // Get a fallback src to use
        fetch(endpoint)
          .then((resp) => resp.text())
          .then((src) => {
            this.$el.setAttribute('src', src);
            this.$emit('update-video-url', src);

            this.$nextTick(() => {
              // If we were supposed to start playing, attempt to play again
              if (this.videoPlaying) {
                this.play();
              }
            });

            this.vidErr = videoErrorState.UPDATE_COMPLETE;
          }).catch(() => {
            // Failed to get new media url
            unrecoverableError();
          });
      } else if (this.vidErr !== videoErrorState.REQUESTING_MEDIA) {
        // Unless we're waiting for an updated media request, advance to the next post
        unrecoverableError();
      }
    },

    play() {
      if (this.videoPlaying) {
        this.$el.play();
      }
    },

    pause() {
      this.$el.pause();
    },

    muteVideo() {
      try {
        this.$el.muted = true;
        this.$el.setAttribute('muted', 'true');
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },

    unmuteVideo() {
      try {
        this.$el.muted = false;
        this.$el.setAttribute('muted', 'false');
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
  },
};
</script>
