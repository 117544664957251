export default {
  none: {
    enter: '',
    exit: '',
    mode: '',
  },
  'isolated': {
    'Slide': {
      'previous': {
        'enter': 'animated fadeInLeft',
        'exit': 'animated fadeOutRight',
        'mode': ''
      },
      'next': {
        'enter': 'animated fadeInRight',
        'exit': 'animated fadeOutLeft',
        'mode': ''
      }
    },
    'Bounce': {
      'previous': {
        'enter': 'animated bounceInLeft',
        'exit': 'animated bounceOutRight',
        'mode': ''
      },
      'next': {
        'enter': 'animated bounceInRight',
        'exit': 'animated bounceOutLeft',
        'mode': ''
      }
    },
    'Roll': {
      'previous': {
        'enter': 'animated rollIn',
        'exit': 'animated rollOut',
        'mode': ''
      },
      'next': {
        'enter': 'animated rollIn',
        'exit': 'animated rollOut',
        'mode': ''
      }
    },
    'Flip': {
      'previous': {
        'enter': 'animated flipInX',
        'exit': 'animated flipOutX',
        'mode': ''
      },
      'next': {
        'enter': 'animated flipInX',
        'exit': 'animated flipOutX',
        'mode': ''
      }
    },
    'Fade': {
      'next': {
        'enter': 'animated fadeIn',
        'exit': 'animated fadeOut',
        'mode': ''
      },
      'previous': {
        'enter': 'animated fadeIn',
        'exit': 'animated fadeOut',
        'mode': ''
      }
    },
    'Zoom': {
      'next': {
        'enter': 'animated zoomIn',
        'exit': 'animated fadeOutDown',
        'mode': ''
      },
      'previous': {
        'enter': 'animated fadeInUp',
        'exit': 'animated zoomOut',
        'mode': ''
      }
    },
    'Swing': {
      'previous': {
        'enter': 'animated swingInRight',
        'exit': 'animated slideOutLeft3d',
        'mode': ''
      },
      'next': {
        'enter': 'animated swingInLeft',
        'exit': 'animated slideOutRight3d',
        'mode': ''
      }
    },
    'Fly': {
      'previous': {
        'enter': 'animated flyInRight',
        'exit': 'animated flyOutLeft',
        'mode': ''
      },
      'next': {
        'enter': 'animated flyInLeft',
        'exit': 'animated flyOutRight',
        'mode': ''
      }
    }
  },

  'lowerthird': {
    'Slide': {
      'previous': {
        'enter': 'animated fadeInLeft',
        'exit': 'animated fadeOutRight',
        'mode': ''
      },
      'next': {
        'enter': 'animated fadeInRight',
        'exit': 'animated fadeOutLeft',
        'mode': ''
      }
    },
    'Bounce': {
      'previous': {
        'enter': 'animated bounceInLeft',
        'exit': 'animated bounceOutRight',
        'mode': ''
      },
      'next': {
        'enter': 'animated bounceInRight',
        'exit': 'animated bounceOutLeft',
        'mode': ''
      }
    },
    'Roll': {
      'previous': {
        'enter': 'animated rollIn',
        'exit': 'animated rollOut',
        'mode': ''
      },
      'next': {
        'enter': 'animated rollIn',
        'exit': 'animated rollOut',
        'mode': ''
      }
    },
    'Flip': {
      'previous': {
        'enter': 'animated flipInX',
        'exit': 'animated flipOutX',
        'mode': ''
      },
      'next': {
        'enter': 'animated flipInX',
        'exit': 'animated flipOutX',
        'mode': ''
      }
    },
    'Fade': {
      'next': {
        'enter': 'animated fadeIn',
        'exit': 'animated fadeOut',
        'mode': ''
      },
      'previous': {
        'enter': 'animated fadeIn',
        'exit': 'animated fadeOut',
        'mode': ''
      }
    },
    'Zoom': {
      'next': {
        'enter': 'animated zoomIn',
        'exit': 'animated fadeOutDown',
        'mode': ''
      },
      'previous': {
        'enter': 'animated fadeInUp',
        'exit': 'animated zoomOut',
        'mode': ''
      }
    },
    'Swing': {
      'previous': {
        'enter': 'animated swingInRight',
        'exit': 'animated slideOutLeft3d',
        'mode': ''
      },
      'next': {
        'enter': 'animated swingInLeft',
        'exit': 'animated slideOutRight3d',
        'mode': ''
      }
    },
    'Fly': {
      'previous': {
        'enter': 'animated flyInRight',
        'exit': 'animated flyOutLeft',
        'mode': ''
      },
      'next': {
        'enter': 'animated flyInLeft',
        'exit': 'animated flyOutRight',
        'mode': ''
      }
    }
  },

  'lowerthirdscroll': {
    'Slide': {
      'previous': {
        'enter': 'animated fadeInLeft',
        'exit': 'animated fadeOutRight',
        'mode': ''
      },
      'next': {
        'enter': 'animated fadeInRight',
        'exit': 'animated fadeOutLeft',
        'mode': ''
      }
    },
    'Bounce': {
      'previous': {
        'enter': 'animated bounceInLeft',
        'exit': 'animated bounceOutRight',
        'mode': ''
      },
      'next': {
        'enter': 'animated bounceInRight',
        'exit': 'animated bounceOutLeft',
        'mode': ''
      }
    },
    'Roll': {
      'previous': {
        'enter': 'animated rollIn',
        'exit': 'animated rollOut',
        'mode': ''
      },
      'next': {
        'enter': 'animated rollIn',
        'exit': 'animated rollOut',
        'mode': ''
      }
    },
    'Flip': {
      'previous': {
        'enter': 'animated flipInX',
        'exit': 'animated flipOutX',
        'mode': ''
      },
      'next': {
        'enter': 'animated flipInX',
        'exit': 'animated flipOutX',
        'mode': ''
      }
    },
    'Fade': {
      'next': {
        'enter': 'animated fadeIn',
        'exit': 'animated fadeOut',
        'mode': ''
      },
      'previous': {
        'enter': 'animated fadeIn',
        'exit': 'animated fadeOut',
        'mode': ''
      }
    },
    'Zoom': {
      'next': {
        'enter': 'animated zoomIn',
        'exit': 'animated fadeOutDown',
        'mode': ''
      },
      'previous': {
        'enter': 'animated fadeInUp',
        'exit': 'animated zoomOut',
        'mode': ''
      }
    },
    'Swing': {
      'previous': {
        'enter': 'animated swingInRight',
        'exit': 'animated slideOutLeft3d',
        'mode': ''
      },
      'next': {
        'enter': 'animated swingInLeft',
        'exit': 'animated slideOutRight3d',
        'mode': ''
      }
    },
    'Fly': {
      'previous': {
        'enter': 'animated flyInRight',
        'exit': 'animated flyOutLeft',
        'mode': ''
      },
      'next': {
        'enter': 'animated flyInLeft',
        'exit': 'animated flyOutRight',
        'mode': ''
      }
    }
  },

  'ticker': {
    'Slide': {
      'previous': {
        'enter': 'animated fadeInLeft',
        'exit': 'animated fadeOutRight',
        'mode': ''
      },
      'next': {
        'enter': 'animated fadeInRight',
        'exit': 'animated fadeOutLeft',
        'mode': ''
      }
    },
    'Bounce': {
      'previous': {
        'enter': 'animated bounceInLeft',
        'exit': 'animated bounceOutRight',
        'mode': ''
      },
      'next': {
        'enter': 'animated bounceInRight',
        'exit': 'animated bounceOutLeft',
        'mode': ''
      }
    },
    'Roll': {
      'previous': {
        'enter': 'animated rollIn',
        'exit': 'animated rollOut',
        'mode': ''
      },
      'next': {
        'enter': 'animated rollIn',
        'exit': 'animated rollOut',
        'mode': ''
      }
    },
    'Flip': {
      'previous': {
        'enter': 'animated flipInX',
        'exit': 'animated flipOutX',
        'mode': ''
      },
      'next': {
        'enter': 'animated flipInX',
        'exit': 'animated flipOutX',
        'mode': ''
      }
    },
    'Fade': {
      'next': {
        'enter': 'animated fadeIn',
        'exit': 'animated fadeOut',
        'mode': ''
      },
      'previous': {
        'enter': 'animated fadeIn',
        'exit': 'animated fadeOut',
        'mode': ''
      }
    },
    'Zoom': {
      'next': {
        'enter': 'animated zoomIn',
        'exit': 'animated fadeOutDown',
        'mode': ''
      },
      'previous': {
        'enter': 'animated fadeInUp',
        'exit': 'animated zoomOut',
        'mode': ''
      }
    },
    'Swing': {
      'previous': {
        'enter': 'animated swingInRight',
        'exit': 'animated slideOutLeft3d',
        'mode': ''
      },
      'next': {
        'enter': 'animated swingInLeft',
        'exit': 'animated slideOutRight3d',
        'mode': ''
      }
    },
    'Fly': {
      'previous': {
        'enter': 'animated flyInRight',
        'exit': 'animated flyOutLeft',
        'mode': ''
      },
      'next': {
        'enter': 'animated flyInLeft',
        'exit': 'animated flyOutRight',
        'mode': ''
      }
    }
  },

  'stories': {
    'None': {
      'next': {
        'enter': '',
        'exit': '',
        'mode': ''
      },
      'previous': {
        'enter': '',
        'exit': '',
        'mode': ''
      }
    },
    'Slide': {
      'previous': {
        'enter': 'animated fadeInLeft',
        'exit': 'animated fadeOutRight',
        'mode': ''
      },
      'next': {
        'enter': 'animated fadeInRight',
        'exit': 'animated fadeOutLeft',
        'mode': ''
      }
    },
    'Bounce': {
      'previous': {
        'enter': 'animated bounceInLeft',
        'exit': 'animated bounceOutRight',
        'mode': ''
      },
      'next': {
        'enter': 'animated bounceInRight',
        'exit': 'animated bounceOutLeft',
        'mode': ''
      }
    },
    'Roll': {
      'previous': {
        'enter': 'animated rollIn',
        'exit': 'animated rollOut',
        'mode': ''
      },
      'next': {
        'enter': 'animated rollIn',
        'exit': 'animated rollOut',
        'mode': ''
      }
    },
    'Flip': {
      'previous': {
        'enter': 'animated flipInX',
        'exit': 'animated flipOutX',
        'mode': ''
      },
      'next': {
        'enter': 'animated flipInX',
        'exit': 'animated flipOutX',
        'mode': ''
      }
    },
    'Fade': {
      'next': {
        'enter': 'animated fadeIn',
        'exit': 'animated fadeOut',
        'mode': ''
      },
      'previous': {
        'enter': 'animated fadeIn',
        'exit': 'animated fadeOut',
        'mode': ''
      }
    },
    'Zoom': {
      'next': {
        'enter': 'animated zoomIn',
        'exit': 'animated fadeOutDown',
        'mode': ''
      },
      'previous': {
        'enter': 'animated fadeInUp',
        'exit': 'animated zoomOut',
        'mode': ''
      }
    },
    'Swing': {
      'previous': {
        'enter': 'animated swingInRight',
        'exit': 'animated slideOutLeft3d',
        'mode': ''
      },
      'next': {
        'enter': 'animated swingInLeft',
        'exit': 'animated slideOutRight3d',
        'mode': ''
      }
    },
    'Fly': {
      'previous': {
        'enter': 'animated flyInRight',
        'exit': 'animated flyOutLeft',
        'mode': ''
      },
      'next': {
        'enter': 'animated flyInLeft',
        'exit': 'animated flyOutRight',
        'mode': ''
      }
    }
  },

  'cinematic': {
    'Fade': {
      'next': {
        'enter': 'animated fadeIn',
        'exit': 'animated fadeOut',
        'mode': 'in-out'
      },
      'previous': {
        'enter': 'animated fadeIn',
        'exit': 'animated fadeOut',
        'mode': 'in-out'
      }
    },
    'Ken Burns': {
      'next': {
        'enter': 'animated fadeIn',
        'exit': 'animated fadeOut',
        'mode': ''
      },
      'previous': {
        'enter': 'animated fadeIn',
        'exit': 'animated fadeOut',
        'mode': ''
      }
    },
    'Zoom In': {
      'next': {
        'enter': 'animated scaleUpIn',
        'exit': 'animated scaleUpOut',
        'mode': ''
      },
      'previous': {
        'enter': 'animated scaleUpIn',
        'exit': 'animated scaleUpOut',
        'mode': ''
      }
    },
    'Zoom Out': {
      'next': {
        'enter': 'animated scaleDownIn',
        'exit': 'animated scaleDownOut',
        'mode': ''
      },
      'previous': {
        'enter': 'animated scaleDownIn',
        'exit': 'animated scaleDownOut',
        'mode': ''
      }
    },
    'Swing': {
      'previous': {
        'enter': 'animated swingInRight',
        'exit': 'animated slideOutLeft3d',
        'mode': ''
      },
      'next': {
        'enter': 'animated swingInLeft',
        'exit': 'animated slideOutRight3d',
        'mode': ''
      }
    },
    'Fly': {
      'previous': {
        'enter': 'animated flyInRight',
        'exit': 'animated flyOutLeft',
        'mode': ''
      },
      'next': {
        'enter': 'animated flyInLeft',
        'exit': 'animated flyOutRight',
        'mode': ''
      }
    }
  },
  'upnext': {
    'Slide': {
      'previous': {
        'enter': 'animated slideInLeft',
        'exit': 'animated slideOutRight',
        'mode': ''
      },
      'next': {
        'enter': 'animated slideInRight',
        'exit': 'animated slideOutLeft',
        'mode': ''
      }
    },
    'Slide Down': {
      'next': {
        'enter': 'animated slideInDown',
        'exit': 'animated slideOutDown',
        'mode': ''
      },
      'previous': {
        'enter': 'animated slideInUp',
        'exit': 'animated slideOutUp',
        'mode': ''
      }
    }
  },
  'grid': {
    'Fade': {
      'next': {
        'enter': 'animated fadeIn',
        'exit': 'animated fadeOut',
        'mode': ''
      },
      'previous': {
        'enter': 'animated fadeIn',
        'exit': 'animated fadeOut',
        'mode': ''
      }
    },
    'Flip': {
      'previous': {
        'enter': 'animated flipInX',
        'exit': 'animated flipOutX',
        'mode': ''
      },
      'next': {
        'enter': 'animated flipInX',
        'exit': 'animated flipOutX',
        'mode': ''
      }
    },
    'Slide Down': {
      'next': {
        'enter': 'animated slideInDown',
        'exit': 'animated slideOutDown',
        'mode': ''
      },
      'previous': {
        'enter': 'animated slideInUp',
        'exit': 'animated slideOutUp',
        'mode': ''
      }
    },
    'Slide': {
      'previous': {
        'enter': 'animated fadeInLeft',
        'exit': 'animated fadeOutRight',
        'mode': ''
      },
      'next': {
        'enter': 'animated fadeInRight',
        'exit': 'animated fadeOutLeft',
        'mode': ''
      }
    }
  },
  'waterfall': {
    'Fade': {
      'next': {
        'enter': 'animated fadeIn',
        'exit': 'animated fadeOut',
        'mode': ''
      },
      'previous': {
        'enter': 'animated fadeIn',
        'exit': 'animated fadeOut',
        'mode': ''
      }
    },
    'Zoom': {
      'previous': {
        'enter': 'animated zoomIn',
        'exit': 'animated zoomOut',
        'mode': ''
      },
      'next': {
        'enter': 'animated zoomIn',
        'exit': 'animated zoomOut',
        'mode': ''
      }
    },
    'Scale': {
      'previous': {
        'enter': 'animated scale',
        'exit': 'animated scale',
        'mode': ''
      },
      'next': {
        'enter': 'animated scale',
        'exit': 'animated scale',
        'mode': ''
      }
    },
    'Slide Down': {
      'next': {
        'enter': 'animated slideInDown',
        'exit': 'animated slideOutDown',
        'mode': ''
      },
      'previous': {
        'enter': 'animated slideInUp',
        'exit': 'animated slideOutUp',
        'mode': ''
      }
    },
    'Slide': {
      'previous': {
        'enter': 'animated fadeInDown',
        'exit': 'animated fadeOutDown',
        'mode': ''
      },
      'next': {
        'enter': 'animated fadeInDown',
        'exit': 'animated fadeOutDown',
        'mode': ''
      }
    },
    'Parallax': {
      'previous': {
        'enter': 'animated fadeInDown',
        'exit': 'animated fadeOutDown',
        'mode': ''
      },
      'next': {
        'enter': 'animated fadeInDown',
        'exit': 'animated fadeOutDown',
        'mode': ''
      }
    }
  },
  'chat': {
    'Push Up': {
      'previous': {
        'enter': 'animated fadeInUp',
        'exit': 'animated fadeOutUp',
        'mode': ''
      },
      'next': {
        'enter': 'animated fadeInUp',
        'exit': 'animated fadeOutUp',
        'mode': ''
      }
    }
  },
  'carousel': {
    'Slide': {
      'previous': {
        'enter': 'animated fadeInLeft',
        'exit': 'animated fadeOutRight',
        'mode': ''
      },
      'next': {
        'enter': 'animated fadeInRight',
        'exit': 'animated fadeOutLeft',
        'mode': ''
      }
    },
    'Scale': {
      'previous': {
        'enter': 'animated fadeInDown',
        'exit': 'animated fadeOutDown',
        'mode': ''
      },
      'next': {
        'enter': 'animated fadeInDown',
        'exit': 'animated fadeOutDown',
        'mode': ''
      }
    },
    'Flow': {
      'previous': {
        'enter': 'animated fadeInDown',
        'exit': 'animated fadeOutDown',
        'mode': ''
      },
      'next': {
        'enter': 'animated fadeInDown',
        'exit': 'animated fadeOutDown',
        'mode': ''
      }
    }
  },
  'mural': {
    'Flip': {
      'previous': {
        'enter': 'animated flipInX',
        'exit': 'animated flipOutX',
        'mode': ''
      },
      'next': {
        'enter': 'animated flipInX',
        'exit': 'animated flipOutX',
        'mode': ''
      }
    },
    'Fade': {
      'next': {
        'enter': 'animated fadeIn',
        'exit': 'animated fadeOut',
        'mode': ''
      },
      'previous': {
        'enter': 'animated fadeIn',
        'exit': 'animated fadeOut',
        'mode': ''
      }
    }
  }
};
