<template>
  <div :class="getThemeClass" class="theme-wrapper">
    <div
      class="fullscreen-wrapper"
      :style="{ 'background-color': theme.settings.backgroundColor }"
      :class="[
        'post-height-' + theme.settings.postHeight,
        { 'rounded-post-corners': theme.settings.roundedPostCorners },
        'post-color-theme-' + theme.settings.postColorTheme,
        'cta-template-' + theme.settings.ctaTemplate,
        'font-' + theme.settings.font,
        'cta-social-icon-style-' + theme.settings.ctaSocialIconStyle,
        { 'no-title': !theme.settings.showCtaText },
        { 'no-social-icons': !theme.settings.showCtaSocialIcons },
        { 'safe-area': safeArea },
        { 'has-qr-code': showQrCode },
        theme.settings.qrCodePosition === 'right' ? 'qr-right' : 'qr-left',
      ]">
      <div
        v-if="safeArea"
        :class="{ 'darkened-background': theme.settings.darkenBackground }"
        class="backdrop"
        :style="[backgroundStyle]">
        <video
          v-if="theme.settings.backgroundVideo"
          :src="theme.settings.backgroundVideo"
          class="background-video"
          width="auto"
          autoplay
          loop
          muted></video>
        <div class="background-overlay"></div>
      </div>
      <div class="display-wrapper"
        :style="safeArea ? wrapperStyles : {}"
        :class="{ 'empty-header': emptyHeader }">
        <div
          v-if="
            !safeArea && (theme.settings.backgroundImage ||
            theme.settings.backgroundVideo)"
          :class="{ 'darkened-background': theme.settings.darkenBackground }"
          class="backdrop"
          :style="[backgroundStyle]">
          <video
            v-if="theme.settings.backgroundVideo"
            :src="theme.settings.backgroundVideo"
            class="background-video"
            width="auto"
            autoplay
            loop
            muted></video>
          <div class="background-overlay"></div>
        </div>

        <header-component :theme="theme"></header-component>
        <div class="qr-module" v-if="showQrCode">
            <img class="square-placeholder" src="/live/assets/img/square.png">
            <qr-code :settings="theme.settings" />
          </div>

        <div class="posts" :class="[theme.settings.animation, { 'hide-links': theme.settings.hideLinks }]">
          <template v-if="post">
            <transition
              appear
              :enter-active-class="enterAnim"
              :leave-active-class="exitAnim"
              :mode="mode">
              <component
                v-if="currentPost"
                :is="getPostType(currentPost)"
                :key="getKey(currentPost)"
                :theme="theme"
                :panel="currentPost"
                :post="currentPost"></component>
            </transition>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IsolatedPostComponent from './IsolatedPost.vue';
import PostPanelComponent from '../../post-panel/PostPanel.vue';
import HeaderComponent from '../../../header/v1.5/Header.vue';
import QRCodeComponent from '../../../qr-code/v2.0/QRCode.vue';
import AnimationMixins from '../../../../mixins/animation-mixins';
import LayoutMixins from '../../../../mixins/layout-mixins';

export default {
  props: ['posts', 'theme', 'post'],
  mixins: [LayoutMixins, AnimationMixins],

  components: {
    'header-component': HeaderComponent,
    post: IsolatedPostComponent,
    panel: PostPanelComponent,
    'qr-code': QRCodeComponent,
  },
  computed: {
    safeArea() {
      return this.theme.settings.safeArea;
    },
    wrapperStyles() {
      return {
        'max-width': this.safeAreaMaxWidth,
        'max-height': this.safeAreaMaxHeight,
      };
    },
    safeAreaMaxWidth() {
      const { safeArea, safeWidth } = this.theme.settings;

      return safeArea && safeWidth && safeWidth > 0
        ? `${safeWidth}px`
        : '100%';
    },
    safeAreaMaxHeight() {
      const { safeArea, safeHeight } = this.theme.settings;

      return safeArea && safeHeight && safeHeight > 0
        ? `${safeHeight}px`
        : '100%';
    },
    emptyHeader() {
      const { logo, showCtaText, showCtaSocialIcons } = this.theme.settings;
      return !logo && !showCtaText && !showCtaSocialIcons;
    },
    backgroundStyle() {
      if (this.theme.settings.backgroundImage !== '') {
        return {
          backgroundImage: `url('${this.theme.settings.backgroundImage}')`,
        };
      }
      return {};
    },
    showQrCode() {
      const { showQrCode, qrCodeCtaPlacement } = this.theme.settings;
      return showQrCode && !qrCodeCtaPlacement;
    },
    addQRCodeClass() {
      const {
        settings,
      } = this.theme;

      const {
        showQrCode,
        qrCodeCtaPlacement,
        ctaTemplate,
      } = settings;

      return showQrCode && qrCodeCtaPlacement && ctaTemplate !== 1;
    },
  },
};
</script>
