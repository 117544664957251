import twemoji from '@twemoji/api';

// eslint-disable-next-line import/prefer-default-export
export function postWithEmojis(post) {
  if (!post) return post;

  const formattedFields = {};
  if (post.text) formattedFields.text = twemoji.parse(post.text);
  if (post.title) formattedFields.title = twemoji.parse(post.title);
  if (post.html) formattedFields.html = twemoji.parse(post.html);
  if (post.user_real_name) {
    formattedFields.user_real_name = twemoji.parse(post.user_real_name);
  }

  return { ...post, ...formattedFields };
}
