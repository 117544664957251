<template>
  <div>
    <group
      v-for="(group, index) in virtualGroups"
      :key="index"
      :animkey="(page * limit) + index"
      :element="group"
      :graphic="graphic"
    />
  </div>
</template>

<script>
import Group from '../group/Group.vue';
import dataRepeaterMixins from '../../mixins/data-repeater-mixins';

export default {
  name: 'DataList',

  components: {
    Group,
  },

  mixins: [dataRepeaterMixins],

  computed: {
    /**
    * Clone list object for each set of elements to render
    */
    virtualGroups() {
      return this.virtualElements.map((elements) => ({
        ...this.element,
        elements,
      }));
    },
  },
};
</script>
