<template>
  <div class="element text" :style="[getBasicStyles, getTransformStyles]" :class="[element.cssClasses, element.animationInStyle, element.animationOutStyle]">
    <div class="text-wrap" :style="[getVisibility, getTextShadowStyles, getTextBackgroundStyles]" :class="['vertical-' + element.verticalAlign]">
      <transition
        appear
        :enter-to-class="animationIn"
        :leave-to-class="animationOut"
        enter-class="enter"
        @before-enter="beforeEnter"
        @after-enter="afterEnter"
        @before-leave="beforeLeave"
        @after-leave="afterLeave"
        type="animation"
        mode=""
      >
        <div class="animated-element" :key="formattedDate" :style="getAnimationStyles" :class="getLoopingClass">
          <div
            class="message"
            :style="{ textDecoration: element.styles.textDecoration }"
          >{{formattedDate}}</div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns';
import GraphicsMixins from '../../../mixins/graphics-mixins';

export default {
  name: 'DateElement',
  mixins: [GraphicsMixins],
  data() {
    return {
      now: new Date(),
      timer: null,
    };
  },
  computed: {
    dataDate() {
      return this.element.data?.dataValues?.text ?? false;
    },
    whichDate() {
      return this.dataDate || this.now;
    },

    formattedDate() {
      let offset;

      function stdTimezoneOffset(_date) {
        const jan = new Date(_date.getFullYear(), 0, 1);
        const jul = new Date(_date.getFullYear(), 6, 1);
        return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
      }

      function dst(_date) {
        return _date.getTimezoneOffset() < stdTimezoneOffset(_date);
      }

      if (!this.element.customTimeZone) {
        return format(
          new Date(this.whichDate),
          this.element.customFormat ? this.element.customFormatStyle : this.element.format,
        );
      }

      const date = new Date(this.whichDate);
      const localTime = date.getTime();
      const localOffset = date.getTimezoneOffset() * 60000;
      const utc = localTime + localOffset;
      offset = Number(this.element.customTimeZoneOffset);

      if (this.element.customTimeZoneHasDaylightSavings && dst(new Date())) {
        offset += 1;
      }

      const localTimestamp = utc + (3600000 * offset);

      return format(
        new Date(localTimestamp),
        this.element.customFormat ? this.element.customFormatStyle : this.element.format,
      );
    },
    getTextShadowStyles() {
      if (this.element.textShadow) {
        const textShadowColor = this.element.data?.dataValues?.textShadowColor
        ?? this.element.textShadowColor;

        return { textShadow: `${this.element.textShadowX}px ${this.element.textShadowY}px ${this.element.textShadowBlur}px ${textShadowColor}` };
      }
    },
    getTextBackgroundStyles() {
      if (this.element.background) {
        const { data } = this.element;
        const backgroundColor = data?.dataValues?.backgroundColor ?? this.element.backgroundColor;
        const gradientColorOne = data?.dataValues?.gradientColorOne ?? this.element.gradientColorOne;
        const gradientColorTwo = data?.dataValues?.gradientColorTwo ?? this.element.gradientColorTwo;

        if (this.element.fillStyle === 'gradient') {
          return { backgroundImage: `linear-gradient(${this.element.gradientDirection}deg, ${gradientColorOne}, ${gradientColorTwo})` };
        }
        return { backgroundImage: 'none', backgroundColor };
      }
    },
  },
  created() {
    if (!this.dataDate) {
      this.startDateTimer();
    }
  },
  methods: {
    startDateTimer() {
      this.timer = setInterval(() => {
        this.now = new Date();
      }, 1000);
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
