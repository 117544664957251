import { utils } from 'xlsx';

export const filterByRange = (data, range) => {
  const {
    s: start,
    e: end,
  } = utils.decode_range(range);

  const aoa = [];

  // For each row...
  for (let i = start.r; i <= end.r; i += 1) {
    if (!data[i]) { break; }

    const row = [];

    // For each column...
    for (let j = start.c; j <= end.c; j += 1) {
      const cell = data[i][j];
      row.push(typeof cell === 'string' ? { text: cell } : cell);
    }

    aoa.push(row);
  }

  return aoa;
};
