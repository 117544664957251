var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"element image",class:[_vm.element.cssClasses, _vm.element.animationInStyle, _vm.element.animationOutStyle],style:(_vm.getBasicStyles)},[_c('div',{staticClass:"image-wrap"},[_c('transition',{attrs:{"appear":"","enter-to-class":_vm.animationIn,"leave-to-class":_vm.animationOut,"enter-class":"enter","type":"animation"},on:{"before-enter":_vm.beforeEnter,"after-enter":_vm.afterEnter,"before-leave":_vm.beforeLeave,"after-leave":_vm.afterLeave}},[_c('div',{key:_vm.src,staticClass:"animated-element",class:[_vm.getFillTypeClass, _vm.getMaskClass, _vm.getLoopingClass],style:([
          _vm.getAnimationStyles,
          _vm.getBorderStyles,
          _vm.getImageBorderRadiusStyles,
          _vm.getBoxShadowStyles,
          _vm.getBlurStyles,
          _vm.getBackgroundStyles,
        ])},[(_vm.isVideoEmbed)?[_c('video-embed',{key:_vm.src,ref:"videoEl",staticClass:"media iframe",attrs:{"src":_vm.src,"muted":_vm.isMuted,"autoplay":_vm.shouldAutoplay,"loop":_vm.element.videoOptions.loop},on:{"updated:muted":_vm.isMuted,"durationchange":_vm.onDurationChange}})]:(_vm.isVideo)?[(!_vm.videoError)?_c('video',{key:_vm.src,ref:"videoEl",staticClass:"media video",attrs:{"type":"video/mp4","autoplay":_vm.shouldAutoplay,"loop":_vm.videoShouldLoop,"src":_vm.src},domProps:{"muted":_vm.isMuted},on:{"durationchange":_vm.onDurationChange,"error":_vm.handleVideoError}}):_vm._e()]:(_vm.src)?_c('img',{staticClass:"media photo",attrs:{"src":_vm.src,"alt":" "}}):_vm._e()],2)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }