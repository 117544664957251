<template>
  <transition
    appear
    :mode="transitionMode"
    :duration="{ leave: leave }"
    @before-enter="$emit('before-enter')"
    @after-enter="afterEnter"
    @before-leave="$emit('before-leave', leave)"
  >
    <div
      v-if="graphic"
      :key="graphic.template_id"
      class="theme-wrapper graphics-wrapper"
      :style="getStyles"
      :class="getSnippetClasses"
      :scale="graphic.scale"
    >
      <transition-group :duration="leave">
        <graphic-element
          v-for="element in graphicElements"
          :key="element.id"
          ref="child"
          :element="element"
          :graphic="graphic"
          v-on="$listeners"
        />
      </transition-group>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex';
import GraphicElement from './render-elements';
import { calcLeaveDuration } from '../utils/transitions';

export default {
  components: {
    GraphicElement,
  },

  props: {
    // Graphic Element
    graphic: { type: Object, required: true },

    // Timeline Item
    item: {
      type: Object,
      default: () => ({}),
    },

    snippets: { type: Array, required: true },
    scale: { type: Number, default: 1 },
    zIndex: { type: Number, default: 0 },
  },

  emits: ['before-enter', 'after-enter', 'before-leave'],

  provide() {
    const data = {};

    Object.defineProperty(data, 'item', {
      enumerable: true,
      get: () => this.item,
    });

    return data;
  },

  data() {
    return {
      leave: 0,
    };
  },

  computed: {
    ...mapState({
      transitionMode: (state) => (state.production.transitionMode === 'out-in' ? 'out-in' : ''),
    }),
    graphicElements() {
      return (
        (this.graphic && this.graphic.elements)
          ? this.graphic.elements.slice().reverse().filter((x) => x.visible)
          : []
      );
    },

    getStyles() {
      if (!this.graphic) {
        return {};
      }

      const { width, height } = this.graphic.canvas || {};

      const styles = {
        width: `${width}px`,
        height: `${height}px`,
        zIndex: this.zIndex || 0,
      };

      if (styles && this.scale) {
        styles.transform = `scale(${this.scale})`;
      }

      return styles;
    },

    template_id() {
      return this.graphic && this.graphic.template_id;
    },

    timeline_id() {
      return this.graphic && this.graphic.timeline_id;
    },

    getSnippetClasses() {
      let classname = '';

      this.graphic.snippets.forEach((s) => {
        classname += (` snp-${s}`);
      });

      return classname;
    },
  },

  watch: {
    template_id() {
      if (this.graphic) {
        this.registerCustomCSS();
      }
    },

    graphic(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (this.graphic) {
          this.$emit('frameloaded');
          this.$emit('start-timer', this.graphic.displayTime);
        }
      }
    },
  },

  created() {
    if (this.graphic) {
      this.registerCustomCSS();
    }
  },

  methods: {
    afterEnter() {
      this.$emit('after-enter');
      this.calcLeaveDuration();
    },

    calcLeaveDuration() {
      this.leave = calcLeaveDuration(this.graphic);
    },

    registerCustomCSS() {
      const cssMarker = $("meta[name='custom-css-marker']");

      if (this.graphic.snippets) {
        // Iterate over each snippet on this theme, and make sure the css gets added
        this.graphic.snippets.forEach((snippetId) => {
          const snippetBlock = $(`style#snp-${snippetId}`);

          // We haven't yet added a style block for this snippet
          if (snippetBlock.length === 0) {
            // Find the snippet that matches this id and add the css block
            this.snippets.some((snippet) => {
              if (snippet._id !== snippetId) {
                return false; // This isn't the right snippet, try the next one
              }

              cssMarker.before(`<style id="snp-${snippetId}">${snippet.css}</style>`);
              return true; // We found the snippet, we can stop iterating
            });
          }
        });
      }
    },
  },
};
</script>
