import SocialGraphic from './data/Social.vue';
import SheetData from './data/SheetData.vue';
import DataList from './data/DataList.vue';
import GroupGraphic from './group/Group.vue';
import ImageGraphic from './image/Image.vue';
import OvalGraphic from './oval/Oval.vue';
import RectangleGraphic from './rectangle/Rectangle.vue';
import TextGraphic from './text/Text.vue';
import DateGraphic from './widgets/date/Date.vue';
import QRCodeGraphic from './widgets/qrcode/QRCode.vue';
import TimerGraphic from './widgets/timer/Timer.vue';
import TriangleGraphic from './triangle/Triangle.vue';
import VideoGraphic from './video/Video.vue';
import SlideshowGraphic from './widgets/slideshow/Slideshow.vue';
import MultiMediaGraphic from './multi-media/MultiMedia.vue';
import SportradarGraphic from './data/Sportradar.vue';
import LinkedDataGraphic from './LinkedData.vue';
import MarqueeGraphic from './marquee/MarqueeWrapper.vue';
import MarqueeImage from './marquee/MarqueeImage.vue';
import MarqueeText from './marquee/MarqueeText.vue';
import Shoppable from './data/Shoppable.vue';

export default {
  name: 'ElementRenderer',
  functional: true,
  render(h, ctx) {
    const getComponent = () => {
      switch (ctx.props.element.type) {
        case 'text':
          return TextGraphic;
        case 'group':
          return GroupGraphic;
        case 'image':
          return ImageGraphic;
        case 'rectangle':
          return RectangleGraphic;
        case 'triangle':
          return TriangleGraphic;
        case 'oval':
          return OvalGraphic;
        case 'slideshow':
          return SlideshowGraphic;
        case 'date':
          return DateGraphic;
        case 'qrcode':
          return QRCodeGraphic;
        case 'timer':
          return TimerGraphic;
        case 'video':
          return VideoGraphic;
        case 'multi-media':
          return MultiMediaGraphic;
        case 'social-data':
          return SocialGraphic;
        case 'data-list':
          return DataList;
        case 'sheet-data':
          return SheetData;
        case 'sportradar':
          return SportradarGraphic;
        case 'linked-data':
          return LinkedDataGraphic;
        case 'marquee':
          return MarqueeGraphic;
        case 'marquee-image':
          return MarqueeImage;
        case 'marquee-text':
          return MarqueeText;
        case 'shoppable':
          return Shoppable;

        default:
          console.error(`${ctx.props.element.type} is not a registered element type in Graphics.`);
          break;
      }
    };

    return h(getComponent(), {
      ...ctx.data,
      props: ctx.props,
      attrs: ctx.data.attrs,
      on: ctx.listeners,
      ref: ctx.data.ref,
    });
  },
};
