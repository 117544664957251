<template>
  <div
    class="element slideshow"
    :class="[element.cssClasses]"
  >
    <template v-if="element.hasProgressIndicator">
      <progress-indicator
        v-if="entries && entries.length > 1"
        :index="timedIndex"
        :parent="element"
        :element="element.progressIndicator"
        :entries="entries"
      />
    </template>

    <transition
      :key="slideIndex"
      appear
      :duration="{ leave: leave }"
      @before-enter="beforeEnter"
      @after-enter="calcLeaveDuration"
      @before-leave="beforeLeave"
      @after-leave="afterLeave"
    >
      <transition-group
        :key="slideIndex"
        :duration="leave"
        tag="div"
        class="slide-wrapper"
      >
        <graphic-element
          v-for="element in graphicElements"
          :key="element.id"
          ref="child"
          :animkey="slideIndex"
          :element="element"
          :graphic="graphic"
          v-on="$listeners"
        />
      </transition-group>
    </transition>
  </div>
</template>

<script>
import GraphicsMixins from '../../../mixins/graphics-mixins';
import ProgressIndicator from './ProgressIndicator.vue';

export default {
  mixins: [GraphicsMixins],
  components: {
    // GraphicElement added in beforeCreate
    ProgressIndicator,
    GraphicElement: () => import('../../render-elements'),
  },

  data() {
    return {
      slideIndex: 0,
      timedIndex: 0,
      current: null,
      timer: null,
      leave: 0,
    };
  },

  computed: {
    entries() {
      return this.element.entries.filter((entry) => entry.meta.visible);
    },

    graphicElements() {
      return this.current && this.current.length ? this.current.slice().filter((x) => x.visible) : [];
    },
  },

  created() {
    this.setFieldsKey();
    this.setCurrent();
    this.startTimer();
  },

  beforeDestroy() {
    clearTimeout(this.timer);
  },

  methods: {
    beforeEnter() {
      // We need to wait to change the progress bar til we're done loading the next slides
      this.timedIndex = this.slideIndex;
      this.$emit('before-enter');
    },

    calcLeaveDuration() {
      if (!this.current || !this.current.length) {
        return;
      }

      this.leave = this.current
        .concat(this.current.reduce((arr, elem) => arr.concat(elem.elements || []), []))
        .flatMap((elem) => (elem.animation ? Number(elem.animationOutDuration) + Number(elem.animationOutDelay) : 0))
        .reduce((a, b) => ((b >= a) ? b : a));

      this.afterLeave();
    },

    setFieldsKey() {
      const self = this;
      this.fields = [];

      function setFields(elements, index) {
        elements.forEach((element) => {
          self.fields[index] = self.fields[index] || {};
          self.fields[index][element.template_id] = element;

          if (element.entries) {
            setFields(element.entries, index);
          }
        });
      }

      this.entries.forEach((entry, index) => {
        setFields(entry.fields, index);
      });
    },

    setCurrent() {
      const self = this;
      const copy = JSON.parse(JSON.stringify(this.element.elements));

      self.setFieldsKey();

      function setOverrides(elements) {
        elements.forEach((el) => {
          if (self.fields[self.slideIndex] && self.fields[self.slideIndex][el.id]) {
            Object.assign(el, self.fields[self.slideIndex][el.id]);
          }

          if (el.elements) {
            setOverrides(el.elements);
          }
        });
      }

      setOverrides(copy);
      this.current = copy.reverse();
    },

    startTimer() {
      const self = this;
      this.timer = setTimeout(() => {
        clearTimeout(self.timer);
        self.advance();
      }, self.element.cycleTime);
    },

    advance() {
      if (this.entries.length) {
        if (this.slideIndex >= this.entries.length - 1) {
          this.slideIndex = 0;
        } else {
          this.slideIndex += 1;
        }

        this.setCurrent();
      }

      this.startTimer();
    },
  },
};
</script>
