<template>
  <div
    class="element image"
    :style="getBasicStyles"
    :class="[element.cssClasses, element.animationInStyle, element.animationOutStyle]"
  >
    <div class="image-wrap">
      <transition
        appear
        :enter-to-class="animationIn"
        :leave-to-class="animationOut"
        enter-class="enter"
        type="animation"
        @before-enter="beforeEnter"
        @after-enter="afterEnter"
        @before-leave="beforeLeave"
        @after-leave="afterLeave"
      >
        <div
          :key="src"
          class="animated-element"
          :class="[getFillTypeClass, getMaskClass, getLoopingClass]"
          :style="[
            getAnimationStyles,
            getBorderStyles,
            getImageBorderRadiusStyles,
            getBoxShadowStyles,
            getBlurStyles,
            getBackgroundStyles,
          ]"
        >
          <template
            v-if="isVideoEmbed"
          >
            <video-embed
              ref="videoEl"
              :key="src"
              class="media iframe"
              :src="src"
              :muted="isMuted"
              :autoplay="shouldAutoplay"
              :loop="element.videoOptions.loop"
              @updated:muted="isMuted"
              @durationchange="onDurationChange"
            />
          </template>

          <template v-else-if="isVideo">
            <!-- FYI: Note that we do not want to fallback to anything when video doesn't work
                 so that it is clear to the user running a production that the video is not
                 going to play. The poster backup looks too much like it could be buffering and text
                 fallback would not look good during a production. I have left the basic logic for
                 displaying some kind of alt content. Just in case we have a use case in the future
                 we don't have to reinvent that wheel. -->
            <video
              v-if="!videoError"
              ref="videoEl"
              :key="src"
              class="media video"
              type="video/mp4"
              :autoplay="shouldAutoplay"
              :muted="isMuted"
              :loop="videoShouldLoop"
              :src="src"
              @durationchange="onDurationChange"
              @error="handleVideoError"
            />
          </template>

          <!-- TODO: figure out what to do for image load issues -->
          <img
            v-else-if="src"
            :src="src"
            alt=" "
            class="media photo"
          >
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { has } from 'lodash-es';
import { mapActions, mapGetters } from 'vuex';
import { VideoEmbed } from '@tagboard/tgb-component-lib';

import GraphicsMixins from '../../mixins/graphics-mixins';

const {
  INSTAGRAM_BASE,
  TIKTOK_BASE,
} = require('../../../../../../services/services');

export default {
  name: 'MultiMediaElement',
  mixins: [GraphicsMixins],

  inject: [
    'post',
    'isTagboardPost',
  ],

  components: {
    VideoEmbed,
  },

  data() {
    return {
      videoError: false,
      useFallbackImage: false, // currently no way to set fallback on multi-media, but might as well flesh it in
    };
  },

  computed: {
    ...mapGetters(['isPlaying', 'videoPlaying']),

    mappedSrc() {
      return has(this.element, 'data.dataValues.src') && this.element.data.dataValues.src;
    },

    src() {
      if (!this.useFallbackImage && this.mappedSrc) {
        return this.mappedSrc;
      }

      return this.element.src;
    },

    mappedIsVideo() {
      return has(this.element, 'data.dataValues.isVideo') && this.element.data.dataValues.isVideo;
    },

    isVideo() {
      return this.mappedIsVideo || this.element.isVideo;
    },

    isVideoEmbed() {
      const { network } = this.post;
      return this.isVideo && ['youtube', 'facebook'].includes(network);
    },

    isMuted() {
      // logic for toggling just this video would go here
      return this.isGif || this.$store.getters.isMuted;
    },

    /**
    * True if auto-advancing, videoPlaying, or is a tagboard post
    */
    shouldAutoplay() {
      return (
        this.isPlaying
        || this.videoPlaying
        || this.isTagboardPost
        || this.isGif
      );
    },

    getBackgroundStyles() {
      if (this.element.fillStyle === 'gradient') {
        if (this.element.backgroundImage && this.element.backgroundImageUrl !== '') {
          // Background image with gradient colors
          return {
            backgroundImage:
            `url(${this.element.backgroundImageUrl}),
            linear-gradient(${this.element.gradientDirection}deg,
            ${this.element.gradientColorOne},
            ${this.element.gradientColorTwo})`,
            backgroundSize: this.element.backgroundSize,
          };
        }

        // Just gradient
        return {
          backgroundImage:
          `linear-gradient(${this.element.gradientDirection}deg,
          ${this.element.gradientColorOne},
          ${this.element.gradientColorTwo})`,
        };
      }

      if (this.element.backgroundImage && this.element.backgroundImageUrl !== '') {
        // Just a background image
        return {
          backgroundColor: this.element.backgroundColor,
          backgroundImage: `url(${this.element.backgroundImageUrl})`,
          backgroundSize: this.element.backgroundSize,
        };
      }

      // Plain ole background color
      return { backgroundImage: 'none', backgroundColor: this.element.backgroundColor };
    },

    getFillTypeClass() {
      return this.element.fillType ? this.element.fillType : '';
    },

    maskPosition() {
      return this.element.maskPosition ?? 'center-center';
    },
    getMaskClass() {
      return this.element.mask ? `mask ${this.element.maskShape} ${this.maskPosition}` : '';
    },

    getImageBorderRadiusStyles() {
      return this.element.mask && this.element.maskShape === 'rectangle' ? {
        borderRadius:
        `${this.element.borderTopLeftRadius}px
        ${this.element.borderTopRightRadius}px
        ${this.element.borderBottomRightRadius}px
        ${this.element.borderBottomLeftRadius}px`,
      } : '';
    },

    videoShouldLoop() {
      return this.element.videoOptions.loop === true || this.isGif;
    },

    isGif() {
      return this.post.videos && this.post.videos[0].type === 'gif';
    },
  },

  watch: {
    /**
    * If user enabled auto-advance, then start playing video if there is one
    */
    isPlaying(isPlaying) {
      if (isPlaying) {
        this.playVideo();
      } else {
        this.pauseVideo();
      }
    },

    /**
    * Toggle video state if `videoPlaying` flag changes
    */
    videoPlaying(isPlaying) {
      if (isPlaying) {
        this.playVideo();
      } else {
        this.pauseVideo();
      }
    },

    // currently no way to set fallback on multi-media, comment in when supported
    // mappedSrc() {
    //   this.useFallbackImage = false;
    // },
  },

  methods: {
    ...mapActions([
      'setPlayDuration',
      'setPostDuration',
      'setVideoDuration',
    ]),

    // currently no way to set fallback on multi-media, comment in and add error prop to img when supported
    // handleImageError() {
    //   this.useFallbackImage = true;
    // },

    onDurationChange(e) {
      if (!this.isGif) {
        let { duration } = e.target;

        duration *= 1e3;

        if (duration) {
          if (this.post) {
            // Post duration is used to control cycle speed of tagboards
            // It does not effect the auto-advance timer,
            // since that can ignore post durations based on the context.
            this.setPostDuration({
              postId: this.post.post_id,
              duration,
            });
          }

          // Only send play duration if this is an individual post.
          // Posts inside a tagboard aren't waited on
          if (!this.isTagboardPost) {
            this.setPlayDuration(duration);
          }

          // Send video duration to other clients regardless of where the media came from
          this.setVideoDuration(duration);
        }
      }
    },

    playVideo() {
      if (this.$refs.videoEl) {
        this.$refs.videoEl.play();
      }
    },

    pauseVideo() {
      if (this.$refs.videoEl) {
        this.$refs.videoEl.pause();
      }
    },

    async dealWithBadTiktokVideo() {
      const response = await fetch(`${TIKTOK_BASE}/video/${this.post.post_id}`);

      if (response.ok) {
        this.element.src = await response.text();
      } else {
        this.videoError = true;
      }
    },

    async onInstaVideoError() {
      const postId = this.post.post_id;

      let shortcode = this.post.permalink.replace(/\/$/, '').split('/');
      shortcode = shortcode[shortcode.length - 1];

      const resp = await fetch(`${INSTAGRAM_BASE}/video/${shortcode}?post_id=${postId}`);

      if (resp.ok) {
        this.element.src = await resp.text();
      } else {
        this.videoError = true;
      }
    },

    handleVideoError(e) {
      if (this.element.network === 'tiktok') {
        this.dealWithBadTiktokVideo(e);
      } else if (this.element.network === 'instagram') {
        this.onInstaVideoError(e);
      } else {
        this.videoError = true;
      }
    },
  },
};
</script>
