import Vue from 'vue';

Vue.filter('timestampToString', function(timestamp) {
  if (!timestamp) return '';
  // Converts a date value to string using a predefined format
  var month_names = new Array("Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec");

  var date = new Date(parseInt(timestamp, 10) * 1000);
  var hours = date.getHours();
  var suffix = (hours >= 12) ? "pm" : "am";

  if(hours === 0) {
    hours = 12;
  } else if(hours > 12) {
    hours -= 12;
  }

  // 0-pad the minutes. Is there really not a javascript function for this
  var minutes = '' + date.getMinutes();

  while(minutes.length < 2)
  minutes = '0' + minutes;

  return(month_names[date.getMonth()] + " " + date.getDate() + " " + date.getFullYear() + " - " + hours + ":" + minutes + suffix);
});

Vue.filter('truncate', function(str, val) {
  if(!str || str.length < val) { return str; }

  return str.substring(0, val) + '...';
});

Vue.filter('capitalize', function(str) {
  if(!str || !str.length) { return str; }
  return str.charAt(0).toUpperCase() + str.slice(1);
});

Vue.filter('timestamp', function(str) {
  var date = new Date(str);
  return ('0' + (date.getMonth() + 1)).slice(-2) + '/'  + ('0' + date.getDate()).slice(-2) + '/' + date.getFullYear();
});


Vue.filter('toMinutesAndSeconds', function(milliseconds) {
  var minutes = Math.floor(milliseconds / 60000),
      seconds = ((milliseconds % 60000) / 1000).toFixed(0);

  return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
});
