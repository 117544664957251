import Vue from 'vue';

let createReactiveNestedObject;

function fillArray(rootArray, arrayElements) {
  arrayElements.forEach((element, i) => {
    if (element instanceof Array) {
      rootArray.push([]);
    } else if (element instanceof Object) {
      rootArray.push({});
    } else {
      rootArray.push(element);
    }

    createReactiveNestedObject(rootArray[i], element);
  });
}

function createReactiveArray(isArray, root, key, values) {
  if (isArray) {
    root.push([]);
  } else {
    Vue.set(root, key, []);
  }

  fillArray(root[key], values);
}

function createReactiveObject(isArray, obj, key, values) {
  if (isArray) {
    obj.push({});
  } else {
    Vue.set(obj, key, {});
  }

  createReactiveNestedObject(obj[key], values);
}

function setValue(isArray, obj, key, value) {
  if (isArray) {
    obj.push(value);
  } else {
    Vue.set(obj, key, value);
  }
}

createReactiveNestedObject = (rootProp, object) => {
  const root = rootProp;
  const isArray = root instanceof Array;

  if (typeof object === 'object') {
    Object.keys(object).forEach((key) => {
      if (object[key] instanceof Array) {
        createReactiveArray(isArray, root, key, object[key]);
      } else if (object[key] instanceof Object) {
        createReactiveObject(isArray, root, key, object[key]);
      } else {
        setValue(isArray, root, key, object[key]);
      }
    });
  }
};

export default {
  createReactiveNestedObject,
};
