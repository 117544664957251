import { request } from '../utils/request';

export default {
  getProduction(id) {
    return request({
      type: 'GET',
      url: `/api/v2/productions/${id}?merge_deltas=true`,
    });
  },
};
