import SnippetService from '../../services/snippetservice';

const state = {
  snippets: [],
  ready: false,
  owner: jQuery('meta[name=data-owner-id]').attr('content')
};

const actions = {
  getSnippets: function (context) {
    const deferred = $.Deferred();

    if(!state.owner) { return; }

    SnippetService.getUserSnippets(state.owner).then(function(data) {
      context.commit('SET_USER_SNIPPETS', data.result);
      deferred.resolve(data.result);
    }).fail(function (reason) {
      deferred.reject(reason);
    });

    return deferred.promise();
  },
  setOwner: function(context, owner) {
    if(!state.owner) {
      context.commit('SET_OWNER', owner);
      context.dispatch('getSnippets');
    }
  }
};

const mutations = {
  SET_USER_SNIPPETS: function(state, snippets) {
    state.snippets = snippets;
    state.ready = true;
  },
  SET_OWNER: function(state, owner) {
    state.owner = owner;
  }
};

export default {
  state,
  actions,
  mutations,
};
