/**
 * Sleep for provided amount of milliseconds, then resolve promise.
 * @param {Number} t - Amount of time to sleep
 */
const sleep = (t) => new Promise((resolve) => {
  setTimeout(resolve, t);
});

/**
 * Fetch an image and resolve promise when image loads (or errors).
 * @param {String} src - Image src url
 */
const preloadImage = (src) => new Promise((resolve) => {
  const img = new Image();

  // Ignore errors and just resolve for now
  img.onload = () => { resolve(); };
  img.onerror = () => { resolve(); };

  img.src = src;
});

export {
  sleep,
  preloadImage,
};
