import { mapState } from 'vuex';
import { forEach, has } from 'lodash-es';

/**
 * checkImage verifies that the provided image src is valid.
 */
const checkImage = (src) => new Promise((resolve, reject) => {
  const img = new Image();
  img.src = src;
  img.onload = resolve;
  img.onerror = reject;
});

export default {
  props: ['element', 'graphic', 'animkey'],

  data() {
    return {
      live: false,
      isLooping: false,
      animationIn: this.element.animation ? `animated ${this.element.animationInStyle}` : '',
      animationOut: this.element.animation ? `animated ${this.element.animationOutStyle}` : '',
      animationDuration: `${this.element.animationInDuration}ms`,
      animationDelay: `${this.element.animationInDelay}ms`,
      animationTimingFunction: `cubic-bezier(${this.element.animationInEasing})`,

      // Whether or not to bypass data mapped image and use fallback
      useFallbackImage: false,
    };
  },

  mounted() {
    this.checkImage();
  },

  watch: {
    backgroundImageUrl() {
      this.checkImage();
    },
  },

  beforeDestroy() {
    this.isLooping = false;
    clearInterval(this.loopInterval);

    if (this.element.animation) {
      this.$nextTick(() => {
        $(this.$el).find('.animated-element').first()
          .removeClass('in out')
          .css({
            animationDuration: `${this.element.animationOutDuration}ms`,
            animationDelay: `${this.element.animationOutDelay}ms`,
            animationTimingFunction: `cubic-bezier(${this.element.animationOutEasing})`,
          })
          .addClass(this.animationOut); // Vue doesn't trigger the leave animation when a parent component is destroyed
      });
    }
  },
  methods: {
    /**
     * If the current element has a background image, then validate that that image exists.
     * It will only validate if there's a fallback image.
     */
    checkImage() {
      if (
        this.element.backgroundImage
        && this.backgroundImageUrl
        && this.fallbackBackgroundImageUrl
        && this.backgroundImageUrl !== this.fallbackBackgroundImageUrl
      ) {
        // We only need to verify the image if
        // we actually have a background image and a DIFFERENT fallback image
        checkImage(this.backgroundImageUrl).then(() => {
          this.useFallbackImage = false;
        }).catch(() => {
          this.useFallbackImage = true;
        });
      }
    },

    beforeEnter() {
      if (this.element.animation) {
        const delay = this.live ? '0ms' : `${this.element.animationInDelay}ms`;
        this.animationDuration = `${this.element.animationInDuration}ms`;
        this.animationDelay = delay;
        this.animationTimingFunction = `cubic-bezier(${this.element.animationInEasing})`;
      }

      this.$emit('before-enter');
    },

    beforeLeave() {
      if (this.element.animation) {
        this.animationDuration = `${this.element.animationOutDuration}ms`;
        this.animationDelay = '0ms';
        this.animationTimingFunction = `cubic-bezier(${this.element.animationOutEasing})`;
      }

      this.$emit('before-leave');
    },

    afterLeave() {
      this.$emit('after-leave');
    },

    afterEnter(el) {
      this.live = true;

      if (this.element.loopingAnimation) {
        this.animationDuration = `${this.element.loopingAnimationDuration}ms`;
        this.animationTimingFunction = `cubic-bezier(${this.element.loopingAnimationEasing})`;

        clearInterval(this.loopInterval);
        this.loopInterval = setInterval(() => {
          if ($(el).hasClass('in')) {
            $(el).removeClass('in').addClass('out');
          } else {
            $(el).removeClass('out').addClass('in');
          }
        }, (this.element.loopingAnimationDuration + this.element.loopingAnimationDelay));

        this.isLooping = true;
      }

      this.$emit('after-enter');
    },
  },

  computed: {
    ...mapState({
      transitionMode: (state) => (state.production.transitionMode === 'out-in' ? 'out-in' : ''),
      actions: (state) => state.production.displayActions,
    }),

    graphic_id() {
      return this.graphic.graphic_id;
    },

    getVisibility() {
      return { visibility: 'visible' };
    },

    /**
     * Separate styles wrapper for font specific stuff.
     * Useful when you need to set font styling at a different layer in the DOM.
     */
    getFontStyles() {
      const { data } = this.element;

      const fontSize = data?.dataValues?.fontSize ?? this.element.styles.fontSize;
      const letterSpacing = data?.dataValues?.letterSpacing ?? this.element.styles.letterSpacing;

      return {
        fontSize: `${fontSize}px`,
        letterSpacing: `${letterSpacing}px`,
      };
    },

    getBasicStyles() {
      const stylesObj = {};
      const needsPixelsArray = ['top', 'left', 'width', 'height', 'fontSize', 'letterSpacing'];
      const { type } = this.element;

      forEach(this.element.styles, (value, key) => {
        let adjustedValue = value;

        // Check if the current key is 'height' and the type is 'qrcode' to adjust it
        if (key === 'height' && type === 'qrcode') {
          adjustedValue = value + 12; // Add 12px to the height for qrcode type
        }

        // Apply the value to the stylesObj
        stylesObj[key] = this.element.data?.dataValues?.[key] ?? adjustedValue;

        // Add 'px' to properties in needsPixelsArray
        if (needsPixelsArray.indexOf(key) !== -1) {
          stylesObj[key] = `${adjustedValue}px`;
        }
      });

      const media = type === 'image' || type === 'media';

      if (!media && !has(stylesObj, 'lineHeight')) stylesObj.lineHeight = 1.25; // preserve "old" text styles

      return stylesObj;
    },

    getAnimationStyles() {
      if (!this.element.animation) {
        return null;
      }

      return {
        animationDuration: this.animationDuration,
        animationDelay: this.animationDelay,
        animationTimingFunction: this.animationTimingFunction,
      };
    },

    getLoopingClass() {
      return this.isLooping ? this.element.loopingAnimationStyle : '';
    },

    getBorderStyles() {
      if (!this.element.border) {
        return null;
      }

      const { borderWidth } = this.element;

      const { data } = this.element;
      const borderColor = data?.dataValues?.borderColor ?? this.element.borderColor;

      return {
        border: `${borderWidth}px solid ${borderColor}`,
      };
    },

    getBoxShadowStyles() {
      if (this.element.boxShadow) {
        const { data } = this.element;

        const boxShadowColor = data?.dataValues?.boxShadowColor ?? this.element.boxShadowColor;

        const {
          boxShadowX,
          boxShadowY,
          boxShadowBlur,
          boxShadowSpread,
        } = this.element;

        // Experimental box shadow uses CSS drop-shadow filter instead of box-shadow
        if (this.element.dropShadow) {
          const shadow = `${boxShadowX}px ${boxShadowY}px ${boxShadowBlur}px ${boxShadowColor}`;

          return {
            filter: `drop-shadow(${shadow})`,
          };
        }

        // eslint-disable-next-line max-len
        const shadow = `${boxShadowX}px ${boxShadowY}px ${boxShadowBlur}px ${boxShadowSpread}px ${boxShadowColor}`;

        return {
          boxShadow: shadow,
        };
      }

      return {};
    },

    getBlurStyles() {
      if (!this.element.blur) {
        return null;
      }

      const { blurSize } = this.element;

      return {
        filter: `blur(${blurSize}px)`,
      };
    },

    getTransformStyles() {
      const { element } = this;
      const skewX = `${element.skewX ?? 0}deg`;
      const skewY = `${element.skewY ?? 0}deg`;
      const rotate = `${element.rotate ?? 0}deg`;
      const scaleX = element.flipX ? -1 : 1;
      const scaleY = element.flipY ? -1 : 1;

      return {
        transform: `skew(${skewX}, ${skewY}) rotate(${rotate}) scale(${scaleX}, ${scaleY})`,
      };
    },

    /* NOTE: It might be cleaner to have a shape-mixin if we are gonna stick to this pattern */
    /* Rectangle/Oval/Triangle Related Properties */

    /**
     * Checks `dataValues` (new) and `dataValue` (legacy) for mapped image,
     * then falls back to static image if there is one set.
     */
    backgroundImageUrl() {
      const { data } = this.element;

      const dataValues = data?.dataValues || {};

      const backgroundImageUrl = dataValues.backgroundImageUrl ?? this.element.backgroundImageUrl;

      if (data?.dataValue && !('backgroundImageUrl' in dataValues)) {
        const { img, text } = data.dataValue;

        // If neither img, or text is formatted as a URL, then ignore them
        if (img && img.indexOf('http') === 0) {
          return img;
        }

        // String conversion because text might be a number
        if (text && `${text}`.indexOf('http') === 0) {
          return text;
        }
      } else if (data && data.isEmptyRow) {
        // Render nothing if entire row is empty
        return '';
      }

      return backgroundImageUrl;
    },

    fallbackBackgroundImageUrl() {
      const { data } = this.element;

      if (data && data.isEmptyRow) {
        // Ignore fallback images if entire data row is empty
        return '';
      }

      return this.element.backgroundImageUrl || '';
    },

    getBackgroundStyles() {
      const {
        backgroundSize,
        gradientDirection,
        backgroundPosition,
      } = this.element;

      // things that can be data driven
      let {
        backgroundColor,
        gradientColorOne,
        gradientColorTwo,
      } = this.element;

      if (this.element.data) {
        const { data } = this.element;
        gradientColorOne = data?.dataValues?.gradientColorOne ?? gradientColorOne;
        gradientColorTwo = data?.dataValues?.gradientColorTwo ?? gradientColorTwo;
        backgroundColor = data?.dataValues?.backgroundColor ?? backgroundColor;
      }

      const hasBackgroundImage = this.element.backgroundImage;
      const backgroundImageUrl = (hasBackgroundImage && this.useFallbackImage)
        ? this.fallbackBackgroundImageUrl
        : this.backgroundImageUrl;

      const backgroundImage = `url('${backgroundImageUrl}')`;

      // eslint-disable-next-line max-len
      const backgroundGradient = `linear-gradient(${gradientDirection}deg, ${gradientColorOne}, ${gradientColorTwo})`;

      if (this.element.fillStyle === 'gradient') {
        if (hasBackgroundImage && backgroundImageUrl !== '') {
          // Background image + gradient
          return {
            backgroundImage: `${backgroundImage}, ${backgroundGradient}`,
            backgroundSize,
            backgroundPosition,
          };
        }

        // No background image, just a gradient
        return {
          backgroundImage: backgroundGradient,
        };
      }

      if (hasBackgroundImage && backgroundImageUrl !== '') {
        // Simple background image, no gradient
        return {
          backgroundImage,
          backgroundColor,
          backgroundSize,
          backgroundPosition,
        };
      }

      // Just a solid color background
      return {
        backgroundImage: 'none',
        backgroundColor,
      };
    },

    getBorderRadiusStyles() {
      const {
        borderTopLeftRadius: tl,
        borderTopRightRadius: tr,
        borderBottomRightRadius: br,
        borderBottomLeftRadius: bl,
      } = this.element;

      return {
        borderRadius: `${tl}px ${tr}px ${br}px ${bl}px`,
      };
    },

    mappedImage() {
      return has(this.element, 'data.dataValues.imageUrl') && this.element.data.dataValues.imageUrl;
    },

    imageUrl() {
      if (!this.useFallbackImage && this.mappedImage) {
        return this.mappedImage;
      }

      return this.element.imageUrl;
    },

    getKey() {
      return String(
        this.element.fillStyle
        + this.element.backgroundColor
        + this.element.gradientDirection
        + this.element.gradientColorOne
        + this.element.gradientColorTwo
        + this.element.backgroundImage
        + this.backgroundImageUrl
        + this.imageUrl,
      );
    },
  },
};
