import Pusher from 'pusher-js';
import { get } from 'lodash-es';
import cache from './cache';

import { sleep } from './helpers';

const MaxRetries = 3;

function getDisplayToken() {
  const cachedScreen = cache('tgb_producer_screen') || {};
  let displayToken;
  try {
    displayToken = document.querySelector('meta[name=data-display-token]').getAttribute('content');
  } catch (err) {
    // Display token not found, attempt to take from cached screen
    displayToken = cachedScreen.display_token;
  }

  return displayToken;
}

/**
 * Get pusher channel name. Channel name is a concatenation of
 * accountId, workbookId, and sheetId (or sheetIndex if no sheetId)
 * @returns {String} - Channel name
 */
const getChannelName = (opts = {}) => {
  const {
    workbookId,
    sheetIndex,
    sheetId,
    accountId,
  } = opts;

  return `sheet_${accountId}_${workbookId}_${sheetId || sheetIndex}`;
};

export default class GoogleClient {
  constructor(props) {
    this.baseUrl = props.baseUrl;
    this.channels = new Map();
    this.pusher = null;
  }

  getClientInstance() {
    if (!this.pusher) {
      this.pusher = new Pusher(process.env.PUSHER_KEY, {
        cluster: process.env.PUSHER_CLUSTER,
        enabledTransports: ['ws', 'wss', 'xhr_streaming'],
      });
    }

    return this.pusher;
  }

  /**
   * Subscribe to workbook channel and bind sync handler
   * @param {Object} element - Graphic element containing sheet data
   * @param {String} accountId - Google account ID (@deprecated: can also be `owner` ID).
   * @param {Function} fn - Sync handler function
   */
  onSync(element, accountId, fn) {
    const { data } = element;
    if (!data) { return; }

    const { workbookId, sheet } = data;
    const key = `${workbookId}:${sheet}`;

    if (this.channels.has(key)) {
      // Channel already exists, so ignore request to create new one
      return;
    }

    const channel = this.getClientInstance().subscribe(
      getChannelName({ ...data, accountId }),
    );

    channel.bind('sync', fn);
    this.channels.set(key, channel);
  }

  /**
   * Unbind sync handlers and unsubscribe from workbook channel
   * @param {Object} element - Graphic element containing sheet data
   */
  off(element) {
    const { data } = element;
    if (!data) { return; }

    const { workbookId, sheet } = data;

    const key = `${workbookId}:${sheet}`;
    if (!this.channels.has(key)) {
      return;
    }

    const channel = this.channels.get(key);
    channel.unbind_all();
    this.getClientInstance().unsubscribe(workbookId);
    this.channels.delete(key);
  }

  /**
   * Fetch and return sheet data for the given element.
   * This functions as a wrapper around the public and private sheet functions.
   * @param {Object} element - Graphic element (i.e. layer)
   * @param {Number} [attempts] - Track number of API request attempts.
   * @returns {Array<Array<Object>>}
   */
  async fetchSheetData(element, opts = {}, attempts = 0) {
    const { data } = element;
    if (!data) { return []; }

    const { accountId } = data;

    const options = {
      ...data,
      key: element.id,
    };

    try {
      // If accountId is set, then use private sheet method
      const sheetData = await accountId
        ? this.fetchPrivateSheetData(options)
        : this.fetchPublicSheetData(options);

      return sheetData;
    } catch (err) {
      if (attempts <= MaxRetries) {
        await sleep(500 * (attempts + 1));
        return this.fetchSheetData(element, opts, attempts + 1);
      }

      // Too many attempts, so just throw
      throw err;
    }
  }

  /**
   * fetchPublicSheetData fetches public sheet data as xlsx, parses that data, and returns it
   * @param {string} opts.url - Public URL of the sheet to export
   * @param {bool} [opts.key] - Unique id to use for namespacing
   * @param {bool} [opts.sheet] - Name of the sheet to fetch data for
   * @returns {Array<Array<Object>>}
   */
  async fetchPublicSheetData(opts = {}) {
    const {
      url,
      key,
      sheet,
    } = opts;

    const query = {
      key,
      sheet,
      url,
    };

    const displayToken = getDisplayToken();

    const queryString = Object.keys(query)
      .filter((k) => query[k])
      .map((k) => `${k}=${encodeURIComponent(query[k])}`)
      .join('&');

    const resp = await fetch(`${this.baseUrl}/sheets/url?${queryString}`, {
      mode: 'cors',
      credentials: 'include',
      headers: {
        'tgb-display-token': displayToken,
      },
    });

    const data = await resp.json();
    return get(data, 'workbook.data', []);
  }

  /**
   * Fetch private sheet through tgb-google, and return aoa of values
   * @param {string|int} opts.accountId - ID of google account that owns the workbook
   * @param {string|int} opts.workbookId - ID of the workbook
   * @param {string} opts.sheetId - ID of the sheet (tab) in the workbook
   * @param {string} opts.sheetName - Name of the sheet (optional if sheetId is provided)
   * @returns {Array<Array<Object>>}
   */
  async fetchPrivateSheetData(opts = {}) {
    const {
      accountId,
      workbookId,
      sheetId,
    } = opts;

    const resp = await fetch(`${this.baseUrl}/sheets/${accountId}/${workbookId}/${sheetId}`, {
      mode: 'cors',
      credentials: 'include',
      headers: {
        'tgb-display-token': getDisplayToken(),
      },
    });

    const data = await resp.json();

    if (!data.values) {
      throw new Error('No values returned for sheet');
    }

    return get(data, 'values', []);
  }
}
