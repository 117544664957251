<template>
  <div class="post" v-bind:class="[post.network, dynamicClasses, noText, post.post_type, videoType, messageType, showAvatar]">

    <div
      v-if="theme.settings.showUsername && post.user_real_name"
      class="author"
      :class="authorStyle"
      v-bind:style="
        [theme.settings.colorTheme === 'custom' ? {'color' : theme.settings.usernameTextColor} : '',
        {'font-size': `${theme.settings.author.size}px`}]
      ">
      <div class="username" v-html="post.user_real_name"></div>
    </div>

    <div class="message-wrap">

      <div class="message-bubble" v-bind:style="[theme.settings.colorTheme === 'custom' ? {'background-color' : getBubbleColor} : '']">

        <template v-if="hasMedia">
          <div v-if="post.videos" class="media-module">
            <component v-bind:post="post" v-bind:is="videoPlayer" :theme="theme"></component>
            <img v-if="hasOverlay" v-bind:src="overlayUrl" alt="" class="overlay">
          </div>
          <div v-else class="media-module">
            <img v-bind:src="imageUrl" alt="" class="media photo">
          </div>
        </template>

        <div
          class="message"
          v-if="postHtml"
          :class="postMessageStyle"
          :style="{'font-size': `${postMessageFontSize}px`}">
          <p
            v-bind:style="[theme.settings.colorTheme === 'custom' ? {'color' : getTextColor} : '']"
            class="post-text"
            v-html="postHtml"
          >
          </p>
        </div>

      </div>

      <div v-if="theme.settings.showAvatars" class="author-bubble" v-bind:style="[theme.settings.colorTheme === 'custom' ? {'background-color' : getBubbleColor} : '']">
        <div v-if="avatarUrl" class="avatar" v-bind:style="{ backgroundImage: 'url(' + avatarUrl + ')' }">
          <img v-bind:src="avatarUrl" alt="" class="user-img">
        </div>
        <div v-else-if="messageType === 'outgoing'" v-bind:style="[theme.settings.colorTheme === 'custom' ? {'color' : getTextColor} : '']" class="initials">{{ theme.settings.outgoingInitials }}</div>
        <div v-else v-bind:style="[theme.settings.colorTheme === 'custom' ? {'color' : getTextColor} : '']" class="initials">{{ getRandomInitials }}</div>
      </div>

    </div>

    <div v-if="theme.settings.showTimestamp" class="post-time" v-bind:style="[theme.settings.colorTheme === 'custom' ? {'color' : theme.settings.timestampTextColor} : '']">
      <div class="timestamp">{{post.post_time|timestampToString}}</div>
    </div>

  </div>
</template>

<script>
import PostRenderMixins from '../../../../mixins/post-render-mixins';
import YoutubeVideoComponent from '../../../video/youtube/YouTubeVideo.vue';
import FacebookVideoComponent from '../../../video/facebook/FacebookVideo.vue';
import GenericVideoComponent from '../../../video/generic/GenericVideo.vue';

export default {
  components: {
    youtube: YoutubeVideoComponent,
    facebook: FacebookVideoComponent,
    generic: GenericVideoComponent,
  },
  mixins: [PostRenderMixins],
  props: ['post', 'theme'],
  computed: {
    getTextColor() {
      return this.post.service_meta && this.post.service_meta.is_response ? this.theme.settings.outgoingMessageTextColor : this.theme.settings.incomingMessageTextColor;
    },
    getBubbleColor() {
      return this.post.service_meta && this.post.service_meta.is_response ? this.theme.settings.outgoingMessageColor : this.theme.settings.incomingMessageColor;
    },
    messageType() {
      return this.post.service_meta && this.post.service_meta.is_response ? 'outgoing' : 'incoming';
    },
    showAvatar() {
      return {
        'show-avatar': this.theme.settings.showAvatars,
      };
    },
    getRandomInitials() {
      return String.fromCharCode(65 + Math.floor(Math.random() * 26)) + String.fromCharCode(65 + Math.floor(Math.random() * 26));
    },

    handleStyle() {
      return [
        this.theme.settings?.handle?.textStyles ?? [''],
        `font-${this.theme.settings?.handle?.fontFamily}`,
      ];
    },

    postMessageStyle() {
      return [
        this.theme.settings?.postMessage?.textStyles ?? [''],
        `font-${this.theme.settings?.postMessage?.fontFamily}`,
      ];
    },

    authorStyle() {
      return [
        this.theme.settings?.author?.textStyles ?? [''],
        `font-${this.theme.settings?.author?.fontFamily}`,
      ];
    },

    postMessageFontSize() {
      return this.theme.settings?.postMessage?.size ?? '';
    },
  },
};
</script>
