import EventBus from '../utils/event-bus';

export default {
  methods: {	
    advance() {
      if(!this.theme.settings.interactive) {
        EventBus.$emit('advance', 'next');
      }
    }
  }
};