/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
import currencySymbol from 'currency-symbol';

const namespaced = true;
const state = () => ({
  dataType: null,
  // we may have a data provider in the future
  // for name we are hardcoding values and user will change in producer
  selectedProduct: {},
  termsText: 'We may earn a commission if you purchase through this QR code. All prices are as is and are subject to change.',
});

const getters = {
  // using getters to format selected product because we may have a data provider in the future
  // we we do that we'll likely look up the product by id and so by using the getter,
  // there will be no need to change the component
  getProduct: (state) => state.selectedProduct,
  getFormattedProductPrice: (state, getters) => {
    if (!state.selectedProduct?.currentPrice) {
      return '';
    }
    return currencySymbol.symbol(getters.getProduct?.currency) + getters.getProduct.currentPrice;
  },
  getTermsText: (state) => state.termsText,

};

const actions = {
  setData({ commit }, payload) {
    const {
      type,
      selectedProduct = {},
    } = payload;
    commit('SET_SELECTED_PRODUCT', selectedProduct);
    commit('SET_DATA_TYPE', type);
  },
};

const mutations = {
  SET_SELECTED_PRODUCT(state, payload) {
    state.selectedProduct = payload;
  },
  SET_DATA_TYPE(state, payload) {
    state.dataType = payload;
  },
};

export default {
  namespaced,
  state,
  actions,
  getters,
  mutations,
};
