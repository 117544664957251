import Vue from 'vue';
import { get } from 'lodash-es';

import GoogleClient from '../../utils/google';

const gClient = new GoogleClient({
  baseUrl: process.env.GOOGLE_API,
});

const state = {
  // Key: workbookId
  // Value: matrix of sheet data
  bySheetId: {},

  // Track loading flags by sheet ID
  isLoading: {},
};

const actions = {
  /**
   * Fetch sheet data for given sheet.
   * If necessary, establish pusher client to listen for sync messages
   * @param {Boolean} opts.element - Sheet data element.
   * @param {Boolean} [opts.sync] - Whether to ignore current state and force a fetch anyway.
   * @returns {Promise<void>}
   */
  async getSheetData(context, opts = {}) {
    const { production } = context.rootState;
    const { owner } = production;

    const {
      element,
      sync = false,
    } = opts;

    const {
      accountId,
      type,
      sheet,
      workbookId,
    } = element.data;

    const data = get(context.state.bySheetId, [workbookId, sheet]);
    if (data && !sync) {
      // Data already exists, so don't bother loading
      return;
    }

    const isLoading = get(context.state.isLoading, [workbookId, sheet], false);
    if (isLoading) { return; }

    context.commit('SET_LOADING', {
      workbookId,
      sheet,
      value: true,
    });

    try {
      const value = await gClient.fetchSheetData(element);

      context.commit('SET_SHEET_DATA', {
        workbookId,
        sheet,
        value,
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Failed to fetch sheet data:', err);
    }

    // Setup pusher channel if necessary.
    // Will not rebind sync handler if one already exists.
    if (element.pollingEnabled && type === 'private-google-sheet') {
      // NOTE: If user is using the same sheet for multiple graphics,
      // and one is set to auto-sync and the other manual,
      // we could still end up automatically syncing both sheets in that case.
      gClient.onSync(element, accountId || owner, () => {
        context.dispatch('getSheetData', {
          element,
          sync: true,
        });
      });
    }

    context.commit('SET_LOADING', {
      workbookId,
      sheet,
      value: false,
    });
  },

  /**
   * Disconnect from the pusher channel, which will stop polling
   */
  stopPolling(context, { element }) {
    gClient.off(element);
  },
};

const mutations = {
  /* eslint-disable no-shadow, no-param-reassign */

  SET_LOADING(state, { workbookId, sheet, value }) {
    if (!state.isLoading[workbookId]) {
      Vue.set(state.isLoading, workbookId, {});
    }

    Vue.set(state.isLoading[workbookId], sheet, value);
  },

  SET_SHEET_DATA(state, { workbookId, sheet, value }) {
    if (!state.bySheetId[workbookId]) {
      Vue.set(state.bySheetId, workbookId, {});
    }

    Vue.set(state.bySheetId[workbookId], sheet, value);
  },
};

const getters = {};

export default {
  state,
  actions,
  mutations,
  getters,
};
