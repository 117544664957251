<template>
  <div
    class="element text"
    :style="correctedStyles"
    :class="[element.cssClasses, element.animationInStyle, element.animationOutStyle]"
  >
  <div
    class="text-wrap"
    :style="[
      getVisibility,
      getTextShadowStyles,
      overrideWrapStyles,
    ]"
    :class="[{'ticker': element.ticker }, 'vertical-' + element.verticalAlign]"
  >
    <transition
      appear
      type="animation"
      :mode="transitionMode"
      :enter-to-class="animationIn"
      :leave-to-class="animationOut"
      enter-class="enter"
      @before-enter="beforeEnter"
      @after-enter="afterEnter"
      @before-leave="beforeLeave"
      @after-leave="afterLeave"
    >
      <div
        :key="text"
        class="animated-element"
        :class="[getLoopingClass]"
        :style="[
          getFontStyles,
          getAnimationStyles,
          getTextBackgroundStyles,
          overrideAnimatedStyles,
        ]"
      >
          <text-anim
            :element="element"
            :text="text"
            @set-styles="setStyles"
          />
        </div>
      </transition>
  </div>
  </div>
</template>

<script>
import omit from 'lodash/omit';

import GraphicsMixins from '../../mixins/graphics-mixins';
import TextAnimComponent from '../text-anim/TextAnim.vue';

export default {
  name: 'TextEl',
  mixins: [GraphicsMixins],
  components: {
    'text-anim': TextAnimComponent,
  },

  data() {
    return {
      overrideRootStyles: {},
      overrideWrapStyles: {},
      overrideAnimatedStyles: {},
    };
  },

  computed: {
    /**
     * Returns the text value of the element.
     * If the element is mapped to a data field, pull from that instead.
     */
    text() {
      if (this.element.data) {
        if ('dataValue' in this.element.data) {
          const { dataValue } = this.element.data;
          return (dataValue && dataValue.text) || '';
        }

        if ('dataValues' in this.element.data) {
          const { dataValues } = this.element.data;
          // we can map things other than text, so text could be hardcoded or come from data
          // yet have some other prop, like color based things, mapped
          return (dataValues?.text !== undefined && dataValues?.text !== null)
            ? dataValues.text : this.element.text;
        }
      }

      return this.element.text;
    },

    correctedStyles() {
      return omit({
        ...this.getBasicStyles,
        ...this.overrideRootStyles,
        ...this.getTransformStyles,
      }, ['fontSize', 'letterSpacing']);
    },

    getTextShadowStyles() {
      if (!this.element.textShadow) {
        return {};
      }

      const {
        textShadowX,
        textShadowY,
        textShadowBlur,
      } = this.element;

      const textShadowColor = this.element.data?.dataValues?.textShadowColor ?? this.element.textShadowColor;

      return {
        textShadow: `${textShadowX}px ${textShadowY}px ${textShadowBlur}px ${textShadowColor}`,
      };
    },

    getTextBackgroundStyles() {
      if (!this.element.background) {
        return {};
      }

      const { gradientDirection, data } = this.element;
      const backgroundColor = data?.dataValues?.backgroundColor ?? this.element.backgroundColor;
      const gradientColorOne = data?.dataValues?.gradientColorOne ?? this.element.gradientColorOne;
      const gradientColorTwo = data?.dataValues?.gradientColorTwo ?? this.element.gradientColorTwo;

      if (this.element.fillStyle === 'gradient') {
        return {
          backgroundImage: `linear-gradient(${gradientDirection}deg, ${gradientColorOne}, ${gradientColorTwo})`,
        };
      }

      return {
        backgroundImage: 'none',
        backgroundColor,
      };
    },
  },

  methods: {
    setStyles(styles) {
      this.overrideRootStyles = styles.root ? styles.root : {};
      this.overrideWrapStyles = styles.wrap ? styles.wrap : {};
      this.overrideAnimatedStyles = styles.animated ? styles.animated : {};
    },
  },
};
</script>
