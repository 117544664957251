var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"element text",class:[_vm.element.cssClasses, _vm.element.animationInStyle, _vm.element.animationOutStyle],style:(_vm.correctedStyles)},[_c('div',{staticClass:"text-wrap",class:[{'ticker': _vm.element.ticker }, 'vertical-' + _vm.element.verticalAlign],style:([
    _vm.getVisibility,
    _vm.getTextShadowStyles,
    _vm.overrideWrapStyles,
  ])},[_c('transition',{attrs:{"appear":"","type":"animation","mode":_vm.transitionMode,"enter-to-class":_vm.animationIn,"leave-to-class":_vm.animationOut,"enter-class":"enter"},on:{"before-enter":_vm.beforeEnter,"after-enter":_vm.afterEnter,"before-leave":_vm.beforeLeave,"after-leave":_vm.afterLeave}},[_c('div',{key:_vm.text,staticClass:"animated-element",class:[_vm.getLoopingClass],style:([
        _vm.getFontStyles,
        _vm.getAnimationStyles,
        _vm.getTextBackgroundStyles,
        _vm.overrideAnimatedStyles,
      ])},[_c('text-anim',{attrs:{"element":_vm.element,"text":_vm.text},on:{"set-styles":_vm.setStyles}})],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }