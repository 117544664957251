<template>
  <div>
    <!--
      Using template_id as key is neccessary to keep elements on screen between groups.
      We could still see some warnings if the user has
      duplicates of the same graphic template in a group though.
    -->
    <graphic
      v-for="(graphic, index) in filteredGraphics"
      :key="graphic.key"

      :graphic="graphic"
      :item="items[index]"

      :snippets="snippets"
      :z-index="zIndexMap[graphic.timeline_id]"

      @before-enter="$emit('before-enter', graphic)"
      @after-enter="$emit('after-enter', graphic)"
      @before-leave="beforeLeave"
    />
  </div>
</template>

<script>
import Graphic from './Graphic.vue';

import sportradarModule from '../../../../../store/modules/sportradar';
import shoppableStoreModule from '../../../../../store/modules/shoppable';

import { calcLeaveDuration } from '../utils/transitions';
import { preloadImage, sleep } from '../utils/helpers';

export default {
  components: {
    Graphic,
  },

  props: {
    graphics: { type: Array, required: true },

    // Timeline Item
    item: { type: Object, required: false },

    // TODO: We should be able to inject snippets here instead of passing to child graphics
    snippets: { type: Array, default: () => ([]) },
  },

  emits: [
    // Transition events are bubbled up from Graphic component
    // and will contain the graphic that triggered the event (if needed)
    'before-enter',
    'after-enter',
    'before-leave',
    'after-leave',
  ],

  data() {
    return {
      graphicsToRender: this.graphics,

      // Graphics that are ready to show, whenever the current ones animate off
      queuedGraphics: [],

      // Reset every transition. Used to keep things layered properly during animations.
      zIndexMap: {},
    };
  },

  computed: {
    /**
    * Child timeline items, in the case of graphics groups
    * If root is not a group, just return an array with the root element in it.
    * @returns {Array<Object>} timeline items
    */
    items() {
      if (this.item?.layout === 'graphics') {
        return this.item.posts;
      }

      return this.item ? [this.item] : [];
    },

    transitionMode() {
      return this.$store.state.production.transitionMode;
    },

    /**
     * Filter out hidden graphics and reverse the order so the last graphic is on top.
     * If there are multiple graphics with the same template_id, append the timeline_id to the key.
     * This has negative effects on transitions between disparate groups, but is necessary for
     * keeping elements on screen between groups. Otherwise, items with the same template_id
     * will forcefully re-render whenever you toggle the visibility of another graphic in the group.
     *
     * TL;DR - The user really shouldn't be putting duplicates of the same graphic in a group.
     *
     * @returns {Object[]} - List of graphics to render
     */
    filteredGraphics() {
      const graphics = this.graphicsToRender
        .slice()
        .reverse()
        .filter((g) => !g.hidden)
        .map((g) => ({
          ...g,
          key: `${g.template_id}${this.graphicsToRender.filter((g2) => g2.template_id === g.template_id).length > 1 ? `:${g.timeline_id}` : ''}`,
        }));

      return graphics;
    },
  },

  watch: {
    graphics(newVal) {
      if (!newVal?.length) {
        // Immediately animate the current graphics out
        this.graphicsToRender = [];
        return;
      }

      this.transitionToGraphics(newVal).then(() => {
        this.buildLayerMap();
      });
    },
  },

  methods: {
    /**
    * Hold graphics while we preload async data and inject it.
    * Once the data is loaded, we'll let the exit transitions play,
    * then animate the new graphics in.
    * @param {Object[]} graphics - List of new graphics to show
    */
    async transitionToGraphics(graphics) {
      this.queuedGraphics = graphics;

      // Before updating `graphicsToRender`,
      // we need to ensure all async data is loaded for new graphics
      // TODO: We may also want to emit messages all the
      //       way back to Producer to keep the client up-to-date
      try {
        await this.loadGraphicsData(graphics);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('Error preloading graphic data:', err);
      }

      // If there's no graphics, or we just want transitions to overlap...
      if (!this.graphicsToRender?.length || this.transitionMode === 'overlap') {
        // ... then show the new graphics right away
        this.graphicsToRender = this.queuedGraphics;
        return;
      }

      // Rest of the function assumes transitionMode === 'out-in'

      // Get list of graphics that should remain on screen during transition
      const lockedGraphics = this.graphicsToRender
        .filter((g) => !!this.queuedGraphics.some((g2) => (
          g.template_id === g2.template_id
          && !g2.hidden
        )));

      // Once async data is loaded and injected into queuedGraphics, we remove the current graphics
      // Duration calculation ignores any graphics that haven't changed
      const duration = Math.max(
        ...(
          this.graphicsToRender
            .filter((g) => !lockedGraphics.some((g2) => g.timeline_id === g2.timeline_id))
            .map((g) => calcLeaveDuration(g))
        ),
      );

      // Switch over to locked graphics ONLY
      this.graphicsToRender = lockedGraphics;

      // ... and we wait for the unlocked graphics to animate off the screen
      await sleep(duration);

      this.graphicsToRender = this.queuedGraphics;
    },

    /**
    * Fetch any async data needed to render graphics,
    * and inject that data on the graphics to speed up initial render.
    * @param {Object[]} graphics - List of graphics to fetch data for (if necessary)
    */
    async loadGraphicsData(graphics) {
      // Recursively loop over graphics' layers:
      // - Prefetch any google sheet data and attach to graphic layer(s)
      // - Prefetch SportRadar data and attach to graphic layer(s)
      // - Load graphic images for fields: imageUrl, backgroundImage, backgroundImageUrl
      await Promise.all(
        graphics.map((graphic) => this.loadGraphicData(graphic)),
      );
    },

    /**
    * Fetch any async data needed for specific graphic.
    * Will recursively loop over nested layers looking for any graphics that need async data.
    */
    async loadGraphicData(graphic) {
      if (!graphic?.elements?.length) {
        return;
      }

      const { elements } = graphic;

      await Promise.all(
        elements.map(async (el) => {
          const element = el;
          const promises = [];
          switch (element.type) {
            case 'sheet-data': {
              promises.push(this.$store.dispatch('getSheetData', { element }));
              break;
            }

            case 'sportradar': {
              const srStateModule = `${element.id}_${graphic.timeline_id}`;

              if (!this.$store.hasModule(srStateModule)) {
                this.$store.registerModule(srStateModule, sportradarModule);
              }

              promises.push(this.$store.dispatch(`${srStateModule}/getData`, {
                graphicOwner: graphic.owner,
                graphicElements: element.elements,
                ...element.data,
              }));

              break;
            }

            case 'shoppable': {
              const { data } = element;

              if (!data) {
                // skip setting up store if no data is provided
                break;
              }

              const shoppableStoreNamespace = `${element.id}_${graphic.timeline_id}`;

              if (!this.$store.hasModule(shoppableStoreNamespace)) {
                this.$store.registerModule(shoppableStoreNamespace, shoppableStoreModule);
              }

              promises.push(this.$store.dispatch(`${shoppableStoreNamespace}/setData`, data));

              break;
            }

            default: break;
          }

          promises.push(this.preloadImages(element));

          if (element.elements?.length) {
            promises.push(this.loadGraphicData(element));
          }

          await Promise.all(promises);
        }),
      );
    },

    /**
    * Grab current elements and assign z-indices to zIndexMap according to layer order.
    * This map helps to keep things in the right spot during transitions
    */
    buildLayerMap() {
      // Build zIndex map to prevent layering weirdness during transition
      this.zIndexMap = this.graphicsToRender.reduce((memo, graphic, index) => ({
        ...memo,
        [graphic.timeline_id]: this.graphicsToRender.length - index - 1, // Reverse order
      }), {});
    },

    /**
    * Preload any static images found on the given element.
    * @param {Object} element - Graphic layer
    */
    async preloadImages(element) {
      const {
        imageUrl,
        backgroundImage,
        backgroundImageUrl,
      } = element;

      await Promise.all([
        imageUrl ? preloadImage(imageUrl) : null,
        (backgroundImage && backgroundImageUrl) ? preloadImage(backgroundImageUrl) : null,
      ]);
    },

    beforeLeave(duration) {
      // Triggered once for every graphic in a group
      this.$emit('before-leave');

      setTimeout(() => {
        // Triggered once for every graphic in a group
        // It's up to the parent component to handle simultaneous events
        this.$emit('after-leave');
      }, duration);
    },
  },
};
</script>
