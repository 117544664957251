export default {
  youtubeAPIReady: false,

  loadYoutubeApi() {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    $('body').append(tag);
    window.onYouTubeIframeAPIReady = () => {
      this.youtubeAPIReady = true;
    };
  },

  youtubeReadyPromise() {
    const deferred = $.Deferred();
    if (!this.youtubeAPIReady) {
      window.onYouTubeIframeAPIReady = () => {
        deferred.resolve();
        this.youtubeAPIReady = true;
      };
    } else {
      deferred.resolve();
    }
    return deferred.promise();
  },
};
