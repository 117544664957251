<template>
  <div class="youtube-player" v-bind:id="post.post_id"></div>
</template>

<script>
/* global YT */

export default {
  props: ['post'],
  data() {
    return {
      player: null,
    };
  },
  mounted() {
    const $player = $(this.$el);
    this.player = this.loadYouTubeVideo($player);
  },
  watch: {
    videoPlaying(newValue) {
      if (!this.player) {
        return;
      }

      if (newValue) {
        this.playYoutubeVideo();
      } else {
        this.pauseYoutubeVideo();
      }
    },

    muted(newValue) {
      if (!this.player) {
        return;
      }

      if (newValue) {
        this.muteYoutubeVideo();
      } else {
        this.unmuteYoutubeVideo();
      }
    },
  },
  computed: {
    videoPlaying() {
      return this.$store.getters.videoPlaying;
    },
    muted() {
      return this.$store.getters.isMuted;
    },
    playing() {
      return this.$store.getters.isPlaying;
    },
  },
  beforeDestroy() {
    this.player.destroy();
  },
  methods: {
    loadYouTubeVideo($player) {
      return new YT.Player($player.get(0), {
        videoId: this.post.post_id,
        playerVars: {
          autohide: 1,
          autoplay: 0,
          playlist: this.post.post_id,
          controls: 0,
          enablejsapi: 1,
          loop: 0,
          playsinline: 1,
          modestbranding: 1,
          showinfo: 0,
          rel: 0,
        },
        events: {
          onReady: (e) => {
            if (this.muted) {
              e.target.mute();
            }

            const duration = this.player.getDuration() * 1e3;

            // TODO: We might want to avoid setting play duration if this is in a tagboard
            this.$store.dispatch('setPlayDuration', duration);

            this.$store.dispatch('setVideoDuration', duration);

            if (this.videoPlaying) { // if we are supposed to start playing
              this.playYoutubeVideo();
            }
          },

          onStateChange: (event) => {
            if (event.data === YT.PlayerState.ENDED) {
              if (!this.playing) {
                this.player.seekTo(0);
              } else {
                this.$emit('finished');
              }
            }
          },

          onError() {
            if (this.playing) {
              this.$emit('finished');
            }
          },
        },
      });
    },

    playYoutubeVideo() {
      this.player?.playVideo();
    },

    stopYoutubeVideo() {
      this.player?.stopVideo();
    },

    pauseYoutubeVideo() {
      this.player?.pauseVideo();
    },

    muteYoutubeVideo() {
      this.player?.mute();
    },

    unmuteYoutubeVideo() {
      this.player?.unMute();
    },
  },
};
</script>
