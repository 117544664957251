const FontFaceObserver = require('fontfaceobserver');

export default {
  createFontLinks(fonts) {
    let css = '';

    fonts.forEach((font) => {
      const fontFace = `
        @font-face {
          font-family: '${font.fontId}';
          src: url('${font.fontLink}');
          font-weight: bold;
          font-style: normal;
          font-display: block;
        }

      `;

      css += fontFace;
    });

    const styleEl = document.createElement('style');
    styleEl.id = 'customFonts';
    styleEl.innerHTML = css;

    document.head.appendChild(styleEl);

    Promise.all(
      fonts.map((font) => {
        try {
          const observer = new FontFaceObserver(font.fontId);
          return observer.load(null, 15e3);
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log('Error getting font file', err);
        }
      }),
    );
  },
};
