const TimerInterval = 100;

export default class Timer {
  constructor(opts = {}) {
    this.t = 0;

    this.meta = opts.meta || {};
    this.duration = opts.duration;
    this.onDone = opts.onDone;

    if (this.duration) {
      this.start();
    }
  }

  start() {
    this.isRunning = true;

    let prevTime = Date.now();

    this.interval = setInterval(() => {
      // Instead of incrementing by TimerInterval,
      // we use the real time elapsed, since background tabs might throttle timeouts
      this.t += Date.now() - prevTime;
      prevTime = Date.now();

      if (this.t >= this.duration && !this.isDone) {
        this.isDone = true;
        this.pause();
        this.onDone();
      }
    }, TimerInterval);
  }

  pause() {
    this.isRunning = false;
    clearInterval(this.interval);
  }

  getTime() {
    return this.t;
  }

  setDuration(d) {
    this.duration = d;
  }

  reset() {
    this.t = 0;
  }
}
