<template>
  <div :class="getThemeClass" class="theme-wrapper">
    <div
      class="fullscreen-wrapper"
      :style="{ 'background-color': theme.settings.backgroundColor }"
      :class="[
        { 'crop-images': theme.settings.cropImages },
        {
          'show-blurred-images':
            !theme.settings.cropImages && theme.settings.showBlurredImages,
        },
        { 'hide-media-captions': !theme.settings.showMediaCaptions },
        'cta-template-' + theme.settings.ctaTemplate,
        'font-' + theme.settings.font,
        'cta-social-icon-style-' + theme.settings.ctaSocialIconStyle,
        { 'no-title': !theme.settings.showCtaText },
        { 'no-social-icons': !theme.settings.showCtaSocialIcons },
        { 'safe-area': safeArea },
      ]">
      <div
        v-if="safeArea"
        :class="{ 'darkened-background': theme.settings.darkenBackground }"
        class="backdrop"
        :style="[backgroundStyle]">
        <video
          v-if="theme.settings.backgroundVideo"
          :src="theme.settings.backgroundVideo"
          class="background-video"
          width="auto"
          autoplay
          loop
          muted></video>
        <div class="background-overlay"></div>
      </div>
      <div class="display-wrapper" :style="safeArea ? wrapperStyles : {}">
        <div
          v-if="
            !safeArea &&
            (theme.settings.backgroundImage || theme.settings.backgroundVideo)
          "
          :class="{ 'darkened-background': theme.settings.darkenBackground }"
          class="backdrop"
          :style="[backgroundStyle]">
          <video
            v-if="theme.settings.backgroundVideo"
            :src="theme.settings.backgroundVideo"
            class="background-video"
            width="auto"
            autoplay
            loop
            muted></video>
          <div class="background-overlay"></div>
        </div>

        <header-component :theme="theme"></header-component>

        <div
          class="posts"
          :class="theme.settings.animation"
          :data-animation="theme.settings.animation">
          <template v-if="post">
            <transition
              appear
              :enter-active-class="enterAnim"
              :leave-active-class="exitAnim"
              :mode="mode">
              <component
                v-if="currentPost"
                :is="getPostType(currentPost)"
                :key="getKey(currentPost)"
                :theme="theme"
                :panel="currentPost"
                :post="currentPost"></component>
            </transition>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CinematicPostComponent from './CinematicPost.vue';
import PostPanelComponent from '../../post-panel/PostPanel.vue';
import HeaderComponent from '../../../header/v1.5/Header.vue';
import AnimationMixins from '../../../../mixins/animation-mixins';
import LayoutMixins from '../../../../mixins/layout-mixins';

export default {
  props: ['posts', 'theme', 'post'],
  mixins: [LayoutMixins, AnimationMixins],

  components: {
    'header-component': HeaderComponent,
    post: CinematicPostComponent,
    panel: PostPanelComponent,
  },
  computed: {
    safeArea() {
      return this.theme.settings.safeArea;
    },
    wrapperStyles() {
      return {
        'max-width': this.safeAreaMaxWidth,
        'max-height': this.safeAreaMaxHeight,
      };
    },
    safeAreaMaxWidth() {
      const { safeArea, safeWidth } = this.theme.settings;

      return safeArea && safeWidth && safeWidth > 0
        ? `${safeWidth}px`
        : '100%';
    },
    safeAreaMaxHeight() {
      const { safeArea, safeHeight } = this.theme.settings;

      return safeArea && safeHeight && safeHeight > 0
        ? `${safeHeight}px`
        : '100%';
    },
    backgroundStyle() {
      if (this.theme.settings.backgroundImage !== '') {
        return {
          backgroundImage: `url('${this.theme.settings.backgroundImage}')`,
        };
      }
      return {};
    },
  },
};
</script>
