<template>
  <div v-bind:class="getThemeClass" class="theme-wrapper">
    <div class="fullscreen-wrapper" v-bind:style="{'background-color' : theme.settings.backgroundColor}">
      <div class="display-wrapper">
        <div v-if="theme.settings.backgroundImage || theme.settings.backgroundVideo" v-bind:class="{ 'darkened-background': theme.settings.darkenBackground }" class="backdrop" v-bind:style="[theme.settings.backgroundImage !== '' ? { backgroundImage: 'url(' + theme.settings.backgroundImage + ')' } : '']">
          <video v-if="theme.settings.backgroundVideo" v-bind:src="theme.settings.backgroundVideo" class="background-video" width="auto" autoplay loop muted></video>
          <div class="background-overlay"></div>
        </div>

        <div class="posts" v-bind:class="theme.settings.animation">
          <post v-if="currentPost" :key="getKey(currentPost)" :theme="theme" :post="currentPost"></post>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '../../../../utils/event-bus';
import StoriesPostComponent from './StoriesPost.vue';
import HeaderComponent from '../../../header/v1.5/Header.vue';
import AnimationMixins from '../../../../mixins/animation-mixins';
import LayoutMixins from '../../../../mixins/layout-mixins';

export default {
  props: ['posts', 'post', 'theme'],
  components: {
    'header-component': HeaderComponent,
    post: StoriesPostComponent,
  },
  mixins: [
    LayoutMixins,
    AnimationMixins,
  ],
  methods: {
    setPost() {
      if (!this.post) {
        return;
      }

      const postNetwork = this.post.network;
      const themeNetwork = this.theme.settings.network;

      // eslint-disable-next-line max-len
      if ((themeNetwork == 'instagram' && postNetwork != 'instagram-stories') || (themeNetwork == 'snapchat' && postNetwork != 'snapchat')) {
        EventBus.$emit('advance', 'next');
      } else {
        this.currentPost = this.post;
        if ((this.isGif || !this.isVideo) && !this.theme.settings.interactive) {
          EventBus.$emit('resetTimer');
          EventBus.$emit('startTimer', this.theme.settings.displayTime);
        }
      }
    },
  },
};
</script>
