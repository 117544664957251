<template>
  <transition name="modal">
    <div class="modal-mask" v-bind:class="classes" v-on:click.stop="$emit('close')">
      <slot name="modal-body"></slot>
    </div>
  </transition>
</template>

<script>
export default {
  props: ['classes'],
};
</script>
