export default {
  computed: {
    playing() {
      return this.$store.getters.isPlaying;
    },
    enterAnim() {
      return this.$store.getters.getEnter;
    },
    exitAnim() {
      return this.$store.getters.getExit;
    },
    mode() {
      return this.$store.getters.getMode;
    },
  },
};
