import Fonts from '../../services/custom-fonts';

const state = {
  customFonts: [],
};

const actions = {
  async getFonts(context, owner) {
    const { theme, production } = context.rootState;

    const displayToken = (
      theme?.displayToken
      || production?.display_token
    );

    const data = await Fonts.getFonts(owner, displayToken);
    context.commit('GET_CUSTOM_FONTS', data.result.assets);
  },
};

const mutations = {
  /* eslint-disable no-param-reassign, no-shadow */

  GET_CUSTOM_FONTS(state, payload) {
    state.customFonts = payload;
  },
};

export default {
  state,
  actions,
  mutations,
};
