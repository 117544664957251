import { request } from '../utils/request';
import { SCREEN_BASE } from './services';

export default {
  getScreen(screenId) {
    return request({
      type: 'GET',
      url: `${SCREEN_BASE}/screen/${screenId}`,
    });
  },
};
