/**
 * Store or retrieve a cached value by key.
 * @param {String} key - Cache key.
 * @param {*} [value] - Value to store in cache. If not provided, the value from cache is returned instead.
 * @returns {*}
 */
export default (key, value) => {
  // Dumb fix, just don't let cache work unless using code screen
  if (window.location.pathname !== '/') { return null; }

  if (typeof value !== 'undefined') {
    // Set Value
    localStorage.setItem(key, JSON.stringify(value));
    return value;
  }

  // Get Value
  // Attempt to parse as object first.
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (err) { /* noop */ }

  // If failed, then return without parsing JSON.
  return localStorage.getItem(key);
};
