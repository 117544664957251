/**
 * `posts` module is used for fetching social media posts
 * and keeping them stored for future use.
 *
 * Posts are loaded either for a full tagboard (a la featured posts)
 * or by ID (in the case of individual posts added to a theme/graphic)
 */

import Vue from 'vue';

import { preloadPosts } from '../../utils/post-utils';
import Posts from '../../services/posts';

const state = {
  // Map of social posts with `post_id` as the key
  lookupTable: {},

  // List of social posts by tagboard, where the key is the tagboard ID
  byTagboard: {},

  // String<Number> map of post durations
  postDurations: {},
};

const actions = {
  /**
   * Get list of featured social posts for the given tagboard,
   * then store them in various lookup tables.
   * TODO: Implement pagination using before/since
   */
  async getFeaturedPosts(context, payload) {
    const { tagboardId } = payload;

    // We'll just get fresh tagboard posts and ignore what's already in state,
    // that we avoid running into the issue of the tagboard never updating for
    // users that don't reload the page.
    const { posts } = await Posts.getFeaturedPosts(tagboardId);

    // TODO: We do something weird where we fetch removed posts and filter those out of the first list.
    // That should be handled by the backend, but we might need it here.

    // Store with tagboard AND in the lookup table so we can re-use if necessary
    context.commit('ADD_TO_LOOKUP_TABLE', posts);
    context.commit('ADD_POSTS_TO_TAGBOARD', { tagboardId, posts });
    return posts;
  },

  /**
   * Get list of specific social posts by ID(s),
   * then store them in the lookup table.
   */
  async getManyPosts(context, payload) {
    const { owner } = context.rootState.production;
    const { posts } = payload;

    // Exclude posts already in the lookup table.
    // Also simplify data or the API will get mad and yell at us.
    const filteredPosts = posts
      .filter((post) => !(post.post_id in context.state.lookupTable))
      .map((post) => ({
        post_id: post.post_id,
        network: post.network,
      }));

    // Don't bother loading anything if we already have all the posts we need
    if (!filteredPosts.length) {
      return [];
    }

    const { results } = await Posts.getManyPosts(owner, filteredPosts);
    context.commit('ADD_TO_LOOKUP_TABLE', results);
    return results;
  },

  /**
   * Set post duration for social video post
   * @param {Object} context
   * @param {String} payload.postId
   * @param {Number} payload.duration
   */
  setPostDuration(context, payload) {
    context.commit('SET_POST_DURATION', payload);
  },

  /**
   * Check if live element is a graphic that contains social posts,
   * and preload those social posts and put them in the store.
   * Could probably be expanded to include themes,
   * if those aren't loaded in an iframe in the future.
   * @param {Object} context
   * @param {Object} payload - Live layout
   */
  async getLiveSocialPosts(context, payload) {
    if (!payload) { return null; }

    const getSocialPosts = (graphic) => {
      const [id] = graphic.socialData || [];
      const { posts } = graphic.fields[id] || {};
      return posts || [];
    };

    const loadPosts = async (posts) => {
      if (!posts.length) { return []; }
      const [post] = posts;

      if (post.type === 'tagboard') {
        // Load featured posts for the tagboard
        return context.dispatch('getFeaturedPosts', {
          tagboardId: post._id,
        });
      }

      // Load individual posts
      return context.dispatch('getManyPosts', { posts });
    };

    let posts = null;

    switch (payload.layout) {
      case 'graphic': {
        // Get posts from current graphic
        posts = await loadPosts(
          getSocialPosts(payload),
        );

        break;
      }

      case 'graphics': {
        // Get social posts from ALL graphics in the group
        posts = await Promise.all(
          payload.posts.map((graphic) => (
            loadPosts(
              getSocialPosts(graphic),
            )
          )),
        );

        break;
      }

      default: {
        // Unsupported layout type (e.g. theme, panel)
        return null;
      }
    }

    // Preload post images (not videos for now)
    await preloadPosts(posts);

    return posts;
  },
};

const mutations = {
  /* eslint-disable no-shadow, no-param-reassign */
  ADD_TO_LOOKUP_TABLE(state, posts) {
    state.lookupTable = {
      ...state.lookupTable,
      ...posts.reduce((memo, post) => ({
        ...memo,
        [post.post_id]: post,
      }), {}),
    };
  },

  ADD_POSTS_TO_TAGBOARD(state, payload) {
    const { tagboardId, posts } = payload;
    state.byTagboard[tagboardId] = posts;
  },

  SET_POST_DURATION(state, payload) {
    const { postId, duration } = payload;
    Vue.set(state.postDurations, postId, duration);
  },
};

const getters = {
  /**
   * Get list of posts by tagboard ID out of state
   */
  getPostsByTagboard: (state) => (tagboardId) => state.byTagboard[tagboardId] || [],

  /**
   * Return post from state by ID
   */
  getPostById: (state) => (id) => state.lookupTable[id],
};

export default {
  state,
  actions,
  mutations,
  getters,
};
