const SHORTENER_URL = process.env.TAGBOARD_SHORT_URLS_URL;
const lambdaHeader = process.env.LAMBDA_AUTH_HEADER;
const lambdaHeaderValue = process.env.LAMBDA_AUTH_HEADER_VALUE;

/**
 * POST {userId, productionId, url}
 * returns { data: { shortUrl: ''}}
 * @type {{getShortURL: (function(*, *): *)}}
 */
export default {
  /**
   * getShortURL sends a url + opts to the shortener service
   * and receives back a unique short URL mapped to the long URL
   * @param {string} url - Full URL to shorten
   * @param {Object} opts - Options used to ensure the short URL is unique
   * @param {Object} opts.user
   * @param {Object} opts.production
   * @param {Object} opts.item - Timeline item
   * @param {Object} opts.element - Timeline item element (e.g. qrcode element inside a graphic)
   * @returns {Promise<Object>}
   */
  async getShortURL(url, opts) {
    const {
      user: userId,
      production,
      item,
      element,
    } = opts;

    const body = {
      url,
      userId,

      productionId: production.id || production._id,
      productionTitle: production.title,

      // Title of the timeline item? Necessary?
      timelineTitle: item.title,
      timelineId: item.timeline_id,

      // Catch-all for different "id" formats
      elementId: element?.id || element?._id,
    };

    const resp = await fetch(`${SHORTENER_URL}/url/short`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        [lambdaHeader]: lambdaHeaderValue,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    if (!resp.ok) {
      const err = await resp.json();
      throw new Error(err?.message || err || 'Failed to shorten URL');
    }

    return resp.json();
  },
};
