import { omit } from 'lodash-es';

import cache from './cache';

const getMeta = (name) => {
  const el = document.querySelector(`meta[name=${name}]`);
  if (!el) { return ''; }
  return el.getAttribute('content');
};

const getDefaultRequestOpts = () => {
  const cachedScreen = cache('tgb_producer_screen') || {};

  const userToken = getMeta('data-user-token');
  const displayToken = getMeta('data-display-token') || cachedScreen.display_token;

  const headers = {
    'Content-Type': 'application/json',
  };

  if (userToken) {
    headers['tgb-token'] = userToken;
  }

  if (displayToken) {
    headers['tgb-display-token'] = displayToken;
  }

  return {
    headers,
    credentials: 'include',
    mode: 'cors',
  };
};

/**
 * Converts object to querystring, filtering out falsey fields
 * @param {Object} obj
 * @returns {String}
 */
const buildQueryString = (obj = {}) => (
  Object.entries(obj).reduce((memo, [key, value]) => {
    if (!value) {
      return memo;
    }

    return `${memo}&${key}=${encodeURIComponent(value)}`;
  }, '?')
);

const requestAuth = () => {
  const cachedScreen = cache('tgb_producer_screen') || {};

  const userToken = getMeta('data-user-token');
  const displayToken = getMeta('data-display-token') || cachedScreen.display_token;

  if (userToken) {
    return {
      xhrFields: {
        withCredentials: true,
      },
      headers: {
        'tgb-token': userToken,
      },
    };
  }

  if (displayToken) {
    return {
      xhrFields: {
        withCredentials: true,
      },
      headers: {
        'tgb-display-token': displayToken,
      },
    };
  }

  return null;
};

const request = function _tgbRequest(options) {
  if (options === null) {
    throw new Error('Missing options');
  }

  // TODO: Ew jQuery
  return $.ajax({
    ...options,
    ...(requestAuth() || {}),
  });
};

/**
 * Make a request with default options using Fetch API.
 * Default options can be overridden.
 * For more info about options:
 * https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
 *
 * @param {String} url - Endpoint to fetch
 * @param {Object} [opts] - Additional options to include with request
 * @param {Object} [opts.query] - Object containing values to append as query string.
 * @returns {Object} JSON response body
 */
const requestFetch = async (url, opts = {}) => {
  if (opts.query) {
    // eslint-disable-next-line no-param-reassign
    url += buildQueryString(opts.query);
  }

  const resp = await fetch(url, {
    ...getDefaultRequestOpts(),
    ...omit(opts, ['query']),
  });

  return resp.json();
};

export {
  getDefaultRequestOpts,
  buildQueryString,
  request,
  requestFetch as fetch,
};
