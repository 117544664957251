// Dynamically initialize a FB embedded video added to the page,
// pass a jQuery object of the element containing the facebook embed div
var addEmbeddedVideo = function _addEmbeddedVideo($media, force){
  // Only do this if we haven't added a player for this Video
  if (FB && (force || !$media.find('.fb-video').data('fbPlayer'))) {
    FB.XFBML.parse($media[0]);
  }
},

// This generates the html string for the video divs that facebook uses to load it's embedded video player
generateVideoDiv = function _generateVideoDiv($media, postData, options) {
  var pid = postData.post_id,
  opts = options || {},
  popUpMedia,
  code;

  if($media.hasClass('popup-media')) {
    popUpMedia = 'popup-media';
    code = '<img class="vid-size" src="' + postData.videos[0].p + '" alt=""><div class="vid-container"><div id="fb-video-' + pid + popUpMedia +'" class="fb-video" data-href="https://www.facebook.com/video.php?v=' + pid + '" data-autoplay="true" ';
  } else {
    code = '<img class="vid-size" src="' + postData.videos[0].p + '" alt=""><div class="vid-container"><div id="fb-video-' + pid + '" class="fb-video" data-href="https://www.facebook.com/video.php?v=' + pid + '" data-autoplay="true" ';
  }

  // Makes the video container tall, and centers it in it's container to hide the post details from
  // showing on this video in successive playthroughs
  if (opts.overflow) {
    opts.height = 5000;
    code += ' data-overflow="true" ';
  }

  if (opts.height) {
    code += ' data-height="' + opts.height + '" ';
  }

  return code + ' data-allowfullscreen="false"></div></div>';	// Add footer and return
},

isEmbeddedVideo = function _isEmbeddedVideo($post) {
  return $post.find('.fb-video').length ? true : false;
},


playVideo = function _playVideo($post, fromBeginning) {
  var player = $post.find('.fb-video').data('fbPlayer');

  if (player) {
    if (fromBeginning) {
      player.seek(0);
    }
    player.play();
    mute ? player.mute() : player.unmute();
  }
},

stopVideo = function _stopVideo($post) {
  var player = $post.find('.fb-video').data('fbPlayer');

  if (player) {
    player.pause();
  }
},

setMute = function _setMute($post, muteValue) {
  var player = $post.find('.fb-video').data('fbPlayer');

  if (player) {
    muteValue ? player.mute() : player.unmute();
  }
},

// Load Facebook SDK for FB video playback
init = function _initFB() {
  if ($('#fb-root').length) {
    window.fbAsyncInit = function() {
      FB.init({
        appId      : '232298016899680',
        xfbml      : true,
        version    : 'v2.9'
      });
    };

    // Load the facebook SDK
    (function(d, s, id){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s); js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }
};

// External interface
export default {
  init,
  addEmbeddedVideo,
  playVideo,
  stopVideo,
  setMute,
  isEmbeddedVideo,
};
