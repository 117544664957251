<template>
  <div
    ref="anim-wrapper"
    class="anim-wrapper"
  >
    <!-- eslint-disable vue/no-v-html -->
    <div
      ref="text"
      class="message"
      :style="textStyles"
      v-html="text"
    />
  </div>
</template>

<script>
import GraphicsMixins from '../../mixins/graphics-mixins';

export default {
  mixins: [GraphicsMixins],

  props: {
    element: { type: Object, required: true },
    text: { type: String, required: true },
  },

  data() {
    return {
      updating: false,
      fineTuneIterations: 0, // The number of times that fineTine() has run. Putting here so that we can use Vue inspector to debug iterations more easily.
      fineTuneMaxIterations: 1500, // An upper limit to prevent infinite recursion. Should be larger than font size upper limit.
    };
  },

  computed: {
    textStyles() {
      return {
        textDecoration: this.element.styles.textDecoration,
        ...((this.needsNoWrap && !this.isSocial) && { whiteSpace: 'nowrap' }),
        ...((this.isSocial && this.element.network !== 'reddit') && { whiteSpace: 'pre-wrap' }),
      };
    },

    isSocial() {
      return this.element.type === 'social-text';
    },

    needsNoWrap() {
      return !(this.element.styles.textFlowOption === 2);
    },

    textEl() {
      return this.$refs.text;
    },
  },

  mounted() {
    document.fonts.onloadingdone = () => this.resizeText();

    setTimeout(() => {
      this.resizeText();
    });
  },

  created() {
    this.modernize();
  },

  updated() {
    // Done in updated because "Update live" in producer doesn't re-trigger created
    this.modernize();
  },

  methods: {
    modernize() {
      // "modernize" v1 data
      if (this.element.version === 'v1') {
        this.$set(this.element.styles, 'textFlowOption', 2); // Text-Wrap
        this.$set(this.element.styles, 'textBoxOption', 1);
        if (this.element.fitText) {
          this.$set(this.element.styles, 'maxSizeText', this.element.styles.fontSize);
        } else {
          this.$set(this.element.styles, 'maxSizeText', 0);
        }
      }
    },

    setParentStyles(styles) {
      this.$emit('set-styles', styles);
    },

    getTextWidth() {
      return [...this.textEl.childNodes].reduce((acc, child) => {
        if (child.offsetWidth > acc.width) {
          acc.width = child.offsetWidth;
          acc.length = child.textContent.length;
        }
        return acc;
      }, { width: 0, length: 0 });
    },

    getTextHeight() {
      return [...this.textEl.childNodes].reduce((acc, child) => acc + child.offsetHeight, 0);
    },

    setFontSize(fontSize) {
      this.$set(this.element.styles, 'fontSize', fontSize);
      this.textEl.style.fontSize = `${fontSize}px`;
    },

    scaleText() {
      if (!this.textEl) {
        return;
      }

      // adapted from here: https://github.com/BrOrlandi/big-text.js/blob/master/big-text.js
      this.fineTuneIterations = 0; // ensure this is reset on every run of scaleText()
      const textWrapEnabled = this.element.styles.textFlowOption === 2;
      const fontSizeFactor = 0.8;

      // set styles to get sizes of just text
      this.textEl.style.fontSize = `${1000 * fontSizeFactor}px`;
      const children = this.textEl.childNodes;
      children.forEach((ch) => {
        const child = ch;
        child.style = child.style || {};

        if (child.nodeName === '#text') {
          if (!child.textContent.trim()) {
            this.textEl.removeChild(child);
            return;
          }

          const newDiv = document.createElement('div');
          this.textEl.insertBefore(newDiv, child);
          newDiv.innerText = child.textContent;
          this.textEl.removeChild(child);
          newDiv.style.alignSelf = 'flex-start';
          return;
        }

        child.style.alignSelf = 'flex-start'; // eslint-disable-line no-param-reassign
      });

      const { width, height } = this.element.styles;
      const childData = this.getTextWidth();

      // total children height
      const childrenHeight = this.getTextHeight();
      const widthFactor = (width - (childData.length * this.element.styles.letterSpacing)) / childData.width;
      const heightFactor = height / childrenHeight;
      let lineHeight;

      if (textWrapEnabled) {
        // DO things differently because of wrapping
        const hyp1 = Math.sqrt(width ** 2 + height ** 2);
        const hyp2 = Math.sqrt(childData.width ** 2 + childrenHeight ** 2);

        const hypFactor = hyp1 / hyp2;

        lineHeight = Math.floor(hypFactor * 1000);
      } else if (heightFactor > widthFactor) {
        lineHeight = Math.floor(widthFactor * 1000);
      } else {
        lineHeight = Math.floor(heightFactor * 1000);
      }

      const fontSize = Math.floor((lineHeight * fontSizeFactor));
      this.setFontSize(fontSize);
      const { maxSizeText } = this.element.styles;

      // Sometimes the width is slightly off but close so this recursively calls until it is correct
      const fineTune = (lastCheck = false) => {
        this.$nextTick(() => {
          const textWidth = this.getTextWidth().width;
          const textHeight = this.getTextHeight();
          const tooWide = textWidth > width;
          const tooTall = textHeight > height;

          // bigger didn't fit, go back down and be done
          if (lastCheck && (tooWide || tooTall)) {
            this.setFontSize(this.element.styles.fontSize - 1);
            children.forEach((child) => {
              if (child.style && child.style.removeProperty) {
                child.style.removeProperty('align-self');
              }
            });
            this.updating = false;
            return;
          }

          // Prevent infinite recursion.
          this.fineTuneIterations += 1;
          if (this.fineTuneIterations >= this.fineTuneMaxIterations) {
            children.forEach((child) => {
              if (child.style && child.style.removeProperty) {
                child.style.removeProperty('align-self');
              }
            });

            this.updating = false;
            return;
          }

          // If too big, scale down
          if (tooWide || tooTall) {
            let sizeDiff = 1;

            // this tends to be off
            if (textWrapEnabled) {
              let ratio;
              if (tooWide) {
                ratio = textWidth / width;
              } else if (tooTall) {
                ratio = textHeight / height;
              }
              // Pi is a magic number that somehow works "best" :P
              sizeDiff = Math.max(1, Math.floor(ratio * Math.PI));
            }

            this.setFontSize(this.element.styles.fontSize - sizeDiff);
            fineTune(); // NOTE: recursive invocation
            return;
          }

          // It fits but is larger than maxSizeText, so enforce max size and be done.
          if (maxSizeText > 0 && this.element.styles.fontSize > maxSizeText) {
            this.setFontSize(Math.min(this.element.styles.fontSize, maxSizeText));
            children.forEach((child) => {
              if (child.style && child.style.removeProperty) {
                child.style.removeProperty('align-self');
              }
            });
            this.updating = false;
            return;
          }

          // See if bigger fits
          this.setFontSize(this.element.styles.fontSize + 1);
          fineTune(true); // NOTE: recursive invocation
        });
      };

      this.updating = true;
      fineTune();
    },

    scaleContainer() {
      // disable styles
      this.setParentStyles({
        root: {
          height: 'auto',
          width: this.element.styles.textFlowOption === 2 ? this.element.styles.width : 'auto',
        },
        animated: {
          position: 'static',
        },
        wrap: {
          position: 'static',
        },
      });
      this.$refs['anim-wrapper'].style.position = 'static';
      this.textEl.style.position = 'static';

      this.updating = true;
      this.$nextTick(() => {
        // measure
        const width = this.element.styles.textFlowOption === 1 ? this.textEl.offsetWidth : this.element.styles.width;
        const height = this.textEl.offsetHeight;

        // set/reset styles
        this.setParentStyles({
          root: {
            height: `${height}px`,
            width: `${width}px`,
          },
          wrap: {
            position: 'absolute',
          },
        });
        this.$refs['anim-wrapper'].style.position = 'absolute';
        this.textEl.style.position = 'absolute';
        this.updating = false;
      });
    },

    resizeText() {
      if (this.element.text && this.element.text.length === 0) return;
      if (this.updating) return;
      if (this.element.ticker) {
        this.setTicker();
        return;
      }

      // Text Flow
      if (this.element.fitText) {
        this.scaleText();
      }

      // Text Box
      if (this.element.styles.textBoxOption === 2) {
        this.scaleContainer();
      }
    },

    setTicker() {
      const textWidth = this.textEl.offsetWidth;
      const parentWidth = this.element.styles.width;
      const crawlSpeedPixels = 100; // crawl this many pixels every crawlSpeedSeconds
      const crawlSpeedSeconds = this.element.tickerCrawlSpeed || 2000; // unit is ms. so 2000 here means 2000ms or 2 seconds.
      const animDistance = textWidth + parentWidth;
      const textAnimationLength = (animDistance * crawlSpeedSeconds) / crawlSpeedPixels; // this means "crawl 100px (crawlSpeedPixels) every 2 seconds (crawlSpeedSeconds)".

      this.textEl.style.animationDuration = `${textAnimationLength}ms`;
      this.$refs['anim-wrapper'].style.animationDuration = `${textAnimationLength}ms`;
    },
  },
};
</script>

<style lang="scss">
// start text at 0, end "off screen" to the left
@keyframes ticker {
  from {
    transform: translate(0);
  }
  to {
    transform: translate(-100%);
  }
}
// start wrapper "off screen" to the right, end at 0
@keyframes ticker-wrapper {
  from {
    transform: translate(100%);
  }
  to {
    transform: translate(0);
  }
}

.anim-wrapper {
  width: 100%;
  height: 100%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: ticker-wrapper;
}
</style>
