<template>
  <div :class="getThemeClass" class="theme-wrapper">
    <div
      class="fullscreen-wrapper"
      :style="{ backgroundColor: theme.settings.keyColor }"
      :class="[
        'post-color-theme-' + theme.settings.postColorTheme,
        'font-' + theme.settings.font,
        { 'no-title': !theme.settings.showCtaText },
        { 'safe-area': safeArea },
      ]">
      <div
        class="display-wrapper"
        :style="[
          { 'background-color': theme.settings.backgroundColor },
          safeArea ? wrapperStyles : {},
        ]">
        <div
          v-if="theme.settings.backgroundImage || theme.settings.backgroundVideo"
          :class="{ 'darkened-background': theme.settings.darkenBackground }"
          class="backdrop"
          :style="[backgroundStyle]">
          <video
            v-if="theme.settings.backgroundVideo"
            :src="theme.settings.backgroundVideo"
            class="background-video"
            width="auto"
            autoplay
            loop
            muted></video>
          <div class="background-overlay"></div>
        </div>

        <header-component :theme="theme"></header-component>

        <div class="posts" :class="[theme.settings.animation, { 'hide-links': theme.settings.hideLinks }]">
          <template v-if="post">
            <transition
              appear
              :enter-active-class="enterAnim"
              :leave-active-class="exitAnim"
              :mode="mode">
              <component
                :is="getPostType(post)"
                :key="getKey(post)"
                :theme="theme"
                :panel="post"
                :post="post"></component>
            </transition>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TickerPostComponent from './TickerPost.vue';
import HeaderComponent from '../../../header/v1.5/Header.vue';
import PostPanelComponent from '../../post-panel/PostPanel.vue';
import LayoutMixins from '../../../../mixins/layout-mixins';

export default {
  props: ['posts', 'theme', 'post'],
  mixins: [LayoutMixins],

  components: {
    'header-component': HeaderComponent,
    post: TickerPostComponent,
    panel: PostPanelComponent,
  },

  data() {
    return {
      hasOwnTimer: true,
    };
  },

  computed: {
    enterAnim() {
      return this.$store.getters.getEnter;
    },
    exitAnim() {
      return this.$store.getters.getExit;
    },
    mode() {
      return this.$store.getters.getMode;
    },
    safeArea() {
      return this.theme.settings.safeArea;
    },
    wrapperStyles() {
      const { safeWidth, safeHeight } = this.theme.settings;

      const styles = {
        'max-width': `${safeWidth}px`,
        'max-height': `${safeHeight}px`,
        bottom: `calc((100% - ${safeHeight}px) / 2)`,
      };

      return styles;
    },
    safeAreaMaxWidth() {
      return this.theme.settings.safeArea && this.theme.settings.safeWidth > 0
        ? `${this.theme.settings.safeWidth}px`
        : '100%';
    },
    safeAreaMaxHeight() {
      return this.theme.settings.safeArea && this.theme.settings.safeHeight > 0
        ? `${this.theme.settings.safeHeight}px`
        : '100%';
    },
    backgroundStyle() {
      if (this.theme.settings.backgroundImage !== '') {
        return {
          backgroundImage: `url('${this.theme.settings.backgroundImage}')`,
        };
      }
      return {};
    },
  },
};
</script>
