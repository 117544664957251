import '../sass/main.scss';

import Vue from 'vue';
import SocketIO from './plugins/socket-io';

import store from './store/producer';
import AppComponent from './components/app/App.vue';

require('./utils/filters');

window.jQuery = require('jquery');

window.$ = window.jQuery;
require('./lib/jquery.shrinkify');

const Sentry = require('@tagboard/lib-sentry-vue');

Vue.use(SocketIO, { store });

try {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    env: process.env.NODE_ENV,
    release: process.env.COMMIT_HASH,
    tracesSampleRate: process.env.SENTRY_TRACES_SAMPLE_RATE,
    Vue,
  });

  // Make this global so we can manually capture exceptions
  window.Sentry = Sentry.client;
} catch (e) {
  // Stub out what Sentry would do if we set it up.
  // Since we don't use it locally or for staging.
  window.Sentry = {
    captureException(err) {
      // eslint-disable-next-line no-console
      console.info('Would send exception to Sentry:', err);
    },
  };
}

// dont allow right clicks (because touchscreens)
window.oncontextmenu = () => false;

window.vm = new Vue({
  el: '#app',
  store,

  components: {
    'app-component': AppComponent,
  },

  render(h) {
    return h(AppComponent);
  },
});
