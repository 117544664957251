var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"marquee-image",class:[
    _vm.element.cssClasses,
  ],style:([
    _vm.getBasicStyles,
    _vm.getTransformStyles,
    _vm.getBorderStyles,
    _vm.getImageBorderRadiusStyles,
    _vm.getBoxShadowStyles,
  ])},[_c('img',{attrs:{"src":_vm.imageUrl,"alt":"Marquee Image"},on:{"error":_vm.handleImageError}})])
}
var staticRenderFns = []

export { render, staticRenderFns }