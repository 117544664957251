import { findIndex } from 'lodash-es';

export default {
  data() {
    return {
      showModal: false,
      modalPost: null,
      modalEnter: 'animated fadeInRight',
      modalLeave: 'animated fadeOutLeft',
    };
  },
  computed: {
    modalPostIndex() {
      const self = this;

      return findIndex(this.posts, (post) => post.post_id === self.modalPost.post_id);
    },

    playing() {
      return !this.theme.settings.interactive;
    },
  },
  methods: {
    showPostModal(post) {
      this.modalPost = post;
      this.showModal = true;
    },
    navigateModal(direction) {
      this.modalEnter = direction === 'next' ? 'animated fadeInRight' : 'animated fadeInLeft';
      this.modalLeave = direction === 'previous' ? 'animated fadeOutRight' : 'animated fadeOutLeft';
      this.modalPost = direction === 'next' ? this.posts[this.modalPostIndex + 1] : this.posts[this.modalPostIndex - 1];
    },
  },
};
