import { ASSET_MANAGER_BASE } from './services';

export default {
  /**
   * Fetch custom fonts by owner.
   * @param {Number} owner - ID of the account that owns the fonts.
   * @returns {Object} API Response
   */
  async getFonts(owner, displayToken) {
    const headers = {};
    if (displayToken) {
      headers['tgb-display-token'] = displayToken;
    }

    const resp = await fetch(`${ASSET_MANAGER_BASE}/assets/${owner}?asset_type=user-font`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers,
    });

    return resp.json();
  },
};
