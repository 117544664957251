<template>
  <div :class="getThemeClass" class="theme-wrapper">
    <div class="fullscreen-wrapper" :class="[{'crop-images' : theme.settings.cropImages}, {'show-blurred-images' : !theme.settings.cropImages && theme.settings.showBlurredImages}, {'hide-media-captions' : !theme.settings.showMediaCaptions}, 'grid-columns-' + theme.settings.columns, 'grid-rows-' + theme.settings.rows, 'post-color-theme-' + theme.settings.postColorTheme, 'cta-template-' + theme.settings.ctaTemplate, 'font-' + theme.settings.font, 'cta-social-icon-style-' + theme.settings.ctaSocialIconStyle, {'no-title' : !theme.settings.showCtaText}, {'no-social-icons' : !theme.settings.showCtaSocialIcons}]">
      <div class="display-wrapper">

        <div class="posts"
          :class="{ 'hide-links': theme.settings.hideLinks }"
          v-show="ready">
          <div class="grid-cell" v-if="muralPosts.length" v-for="(post, index) in muralPosts">
            <transition
                  :enter-active-class="enterAnim"
                  :leave-active-class="exitAnim"
                  mode="">
              <post :key="post.post_id" :theme="theme" :post="post"></post>
            </transition>
          </div>
        </div>
      </div>

      <div v-if="theme.settings.backgroundVideo" class="backdrop" :style="{opacity: theme.settings.backgroundOpacity}" >
        <video v-if="theme.settings.backgroundVideo" :src="theme.settings.backgroundVideo" class="background-video" width="auto" autoplay loop muted></video>
      </div>

      <div v-else-if="theme.settings.backgroundImage" class="backdrop" :style="[{opacity: theme.settings.backgroundOpacity}, theme.settings.backgroundImage !== '' ? { backgroundImage: 'url(' + theme.settings.backgroundImage + ')' } : '']"></div>

      <template v-if="hasPostDetailModal">
        <transition
          name="modal"
          v-on:after-leave="afterLeave">
          <modal class="post-modal post-detail-modal" v-show="showModal">
            <template slot="modal-body">
              <div class="isolated-v1_0 modal-body">
                <isolated v-if="modalPost" :theme="theme" :post="modalPost"></isolated>
              </div>
            </template>
          </modal>
        </transition>
      </template>
    </div>
  </div>
</template>

<script>
import { clone } from 'lodash-es';
import { shrinkify } from '../../../../utils/shrinkify';

import EventBus from '../../../../utils/event-bus';
import MuralPostComponent from './MuralPost.vue';
import IsolatedPostComponent from '../../../modal/ModalIsolatedPost.vue';
import ModalComponent from '../../../modal/Modal.vue';
import MultipostMixins from '../../../../mixins/multipost-mixins';
import HeaderComponent from '../../../header/v1.5/Header.vue';
import LayoutMixins from '../../../../mixins/layout-mixins';

const { STORY_BASE } = require('../../../../services/services');

export default {
  props: ['posts', 'theme', 'post'],
  components: {
    'header-component': HeaderComponent,
    post: MuralPostComponent,
    modal: ModalComponent,
    isolated: IsolatedPostComponent,
  },
  mixins: [
    LayoutMixins,
    MultipostMixins,
  ],
  data() {
    return {
      muralPosts: [],
      lastFlipped: null,
      lastPostDetailIndex: null,
      lastPostDetailPostId: null,
      cellIndexArray: [],
      postDetailCycleTimer: null,
      postDetailDisplayTimer: null,
      before: null,
      lastFillIndex: 0,
      ready: false,
    };
  },
  computed: {
    getPostBlockCount() {
      return this.theme.settings.rows * this.theme.settings.columns;
    },
    enterAnim() {
      return this.$store.getters.getEnter;
    },
    exitAnim() {
      return this.$store.getters.getExit;
    },
    hasPostDetailModal() {
      return this.theme.settings.showPostDetail;
    },
  },
  created() {
    this.muralPosts = this.posts.slice();
    this.ready = false;
    this.getMorePosts();
  },
  methods: {
    async setPost() {
      if (!this.ready) { return; }

      try {
        await this.checkPostMedia(this.post);
      } catch (err) {
        this.$store.dispatch('removePost', this.post.post_id);
        EventBus.$emit('advance', 'next');
        return;
      }

      // Randomly remove posts to make room for other posts
      this.$nextTick(() => {
        const randomPost = this.randomPost();
        this.muralPosts.splice(randomPost, 1, this.post);
        this.$store.dispatch('startTimer', this.theme.settings.displayTime);
      });
    },
    getMorePosts() {
      if (!this.posts.length) {
        window.parent.postMessage('tgbLive:loaded', '*'); // let producer know to just show the theme now
        this.$emit('frameloaded');
        return;
      }

      const MAX_UNIQUE_POST_COUNT = 300;
      const BASE_FEATURED_POSTS_URL = `${STORY_BASE}/posts/`;
      const DEFAULT_NETWORKS = 'twitter,facebook,instagram,youtube,heymarket,reddit,tiktok,twitch,share-it';
      let url = `${BASE_FEATURED_POSTS_URL + (this.theme.settings.tagboard ? this.theme.settings.tagboard._id : this.$store.state.tagboard.tagboard_id)}?count=${this.theme.settings.librarySize}&media_only=true` + `&network=${DEFAULT_NETWORKS}`;
      const { before } = this;

      url += `&before=${before}`;

      // IF postLoader returned less results than the library size, we're all out of featured posts, so move on
      if (this.$options.temp < this.theme.settings.librarySize) {
        this.fillPosts(this.getPostBlockCount);
      } else {
        $.ajax({
          type: 'GET',
          url,
        }).then((data) => {
          this.before = data.meta.before;
          this.muralPosts = this.muralPosts.concat(data.posts);

          if (this.muralPosts.length < MAX_UNIQUE_POST_COUNT && data.meta.count >= this.theme.settings.librarySize) {
            this.getMorePosts();
          } else {
            this.fillPosts(this.getPostBlockCount - this.muralPosts.length);
          }
        }).fail((reason) => {
          console.log(reason);
          this.fillPosts(this.getPostBlockCount - this.muralPosts.length);
        });
      }
    },
    fillPosts(count) {
      let j = this.lastFillIndex;
      for (let i = 0; i < count; i++) {
        j = this.muralPosts[j] ? j : 0;
        const tmp = clone(this.muralPosts[j]);
        tmp.id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        this.muralPosts.push(tmp);
        j++;
      }

      this.lastFillIndex = j;

      this.$nextTick(() => {
        this.load();
      });
    },
    load() {
      let count = 0;

      $(this.$el).find('.media-module img').not('.loaded').imagesLoaded()
        .done(() => {
          this.ready = true;
          window.parent.postMessage('tgbLive:loaded', '*');
          this.$emit('frameloaded');
          this.$store.dispatch('startTimer', this.theme.settings.displayTime);

          if (this.theme.settings.showPostDetail) {
            this.startPostDetailCycleTimer();
          }
        })
        .progress((instance, image) => {
          if (!image.isLoaded) {
            const $img = $(image.img);

            this.$store.dispatch('removePost', $img.data('id'));

            const postIndex = this.muralPosts.findIndex((post) => post.post_id == $img.data('id'));

            if (postIndex !== -1) {
              this.muralPosts.slice(postIndex, 1);
            }

            $img.closest('.grid-cell').remove();
            count++;
          } else {
            $(image.img).addClass('loaded');
          }
        })
        .fail(() => {
          this.fillPosts(count);
        });
    },
    startPostDetailCycleTimer(time) {
      const cycleTime = time || this.theme.settings.postDetailCycleTime;
      let randomPostIndex;

      clearTimeout(this.postDetailCycleTimer);

      this.showModal = false;
      let lastPostDetailPostId;

      this.postDetailCycleTimer = setTimeout(() => {
        let count = -1;
        do {
          randomPostIndex = Math.floor(Math.random() * this.$el.querySelectorAll('.post').length);
          count++;
        } while (this.muralPosts.length && (count < this.muralPosts.length) && ((randomPostIndex === this.lastPostDetailIndex) || (this.muralPosts[randomPostIndex] && this.muralPosts[randomPostIndex].post_id === this.lastPostDetailPostId)) && this.getPostBlockCount > 1);

        this.lastPostDetailIndex = randomPostIndex;
        this.lastPostDetailPostId = this.muralPosts[randomPostIndex]
          ? this.muralPosts[randomPostIndex].post_id : lastPostDetailPostId;

        this.modalPost = this.muralPosts[randomPostIndex];
        this.$nextTick(() => {
          const postDetailModal = this.$el.querySelector('.post-detail-modal');
          const mediaModuleImages = postDetailModal.querySelectorAll('.media-module img');

          Promise.all(Array.from(mediaModuleImages).map((img) => new Promise((resolve, reject) => {
            img.onload = resolve;
            img.onerror = reject;
          })))
            .then(() => {
              this.showModal = true;

              this.$nextTick(() => {
                const post = this.$el.querySelector('.post-detail-modal');
                const textModule = post.querySelector('.text-module');
                const message = post.querySelector('.message');
                const metaAuthor = post.querySelector('.meta-author');
                const postTime = post.querySelector('.post-time');
                let postTimeHeight = 0;

                if (postTime && window.getComputedStyle(postTime).display !== 'none') {
                  postTimeHeight = postTime.offsetHeight;
                }

                if (message) {
                  shrinkify(message, {
                    minFontPct: 5,
                    maxHeightPx: textModule.offsetHeight - metaAuthor.offsetHeight - postTimeHeight,
                    width: false,
                  });
                }

                EventBus.$emit('resize');
                this.startPostDetailDisplayTimer();
              });
            })
            .catch(() => {
              this.startPostDetailCycleTimer(1);
            });
        });
      }, cycleTime);
    },

    startPostDetailDisplayTimer() {
      clearTimeout(this.postDetailDisplayTimer);

      this.postDetailDisplayTimer = setTimeout(() => {
        this.startPostDetailCycleTimer();
      }, this.theme.settings.postDetailDisplayTime);
    },
    randomPost() {
      let index;
      let ci = -1;

      if (this.cellIndexArray.length === 0) {
        // We've cycled all the grid cells, fill up the array again
        for (let i = 0; i < this.getPostBlockCount; ++i) {
          this.cellIndexArray.push(i);
        }
      }

      // Randomly pick a remaining cell that hasn't cycled
      do {
        ci = Math.floor(Math.random() * this.cellIndexArray.length);
        index = this.cellIndexArray[ci];
      } while (this.cellIndexArray.length > 1 && index === this.lastFlipped);
      // If this index matches the previous one, try a different one

      if (ci !== -1) {
        this.cellIndexArray.splice(ci, 1);
      }

      this.lastFlipped = index;
      return index;
    },
    afterLeave() {
      this.modalPost = null;
    },
  },
};
</script>
