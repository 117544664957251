<template>
  <div class="post" v-bind:id="post.uid" v-bind:class="[post.network, dynamicClasses, post.post_type, videoType, noAvatar, noCommentOrAttribution]" v-bind:data-provider="post.network">
    <template v-if="hasMedia">
      <div class="blurred-media-module">
        <div v-if="post.videos" class="blurred-media video" v-bind:style="{ backgroundImage: 'url(' + posterUrl + ')' }"></div>
        <div v-else class="blurred-media photo" v-bind:style="{ backgroundImage: 'url(' + imageUrl + ')' }"></div>
      </div>

      <div v-if="post.videos" class="media-module">
        <component v-on:finished="advance" v-on:update-video-url="updateVideoUrl" v-bind:post="post" v-bind:is="videoPlayer" :theme="theme"></component>

        <div v-if="post.network === 'tiktok'" class="audio-attribution clearfix">
          <span class="audio-icon icon icon-music-note"></span>
          <span class="audio-avatar" v-bind:style="{ backgroundImage: 'url(' + post.service_meta.music_avatar + ')' }"></span>
          <div class="audio-info">
            <span class="audio-info-inner">&nbsp;&nbsp;&nbsp;&nbsp;{{post.service_meta.music_name}} - {{post.service_meta.music_author}}&nbsp;&nbsp;&nbsp;&nbsp;{{post.service_meta.music_name}} - {{post.service_meta.music_author}}&nbsp;&nbsp;&nbsp;&nbsp;{{post.service_meta.music_name}} - {{post.service_meta.music_author}}</span>
          </div>
        </div>
      </div>

      <div v-else class="media-module">
        <img v-bind:src="imageUrl" alt="" class="media photo">
      </div>
    </template>

    <div class="text-module" v-if="hasCommentOrAttribution">
      <div v-bind:class="isVerified" class="meta-author clearfix">
        <span class="avatar" v-if="avatarUrl" v-bind:style="{ backgroundImage: 'url(' + avatarUrl + ')' }"><img v-bind:src="avatarUrl" alt="" class="user-img"></span>

        <template v-if="post.network === 'instagram'">
          <span
            v-if="post.user_name"
            class="handle"
            :class="handleStyle"
            :style="{'font-size':`${handleFontSize}px`}"
          >@<span v-html="post.user_name"></span>
          </span>
          <span
            v-else
            class="handle"
            :class="handleStyle"
            :style="{'font-size':`${handleFontSize}px`}"
          >
            Instagram user
          </span>
        </template>

        <template v-if="post.network === 'share-it'">
          <span
            v-if="post.user_name"
            class="handle"
            :class="handleStyle"
            :style="{'font-size':`${handleFontSize}px`}"
          >
            <span v-html="post.user_name"></span>
          </span>
        </template>

        <template v-if="post.network === 'reddit'">
          <span v-if="post.service_meta.subreddit" class="subreddit">in r/<span v-html="post.service_meta.subreddit"></span></span>
          <span
            class="handle"
            :class="handleStyle"
            :style="{'font-size':`${handleFontSize}px`}"
          >by u/<span v-html="post.user_real_name"></span>
          </span>
        </template>

        <template v-if="post.network !== 'instagram' && post.network !== 'reddit'">
          <span
            class="author"
            :class="authorStyle"
            :style="{'font-size':`${authorFontSize}px`}"
            v-html="post.user_real_name"
          />
          <span
            v-if="post.network === 'twitter' || post.network === 'tiktok'"
            class="handle"
            :class="handleStyle"
            :style="{'font-size':`${handleFontSize}px`}"
          >@<span v-html="post.user_name"></span>
          </span>
        </template>
      </div>

      <div class="post-time clearfix">
        <span class="timestamp">{{post.post_time|timestampToString}}</span>
      </div>

      <div
        class="message"
        :class="postMessageStyle"
        >
        <div class="post-title" v-if="post.tgb_meta && !post.tgb_meta.isComment && post.title" v-html="post.title"></div>
        <div
          class="post-text"
          v-html="postHtml"
        ></div>
      </div>
    </div>

    <div class="origin-flag"></div>
  </div>
</template>

<script>
import EventBus from '../../../../utils/event-bus';
import PostRenderMixins from '../../../../mixins/post-render-mixins';
import PostTimingMixins from '../../../../mixins/post-timing-mixins';
import YoutubeVideoComponent from '../../../video/youtube/YouTubeVideo.vue';
import FacebookVideoComponent from '../../../video/facebook/FacebookVideo.vue';
import GenericVideoComponent from '../../../video/generic/GenericVideo.vue';

export default {
  mixins: [PostRenderMixins, PostTimingMixins],
  props: ['post', 'theme'],

  components: {
    youtube: YoutubeVideoComponent,
    facebook: FacebookVideoComponent,
    generic: GenericVideoComponent,
  },

  mounted() {
    EventBus.$on('resize', this.layoutIsolatedPost);

    this.$nextTick(() => {
      this.layoutIsolatedPost();
    });
  },

  beforeDestroy() {
    EventBus.$off('resize', this.layoutIsolatedPost);
  },

  computed: {
    handleStyle() {
      return [
        this.theme.settings?.handle?.textStyles ?? [''],
        `font-${this.theme.settings?.handle?.fontFamily}`,
      ];
    },

    postMessageStyle() {
      return [
        this.theme.settings?.postMessage?.textStyles ?? [''],
        `font-${this.theme.settings?.postMessage?.fontFamily}`,
      ];
    },

    authorStyle() {
      return [
        this.theme.settings?.author?.textStyles ?? [''],
        `font-${this.theme.settings?.author?.fontFamily}`,
      ];
    },

    handleFontSize() {
      return this.theme.settings?.handle?.size ?? '';
    },

    postMessageFontSize() {
      return this.theme.settings?.postMessage?.size ?? '';
    },

    authorFontSize() {
      return this.theme.settings?.author?.size ?? '';
    },
  },

  methods: {
    layoutIsolatedPost() {
      const $post = $(this.$el);
      const autoPostHeight = this.theme.settings.postHeight == 'auto';
      const $textModule = $post.find('.text-module');
      const $message = $post.find('.message');
      const $metaAuthor = $post.find('.meta-author');
      const $postTime = $post.find('.post-time');
      let $postTimeHeight;

      $post.removeClass('portrait-post');

      if ($post.hasClass('twitter')) {
        $post.find("a:contains('pic.twitter.com')").addClass('twitter-media-url');
        $post.find("a:contains('https://twitter.com')").addClass('quote-tweet-url');
      }

      $post.find("a:not('.hashtag'):not('.mention'):not('.twitter-media-url'):not('.quote-tweet-url')").addClass('link');

      $message.css('font-size', '');
      if (autoPostHeight) {
        $post.css('height', '');
        $post.css('margin-top', '');
      }

      // aspect ratio < 3/4
      if ($post.outerWidth() / $post.outerHeight() < 1) {
        $post.addClass('portrait-post');
      }

      if ($postTime.length && $postTime.is(':visible')) {
        $postTimeHeight = $postTime.outerHeight(true);
      } else {
        $postTimeHeight = 0;
      }

      const metaAuthorHeight = $metaAuthor.is(':visible') ? $metaAuthor.outerHeight(true) : 0;

      if ($post.hasClass('text') && autoPostHeight) {
        // shrinkify post text
        if ($message.length) {
          $message.shrinkify({
            minFontPct: 5,
            maxHeightPx: $post.height() - ($textModule.outerHeight() - $textModule.height()) - metaAuthorHeight - $postTimeHeight,
            width: false,
          });
        }
        $post.css('height', $post.outerHeight());
      } else {
        // shrinkify post text
        if ($message.length) {
          $message.shrinkify({
            minFontPct: 5,
            maxHeightPx: $textModule.height() - metaAuthorHeight - $postTimeHeight,
            width: false,
          });
        }
      }

      // need this if this is auto-height post style
      if (autoPostHeight) {
        $post.css('height', $post.outerHeight());
      }

      // need this if this is auto-height post style
      if (autoPostHeight) {
        $post.css('margin-top', -$post.outerHeight() / 2);
      }
    },
  },
};
</script>
