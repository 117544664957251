<template>
  <div
    class="element group"
    :style="getBasicStyles"
    :class="[element.cssClasses, element.animationInStyle, element.animationOutStyle]"
  >
    <div class="group-wrap">
      <transition
        appear
        :enter-to-class="animationIn"
        :leave-to-class="animationOut"
        enter-class="enter"
        type="animation"
        :mode="transitionMode"
        @before-enter="beforeEnter"
        @after-enter="afterEnter"
        @before-leave="beforeLeave"
        @after-leave="afterLeave"
      >
        <div
          :key="getKey"
          class="animated-element"
          :style="[
            getAnimationStyles,
            getBackgroundStyles,
            getBorderStyles,
            getBoxShadowStyles,
            getBlurStyles
          ]"
          :class="[getLoopingClass, element.cssClasses]"
        >
          <div
            v-if="hasBackground"
            class="background-image"
            :style="{
              backgroundSize: element.backgroundSize,
              backgroundImage: 'url(' + element.backgroundImageUrl + ')',
              backgroundPosition: element.backgroundPosition,
            }"
          />

          <graphic-element
            v-for="el in graphicElements"
            :id="el.id"
            :key="el.id"
            :graphic="graphic"
            class="group-item"
            :element="el"
            v-on="$listeners"
          />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import GraphicsMixins from '../../mixins/graphics-mixins';

export default {
  name: 'Group',
  mixins: [GraphicsMixins],

  components: {
    GraphicElement: () => import('../render-elements'),
  },

  computed: {
    hasBackground() {
      const { backgroundImage, backgroundImageUrl } = this.element;
      return backgroundImage && backgroundImageUrl?.length;
    },

    getKey() {
      return this.animkey ? this.animkey : null;
    },

    graphicElements() {
      return this.element.elements
        ? this.element.elements.slice().reverse().filter((x) => x.visible)
        : [];
    },

    getBackgroundStyles() {
      if (!this.element.background) {
        return {};
      }

      if (this.element.fillStyle === 'gradient') {
        const {
          gradientDirection,
          gradientColorOne,
          gradientColorTwo,
        } = this.element;

        const backgroundImage = `linear-gradient(${gradientDirection}deg, ${gradientColorOne}, ${gradientColorTwo})`;

        return { backgroundImage };
      }

      return {
        backgroundImage: 'none',
        backgroundColor: this.element.backgroundColor,
      };
    },
  },

  methods: {
    beforeLeave() {
      if (this.element.animation) {
        this.animationDuration = `${this.element.animationOutDuration}ms`;
        this.animationDelay = `${this.element.animationOutDelay}ms`;
        this.animationTimingFunction = `cubic-bezier(${this.element.animationInEasing})`;
      }
    },
  },
};
</script>
