<template>
  <div :class="getThemeClass" class="theme-wrapper">
    <div
      class="fullscreen-wrapper"
      :style="{ 'background-color': theme.settings.backgroundColor }"
      :class="[
        { interactive: theme.settings.interactive },
        { 'crop-images': theme.settings.cropImages },
        {
          'show-blurred-images':
            !theme.settings.cropImages && theme.settings.showBlurredImages,
        },
        'gutter-' + theme.settings.gutter,
        { mosaic: this.theme.settings.mosaic },
        isGrid ? 'grid-columns-' + theme.settings.columns : '',
        isGrid ? 'grid-rows-' + theme.settings.rows : '',
        isMosaic ? 'mosaic-' + theme.settings.mosaicTemplate : '',
        'post-color-theme-' + theme.settings.postColorTheme,
        'cta-template-' + theme.settings.ctaTemplate,
        'font-' + theme.settings.font,
        'cta-social-icon-style-' + theme.settings.ctaSocialIconStyle,
        { 'no-title': !theme.settings.showCtaText },
        { 'no-social-icons': !theme.settings.showCtaSocialIcons },
        { 'safe-area': safeArea },
        { 'has-qr-code': !showQrCode},
      ]">
      <div
        v-if="safeArea"
        :class="[{ 'darkened-background': theme.settings.darkenBackground }]"
        class="backdrop"
        :style="[backgroundStyle]">
        <video
          v-if="theme.settings.backgroundVideo"
          :src="theme.settings.backgroundVideo"
          class="background-video"
          width="auto"
          autoplay
          loop
          muted></video>
        <div class="background-overlay"></div>
      </div>
      <div
        class="display-wrapper"
        :style="safeArea ? wrapperStyles : {}"
        :class="{ 'empty-header': emptyHeader }">
        <div
          v-if="
            !safeArea && (theme.settings.backgroundImage ||
            theme.settings.backgroundVideo)"
          :class="{ 'darkened-background': theme.settings.darkenBackground, }"
          class="backdrop"
          :style="[backgroundStyle]">
          <video
            v-if="theme.settings.backgroundVideo"
            :src="theme.settings.backgroundVideo"
            class="background-video"
            width="auto"
            autoplay
            loop
            muted></video>
          <div class="background-overlay"></div>
        </div>

        <header-component :theme="theme"></header-component>

        <div class="posts" ref="postsDiv" :class="{'hide-links': theme.settings.hideLinks}">
          <template v-if="theme.settings.mosaic">
            <div class="mosaic-container">
              <div class="grid-cell" v-if="showQrCode">
                <div class="qr-module">
                  <img class="square-placeholder" src="/live/assets/img/square.png">
                  <qr-code :settings="theme.settings" />
                </div>
              </div>
              <div
                v-if="gridPosts.length"
                v-for="(num, index) in gridPosts"
                :key="index"
                class="grid-cell"
              >
                <transition
                  v-bind:enter-active-class="enterAnim"
                  v-bind:leave-active-class="exitAnim"
                  mode="">
                  <post
                    v-on:postclicked="showPostModal"
                    v-on:update-video-url="updateVideoUrl($event, ind)"
                    :key="gridPosts[index].post_id"
                    :theme="theme"
                    :post="gridPosts[index]"></post>
                </transition>
              </div>
            </div>
          </template>

          <template v-else>
            <div class="grid-cell" v-if="showQrCode">
              <div class="qr-module">
                <img class="square-placeholder" src="/live/assets/img/square.png">
                <qr-code :settings="theme.settings" />
              </div>
            </div>
            <div
              class="grid-cell"
              v-if="gridPosts.length"
              v-for="(num, index) in gridPosts">
              <transition
                v-bind:enter-active-class="enterAnim"
                v-bind:leave-active-class="exitAnim"
                mode="">
                <post
                  v-on:postclicked="showPostModal"
                  v-on:update-video-url="updateVideoUrl($event, ind)"
                  :key="gridPosts[index].post_id"
                  :theme="theme"
                  :post="gridPosts[index]"></post>
              </transition>
            </div>
          </template>
        </div>
      </div>

      <template v-if="theme.settings.interactive">
        <modal class="post-modal" v-if="showModal" @close="showModal = false">
          <template slot="modal-body">
            <span
              v-if="modalPostIndex !== 0"
              @click.stop="navigateModal('previous')"
              class="previous icon icon-chevron-left"></span>
            <div class="isolated-v1_0 modal-body">
              <transition
                name="custom-classes-transition"
                :enter-active-class="modalEnter"
                :leave-active-class="modalLeave">
                <isolated
                  :key="modalPost.network + modalPost.post_id"
                  :theme="theme"
                  :post="modalPost" />
              </transition>
            </div>
            <span
              v-if="modalPostIndex < posts.length - 1"
              @click.stop="navigateModal('next')"
              class="next icon icon-chevron-right"></span>
          </template>
        </modal>
      </template>

      <template v-if="hasPostDetailModal">
        <transition name="modal">
          <modal
            class="post-modal post-detail-modal"
            v-if="showModal"
            @close="showModal = false">
            <template slot="modal-body">
              <div class="isolated-v1_0 modal-body">
                <isolated :theme="theme" :post="modalPost"></isolated>
              </div>
            </template>
          </modal>
        </transition>
      </template>
    </div>
  </div>
</template>

<script>
import { map } from 'lodash-es';

import EventBus from '../../../../utils/event-bus';
import GridPostComponent from './GridPost.vue';
import IsolatedPostComponent from '../../../modal/ModalIsolatedPost.vue';
import ModalComponent from '../../../modal/Modal.vue';
import HeaderComponent from '../../../header/v1.5/Header.vue';
import QRCodeComponent from '../../../qr-code/v2.0/QRCode.vue';
import MultipostMixins from '../../../../mixins/multipost-mixins';
import LayoutMixins from '../../../../mixins/layout-mixins';

const { STATIC_BASE } = require('../../../../services/services');

export default {
  props: ['theme', 'posts', 'post'],

  components: {
    'header-component': HeaderComponent,
    post: GridPostComponent,
    modal: ModalComponent,
    isolated: IsolatedPostComponent,
    'qr-code': QRCodeComponent,
  },

  mixins: [LayoutMixins, MultipostMixins],

  data() {
    return {
      gridPosts: [],
      lastFlipped: null,
      lastPostDetailIndex: null,
      postBlockCount: new Array(this.getPostBlockCount()),
      cellIndexArray: [],
      postDetailCycleTimer: null,
      postDetailDisplayTimer: null,
      isGrid: !this.theme.settings.mosaic,
      isMosaic: this.theme.settings.mosaic,
    };
  },

  computed: {
    enterAnim() {
      return this.$store.getters.getEnter;
    },
    exitAnim() {
      return this.$store.getters.getExit;
    },
    mode() {
      return this.$store.getters.getMode;
    },
    hasPostDetailModal() {
      return !this.theme.settings.interactive && this.theme.settings.showPostDetail;
    },
    safeArea() {
      return this.theme.settings.safeArea;
    },
    wrapperStyles() {
      return {
        'max-width': this.safeAreaMaxWidth,
        'max-height': this.safeAreaMaxHeight,
      };
    },
    safeAreaMaxWidth() {
      const { safeArea, safeWidth } = this.theme.settings;

      return safeArea && safeWidth && safeWidth > 0
        ? `${safeWidth}px`
        : '100%';
    },
    safeAreaMaxHeight() {
      const { safeArea, safeHeight } = this.theme.settings;

      return safeArea && safeHeight && safeHeight > 0
        ? `${safeHeight}px`
        : '100%';
    },
    emptyHeader() {
      const { logo, showCtaText, showCtaSocialIcons } = this.theme.settings;
      return !logo && !showCtaText && !showCtaSocialIcons;
    },
    backgroundStyle() {
      if (this.theme.settings.backgroundImage !== '') {
        return {
          backgroundImage: `url('${this.theme.settings.backgroundImage}')`,
        };
      }
      return {};
    },
    showQrCode() {
      const { showQrCode, qrCodeCtaPlacement } = this.theme.settings;
      return showQrCode && !qrCodeCtaPlacement;
    },
    addQRCodeClass() {
      const {
        settings,
      } = this.theme;

      const {
        showQrCode,
        qrCodeCtaPlacement,
        ctaTemplate,
      } = settings;

      return showQrCode && qrCodeCtaPlacement && ctaTemplate !== 1;
    },
  },

  created() {
    const self = this;
    // this was stolen from waterfall-component.
    const holder = [];
    const promiseAll = [];

    this.posts.forEach((post) => {
      promiseAll.push(
        self.checkPostMedia(post)
          .then(() => {
            holder.push(post);
          }).catch(() => {
          }),
      );
    });

    Promise.all(promiseAll).then(() => {
      this.gridPosts = holder.slice(0, this.getPostBlockCount());

      this.gridPosts.forEach((post) => {
        self.$store.dispatch('setDisplayNum', post.post_id);
      });
    }).catch((reason) => { console.error(reason); });

    EventBus.$on('resize', this.setFontSize);
  },

  mounted() {
    const self = this;

    this.setFontSize();

    $(self.$el).find('.display-wrapper').imagesLoaded({ background: true })
      .always(() => {
        this.$nextTick(() => {
          window.parent.postMessage('tgbLive:loaded', '*');
          self.$emit('frameloaded');
          if (!self.theme.settings.interactive) {
            EventBus.$emit('startTimer', self.theme.settings.displayTime);
          }

          if (self.theme.settings.showPostDetail && !self.theme.settings.interactive) {
            self.startPostDetailCycleTimer();
          }
        });
      })
      .progress((instance, image) => {
        if ($(image.img).closest('.meta-author').length && !image.isLoaded) {
          $(image.img).closest('.post').find('.user-img').attr('src', `${STATIC_BASE}/live/assets/img/profile_placeholder.jpg`);
          $(image.img).closest('.post').find('.avatar').css('background-image', `url(${STATIC_BASE}/live/assets/img/profile_placeholder.jpg)`);
        }
      });

    // Make sure Safari gets the right font size.
    // Calling it here seems to be too early for the sizes to have resolved sometimes.
    setTimeout(() => { self.setFontSize(); }, 0);
  },

  methods: {
    setPost() {
      if (!this.post) { return; }

      const self = this;

      this.checkPostMedia(self.post)
        .then(() => {
          self.nextGridPost();
        }).catch(() => {
          self.$store.dispatch('removePost', self.post.post_id);
          if (!self.theme.settings.interactive) {
            EventBus.$emit('advance', 'next');
          }
        });
    },
    nextGridPost() {
      if (!this.post) { return; }

      const self = this;
      const postsInView = map(self.gridPosts, (post) => post.post_id);

      if (this.posts.length < this.getPostBlockCount()) {
        return;
      }

      if (postsInView.indexOf(self.post.post_id) !== -1) {
        EventBus.$emit('advance', 'next');
        return;
      }

      if (self.gridPosts.length < this.getPostBlockCount()) {
        self.gridPosts.push(self.post);
      } else {
        const randomPost = self.randomPost();
        self.gridPosts.splice(randomPost, 1, self.post);
      }

      if (!self.theme.settings.interactive) {
        EventBus.$emit('startTimer', self.theme.settings.displayTime);
      }
    },
    startPostDetailCycleTimer() {
      clearTimeout(this.postDetailCycleTimer);
      this.showModal = false;

      this.postDetailCycleTimer = setTimeout(() => {
        let idx;
        do {
          idx = Math.floor(Math.random() * this.gridPosts.length);
        } while (idx === this.lastPostDetailIndex && this.getPostBlockCount() > 1);

        this.lastPostDetailIndex = idx;
        this.modalPost = this.gridPosts[idx];
        this.showModal = true;
        this.startPostDetailDisplayTimer();
      }, this.theme.settings.postDetailCycleTime);
    },
    startPostDetailDisplayTimer() {
      const self = this;

      clearTimeout(this.postDetailDisplayTimer);

      this.postDetailDisplayTimer = setTimeout(() => {
        self.startPostDetailCycleTimer();
      }, this.theme.settings.postDetailDisplayTime);
    },
    updateVideoUrl(data, index) {
      this.gridPosts[index].videos[0].m = data;
    },
    setFontSize() {
      const gridColumns = this.theme.settings.columns;
      const $posts = $(this.$refs.postsDiv);
      const { mosaicTemplate } = this.theme.settings;
      let mosaicColumns;

      if (mosaicTemplate.indexOf('16-9') !== -1) {
        // 16:9 layouts
        if (mosaicTemplate.indexOf('template-7') !== -1 || mosaicTemplate.indexOf('template-8') !== -1) {
          mosaicColumns = 6;
        } else {
          mosaicColumns = 8;
        }
      } else if (mosaicTemplate.indexOf('9-16') !== -1) {
        // 9:16 layouts
        if (mosaicTemplate.indexOf('template-7') !== -1 || mosaicTemplate.indexOf('template-8') !== -1) {
          mosaicColumns = 3;
        } else {
          mosaicColumns = 4;
        }
      } else if (mosaicTemplate.indexOf('4-3') !== -1) {
        // 4:3 layouts
        mosaicColumns = 6;
      } else if (mosaicTemplate.indexOf('3-4') !== -1) {
        // 3:4 layouts
        mosaicColumns = 4;
      } else if (mosaicTemplate.indexOf('32-9') !== -1) {
        // 32:9 layouts
        mosaicColumns = 8;
      }

      if (this.theme.settings.mosaic) {
        $posts.css('font-size', ($posts.width() * 0.07) / mosaicColumns);
      } else {
        $posts.css('font-size', ($posts.width() * 0.07) / gridColumns);
      }
    },
    randomPost() {
      if (this.cellIndexArray.length === 0) {
        // Fill up the array if it's empty
        this.cellIndexArray = Array.from({ length: this.getPostBlockCount() }, (_, i) => i);
      }

      let index = -1;
      while (this.cellIndexArray.length > 0) {
        const randomIndex = Math.floor(Math.random() * this.cellIndexArray.length);
        index = this.cellIndexArray[randomIndex];

        if (!(this.cellIndexArray.length > 1 && index === this.lastFlipped)) {
          // If the index doesn't match the previous one or there's only one index left
          this.lastFlipped = index;
          this.cellIndexArray.splice(randomIndex, 1);
          return index;
        }
      }

      return index; // Return -1 if all indices have been used
    },
    getPostBlockCount() {
      const showQRCode = this.theme.settings.showQrCode && !this.theme.settings.qrCodeCtaPlacement;

      if (this.theme.settings.mosaic && this.theme.settings.mosaicTemplate) {
        const template = this.theme.settings.mosaicTemplate;

        switch (template) {
          case '16-9-template-4':
          case '9-16-template-4':
            return showQRCode ? 16 : 17;
          case '16-9-template-5':
          case '9-16-template-5':
          case '16-9-template-6':
          case '9-16-template-6':
            return showQRCode ? 19 : 20;
          case '16-9-template-7':
          case '9-16-template-7':
            return showQRCode ? 8 : 9;
          case '16-9-template-8':
          case '9-16-template-8':
            return showQRCode ? 5 : 6;
          case '4-3-template-1':
          case '3-4-template-1':
            return showQRCode ? 8 : 9;
          case '4-3-template-2':
          case '3-4-template-2':
          case '4-3-template-3':
          case '3-4-template-3':
            return showQRCode ? 11 : 12;
          case '4-3-template-4':
          case '3-4-template-4':
            return showQRCode ? 14 : 15;
          case '32-9-template-1':
          case '32-9-template-2':
            return showQRCode ? 9 : 10;
          default:
            return showQRCode ? 13 : 14; // Use default for our most common size
        }
      } else {
        const count = this.theme.settings.rows * this.theme.settings.columns;
        return showQRCode ? count - 1 : count;
      }
    },
  },

  beforeDestroy() {
    EventBus.$off('resize', this.setFontSize);
  },
};
</script>
