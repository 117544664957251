<template>
  <div class="theme-wrapper">
    <iframe
      border="0"
      height="100%"
      width="100%"
      allow="autoplay"
      :key="src"
      :src="src"
      @load="sendSettings"
    />
  </div>
</template>

<script>
import EventBus from '../../../utils/event-bus';

export default {
  props: ['theme', 'posts', 'post'],

  data() {
    return {
      query: {},
    };
  },

  mounted() {
    this.query = window.location.search.substr(1)
      .split('&')
      .reduce((memo, param) => ({
        ...memo,
        [param.split('=')[0]]: decodeURIComponent(param.split('=')[1] || true),
      }), {});

    this.$frame = $(this.$el).find('iframe');

    EventBus.$emit('resetTimer');
    EventBus.$emit('startTimer', this.displayTime || 10000);

    this.receiveMessage = (e) => {
      if (typeof e.data !== 'string' || e.data.indexOf('tgbLive') === -1) { return; }

      const [, action, data] = e.data.split(':');

      switch (action) {
        case 'loaded': {
          this.iframeLoaded();
          break;
        }

        case 'videoDuration': {
          const duration = parseInt(data, 10);

          if (!Number.isNaN(duration)) {
            this.$store.dispatch('setVideoDuration', duration);
          }

          break;
        }

        default: break;
      }
    };

    window.addEventListener('message', this.receiveMessage, false);
  },

  watch: {
    displayTime(val) {
      EventBus.$emit('startTimer', val || 10000);
    },

    muted(newVal) {
      if (!this.$frame.length) {
        return;
      }

      const [frame] = this.$frame;

      if (newVal) {
        frame.contentWindow.postMessage('tgbLive:mute', '*');
      } else {
        frame.contentWindow.postMessage('tgbLive:unmute', '*');
      }
    },

    theme(newVal) {
      if (this.$frame.length) {
        this.$frame[0].contentWindow.postMessage(`tgbLive:settings:${JSON.stringify(newVal)}`, '*');
      }
    },
  },

  computed: {
    src() {
      const { production } = this.$store.state;
      let src = '';

      if (this.posts[0] && (this.posts[0]._id || this.posts[0].id)) {
        const tagboardID = this.posts[0]._id || this.posts[0].id;
        src += `/tagboard/${tagboardID}/theme/${this.theme._id}`;
        src += `?muted=${this.muted}`;

        if (production.display_token) {
          src += `&dt=${production.display_token}`;
        }

        if (this.post.timeline_id) {
          src += `&tid=${this.post.timeline_id}`;
        }

        if (this.disableAnimations) {
          src += '&disable_animations=true';
        }
      }

      return src;
    },

    disableAnimations() {
      return this.query.disable_animations === 'true';
    },

    displayTime() {
      return this.theme.displayTime ? this.theme.displayTime : this.theme.playDuration;
    },

    muted() {
      return this.$store.getters.isMuted;
    },

    playing() {
      return this.$store.getters.isPlaying;
    },
  },

  methods: {
    iframeLoaded() {
      this.$emit('frameloaded');
    },

    sendSettings() {
      this.$emit('frameloaded');

      if (this.$frame.length) {
        this.$frame[0].contentWindow.postMessage(`tgbLive:settings:${JSON.stringify(this.theme)}`, '*');
      }
    },
  },

  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage, true);
  },
};
</script>
