import catchify from 'catchify';
import { get } from 'lodash-es';
import twemoji from '@twemoji/api';
import MediaProxy from './media-proxy';

import { preloadImage } from './helpers';

const SOCIAL_MEDIA_CACHE_CDN_BASE_URL = (process.env.SOCIAL_MEDIA_CACHE_CDN_BASE_URL)
  ? process.env.SOCIAL_MEDIA_CACHE_CDN_BASE_URL
  : 'https://d9wxf5clf37aa.cloudfront.net';

const TGB_ASSETS_URL = process.env.TGB_ASSETS_CDN_URL;
const facebookLogoUrl = `${TGB_ASSETS_URL}/logos/facebook`;
const twitterLogoUrl = `${TGB_ASSETS_URL}/logos/twitter`;
const instagramLogoUrl = `${TGB_ASSETS_URL}/logos/instagram`;
const youtubeLogoUrl = `${TGB_ASSETS_URL}/logos/youtube`;
const tiktokLogoUrl = `${TGB_ASSETS_URL}/logos/tiktok`;
const redditLogoUrl = `${TGB_ASSETS_URL}/logos/reddit`;
const heymarketLogoUrl = `${TGB_ASSETS_URL}/logos/heymarket`;
const twitchLogoUrl = `${TGB_ASSETS_URL}/logos/twitch`;
const shareItLogoUrl = `${TGB_ASSETS_URL}/logos/share-it`;

const socialNetworkBranding = {
  facebook: {
    logos: {
      svg: {
        icons: {
          primary: `${facebookLogoUrl}/logo-facebook-primary.svg`,
          white: `${facebookLogoUrl}/logo-facebook-white.svg`,
          black: `${facebookLogoUrl}/logo-facebook-black.svg`,
        },
      },
    },
    colors: {
      primary: '#0866ff',
      secondary: '#ffffff',
    },
  },
  instagram: {
    logos: {
      svg: {
        icons: {
          primary: `${instagramLogoUrl}/logo-instagram-primary.svg`,
          white: `${instagramLogoUrl}/logo-instagram-white.svg`,
          black: `${instagramLogoUrl}/logo-instagram-black.svg`,
        },
      },
    },
    colors: {
      primary: '#FF0069',
      secondary: '#ffd600',
    },
  },
  youtube: {
    logos: {
      svg: {
        icons: {
          primary: `${youtubeLogoUrl}/logo-youtube-primary.svg`,
          white: `${youtubeLogoUrl}/logo-youtube-white.svg`,
          black: `${youtubeLogoUrl}/logo-youtube-black.svg`,
        },
      },
    },
    colors: {
      primary: '#FF0000',
      secondary: '#ffffff',
    },
  },
  twitter: {
    logos: {
      svg: {
        icons: {
          primary: `${twitterLogoUrl}/logo-x-primary.svg`,
          white: `${twitterLogoUrl}/logo-x-white.svg`,
          black: `${twitterLogoUrl}/logo-x-primary.svg`,
        },
      },
    },
    colors: {
      primary: '#000000',
      secondary: '#FFFFFF',
    },
  },
  tiktok: {
    logos: {
      svg: {
        icons: {
          primary: `${tiktokLogoUrl}/logo-tiktok-primary.svg`,
          white: `${tiktokLogoUrl}/logo-tiktok-white.svg`,
          black: `${tiktokLogoUrl}/logo-tiktok-black.svg`,
        },
      },
    },
    colors: {
      primary: '#fe2c55',
      secondary: '#25f4ee',
    },
  },
  reddit: {
    logos: {
      svg: {
        icons: {
          primary: `${redditLogoUrl}/logo-reddit-primary.svg`,
          white: `${redditLogoUrl}/logo-reddit-white.svg`,
          black: `${redditLogoUrl}/logo-reddit-black.svg`,
        },
      },
    },
    colors: {
      primary: '#FF4500',
      secondary: '#FFFFFF',
    },
  },
  heymarket: {
    logos: {
      svg: {
        icons: {
          primary: `${heymarketLogoUrl}/logo-heymarket-primary.svg`,
          white: `${heymarketLogoUrl}/logo-heymarket-white.svg`,
          black: `${heymarketLogoUrl}/logo-heymarket-black.svg`,
        },
      },
    },
    colors: {
      primary: '#000000',
      secondary: '#ffc926',
    },
  },
  twitch: {
    logos: {
      svg: {
        icons: {
          primary: `${twitchLogoUrl}/logo-twitch-primary.svg`,
          white: `${twitchLogoUrl}/logo-twitch-white.svg`,
          black: `${twitchLogoUrl}/logo-twitch-black.svg`,
        },
      },
    },
    colors: {
      primary: '#9146FF',
      secondary: '#F0F0FF',
    },
  },
  // 'share-it': {
  //   logos: {
  //     svg: {
  //       icons: {
  //         primary: `${shareItLogoUrl}/logo-share-it-primary.svg`,
  //         white: `${shareItLogoUrl}/logo-share-it-white.svg`,
  //         black: `${shareItLogoUrl}/logo-share-it-black.svg`,
  //       },
  //     },
  //   },
  //   colors: {
  //     primary: '#00B4CC',
  //     secondary: '#FFFFFF',
  //   },
  // },
};

/**
 * Determines if the supplied url is already social media cache cdn.
 * @param {String} url
 * @param {String} network
 * @param {String} postId - The external id of the social network post.
 * @returns {boolean}
 */
function urlIsCached(url, network, postId) {
  return !!(url && url.length && url.includes(`/${network}/${postId}/`));
}

/**
 * Determines if a feed thumbnail may still exist in the cache or if it has been expired.
 * Note: Feed thumbnails are expired after 60 days. Reference: https://github.com/Tagboard/infrastructure/blob/790a00427053d70e15c9c7f3594646c858ef0c54/core/s3/social-media-cache.ts#L71-L79
 * @param {Number} thumbnailCached - The timestamp (in ms) of when a feed thumbnail was cached.
 * @returns {boolean}
 */
function thumbnailIsStillCached(thumbnailCached) {
  if (!(thumbnailCached > 0)) {
    return false;
  }
  const SixtyDaysInMs = 5184e6;
  return thumbnailCached > (new Date().getTime() - SixtyDaysInMs);
}

/**
 * Determines if a featured/curated post has cached media.
 * @param {Object} post
 * @param {Number} post.mediaCached
 * @param {String} post.post_type
 * @returns {boolean}
 */
function hasInstagramCachedMediaUrl(post) {
  const { post_type: postType, mediaCached } = post || {};
  return !!mediaCached && ['photo', 'video'].includes(postType);
}

/**
 * Returns the cached media url from a post.
 * @param {Object} post - An Instagram post from the Story api.
 * @param size
 * @returns {string|null}
 */
function instagramCachedMediaUrl(post, size) {
  const { post_type: postType, videos } = post || {};

  if (size === 'video' && postType === 'video' && (videos && videos.length)) {
    return videos[0].m;
  }

  let cachedMediaUrl;
  const mediaObject = post[`${postType}s`][0] || {};

  if (size === 't') {
    cachedMediaUrl = (postType === 'photo')
      ? mediaObject.s // small photo
      : mediaObject.t; // video thumbnail
  }

  if (size === 'm') {
    cachedMediaUrl = (postType === 'video')
      ? mediaObject.m // video file
      : mediaObject.m; // medium photo
  }

  if (size === 'l') {
    cachedMediaUrl = (postType === 'video')
      ? mediaObject.p // video poster image
      : mediaObject.l; // large photo
  }

  if (!cachedMediaUrl && mediaObject[size]) {
    cachedMediaUrl = mediaObject[size];
  }

  // It's fine if this falls back to a non-cached url, that can be handled by the invoking method.
  return cachedMediaUrl;
}

function hasInstagramCachedFeedThumbnail(post, size) {
  const { post_type: postType, thumbnailCached } = post || {};
  const hasCachedFeedMedia = thumbnailCached && thumbnailIsStillCached(thumbnailCached);
  const thumbnailCachedPhoto = hasCachedFeedMedia && postType === 'photo' && size === 's';
  const thumbnailCachedVideo = hasCachedFeedMedia && postType === 'video' && size === 't';
  return thumbnailCachedPhoto || thumbnailCachedVideo;
}

/**
 * Determines the implicit Tagboard CDN url path for a "Feed" post thumbnail.
 * Note: Assumes implicit Tagboard CDN url path as
 *       Bottle Brush tends to overwrite the CDN url during updates.
 * @param post
 * @returns {string}
 */
function instagramCachedFeedThumbnail(post) {
  const { post_id: postId, post_type: postType } = post || {};
  const fileName = (postType === 'video') ? 'videoThumbnail' : 'small';
  return `${SOCIAL_MEDIA_CACHE_CDN_BASE_URL}/feed/instagram/${postId}/${fileName}.jpeg`;
}

function getInstagramCdnMediaSize(requestedSize) {
  const validIgCdnMediaSizes = [
    'l', // Full sized original image
    'm', // Medium preview/poster image (320px wide)
    't', // Small thumbnail image. (150px x 150px)
  ];

  if (validIgCdnMediaSizes.includes(requestedSize)) {
    return requestedSize;
  }

  if (requestedSize === 's') {
    return 't';
  }

  return 'l';
}

function instagramCdnMediaUrl(post, size, skipProxy = false) {
  const {
    permalink,
    post_type: postType,
    service_meta: serviceMeta,
    videos,
  } = post || {};
  const { shortcode } = serviceMeta || {};

  // Generate a url to the IG endpoint that resolves to the current IG CDN media url
  const baseUrl = shortcode ? `https://www.instagram.com/p/${shortcode}/` : permalink;
  const mediaSize = getInstagramCdnMediaSize(size);
  let igMediaUrl = `${baseUrl}media/?size=${mediaSize}`;

  if (size === 'video' && postType === 'video' && (videos && videos.length)) {
    // Return stored video url as the IG endpoint does not support actual videos
    igMediaUrl = videos[0].m;
  }

  if (skipProxy === true) {
    return igMediaUrl;
  }

  return MediaProxy.genUrl(igMediaUrl);
}

/**
 * Returns an Instagram "Media" proxy url that resolves to the current CDN url for the media.
 * Note: Only works for the first image/video on a post.
 * Note: For video posts it only returns the still preview images, not the video itself.
 * @param {Object} post - An Instagram post from the Story api.
 * @param {"l" | "m" | "t" | "s" | "p" | "video"} [size] - Size of the media. Defaults to the Full-Sized original.
 * @param {Boolean} [skipProxy] - Returns the raw, non-proxied URL. Instagram urls will throw CORS errors the proxy.
 * @returns {string|undefined}
 */
function instagramMediaUrl(post, size, skipProxy = false) {
  const { network, post_id: postId } = post || {};
  if (network !== 'instagram') {
    throw new Error(`post_id: ${postId} ${network} is not an Instagram post`);
  }

  // Featured/Curated Post Cached Media
  if (hasInstagramCachedMediaUrl(post)) {
    const cachedMediaUrl = instagramCachedMediaUrl(post, size);
    if (urlIsCached(cachedMediaUrl, network, postId)) {
      return cachedMediaUrl;
    }
  }

  // Feed Post Cached Thumbnails
  if (hasInstagramCachedFeedThumbnail(post, size)) {
    return instagramCachedFeedThumbnail(post);
  }

  // Fall back to the Instagram CDN
  return instagramCdnMediaUrl(post, size, skipProxy);
}

/**
 * Get image, video, and videoPoster from social post if they exist.
 * @param {Object} post - Social media post
 * @returns {Object} ret - Contains image, video, and videoPoster keys
 */
function getPostMedia(post) {
  let image;

  const {
    photos,
    videos,
    network,
    post_id: postId,
  } = post;

  if (photos?.length) {
    image = photos[0].l || photos[0].m;
    if (network === 'instagram') {
      image = instagramMediaUrl(post, 'l');
    }

    image = MediaProxy.genUrl(image) || image;
  }

  let videoPoster;
  let video;

  if (videos?.length) {
    [video] = videos;
    videoPoster = video.p;

    switch (network) {
      case 'facebook': {
        // Build the facebook video URL
        const pid = postId.split('_');
        const baseUrl = 'https://www.facebook.com/watch?v=';
        video = baseUrl + pid[1] || pid[0];
        break;
      }

      case 'instagram': {
        // Fancy proxy stuff
        videoPoster = instagramMediaUrl(post, 'l');
        video = instagramMediaUrl(post, 'video');
        break;
      }

      default: {
        // Just destructure the video out
        video = video.m;
        break;
      }
    }
  }

  return {
    image,
    video,
    videoPoster,
    hasMedia: !!(image || video),
  };
}

/**
 * Check post for photos and preload those images,
 * so the browser has them cached ahead of time.
 * @param {Object} post - Social post
 * @returns {Promise<void>} Promise that resolves when media loads.
 */
async function preloadPostMedia(post) {
  const { image } = getPostMedia(post);

  if (image) {
    const [err] = await catchify(
      preloadImage(image),
    );

    if (err) {
      // Print the error, but don't let function throw an error
      console.error('Failed to preload image:', image, err);
    }
  }
}

/**
 * Preload media for all provided posts.
 * @param {Array<Object>} posts - List of social posts
 * @returns {Promise<void>} Promise that resolves when all media loads.
 */
export async function preloadPosts(posts) {
  await Promise.all(
    posts.map((post) => preloadPostMedia(post)),
  );
}

/**
 * Mapping logic for social data
 * @param {Object} opts.post - Social Post
 * @param {Object} opts.element - Graphic Element
 * @param {Object} opts.item - Timeline Item
 * @returns {string|undefined|null}
 */
export const getSocialDataValue = (opts = {}) => {
  const {
    post,
    element,
    property,
  } = opts;

  if (!post) return undefined; // avoid mapping errors when post is undefined or missing

  const map = element?.data?.dataKeyMap || {};

  const {
    title,
    network,
  } = post;

  const {
    image,
    video,
    videoPoster,
  } = getPostMedia(post);

  switch (map[property]) {
    case 'user_name': {
      if (post.user_name && post.user_name !== '') {
        const atMention = network === 'share-it' ? '' : '<span class="tgb-social-at-mention">@</span>';
        const userName = post.user_name;
        return `<div>${atMention}${userName}</div>`;
      }
      return '';
    }

    case 'user_real_name': {
      const verifiedIcon = (color) => `&nbsp<span
        class="fas fa-badge-check tgb-social-verified-icon" style="color:${color}"></span>`;
      const verifiedTypeColors = {
        business: '#e6c134',
        government: '#819baa',
      };
      const verified = post.service_meta?.user_verified;
      const verifiedType = post.service_meta?.verified_type;
      const color = verifiedTypeColors[verifiedType] || 'rgb(29, 155, 240)';
      const realUserName = post.user_real_name || '';
      return `<div>${realUserName} ${verified ? verifiedIcon(color) : ''}</div>`;
    }

    case 'html': {
      let postText = post.html || post.text;
      try {
        postText = twemoji.parse(postText);
      } catch (error) {
        return '';
      }

      // This extra div is needed to make the text wrap correctly when auto resizing.
      // Otherwise the code for auto resizing will inject divs around all the
      // standalone text nodes. It also gives us a class for styling the text.
      postText = `<div class="social-ddg-text--html social-ddg-text--${post.network}">${postText}</div>`;
      if (post.network === 'reddit') {
        postText = `<div class="social-ddg-text--title">${title}</div>${postText || ''}`;
      }

      return postText;
    }

    // Video or image
    case 'media': {
      if (post.videos) {
        if (property === 'src') return video;
        if (property === 'isVideo') return true;
        if (property === 'poster') return videoPoster;
      } else {
        if (property === 'src') return image || null;
        if (property === 'isVideo') return false;
        if (property === 'poster') return null;
      }
      return null;
    }

    case 'user_profile_image_url': {
      let profileImage = post.user_profile_image_url || null;

      if (profileImage) {
        if (post.network === 'twitter') {
          profileImage = profileImage.replace('_normal', '_200x200');
        } else if (post.network === 'facebook' && profileImage.includes('graph.facebook.com')) {
          profileImage = `${profileImage.split('?')[0]}?type=large`;
        }
      }

      return profileImage;
    }

    default: {
      return get(socialNetworkBranding[network], map[property]) || null;
    }
  }
};

export default {
  getPostMedia,
  getSocialDataValue,
  instagramCachedMediaUrl,
  instagramMediaUrl,
  preloadPostMedia,
  preloadPosts,
  socialNetworkBranding,
};
