<template>
  <div v-bind:class="getThemeClass" class="theme-wrapper">
    <div class="fullscreen-wrapper" v-bind:style="{'background-color' : theme.settings.backgroundColor}" v-bind:class="['font-' + theme.settings.font, 'theme-' + theme.settings.theme, 'color-theme-' + theme.settings.colorTheme, {'has-banner' : theme.settings.showBanner}, {'has-network-bar' : theme.settings.showNetworkBar}]">
      <div class="display-wrapper">

        <div v-if="theme.settings.backgroundImage || theme.settings.backgroundVideo" v-bind:class="{ 'darkened-background': theme.settings.darkenBackground }" class="backdrop" v-bind:style="[theme.settings.backgroundImage !== '' ? { backgroundImage: 'url(' + theme.settings.backgroundImage + ')' } : '']">
          <video v-if="theme.settings.backgroundVideo" v-bind:src="theme.settings.backgroundVideo" class="background-video" width="auto" autoplay loop muted></video>
          <div class="background-overlay"></div>
        </div>

        <template v-if="theme.settings.theme !== 'default'">
          <div v-if="theme.settings.theme === 'phone_light' || theme.settings.theme === 'phone_dark'" class="device">
            <div class="device-top">
              <div class="camera"></div>
              <div class="speaker"></div>
            </div>

            <div class="posts">
              <div v-if="theme.settings.showBanner" class="banner" v-bind:style="[theme.settings.colorTheme === 'custom' ? {'background-color' : theme.settings.bannerColor} : '']">

                <div v-if="theme.settings.showNetworkBar" class="network-bar" v-bind:style="[theme.settings.colorTheme === 'custom' ? {'color' : theme.settings.networkBarColor} : '']">
                  <span class="signal icon icon-signal"></span>
                  <span class="service-provider">{{theme.settings.serviceProvider}}</span>
                  <span class="battery icon icon-battery"></span>
                </div>

                <div class="banner-avatar" v-bind:style="[theme.settings.colorTheme === 'custom' && theme.settings.bannerAvatarStyle === 'initials' ? {'background-color' : theme.settings.bannerAvatarColor} : '']">
                  <div v-if="theme.settings.bannerAvatarStyle === 'initials'" class="banner-avatar-initials" v-bind:style="[theme.settings.colorTheme === 'custom' ? {'color' : theme.settings.bannerAvatarInitialsColor} : '']">{{theme.settings.bannerAvatarInitials}}</div>
                  <div v-if="theme.settings.bannerAvatarStyle === 'avatar'" class="banner-avatar-image" v-bind:style="{ 'background-image' : getBannerAvatarImage }"></div>
                </div>

                <div class="banner-title" v-bind:style="[theme.settings.colorTheme === 'custom' ? {'color' : theme.settings.bannerTitleColor} : '']">
                  <div class="title-text">{{theme.settings.bannerTitle}}</div>
                </div>

              </div>

              <transition-group v-on:after-enter="afterEnter" v-on:after-leave="afterEnter" v-bind:style="[theme.settings.colorTheme === 'custom' ? {'background-color' : theme.settings.chatColor} : '']" class="posts-wrapper" name="list" tag="div">
                <post v-for="(postData, index) in oldPosts" v-bind:key="postData.post_id" :theme="theme" :post="postData"></post>
              </transition-group>

            </div>

            <div class="device-bottom">
              <div class="home"></div>
            </div>
          </div>

          <div v-if="theme.settings.theme === 'cupertino_light' || theme.settings.theme === 'cupertino_dark'" class="device">
            <div class="sleep"></div>
            <div class="volume">
              <div class="up"></div>
              <div class="down"></div>
            </div>

            <div class="posts">

              <div v-if="theme.settings.showBanner" class="banner" v-bind:style="[theme.settings.colorTheme === 'custom' ? {'background-color' : theme.settings.bannerColor} : '']">

                <div v-if="theme.settings.showNetworkBar" class="network-bar" v-bind:style="[theme.settings.colorTheme === 'custom' ? {'color' : theme.settings.networkBarColor} : '']">
                  <span class="signal icon icon-signal"></span>
                  <span class="service-provider">{{theme.settings.serviceProvider}}</span>
                  <span class="battery icon icon-battery"></span>
                </div>

                <div class="banner-avatar" v-bind:style="[theme.settings.colorTheme === 'custom' && theme.settings.bannerAvatarStyle === 'initials' ? {'background-color' : theme.settings.bannerAvatarColor} : '']">
                  <div v-if="theme.settings.bannerAvatarStyle === 'initials'" class="banner-avatar-initials" v-bind:style="[theme.settings.colorTheme === 'custom' ? {'color' : theme.settings.bannerAvatarInitialsColor} : '']">{{theme.settings.bannerAvatarInitials}}</div>
                  <div v-if="theme.settings.bannerAvatarStyle === 'avatar'" class="banner-avatar-image" v-bind:style="{ 'background-image' : getBannerAvatarImage }"></div>
                </div>

                <div class="banner-title" v-bind:style="[theme.settings.colorTheme === 'custom' ? {'color' : theme.settings.bannerTitleColor} : '']">
                  <div class="title-text">{{theme.settings.bannerTitle}}</div>
                </div>

              </div>

              <transition-group v-on:after-enter="afterEnter" v-on:after-leave="afterLeave" v-bind:style="[theme.settings.colorTheme === 'custom' ? {'background-color' : theme.settings.chatColor} : '']" class="posts-wrapper" name="list" tag="div">
                <post v-for="(postData, index) in oldPosts" v-bind:key="postData.post_id" :theme="theme" :post="postData"></post>
              </transition-group>

            </div>

            <div class="notch">
              <div class="notch-curve-left"></div>
              <div class="notch-curve-right"></div>
              <div class="camera"></div>
              <div class="speaker"></div>
            </div>
          </div>

          <div v-if="theme.settings.theme === 'mountain_view_light' || theme.settings.theme === 'mountain_view_dark'" class="device">
            <div class="device-top">
              <div class="camera"></div>
              <div class="speaker"></div>
            </div>

            <div class="sleep"></div>
            <div class="volume"></div>

            <div class="posts">

              <div v-if="theme.settings.showBanner" class="banner" v-bind:style="[theme.settings.colorTheme === 'custom' ? {'background-color' : theme.settings.bannerColor} : '']">

                <div v-if="theme.settings.showNetworkBar" class="network-bar" v-bind:style="[theme.settings.colorTheme === 'custom' ? {'color' : theme.settings.networkBarColor} : '']">
                  <span class="signal icon icon-signal"></span>
                  <span class="service-provider">{{theme.settings.serviceProvider}}</span>
                  <span class="battery icon icon-battery"></span>
                </div>

                <div class="banner-avatar" v-bind:style="[theme.settings.colorTheme === 'custom' && theme.settings.bannerAvatarStyle === 'initials' ? {'background-color' : theme.settings.bannerAvatarColor} : '']">
                  <div v-if="theme.settings.bannerAvatarStyle === 'initials'" class="banner-avatar-initials" v-bind:style="[theme.settings.colorTheme === 'custom' ? {'color' : theme.settings.bannerAvatarInitialsColor} : '']">{{theme.settings.bannerAvatarInitials}}</div>
                  <div v-if="theme.settings.bannerAvatarStyle === 'avatar'" class="banner-avatar-image" v-bind:style="{ 'background-image' : getBannerAvatarImage }"></div>
                </div>

                <div class="banner-title" v-bind:style="[theme.settings.colorTheme === 'custom' ? {'color' : theme.settings.bannerTitleColor} : '']">
                  <div class="title-text">{{theme.settings.bannerTitle}}</div>
                </div>

              </div>

              <transition-group v-on:after-enter="afterEnter" v-on:after-leave="afterEnter" v-bind:style="[theme.settings.colorTheme === 'custom' ? {'background-color' : theme.settings.chatColor} : '']" class="posts-wrapper" name="list" tag="div">
                <post v-for="(postData, index) in oldPosts" v-bind:key="postData.post_id" :theme="theme" :post="postData"></post>
              </transition-group>

            </div>

            <div class="device-bottom">
              <div class="home"></div>
            </div>
          </div>
        </template>

        <template v-else>

          <div class="posts">

            <div v-if="theme.settings.showBanner" class="banner" v-bind:style="[theme.settings.colorTheme === 'custom' ? {'background-color' : theme.settings.bannerColor} : '']">

              <div v-if="theme.settings.showNetworkBar" class="network-bar" v-bind:style="[theme.settings.colorTheme === 'custom' ? {'color' : theme.settings.networkBarColor} : '']">
                <span class="signal icon icon-signal"></span>
                <span class="service-provider">{{theme.settings.serviceProvider}}</span>
                <span class="battery icon icon-battery"></span>
              </div>

              <div class="banner-avatar" v-bind:style="[theme.settings.colorTheme === 'custom' && theme.settings.bannerAvatarStyle === 'initials' ? {'background-color' : theme.settings.bannerAvatarColor} : '']">
                <div v-if="theme.settings.bannerAvatarStyle === 'initials'" class="banner-avatar-initials" v-bind:style="[theme.settings.colorTheme === 'custom' ? {'color' : theme.settings.bannerAvatarInitialsColor} : '']">{{theme.settings.bannerAvatarInitials}}</div>
                <div v-if="theme.settings.bannerAvatarStyle === 'avatar'" class="banner-avatar-image" v-bind:style="{ 'background-image' : getBannerAvatarImage }"></div>
              </div>

              <div class="banner-title" v-bind:style="[theme.settings.colorTheme === 'custom' ? {'color' : theme.settings.bannerTitleColor} : '']">
                <div class="title-text">{{theme.settings.bannerTitle}}</div>
              </div>

            </div>

            <transition-group v-on:after-enter="afterEnter" v-on:after-leave="afterEnter" v-bind:style="[theme.settings.colorTheme === 'custom' ? {'background-color' : theme.settings.chatColor} : '']" class="posts-wrapper" name="list" tag="div">
              <post v-for="(postData, index) in oldPosts" v-bind:key="postData.post_id" :theme="theme" :post="postData"></post>
            </transition-group>

          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {
  cloneDeep, map, last, difference,
} from 'lodash-es';

import ChatPostComponent from './ChatPost.vue';
import AnimationMixins from '../../../../mixins/animation-mixins';
import LayoutMixins from '../../../../mixins/layout-mixins';

export default {
  props: ['posts', 'theme', 'post'],
  components: {
    post: ChatPostComponent,
  },
  mixins: [
    LayoutMixins,
    AnimationMixins,
  ],
  data() {
    return {
      timerId: null,
      start: null,
      remaining: null,
      oldPosts: cloneDeep(this.posts),
    };
  },

  computed: {
    playing() {
      return this.$store.getters.isPlaying;
    },

    getBannerAvatarImage() {
      return this.theme.settings.bannerAvatarImage ? `url(${this.theme.settings.bannerAvatarImage})` : 'url(/live/assets/img/logo-hash-t-white.svg)';
    },

    getBannerLogo() {
      return this.theme.settings.bannerLogo ? `url(${this.theme.settings.bannerLogo})` : 'url(/live/assets/img/logo-tagboard-brand.svg)';
    },
  },

  mounted() {
    this.remaining = this.theme.settings.displayTime;

    if (this.playing) {
      this.startTimer();
    }

    if (parent && parent.iframeLoaded) {
      parent.iframeLoaded();
    }
  },

  methods: {
    stopTimer() {
      if (this.timerId) {
        window.clearRequestTimeout(this.timerId);
      }

      this.remaining -= new Date() - this.start;
    },
    startTimer() {
      if (this.timerId) {
        window.clearRequestTimeout(this.timerId);
      }

      this.start = new Date();

      this.timerId = window.requestTimeout(() => {
        this.advance();
      }, this.remaining);
    },
    afterEnter() {
      this.remaining = this.theme.settings.displayTime;

      if (this.playing) {
        this.startTimer();
      }
    },
    afterLeave() {
      this.oldPosts.splice(0, 0, last(this.oldPosts));
    },
    advance() {
      const newPosts = [];
      const oldIds = map(this.oldPosts, 'post_id');
      const allIds = map(this.posts, 'post_id');
      const diff = difference(allIds, oldIds);

      this.posts.forEach((post) => {
        if (diff.indexOf(post.post_id) !== -1) {
          newPosts.push(post);
        }
      });

      if (!newPosts.length && this.theme.settings.loopContent) {
        this.oldPosts.splice(-1);
      } else if (newPosts.length) {
        this.oldPosts.splice(0, 0, newPosts.shift());
      }

      if (!newPosts.length && !this.theme.settings.loopContent) {
        this.remaining = this.theme.settings.displayTime;

        if (this.playing) {
          this.startTimer();
        }
      }
    },
  },

  watch: {
    playing(newVal) {
      if (newVal) {
        this.startTimer();
      } else {
        this.stopTimer();
      }
    },
  },

  beforeDestroy() {
    if (this.timerId) {
      window.clearRequestTimeout(this.timerId);
    }
  },
};
</script>
